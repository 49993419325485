import React from 'react';

export default {
  viewBox: '0 0 307 255',
  paths: (
    <>
      <path
        d="m31 223 49-74-49-65 49-53h148l47 53-47 65 47 74H31Z"
        fill="#8e8e8e"
      />
      <path
        d="m110 84 43-51 41 51-41 53-43-53Zm11 123 32-45 33 45h-65Z"
        stroke="#d4d4d4"
        strokeWidth="17.9"
      />
      <path
        d="m31 223 49-74-49-65 49-53h148l47 53-47 65 47 74H31Z"
        fill="none"
        stroke="#000"
        strokeWidth="17.9"
      />
    </>
  ),
};
