import styled from 'styled-components';
import css from '@styled-system/css';
import { Button, Container, Modal } from 'ui/core';

export const SkillSelectContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({ justifyContent: 'center', width: '100%' })}
`;

export const PickSkillButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    width: '100%',
    borderRadius: 'large',
    border: '1px solid',
    borderColor: 'indicatorBorder',
  })}
`;

export const SelectedSkillCancelButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 'xSmall',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    textTransform: 'capitalize',
    letterSpacing: 0,
    ':hover:enabled': {
      backgroundColor: 'warning',
    },
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const ButtonTextSpan = styled.span`
  ${css({
    flex: 1,
  })}
`;

export const SkillSelectWrapper = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    margin: 'tiny',
    width: '100%',
    alignItems: 'center',
    marginX: 'xSmall',
  })}
`;

export const SelectedSkillContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    margin: 'tiny',
    marginX: 'xSmall',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  })}
`;

export const WeaponModalHack = styled(Modal)`
  ${css({
    width: '100%',
  })}
`;
