import styled from 'styled-components';
import css from '@styled-system/css';
import { Span, Text, Container } from 'ui/core';

export const ShotNamePill = styled(Span)<{ isEnabled: boolean }>`
  ${({ isEnabled }) =>
    css({
      backgroundColor: 'decoBackground',
      borderRadius: 'medium',
      paddingX: 'tiny',
      marginRight: 'tiny',
      border: '1px solid transparent',
      fontSize: 'tiny',
      display: 'inline-block',
      textAlign: 'center',
      opacity: isEnabled ? '100%' : '25%',
    })}
  ':hover': {
    ${css({
      transition: 'all .2s ease',
      border: '1px solid',
      borderColor: 'border',
    })}
  }
`;

export const ShotsContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: 'tiny',
  })}
`;

export const ArcShotText = styled(Text)`
  ${css({
    fontSize: 'xSmall',
  })}
`;
