import React, { useEffect, useState } from 'react';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';

import { Icon, Select, Container } from 'ui/core';
import {
  PetalaceStatContainer,
  PetalaceControlsContainer,
  PetalaceStatsContainer,
} from './styled';

import { petalaces } from './petalaces';
import { AnimatePresence } from 'framer-motion';

// TODO: link to store
interface PetalaceProps {}
export const Petalace: React.FC<PetalaceProps> = ({}) => {
  const { loadout, setPetalace: setSelectedPetalace } = useLoadoutStore(
    (state) => ({ loadout: state.loadout, setPetalace: state.setPetalace }),
    shallow
  );

  const selectedPetalace = loadout.Petalace;

  const petalace = selectedPetalace || petalaces[0];

  useEffect(() => {
    if (selectedPetalace === null) {
      setSelectedPetalace(petalace);
    }
  });

  const {
    name,
    healthUp,
    healthGained,
    staminaUp,
    staminaGained,
    attackUp,
    attackGained,
    defenseUp,
    defenseGained,
  } = petalace;

  const topRow = [healthUp, staminaUp, attackUp, defenseUp];
  const topRowIcons = ['Health', 'Stamina', 'Attack', 'Defense'];
  const bottomRow = [healthGained, staminaGained, attackGained, defenseGained];

  const createTopRow = () => {
    return topRow.map((stat: number, index: number) => {
      const iconName = topRowIcons[index];
      return (
        <PetalaceStatContainer
          key={stat + iconName + petalace.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Icon name={iconName as any} />
          {stat}
        </PetalaceStatContainer>
      );
    });
  };

  const createBottomRow = () => {
    return bottomRow.map((stat: number, index: number) => {
      const keyName = topRowIcons[index];
      return (
        <PetalaceStatContainer
          key={keyName + '_bottom_' + petalace.name}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Icon name="PlusCircle" />
          {stat}
        </PetalaceStatContainer>
      );
    });
  };

  const icon = <Icon name="Petalace" />;
  return (
    <>
      <PetalaceControlsContainer>
        <Select
          options={petalaces}
          selectedOption={selectedPetalace}
          setSelectedOption={setSelectedPetalace}
          icon={icon}
          width="100%"
          fontSize={['small', null, 'medium', null, null]}
        />
      </PetalaceControlsContainer>
      <PetalaceStatsContainer>
        {createTopRow()}
        {createBottomRow()}
      </PetalaceStatsContainer>
    </>
  );
};
