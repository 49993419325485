import styled from 'styled-components';
import css from '@styled-system/css';

import { Button, Container, Text } from 'ui/core';

export const ImportLoadoutContainer = styled(Container)`
  ${css({
    padding: 'medium',
    display: 'flex',
    flexDirection: 'column',
  })}
`;

export const ImportButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    width: 'fit-content',
    margin: 0,
    paddingX: 'small',
  })}
`;

export const ImportFormContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    paddingY: 'medium',
  })}
`;

export const ImportHelperText = styled(Text)`
  ${css({
    fontSize: 'tiny',
  })}
`;

export const ImportForm = styled.form``;

export const ImportInput = styled.input`
  ${css({
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'border',
    padding: 'small',
    fontSize: 'small',
    marginLeft: 'tiny',
    backgroundColor: 'detailsBackground',
    color: 'text',
    '::placeholder': {
      color: 'text',
      opacity: 0.5,
    },
    width: '32ch',
  })}
`;
