import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const NavBarContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  })}
`;

export const NavBarItem = styled(Text)`
  ${css({
    margin: 'medium',
  })}
`;
