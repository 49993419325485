import React from 'react';

import {
  WeaponGearContainer,
  ArmorGearContainer,
  Accessories,
} from 'components';
import { SimpleListContainer } from './styled';

import { ArmorTypeValues } from 'models/enums';

interface GearsetCardProps {
  mobileSelection?: any;
}

export const GearsetCard: React.FC<GearsetCardProps> = ({
  mobileSelection = null,
}) => {
  const armorSet = ArmorTypeValues.map((armorType) => {
    return (
      <ArmorGearContainer
        key={armorType}
        armorType={armorType}
      ></ArmorGearContainer>
    );
  });

  const renderSelectedSlot = () => {
    switch (mobileSelection) {
      case 'LongSword':
        return <WeaponGearContainer />;
      case 'Head':
      case 'Chest':
      case 'Gloves':
      case 'Waist':
      case 'Feet':
        return (
          <ArmorGearContainer
            key={mobileSelection}
            armorType={mobileSelection}
          />
        );
      case 'Talisman':
        return <Accessories />;
      // case 'Overview':
      //   return <SimpleListContainer></SimpleListContainer>;
      default:
        return <WeaponGearContainer />;
    }
  };

  return mobileSelection ? (
    renderSelectedSlot()
  ) : (
    <>
      <WeaponGearContainer></WeaponGearContainer>
      {armorSet}
      <Accessories></Accessories>
    </>
  );
};
