import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M12.4312 22.6379C17.954 22.6379 22.4312 18.1608 22.4312 12.6379C22.4312 7.11506 17.954 2.63791 12.4312 2.63791C6.9083 2.63791 2.43115 7.11506 2.43115 12.6379C2.43115 18.1608 6.9083 22.6379 12.4312 22.6379Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52124 9.63791C9.75634 8.96957 10.2204 8.40601 10.8312 8.04704C11.442 7.68807 12.1601 7.55684 12.8584 7.67662C13.5567 7.79639 14.1901 8.15943 14.6463 8.70143C15.1026 9.24344 15.3523 9.92943 15.3512 10.6379C15.3512 12.6379 12.3512 13.6379 12.3512 13.6379"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4312 17.6379H12.4412"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
};
