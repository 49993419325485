import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M20 2H8.44444V4.72727H15.5556L4 15.6364H10.2222L4 22H8L16.4444 12.9091H11.1111L17.3333 7V13.8182H20V2Z"
        fill="#FDED5D"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="7.68167"
      />
    </>
  ),
};
