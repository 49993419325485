import React from 'react';

export default {
  viewBox: '0 0 307 255',
  paths: (
    <>
      <path
        d="m37 221 49-74-49-65 49-53h148l47 53-47 65 47 74H37Z"
        fill="#8e8e8e"
      />
      <g stroke="#d4d4d4">
        <path
          d="m190 214 32-46 33 46h-65ZM52 214l32-45 34 45H52ZM122 214l32-46 33 46h-65ZM96 154h117l53-69-47-50H90L41 85l55 69Z"
          strokeWidth="17.9"
        />
        <path
          d="M184 148h-55L78 87l51-56 27 4 28-4 47 56-47 61Z"
          strokeWidth="8.8"
        />
        <path d="m112 88 44-48 42 48-42 57-44-57Z" strokeWidth="8.8" />
      </g>
      <path
        d="m31 223 49-74-49-65 49-53h148l47 53-47 65 47 74H31Z"
        fill="none"
        stroke="#000"
        strokeWidth="17.9"
      />
    </>
  ),
};
