import { Text } from 'ui/core';
import { matchSorter } from 'match-sorter';

import { NameFilter } from './components/filters';

const defaultColumn = {
  Filter: () => <></>,
};

const skillColumns = [
  {
    id: 'name',
    Header: 'SKILL',
    accessor: 'name',
    width: 50,
  },
  {
    id: 'description',
    Header: 'DESCRPTION',
    accessor: (originalRow: any) => {
      return originalRow.description;
    },
    Cell: ({ value }: { value: any }) => {
      return <Text fontSize="xSmall">{value}</Text>;
    },
  },
];

const mobileSkillColumns = [
  {
    id: 'name',
    Header: 'SKILL',
    accessor: 'name',
    width: 50,
  },
];

const initialGlobalFilterState = [
  {
    id: 'name',
    value: '',
  },
  {
    id: 'description',
    value: '',
  },
];

const globalFilterResolver = (
  rows: any,
  columnIds: any,
  globalFilterValue: any
) => {
  const nameMatches = matchSorter(rows, globalFilterValue, {
    keys: [
      (row: any) => row.original.name,
      (row: any) => row.original.appliedSkills.map((skill: any) => skill.name),
    ],
    // keys: ['original.name', 'original.appliedSkills.*.name'],
  });

  return globalFilterValue === '' ? rows : nameMatches;
};

const getGlobalFilterComponent = (setFilter: any) => {
  return <NameFilter setFilter={setFilter}></NameFilter>;
};

export const getSkillProperties = () => {
  const baseSkillOptions = {
    defaultColumn,
    initialFilterState: [],
    initialGlobalFilterState,
  };

  return {
    getColumns: () => skillColumns,
    getMobileColumns: () => mobileSkillColumns,
    getOptions: () => ({
      ...baseSkillOptions,
    }),
    getFilterComponents: (
      setFilter: any,
      setAllFilters: any,
      filterValues: any
    ) => <></>,
    getGlobalFilterComponent: (setFilter: any) =>
      getGlobalFilterComponent(setFilter),
  };
};
