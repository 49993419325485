import React, { useState } from 'react';

import {
  GenericStatDetails,
  DecorationsContainer,
  GearControlsAndTitle,
} from 'components';
import {
  ArmorDetailsContainer,
  SimpleSkillsContainer,
  SkillDetail,
  ArmorBodyContainer,
  StatsAndSkillsContainer,
  ArmorDecorationContainer,
  ArmorViewControls,
  ArmorViewButtonContainer,
} from './styled';

import { getSkillRomanNumeral } from 'utils/formatting';
import { ArmorType, Rarity } from 'models/enums';
import { Armor } from 'models';
import { Icon, Tooltip } from 'ui/core';
import { AnimatePresence } from 'framer-motion';

interface ArmorDetailsProps {
  selectedArmor: Armor;
  onCancelArmor: any;
  armorType: ArmorType;
  isMobile?: boolean;
}

export const ArmorDetails: React.FC<ArmorDetailsProps> = ({
  selectedArmor,
  onCancelArmor,
  armorType,
  isMobile = false,
}) => {
  const { appliedSkills } = selectedArmor;

  const [currentView, setCurrentView] = useState<'stats' | 'decorations'>(
    'stats'
  );

  // is this the cleanest way?
  const createSimpleSkills = () => {
    return appliedSkills.map((appliedSkill) => {
      const { name, appliedSkillLevel, maxSkillLevel, skillLevels } =
        appliedSkill;
      const abbreviatedName = name
        .split(' ')
        .map((text: string) => {
          return text === 'Resistance' ? 'Res.' : text;
        })
        .join(' ');
      const currentSkillLevel = Math.min(appliedSkillLevel, maxSkillLevel) + 1;
      return (
        <Tooltip
          content={
            skillLevels[Math.min(appliedSkillLevel, maxSkillLevel)].effect
          }
        >
          <SkillDetail>
            {abbreviatedName} {getSkillRomanNumeral(currentSkillLevel)}
          </SkillDetail>
        </Tooltip>
      );
    });
  };

  return (
    <ArmorDetailsContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <GearControlsAndTitle
        cancelButtonHandler={onCancelArmor}
        rarity={Rarity.DEFAULT}
        gear={selectedArmor}
        gearType={armorType}
      />
      <ArmorBodyContainer>
        {isMobile ? (
          <>
            {currentView === 'stats' ? (
              <StatsAndSkillsContainer
                initial={{ x: -30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <GenericStatDetails gear={selectedArmor} />
                <SimpleSkillsContainer>
                  {createSimpleSkills()}
                </SimpleSkillsContainer>
              </StatsAndSkillsContainer>
            ) : (
              <ArmorDecorationContainer
                initial={{ x: 30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <DecorationsContainer slot={armorType}></DecorationsContainer>
              </ArmorDecorationContainer>
            )}
            <ArmorViewControls>
              <ArmorViewButtonContainer
                disabled={currentView === 'stats'}
                onClick={() => setCurrentView('stats')}
              >
                <Icon name="Back"></Icon>
              </ArmorViewButtonContainer>
              <ArmorViewButtonContainer
                disabled={currentView === 'decorations'}
                onClick={() => setCurrentView('decorations')}
                marginLeft="large"
              >
                <Icon name="Forward"></Icon>
              </ArmorViewButtonContainer>
            </ArmorViewControls>
          </>
        ) : (
          <>
            <StatsAndSkillsContainer>
              <GenericStatDetails gear={selectedArmor} />
              <SimpleSkillsContainer>
                {createSimpleSkills()}
              </SimpleSkillsContainer>
            </StatsAndSkillsContainer>
            <ArmorDecorationContainer>
              <DecorationsContainer slot={armorType}></DecorationsContainer>
            </ArmorDecorationContainer>
          </>
        )}
      </ArmorBodyContainer>
    </ArmorDetailsContainer>
  );
};
