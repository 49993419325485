export const theme = {
  breakpoints: ['450px', '600px', '960px', '1280px', '1920px'],
  space: {
    tiny: '0.4rem', // 6.4px
    xSmall: '0.6rem', // 10px
    small: '0.8rem', // 14px
    medium: '1rem', // 16px
    large: '1.2rem',
    xLarge: '1.5rem',
    huge: '2rem',
  },
  sizes: {
    tiny: '0.4rem', // 6.4px
    xSmall: '0.6rem', // 10px
    small: '0.8rem', // 14px
    medium: '1rem', // 16px
    large: '1.2rem',
    xLarge: '1.5rem',
    huge: '2rem',
  },
  fontSizes: {
    tiny: '0.625rem', // 10px
    xSmall: '0.75rem', // 12px
    small: '0.875rem', // 14px
    medium: '1rem', // 16px
    large: '1.5rem', // 24px
    xLarge: '2rem', // 32px
    huge: '3rem', // 48px
  },
  colors: {
    warning: '#ff6e6e',
    warningDark: '#ed2121',
    red: '#d92c2c',
    orange: '#d9662c',
    yellow: '#d9d12c',
    green: '#70d92c',
    blue: '#2c86d9',
    white: '#fff',
    modes: {
      light: {
        text: '#0a2126',
        background: '#edf6f7',
        cardBackground: 'white',
        cardBorder: 'transparent',
        secondary: '#fff',
        primary: '#9eeafb',
        tertiary: '#08bee7',
        primaryDark: '#3acbeb',
        secondaryText: '#4e7a7e',
        border: '#e7e8e9',
        indicatorBorder: '#e7e8e9',
        themeBlack: '#0a2126',
        activeColor: 'white',
        sharpnessBorder: '#80adb7',
        detailsBorder: '#e7e8e9',
        detailsBackground: 'white',
        selectBackground: 'white',
        selectBorder: '#e7e8e9',
        decoBackground: 'white',
        tableHeaderBackground: '#edf6f7',
        pillColor: '#4055ee',
        emptyAugment: '#b2b2b2',
      },
      dark: {
        text: '#FFF9FB',
        background: '#242230',
        cardBackground: '#5F5C6F',
        cardBorder: 'transparent',
        secondary: 'transparent',
        secondaryText: '#DAA0B2',
        primary: '#B84468',
        tertiary: '#CC4A73',
        primaryDark: '#98163F',
        // border: '#53414D',
        indicatorBorder: '#131225',
        border: '#4d4b60',
        themeBlack: '#f6f6f6',
        activeColor: '#242230',
        sharpnessBorder: '#4d4b60',
        detailsBorder: 'transparent',
        detailsBackground: '#4d4b61',
        selectBackground: '#4d4b60',
        selectBorder: '#242230',
        decoBackground: '#5F5C6F',
        tableHeaderBackground: '#4d4b61',
        pillColor: '#D28D3D',
        emptyAugment: '#42404e',
      },
    },
  },
  radii: {
    small: '4px',
    medium: '8px',
    large: '16px',
    round: '100%',
  },
  shadows: {
    experimental: '0px 0px 16px -4px rgb(0 0 0 / 20%)',
    modes: {
      light: {
        kinsectShadow: '0px 2px 0px 2px #edf6f7',
        weaponSelectShadow: '0 0 16px 4px #9eeafb',
      },
      dark: {
        kinsectShadow: '0px 2px 0px 2px #4d4b60',
        weaponSelectShadow: '0 0 16px 4px #daa0b2',
      },
    },
  },
};

export type Theme = typeof theme;
