import React, { useState } from 'react';

import {
  TalismanTitleContainer,
  TalismanTitleText,
  TalismanButtonControls,
  IconButton,
  TalismanSelectionText,
  SkillPickerContainer,
  MotionDecorationContainer,
  StyledIcon,
} from './styled';
import { DecorationsContainer, SkillPicker } from 'components';
import { Container, Icon } from 'ui/core';
import { AnimatePresence } from 'framer-motion';

interface TalismanProps {}

export const Talisman: React.FC<TalismanProps> = ({}) => {
  const [talismanSelection, setTalismanSelection] = useState<
    'decorations' | 'skills'
  >('decorations');

  return (
    <>
      <TalismanTitleContainer>
        <Icon name="Talisman" />
        <TalismanTitleText>Talisman</TalismanTitleText>
      </TalismanTitleContainer>
      {talismanSelection === 'decorations' ? (
        <MotionDecorationContainer
          initial={{ x: -20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
        >
          <DecorationsContainer slot="Talisman"></DecorationsContainer>
        </MotionDecorationContainer>
      ) : (
        <SkillPickerContainer
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          fontSize={['tiny', null, 'medium', null, null]}
        >
          <SkillPicker />
        </SkillPickerContainer>
      )}
      <TalismanButtonControls>
        <IconButton
          disabled={talismanSelection === 'decorations'}
          onClick={() => setTalismanSelection('decorations')}
          justifyContent="flex-start"
          paddingLeft={talismanSelection === 'decorations' ? 'tiny' : 0}
          paddingRight="tiny"
        >
          {talismanSelection === 'decorations' ? null : (
            <StyledIcon name="Back" height={20} width={20} />
          )}
          <TalismanSelectionText>Decorations</TalismanSelectionText>
        </IconButton>
        <IconButton
          disabled={talismanSelection === 'skills'}
          onClick={() => setTalismanSelection('skills')}
          justifyContent="flex-end"
          paddingLeft="tiny"
          paddingRight={talismanSelection === 'skills' ? 'tiny' : 0}
        >
          <TalismanSelectionText>Skills</TalismanSelectionText>
          {talismanSelection === 'skills' ? null : (
            <StyledIcon name="Forward" height={20} width={20} />
          )}
        </IconButton>
      </TalismanButtonControls>
    </>
  );
};
