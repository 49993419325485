import React from 'react';

export default {
  viewBox: '0 0 384 520',
  paths: (
    <>
      <g id="baseColor">
        <path d="M162.667,409.02l-162.667,74.455l3.198,-289.905c3.727,-10.003 7.46,-20.021 11.844,-29.754c4.675,-10.38 9.676,-20.636 15.307,-30.53c25.975,-45.649 60.524,-89.382 108.537,-113.259c23.613,-11.743 48.844,-17.72 75.194,-18.279c56.365,-1.194 112.747,-1.471 169.124,-1.748c-0,0 -110.209,125.855 -111.463,164.947l0.781,-5.654l-0.907,146.976l25.986,40.991l-34.392,15.741c-0.521,0.619 0.086,56.323 23.99,71.151c64.819,1.288 71.518,-1.562 68.437,0.876l-0.056,84.556l-118.49,-0.16c-0,-0 -55.971,-23.808 -74.423,-110.404Z" />
        <path d="M272.522,159.309l-0.907,146.96l25.986,40.991l-34.392,15.741c-0.521,0.619 0.086,56.323 23.99,71.151c64.819,1.288 71.518,-1.562 68.437,0.876l-0.056,84.556l-118.49,-0.16c-0,-0 -55.971,-23.808 -74.423,-110.404l-162.667,74.455l3.198,-289.905c3.727,-10.003 7.46,-20.021 11.844,-29.754c4.675,-10.38 9.676,-20.636 15.307,-30.53c25.975,-45.649 60.524,-89.382 108.537,-113.259c23.613,-11.743 48.844,-17.72 75.194,-18.279c56.365,-1.194 112.747,-1.471 169.124,-1.748c-0,0 -100.724,115.024 -110.682,159.309Zm-16.259,-7.224c4.016,-13.422 13.047,-31.046 25.204,-49.712c18.833,-28.915 44.502,-61.476 63.32,-84.389c-43.446,0.25 -86.893,0.625 -130.33,1.545c-23.705,0.502 -46.409,5.858 -67.652,16.422c-44.841,22.3 -76.739,63.497 -100.999,106.13c-5.354,9.41 -10.102,19.167 -14.548,29.038c-3.797,8.431 -7.077,17.087 -10.311,25.753c-0,-0 -2.854,258.763 -2.854,258.763l137.172,-62.786c4.907,-2.246 10.568,-2.142 15.39,0.282c4.821,2.423 8.282,6.905 9.406,12.183c13.887,65.173 51.03,90.455 61.319,96.331c0,0 96.427,0.131 96.427,0.131l0.033,-49.582c-11.154,0.153 -28.084,0.194 -50.995,-0.261c-3.192,-0.064 -6.308,-0.985 -9.021,-2.668c-10.739,-6.662 -18.808,-18.715 -23.868,-32.715c-6.099,-16.874 -8.072,-36.376 -8.515,-46.564c-0.236,-5.447 0.064,-9.023 0.2,-9.829c0.832,-4.954 3.045,-7.521 3.973,-8.622c1.692,-2.006 3.807,-3.613 6.194,-4.705c-0,0 15.861,-7.26 15.861,-7.26l-15.075,-23.779c-1.825,-2.879 -2.784,-6.223 -2.763,-9.632l0.841,-136.222c-0.52,-1.76 -0.769,-3.631 -0.707,-5.56c0.06,-1.851 0.308,-3.879 0.739,-6.062l0.201,-1.458c0.233,-1.685 0.696,-3.285 1.358,-4.772Zm16.253,7.249l0.006,-0.041l0,0.016l-0.006,0.025l-0.775,5.613c0.054,-1.712 0.318,-3.591 0.775,-5.613Z" />
      </g>
      <g id="lightColor">
        <path d="M271.741,164.947c0.196,70.215 -0.126,141.322 -0.126,141.322c-0,0 23.265,36.699 25.986,40.991l-175.959,80.538l0.262,-237.238c-0,-0 65.895,-153.306 261.3,-190.56c-0,0 -109.397,124.252 -111.463,164.947Z" />
        <path d="M271.741,164.947c0.196,70.215 -0.126,141.322 -0.126,141.322c-0,0 23.265,36.699 25.986,40.991l-175.959,80.538l0.262,-237.238c-0,-0 65.895,-153.306 261.3,-190.56c-0,0 -109.397,124.252 -111.463,164.947Zm-17.762,-0.902c-0.016,0.317 -0.024,0.634 -0.023,0.952c0.196,70.15 -0.126,141.192 -0.126,141.192c-0.015,3.399 0.944,6.731 2.764,9.602l15.075,23.779c-0,0 -132.212,60.515 -132.212,60.515c-0,0 0.227,-205.566 0.227,-205.566c9.181,-18.751 63.273,-118.546 194.121,-163.168c-16.908,21.015 -36.655,46.778 -51.981,70.317c-16.279,25.004 -27.129,48.27 -27.845,62.377Z" />
      </g>
      <g id="baseColor">
        <circle cx="204.045" cy="301.279" r="29.478" />
        <path d="M204.045,271.801c16.27,-0 29.478,13.208 29.478,29.478c0,16.269 -13.208,29.478 -29.478,29.478c-16.269,0 -29.478,-13.209 -29.478,-29.478c0,-16.27 13.209,-29.478 29.478,-29.478Zm0,17.784c6.454,0 11.694,5.24 11.694,11.694c-0,6.454 -5.24,11.694 -11.694,11.694c-6.454,-0 -11.693,-5.24 -11.693,-11.694c-0,-6.454 5.239,-11.694 11.693,-11.694Z" />
        <circle cx="204.045" cy="205.836" r="29.478" />
        <path d="M204.045,176.358c16.27,-0 29.478,13.208 29.478,29.478c0,16.269 -13.208,29.478 -29.478,29.478c-16.269,-0 -29.478,-13.209 -29.478,-29.478c0,-16.27 13.209,-29.478 29.478,-29.478Zm0,17.784c6.454,0 11.694,5.24 11.694,11.694c-0,6.454 -5.24,11.693 -11.694,11.693c-6.454,0 -11.693,-5.239 -11.693,-11.693c-0,-6.454 5.239,-11.694 11.693,-11.694Z" />
      </g>
      <g id="outLine">
        <path d="M0,483.475l3.198,-289.905c-0,0 60.796,-190.27 210.882,-191.822c150.087,-1.551 169.124,-1.748 169.124,-1.748c-0,0 -110.209,125.855 -111.463,164.947c-0.038,41.88 -0.126,141.322 -0.126,141.322l25.986,40.991l-297.601,136.215Zm18.093,-27.84l2.857,-259.048c4.249,-12.186 25.321,-68.415 67.245,-114.709c30.224,-33.375 71.556,-61.782 126.069,-62.346l130.359,-1.348c-18.803,22.902 -44.378,55.358 -63.156,84.189c-16.364,25.125 -27.064,48.365 -27.502,62.004c-0.006,0.184 -0.009,0.369 -0.009,0.554c-0.037,41.881 -0.126,141.322 -0.126,141.322c-0.003,3.377 0.956,6.686 2.764,9.538l15.075,23.779l-253.576,116.065Z" />
        <path d="M131.234,410.859l-0.117,-218.708c0,0 52.898,-127.089 222.401,-177.073" />
        <path d="M187.917,449.37l71.082,-45.375" />
        <path d="M224.622,495.983l54.923,-59.864" />
        <path d="M302.45,444.469l0.679,62.386" />
        <path d="M204.045,271.801c16.27,-0 29.478,13.208 29.478,29.478c0,16.269 -13.208,29.478 -29.478,29.478c-16.269,0 -29.478,-13.209 -29.478,-29.478c0,-16.27 13.209,-29.478 29.478,-29.478Zm0,14.739c8.135,-0 14.739,6.604 14.739,14.739c0,8.135 -6.604,14.739 -14.739,14.739c-8.134,-0 -14.739,-6.604 -14.739,-14.739c0,-8.135 6.605,-14.739 14.739,-14.739Z" />
        <path d="M204.045,176.358c16.27,-0 29.478,13.208 29.478,29.478c0,16.269 -13.208,29.478 -29.478,29.478c-16.269,-0 -29.478,-13.209 -29.478,-29.478c0,-16.27 13.209,-29.478 29.478,-29.478Zm0,14.739c8.135,-0 14.739,6.604 14.739,14.739c0,8.135 -6.604,14.739 -14.739,14.739c-8.134,-0 -14.739,-6.604 -14.739,-14.739c0,-8.135 6.605,-14.739 14.739,-14.739Z" />
        <path d="M355.636,435.028l-0.056,84.556l-118.49,-0.16c-0,-0 -65.336,-28.776 -77.201,-127.966c-0,-0 103.111,-47.724 102.59,-47.106c-0.522,0.619 0.816,74.972 24.72,89.8c64.819,1.288 71.518,-1.562 68.437,0.876Zm-109.49,-63.77c1.65,15.679 4.908,35.265 10.939,50.369c5.042,12.627 12.29,22.397 20.739,27.638c2.713,1.683 5.829,2.604 9.021,2.668c22.911,0.455 39.841,0.414 50.995,0.261l-0.033,49.582c-0,-0 -96.34,-0.131 -96.34,-0.131c-10.823,-6.267 -49.956,-33.136 -62.021,-99.638c16.353,-7.561 45.872,-21.198 66.7,-30.749Z" />
      </g>
    </>
  ),
};
