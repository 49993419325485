import _ from 'lodash';
import { matchSorter } from 'match-sorter';

import {
  DefenseStatsCell,
  defenseStatsAccessor,
  DecorationSlotsCell,
  decorationSlotsAccessor,
  ActiveSkillsCell,
  activeSkillsAccessor,
} from './components';

import { NameFilter } from './components/filters';

export const armorColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'NAME',
  },
  {
    id: 'decorationSlots',
    accessor: decorationSlotsAccessor,
    Header: 'SLOTS',
    Cell: DecorationSlotsCell,
    width: 66,
  },
  {
    id: 'defenseStats',
    accessor: defenseStatsAccessor,
    Header: 'DEFENSES',
    Cell: DefenseStatsCell,
  },
  {
    id: 'appliedSkills',
    accessor: activeSkillsAccessor,
    Header: 'ACTIVE SKILLS',
    Cell: ActiveSkillsCell,
    width: 300,
  },
];

export const mobileArmorColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'NAME',
  },
  {
    id: 'decorationSlots',
    accessor: decorationSlotsAccessor,
    Header: 'SLOTS',
    Cell: DecorationSlotsCell,
    width: 66,
  },
];

const defaultColumn = {
  Filter: () => <></>,
};

const initialFilterState = [
  {
    id: 'decorationSlots',
    value: [],
  },
];

const initialGlobalFilterState = [
  {
    id: 'name',
    value: '',
  },
  {
    id: 'activeSkills',
    value: '',
  },
];

// TODO: monsters to resolver? need to add to datatype
const globalFilterResolver = (
  rows: any,
  columnIds: any,
  globalFilterValue: any
) => {
  const nameMatches = matchSorter(rows, globalFilterValue, {
    keys: [
      (row: any) => row.original.name,
      (row: any) => row.original.appliedSkills.map((skill: any) => skill.name),
    ],
    // keys: ['original.name', 'original.appliedSkills.*.name'],
  });

  return globalFilterValue === '' ? rows : nameMatches;
};

const getFilterComponents = (
  setFilter: any,
  setAllFilters: any,
  filterValues: any
) => {
  return <></>;
};

const getGlobalFilterComponent = (setFilter: any) => {
  return <NameFilter setFilter={setFilter}></NameFilter>;
};

export const getArmorProperties = () => ({
  getColumns: () => [...armorColumns],
  getMobileColumns: () => [...mobileArmorColumns],
  getOptions: () => ({
    defaultColumn,
    initialFilterState,
    initialGlobalFilterState,
    globalFilterResolver,
  }),
  getFilterComponents: (
    setFilter: any,
    setAllFilters: any,
    filterValues: any
  ) => getFilterComponents(setFilter, setAllFilters, filterValues),
  getGlobalFilterComponent: (setFilter: any) =>
    getGlobalFilterComponent(setFilter),
});
