import styled from 'styled-components';
import css from '@styled-system/css';
import { Button, Container, Text } from 'ui/core';

export const AccessoriesContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    padding: 'xSmall',
    border: '1px solid',
    borderColor: 'cardBorder',
    backgroundColor: 'cardBackground',
    borderRadius: 'medium',
  })}
`;

export const AccesoryContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    width: '50%',
  })}
`;
