import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';
import css from '@styled-system/css';
import { motion, HTMLMotionProps } from 'framer-motion';

type ContainerProps = {
  variant?: 'flexRow' | 'flexCol';
} & ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps &
  HTMLMotionProps<'div'>;

export const Container = styled(motion<ContainerProps>('div'))`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
  ${variant({
    variants: {
      flexRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      flexCol: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  })}
`;

// this doesn't feel right
// maybe create row/column with required param of flexDirection?
// TODO: find a better way
export const SpacedFlexRowContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${css({
    marginY: 'tiny',
  })}
`;

export const HalfFlexColumnContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
