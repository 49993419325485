import React from 'react';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';

import { DecorationSelect } from './components';
import { Button, Icon } from 'ui/core';
import {
  DecorationSelectContainer,
  SelectedDecorationContainer,
  SelectedDecorationCancelButton,
  ButtonTextSpan,
} from './styled';

import { DecorationSlot } from 'models/DecorationSlot';
import { Decoration } from 'models/Decoration';
import { Slot } from 'models/state';

interface DecorationsContainerProps {
  slot: Slot;
  isRampage?: boolean;
}

// Container (either renders a selection component or the selected item) => {GearType}Select (handles generating the appropriate dataset) => modal
export const DecorationsContainer: React.FC<DecorationsContainerProps> = ({
  slot,
  isRampage = false,
}) => {
  // if we're loading decoration slots, that means we have gear to fetch from
  const { loadout, assignDecoration, assignRampageDecoration } =
    useLoadoutStore(
      (state: any) => ({
        loadout: state.loadout,
        assignDecoration: state.assignDecoration,
        assignRampageDecoration: state.assignRampageDecoration,
      }),
      shallow
    );

  const slotLoadout = loadout[slot];
  const decorationSlots = slotLoadout
    ? isRampage
      ? slotLoadout.rampageDecorationSlots
      : slotLoadout.decorationSlots
    : [];

  // TODO: uniform naming for these
  const handleDecorationSelection = (
    slotPosition: number,
    decoration: Decoration
  ) => {
    // replace slotposition with selected decoration and update state
    const newState = [...decorationSlots];

    newState[slotPosition].decoration = decoration;
    newState[slotPosition].isFilled = true;

    isRampage
      ? assignRampageDecoration(slot, newState)
      : assignDecoration(slot, newState);
  };

  const handleDecorationCancel = (slotPosition: number) => {
    // access the right index in the state and set decoration to null & isFilled to false
    const newState = [...decorationSlots];

    const newDecorationSlot = {
      slotPosition,
      slotValue: newState[slotPosition].slotValue,
      isFilled: false,
    };
    newState[slotPosition] = newDecorationSlot;

    isRampage
      ? assignRampageDecoration(slot, newState)
      : assignDecoration(slot, newState);
  };

  return (
    <DecorationSelectContainer>
      {decorationSlots.map((decorationSlot: DecorationSlot, index: number) => {
        const { slotValue, slotPosition, decoration } = decorationSlot;
        const iconNames = {
          0: 'OneSlot',
          1: 'OneSlot',
          2: 'TwoSlot',
          3: 'ThreeSlot',
          4: 'ThreeSlot',
        } as const;
        const iconName = iconNames[slotValue];

        return slotValue > 0 ? (
          !decoration ? (
            <DecorationSelect
              decorationSlot={decorationSlot}
              onSelectDecoration={handleDecorationSelection}
              key={`${index}_${slotValue}_decorationslot_${slotPosition}`}
              isRampage={isRampage}
            ></DecorationSelect>
          ) : (
            <SelectedDecorationContainer
              key={`${index}_${slotValue}_${decoration.name}`}
            >
              <Icon name={iconName} />
              <SelectedDecorationCancelButton
                onClick={() => handleDecorationCancel(slotPosition)}
              >
                <ButtonTextSpan>
                  {decoration.name.replace('Hard', 'H')}
                </ButtonTextSpan>
                <Icon name={'X'} height={15} width={15}></Icon>
              </SelectedDecorationCancelButton>
            </SelectedDecorationContainer>
          )
        ) : null;
      })}
      {decorationSlots.filter((decorationSlot: DecorationSlot) => {
        return decorationSlot.slotValue !== 0;
      }).length === 0 ? (
        <SelectedDecorationContainer opacity={'50%'}>
          No decorations 😔
        </SelectedDecorationContainer>
      ) : null}
    </DecorationSelectContainer>
  );
};
