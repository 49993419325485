interface Petalace {
  id: number;
  name: string;
  healthUp: number;
  healthGained: number;
  staminaUp: number;
  staminaGained: number;
  attackUp: number;
  attackGained: number;
  defenseUp: number;
  defenseGained: number;
}

export const petalaces: Petalace[] = [
  {
    id: 0,
    name: 'Hunting I',
    healthUp: 30,
    healthGained: 3,
    staminaUp: 30,
    staminaGained: 3,
    attackUp: 5,
    attackGained: 1,
    defenseUp: 10,
    defenseGained: 1,
  },
  {
    id: 1,
    name: 'Hunting II',
    healthUp: 50,
    healthGained: 5,
    staminaUp: 50,
    staminaGained: 5,
    attackUp: 10,
    attackGained: 2,
    defenseUp: 20,
    defenseGained: 2,
  },
  {
    id: 2,
    name: 'Strength II',
    healthUp: 75,
    healthGained: 8,
    staminaUp: 75,
    staminaGained: 8,
    attackUp: 5,
    attackGained: 1,
    defenseUp: 10,
    defenseGained: 1,
  },
  {
    id: 3,
    name: 'Fortitude II',
    healthUp: 40,
    healthGained: 4,
    staminaUp: 75,
    staminaGained: 8,
    attackUp: 5,
    attackGained: 1,
    defenseUp: 30,
    defenseGained: 3,
  },
  {
    id: 4,
    name: 'Demon II',
    healthUp: 30,
    healthGained: 2,
    staminaUp: 30,
    staminaGained: 3,
    attackUp: 15,
    attackGained: 3,
    defenseUp: 5,
    defenseGained: 1,
  },
  {
    id: 5,
    name: 'Hunting III',
    healthUp: 70,
    healthGained: 8,
    staminaUp: 70,
    staminaGained: 8,
    attackUp: 13,
    attackGained: 2,
    defenseUp: 30,
    defenseGained: 3,
  },
  {
    id: 6,
    name: 'Strength III',
    healthUp: 100,
    healthGained: 9,
    staminaUp: 90,
    staminaGained: 9,
    attackUp: 10,
    attackGained: 2,
    defenseUp: 15,
    defenseGained: 2,
  },
  {
    id: 7,
    name: 'Fortitude III',
    healthUp: 50,
    healthGained: 8,
    staminaUp: 100,
    staminaGained: 10,
    attackUp: 8,
    attackGained: 1,
    defenseUp: 40,
    defenseGained: 4,
  },
  {
    id: 8,
    name: 'Demon III',
    healthUp: 50,
    healthGained: 5,
    staminaUp: 90,
    staminaGained: 9,
    attackUp: 20,
    attackGained: 4,
    defenseUp: 20,
    defenseGained: 2,
  },
  {
    id: 9,
    name: 'Absolute',
    healthUp: 100,
    healthGained: 10,
    staminaUp: 70,
    staminaGained: 10,
    attackUp: 15,
    attackGained: 2,
    defenseUp: 50,
    defenseGained: 5,
  },
  {
    id: 10,
    name: 'Underworld',
    healthUp: 70,
    healthGained: 10,
    staminaUp: 100,
    staminaGained: 10,
    attackUp: 15,
    attackGained: 2,
    defenseUp: 50,
    defenseGained: 5,
  },
];
