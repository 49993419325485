import React from 'react';

import {
  SimpleSummaryContainer,
  ResistsContainer,
  StatContainer,
} from './styled';
import { Icon } from 'ui/core';

import { sumArmorDefense, sumArmorResistances } from 'utils/stats';
import { Loadout } from 'models/state';

interface SimpleSummaryProps {
  set: Loadout;
}
// TODO: rename this to something stat related.
export const SimpleSummary: React.FC<SimpleSummaryProps> = ({ set }) => {
  const def = sumArmorDefense(set);
  const rawResists = sumArmorResistances(set);

  const prettifyResists = (resists: any) => {
    const stats = ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'] as const;
    const statValues = Object.values(resists);

    return (
      <ResistsContainer>
        {stats.map((stat, index) => {
          return (
            <StatContainer marginLeft="tiny">
              <Icon name={stat} height={20} width={20} />
              {statValues[index]}
            </StatContainer>
          );
        })}
      </ResistsContainer>
    );
  };

  const prettifyDefense = (defense: any) => {
    return (
      <ResistsContainer>
        <StatContainer marginRight="tiny">
          <Icon name="Defense" height={20} width={20} />
          {defense}
        </StatContainer>
      </ResistsContainer>
    );
  };

  return (
    <SimpleSummaryContainer>
      {prettifyDefense(def)} | {prettifyResists(rawResists)}
    </SimpleSummaryContainer>
  );
};
