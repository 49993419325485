import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const FocusedGearContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
  })}
`;
