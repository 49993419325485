import React, { useState } from 'react';
import { HeaderBar, NavBar, MobileNav, LoadoutContainer } from 'components';
import { DataContextProvider } from 'context';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import { theme } from 'ui/theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Container, Text } from 'ui/core';
import { FooterText } from './styled';
import css from '@styled-system/css';
import _ from 'lodash';

const queryClient = new QueryClient();

const getTheme = (mode: any) =>
  _.merge({}, theme, {
    colors: _.get(theme.colors.modes, mode, theme.colors),
    shadows: _.get(theme.shadows.modes, mode, theme.shadows),
  });

export const App = () => {
  const userPreferredTheme =
    (localStorage.getItem('theme') as 'light' | 'dark') || 'dark';
  const [mode, setMode] = useState<'light' | 'dark'>(userPreferredTheme);
  const currentTheme = getTheme(mode);

  const changeThemeMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newMode);
    setMode(newMode);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={currentTheme}>
        <Container width="100%" height="100%" backgroundColor="background">
          <AppContainer backgroundColor={'background'} color={'text'}>
            <DataContextProvider>
              <LoadoutContainer
                modeToggle={{ currMode: mode, changeMode: changeThemeMode }}
              />
            </DataContextProvider>
          </AppContainer>
        </Container>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

const AppContainer = styled(Container)`
  ${css({
    height: ['100%', null, null, null, null],
    width: ['100%', null, null, null, null, '1920px'],
    margin: [null, null, null, null, 'auto'],
    padding: 'huge',
  })}
`;
