import React, { useState, useRef, useEffect } from 'react';
import { NameFilterInput } from './styled';

export const NameFilter: React.FC<any> = ({ setFilter }) => {
  const inputElement = useRef(null);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    if (inputElement.current) {
      // @ts-ignore
      inputElement.current.focus();
    }
  }, []);
  const onChange = (e: any) => {
    const text = e.target.value;
    setInputValue(text);
    setFilter(text);
  };
  return (
    <NameFilterInput
      ref={inputElement}
      autoFocus
      value={inputValue}
      onChange={onChange}
      placeholder="Search data."
    ></NameFilterInput>
  );
};
