import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const SwitchSkillsContainer = styled(Container).attrs({
  variant: 'flexCol',
})``;

export const SwitchSlotAndSelectContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    width: '100%',
    alignItems: 'center',
    paddingTop: 'xSmall',
  })}
`;
