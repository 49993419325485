import React, { useState, useEffect } from 'react';
import { qurioWeaponSchema, QurioEffect } from './constants';
import { Container, Text, Span, Button } from 'ui/core';
import { GenericStatDetails } from 'components';
import { QurioWeaponConfig } from 'models/state';
import { Weapon, isMeleeWeapon, isBow } from 'models/weapons';
import { SharpnessDisplay } from 'components/SharpnessDisplay';
import { AugmentSlots } from './components';

import { cloneDeep } from 'lodash';
import { useLoadoutStore } from 'hooks/useLoadoutStore';
import shallow from 'zustand/shallow';

interface QurioWeaponProps {
  weapon: Weapon;
  onSave: any;
}

type QurioAugment =
  | 'attack'
  | 'affinity'
  | 'element'
  | 'sharpness'
  | 'status'
  | 'rampageDecoration';

export const QurioWeapon: React.FC<QurioWeaponProps> = ({ weapon, onSave }) => {
  const MAX_SLOTS = 5;
  const defaultConfig = {
    availableSlots: MAX_SLOTS,
    attack: -1,
    affinity: -1,
    element: -1,
    sharpness: -1,
    status: -1,
    rampageDecoration: -1,
  } as QurioWeaponConfig;

  const { setQurioWeaponConfig, loadout } = useLoadoutStore((state) => ({
    setQurioWeaponConfig: state.setQurioWeaponConfig,
    loadout: state.loadout,
  }));
  const { QurioWeaponConfig } = loadout;
  const [weapClone, setWeapClone] = useState(cloneDeep(weapon));
  const [currentConfig, setCurrentConfig] = useState(QurioWeaponConfig);

  const hasElement =
    ((isMeleeWeapon(weapClone) || isBow(weapClone)) &&
      weapClone.specialDamage?.length !== 0 &&
      weapClone.specialDamage?.some((specialDamage) =>
        ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
          specialDamage.damageType
        )
      )) ||
    false;

  const hasStatus =
    ((isMeleeWeapon(weapClone) || isBow(weapClone)) &&
      weapClone.specialDamage?.length !== 0 &&
      weapClone.specialDamage?.some((specialDamage) =>
        ['Poison', 'Stun', 'Paralysis', 'Sleep', 'Blast'].includes(
          specialDamage.damageType
        )
      )) ||
    false;

  const canUpgradeDecoration = !weapon!.rampageDecorationSlots!.some(
    (slot) => slot.slotValue === 3
  );

  const saveQurioConfig = () => {
    setQurioWeaponConfig(currentConfig);
    onSave(false);
  };

  const displayBoost = {
    attack: true,
    affinity: true,
    element: hasElement,
    sharpness: isMeleeWeapon(weapon),
    status: hasStatus,
    rampageDecoration: canUpgradeDecoration,
  } as { [key: string]: boolean };

  const changeCorrectConfig = (qurioAugment: QurioAugment, level: number) => {
    const newConfig = {
      ...currentConfig,
    };
    const currLevel = newConfig[qurioAugment];
    const currentQurioSchema = qurioWeaponSchema[qurioAugment as QurioAugment];

    let newLevel = -1;
    let newAvailableSlots = newConfig.availableSlots;

    if (currLevel === level) {
      // click self, toggle off
      newLevel = -1;
      newAvailableSlots += currentQurioSchema.effects[level].cost;
    } else if (currLevel === -1) {
      // clicking any to activate
      newLevel = level;
      newAvailableSlots -= currentQurioSchema.effects[level].cost;
    } else if (level < currLevel) {
      // clicking lesser level
      newLevel = level;
      newAvailableSlots +=
        currentQurioSchema.effects[currLevel].cost -
        currentQurioSchema.effects[level].cost;
    } else if (level > currLevel) {
      // clicking above
      newLevel = level;
      newAvailableSlots -=
        currentQurioSchema.effects[level].cost -
        currentQurioSchema.effects[currLevel].cost;
    }

    newConfig[qurioAugment] = newLevel;
    newConfig['availableSlots'] = newAvailableSlots;

    setCurrentConfig(newConfig);
  };

  const { availableSlots, ...augments } = currentConfig;

  // TODO: rethink elemental
  const applyEffectToStat = (
    newWeapon: any,
    level: number,
    qurioAugment: QurioAugment
  ) => {
    const currentEffect = qurioWeaponSchema[qurioAugment].effects[level];
    const { calculationBonus } = currentEffect;

    switch (qurioAugment) {
      case 'attack':
        newWeapon.baseDamage = weapon.baseDamage + calculationBonus;
        break;
      case 'affinity':
        const currAffinity = parseInt(weapon.affinity, 10);
        newWeapon.affinity = `${currAffinity > 0 ? '+' : ''}${
          currAffinity + calculationBonus
        }%`;
        break;
      case 'element':
        if (isMeleeWeapon(weapon) || isBow(weapon)) {
          const elementalSpecialDamage = [] as any;
          const statusSpecialDamage = [] as any;
          // sort the types
          weapon?.specialDamage?.forEach((specialDamage) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              elementalSpecialDamage.push(specialDamage);
            } else {
              statusSpecialDamage.push(specialDamage);
            }
          });

          // only change elemental ones
          const newSpecialDamage = (elementalSpecialDamage || []).map(
            (specialDamage: any) => {
              const { damageType, damageValue } = specialDamage;
              return {
                damageType,
                damageValue: damageValue + calculationBonus,
              };
            }
          );

          newWeapon.specialDamage = [
            ...newSpecialDamage,
            ...statusSpecialDamage,
          ];
        }
        break;
      case 'sharpness':
        if (isMeleeWeapon(weapon)) {
          const newBaseSharpness = { ...weapon.baseSharpness };
          newBaseSharpness[
            `${newBaseSharpness.purple >= 0 ? 'purple' : 'white'}` as
              | 'purple'
              | 'white'
          ] += calculationBonus;
          newWeapon.baseSharpness = newBaseSharpness;
        }
        break;
      case 'status':
        if (isMeleeWeapon(weapon) || isBow(weapon)) {
          const elementalSpecialDamage = [] as any;
          const statusSpecialDamage = [] as any;
          // sort the types
          weapon?.specialDamage?.forEach((specialDamage) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              elementalSpecialDamage.push(specialDamage);
            } else {
              statusSpecialDamage.push(specialDamage);
            }
          });

          // only change status ones
          const newSpecialDamage = (statusSpecialDamage || []).map(
            (specialDamage: any) => {
              const { damageType, damageValue } = specialDamage;
              return {
                damageType,
                damageValue: damageValue + calculationBonus,
              };
            }
          );

          newWeapon.specialDamage = [
            ...elementalSpecialDamage,
            ...newSpecialDamage,
          ];
        }
        break;
      case 'rampageDecoration':
        const newRampageDecorationSlots = weapon!.rampageDecorationSlots?.map(
          (decoSlot) => {
            if (decoSlot.slotValue > 0) {
              return {
                ...decoSlot,
                slotValue: decoSlot.slotValue + calculationBonus,
              };
            }
            return decoSlot;
          }
        );
        newWeapon.rampageDecorationSlots = newRampageDecorationSlots;
        break;
      default:
        break;
    }
  };

  const resetStat = (newWeapon: any, qurioAugment: QurioAugment) => {
    switch (qurioAugment) {
      case 'attack':
        newWeapon.baseDamage = weapon.baseDamage;
        break;
      case 'affinity':
        newWeapon.affinity = weapon.affinity;
        break;
      case 'element':
        if (isMeleeWeapon(weapon) || isBow(weapon)) {
          const baseElementalSpecialDamage = [] as any;
          const baseStatusSpecialDamage = [] as any;
          const mutatedElementalSpecialDamage = [] as any;
          const mutatedStatusSpecialDamage = [] as any;
          // sort the types
          weapon?.specialDamage?.forEach((specialDamage) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              baseElementalSpecialDamage.push(specialDamage);
            } else {
              baseStatusSpecialDamage.push(specialDamage);
            }
          });

          newWeapon?.specialDamage?.forEach((specialDamage: any) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              mutatedElementalSpecialDamage.push(specialDamage);
            } else {
              mutatedStatusSpecialDamage.push(specialDamage);
            }
          });

          newWeapon.specialDamage = [
            ...baseElementalSpecialDamage,
            ...mutatedStatusSpecialDamage,
          ];
        }
        break;
      case 'sharpness':
        if (isMeleeWeapon(weapon)) {
          newWeapon.baseSharpness = { ...weapon.baseSharpness };
        }
        break;
      case 'status':
        if (isMeleeWeapon(weapon) || isBow(weapon)) {
          const baseElementalSpecialDamage = [] as any;
          const baseStatusSpecialDamage = [] as any;
          const mutatedElementalSpecialDamage = [] as any;
          const mutatedStatusSpecialDamage = [] as any;
          // sort the types
          weapon?.specialDamage?.forEach((specialDamage) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              baseElementalSpecialDamage.push(specialDamage);
            } else {
              baseStatusSpecialDamage.push(specialDamage);
            }
          });

          newWeapon?.specialDamage?.forEach((specialDamage: any) => {
            if (
              ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
                specialDamage.damageType
              )
            ) {
              mutatedElementalSpecialDamage.push(specialDamage);
            } else {
              mutatedStatusSpecialDamage.push(specialDamage);
            }
          });

          newWeapon.specialDamage = [
            ...mutatedElementalSpecialDamage,
            ...baseStatusSpecialDamage,
          ];
        }
        break;
      case 'rampageDecoration':
        newWeapon.rampageDecorationSlots = [
          ...(weapon?.rampageDecorationSlots || []),
        ];
        break;
      default:
        break;
    }
  };

  const resetConfig = () => {
    setCurrentConfig(defaultConfig);
  };

  useEffect(() => {
    const { availableSlots, ...config } = currentConfig;
    const newWeapon = { ...weapClone };
    Object.entries(config).forEach((kv) => {
      const [augment, level] = kv;
      if (level >= 0) {
        applyEffectToStat(newWeapon, level, augment as QurioAugment);
      } else {
        // reset to base
        resetStat(newWeapon, augment as QurioAugment);
      }
    });

    setWeapClone(newWeapon);
  }, [currentConfig]);

  // TODO: tomorrow, finish the display part of the feature
  // DONE - display weapon stats
  // DONE - display slots
  // DONE - reset button
  // DONE - confirm button
  // DONE (locally, util next?)- helpers to apply effects
  //  - if rarity 10 then apply helpers to display
  // TODO - button showing if effects are active
  // TODO - apply stat bonuses in details & in summary

  return (
    <Container
      display="flex"
      flexDirection={['column-reverse', null, null, 'row', null]}
    >
      <Container
        width={['100%', null, null, '50%', null]}
        display="flex"
        flexDirection="column"
      >
        {Object.entries(qurioWeaponSchema).map((kv, index) => {
          const [qurioAugment, qurioAugmentSchema] = kv;
          const { displayName, color, effects } = qurioAugmentSchema;

          return displayBoost[qurioAugment] ? (
            <Container
              key={`${qurioAugment}_index`}
              display="flex"
              flexDirection="column"
              marginBottom="small"
            >
              <Text
                fontWeight="bold"
                fontSize="small"
                color="secondaryText"
                letterSpacing="1px"
              >
                {displayName.toUpperCase()}
              </Text>
              <Container
                marginTop="tiny"
                display="flex"
                flexDirection="row"
                style={{ gap: '2rem' }}
              >
                {effects.map((effect: QurioEffect, index: number) => {
                  const { bonus, cost } = effect;
                  const currentLevel =
                    currentConfig[qurioAugment as QurioAugment];
                  const activeCost =
                    currentLevel !== -1 ? effects[currentLevel].cost : 0;
                  const actualCost = cost - activeCost;
                  const overflow = currentConfig.availableSlots - actualCost;
                  const isDisabled = overflow < 0 && !(index <= currentLevel);

                  const isActivated = index <= currentLevel;
                  return (
                    <Container
                      width="60px"
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      style={{
                        gap: '.4rem',
                        transition: 'all 0.3s ease-in-out',
                      }}
                      key={`${qurioAugment}_${index}`}
                      opacity={isDisabled ? '70%' : null}
                    >
                      <Container
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        style={{ gap: '.2rem' }}
                      >
                        {Array.from(Array(cost).keys()).map((tick) => {
                          return (
                            <Span
                              height="15px"
                              width="10px"
                              backgroundColor={
                                isDisabled
                                  ? tick < activeCost &&
                                    currentConfig.availableSlots !== 0 &&
                                    currentConfig.availableSlots - actualCost >=
                                      0
                                    ? color
                                    : 'gainsboro'
                                  : color
                              }
                              opacity={
                                isActivated ||
                                (currentLevel !== -1 &&
                                  tick < activeCost &&
                                  currentConfig.availableSlots !== 0 &&
                                  currentConfig.availableSlots - actualCost >=
                                    0)
                                  ? null
                                  : '70%'
                              }
                              style={{
                                transition: 'all 0.3s ease-in-out',
                                clipPath:
                                  'polygon(50% 0, 0 35%, 0 37%, 50% 100%, 100% 37%, 100% 35%)',
                              }}
                            />
                          );
                        })}
                      </Container>
                      <Container
                        height="30px"
                        width="30px"
                        borderRadius="100%"
                        bg={isActivated ? 'primary' : 'transparent'}
                        border="1px solid"
                        borderColor="border"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          cursor: 'pointer',
                          pointerEvents: isDisabled ? 'none' : 'auto',
                          transition: 'all 0.3s ease-in-out',
                        }}
                        onClick={() =>
                          changeCorrectConfig(
                            qurioAugment as QurioAugment,
                            index
                          )
                        }
                      >
                        {index + 1}
                      </Container>
                      <Span
                        marginLeft={
                          qurioAugment === 'affinity' ||
                          qurioAugment === 'rampageDecoration'
                            ? null
                            : '-6px'
                        }
                        lineHeight={1}
                        color={isActivated ? 'green' : 'textColor'}
                      >
                        {bonus}
                      </Span>
                    </Container>
                  );
                })}
              </Container>
            </Container>
          ) : null;
        })}
      </Container>
      <Container
        width={['100%', null, null, '50%', null]}
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginBottom={['small', null, null, null, null]}
      >
        <Text
          fontWeight="bold"
          fontSize="small"
          color="secondaryText"
          letterSpacing="1px"
        >
          AUGMENT SLOTS
        </Text>
        <AugmentSlots currentConfig={augments} />
        <Container
          width="100%"
          textAlign="center"
          mt="small"
          display="flex"
          flexDirection="column"
        >
          <Text
            fontWeight="bold"
            fontSize="small"
            color="secondaryText"
            letterSpacing="1px"
            marginBottom="small"
          >
            {weapClone.name}
          </Text>
          <GenericStatDetails gear={weapClone} />
          <Span marginY="tiny">
            Rampage Decoration Level{' '}
            {weapClone &&
              weapClone?.rampageDecorationSlots?.find(
                (slot) => slot.slotValue >= 0
              )?.slotValue}
          </Span>
          {isMeleeWeapon(weapClone) && (
            <SharpnessDisplay
              bonusApplied={currentConfig.sharpness >= 0}
              long
              sharpness={weapClone.baseSharpness}
            />
          )}
        </Container>
        <Container
          width="100%"
          display="flex"
          flexDirection="row"
          style={{ gap: '1rem' }}
        >
          <Button
            width="50%"
            onClick={() => saveQurioConfig()}
            marginTop="large"
            variant="primary"
          >
            SAVE
          </Button>
          <Button
            onClick={() => resetConfig()}
            width="50%"
            marginTop="large"
            variant="warning"
          >
            CLEAR
          </Button>
        </Container>
      </Container>
    </Container>
  );
};
