import styled from 'styled-components';
import css from '@styled-system/css';
import { Span, Container, Icon } from 'ui/core';

export const ShellingCellContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({ alignItems: 'center' })}
`;

export const ShellingTypeSpan = styled(Span)`
  ${css({
    fontSize: 'small',
  })}
`;

export const ShellingIcon = styled(Icon)``;
