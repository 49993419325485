import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M9 9H3V15H9V21H15V15H21V9H15V3H9V9Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
