import styled from 'styled-components';
import css from '@styled-system/css';

export const NameFilterInput = styled.input`
  ${css({
    marginBottom: 'xSmall',
    border: '1px solid',
    borderColor: 'border',
    borderRadius: 'medium',
    paddingLeft: 'xSmall',
    paddingY: 'xSmall',
    width: ['100%', null, '45%', null, null],
    '::placeholder': {
      opacity: '50%',
      color: 'text',
    },
    backgroundColor: 'detailsBackground',
    color: 'text',
  })}
`;
