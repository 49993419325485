//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { useLoadoutStore, useDataContext, useExportStore } from 'hooks';
import { Loadout } from 'models/state';
import {
  Weapon,
  isMeleeWeapon,
  isHuntingHorn,
  isRangedWeapon,
  isBowgun,
  isBow,
  Bow,
  isInsectGlaive,
} from 'models/weapons';

import { Icon, Span, Text, Container } from 'ui/core';
import {
  SkillsContainer,
  SharpnessDisplay,
  GenericStatDetails,
  SwitchSkills,
} from 'components';
import { AugmentSlots } from 'components/QurioWeapon/components';
import {
  TempContainer,
  StatusPane,
  SectionTitle,
  StatSection,
  StatSectionRow,
  IconAndTextContainer,
  StyledIcon,
  DecoNameContainer,
  DecoNamePill,
  LongPane,
  SongsContainer,
  SongSpan,
  ShotsContainer,
  ShotSpan,
  CoatingContainer,
  CoatingIcon,
  WeaponPropertiesContainer,
} from './styled';

import {
  sumArmorDefense,
  getWeaponDefense,
  sumArmorResistances,
  getWeaponAttack,
  getWeaponSpecialDamage,
  getWeaponAffinity,
  applySkillModifiers,
  ResistsDisplay,
} from 'utils/stats';
import {
  getAppliedSkillsFromLoadout,
  getDecorationsFromLoadout,
  getSkillsFromDecorations,
  condenseAppliedSkills,
} from 'utils/skills';
import _ from 'lodash';
import QRCode from 'qrcode';

interface StatusProps {}

// TODO: stop being lazy and refactor to util instead of repeating in TotalStatsCard
// TODO: same with the weapon display stuff
export const Status: React.FC<StatusProps> = () => {
  const [qrCode, setQrCode] = useState<any>();
  const loadout = useLoadoutStore((state) => state.loadout);
  const { getSkillData, getRampageSkillData } = useDataContext();
  const exportString = useExportStore((state: any) => state.exportEncryption);

  const URL = process.env.REACT_APP_SITE_URL || 'http://localhost:3000/';
  const exportURL = `${URL}?loadout=`;

  const actualString = `${exportURL}${exportString}`;
  const {
    Weapon,
    Talisman,
    LoadoutSwitchSkills,
    Petalace,
    Kinsect,
    QurioWeaponConfig,
    ...Armors
  } = loadout;

  const generateQR = async (text) => {
    try {
      return await QRCode.toDataURL(text);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(async () => {
    setQrCode(await generateQR(actualString));
  }, [actualString]);

  // get qurio weapon stuff
  const showWeaponQurio = Weapon && Weapon.rarity === 10;
  const { availableSlots, ...augments } = QurioWeaponConfig;

  // calculate defenses & resists from armorset
  let totalDefense = sumArmorDefense(Armors) + getWeaponDefense(Weapon);
  let totalResistances = sumArmorResistances(Armors);

  // calculate offenses from weapon
  let attack = getWeaponAttack(Weapon);
  let specialDamage = getWeaponSpecialDamage(Weapon);
  let affinity = getWeaponAffinity(Weapon);

  // get skills from armor and decorations to factor into calculations
  const allDecorations = getDecorationsFromLoadout(loadout);
  const allAppliedSkills = getAppliedSkillsFromLoadout(loadout);

  const allDecorationSkills = getSkillsFromDecorations(
    allDecorations,
    getSkillData()
  );

  const condensedAppliedSkills = condenseAppliedSkills([
    ...allDecorationSkills,
    ...allAppliedSkills,
  ]);

  const skillAndLevelArray = condensedAppliedSkills
    .filter((appliedSkill) => appliedSkill !== null)
    .map(({ id, appliedSkillLevel }) => {
      return {
        id,
        appliedSkillLevel,
      };
    });

  const statTuples = [
    {
      statBlock: 'attack' as const,
      stat: 'attack' as const,
      statValue: attack,
    },
    {
      statBlock: 'affinity' as const,
      stat: 'affinity' as const,
      statValue: parseInt(affinity as string, 10),
    },
    {
      statBlock: 'defense' as const,
      stat: 'defense' as const,
      statValue: totalDefense,
    },
    {
      statBlock: 'resists' as const,
      stat: 'resists' as const,
      statValue: totalResistances,
    },
  ];

  if (specialDamage) {
    const spD = {
      statBlock: 'elements' as const,
      stat: 'elements' as const,
      statValue: specialDamage,
    };
    // @ts-ignore
    statTuples.push(spD);
  }

  const calculatedStatTuples = applySkillModifiers(
    statTuples,
    skillAndLevelArray.map((skill) => skill.id),
    skillAndLevelArray.map((skill) => skill.appliedSkillLevel)
  );

  attack = calculatedStatTuples[0].statValue.toString();
  affinity = `${calculatedStatTuples[1].statValue}%`;
  totalDefense = calculatedStatTuples[2].statValue as number;
  totalResistances = calculatedStatTuples[3].statValue as ResistsDisplay;
  if (calculatedStatTuples[4]) {
    specialDamage = calculatedStatTuples[4].statValue;
  }

  const createMeleeWeaponProperties = () => {
    return isMeleeWeapon(Weapon) ? (
      <WeaponPropertiesContainer
        variant={'flexCol'}
        justifyContent="space-evenly"
        height="100%"
      >
        <SharpnessDisplay sharpness={Weapon.baseSharpness} long={true} />
        {isHuntingHorn(Weapon) && (
          <SongsContainer>
            {Weapon.songs.map((song, index) => (
              <SongSpan key={index}>{song.name}</SongSpan>
            ))}
          </SongsContainer>
        )}
      </WeaponPropertiesContainer>
    ) : null;
  };
  const createBowProperties = () => {
    const { coatings, shots } = Weapon as Bow;
    const [arc, ...shotsWithoutArc] = shots;
    return (
      <>
        <ShotsContainer>
          {shotsWithoutArc.map((shot, index) => {
            return (
              <ShotSpan key={index} opacity={shot.isEnabled ? null : '40%'}>
                {shot.type} {shot.level}
              </ShotSpan>
            );
          })}
        </ShotsContainer>
        <CoatingContainer>
          {coatings.map((coating, index) => {
            return (
              <CoatingIcon
                key={index}
                coating={coating.type as any}
                isEnabled={coating.isEnabled}
              />
            );
          })}
        </CoatingContainer>
      </>
    );
  };

  // todo
  const createGunProperties = () => {
    const properties = isBowgun(Weapon) ? Weapon.properties : [];

    return (
      <Container variant="flexRow" width="100%" justifyContent="space-around">
        {properties.map((property: any, index: any) => {
          return (
            <Container variant="flexCol" alignItems="center" key={index}>
              <Text fontWeight="bold">{property.property}</Text>{' '}
              <Text>{property.value}</Text>
            </Container>
          );
        })}
      </Container>
    );
  };

  const createRangedWeaponProperties = () => {
    return isRangedWeapon(Weapon) ? (
      <WeaponPropertiesContainer
        variant="flexCol"
        justifyContent="space-evenly"
        height="100%"
        marginTop="xSmall"
      >
        {isBow(Weapon) && createBowProperties()}
        {isBowgun(Weapon) && createGunProperties()}
      </WeaponPropertiesContainer>
    ) : null;
  };

  const isRampageDecorationWeapon =
    Weapon &&
    Weapon.rampageDecorationSlots!.some((slot) => slot.slotValue !== 0);

  const getRampageSkillInfo = () => {
    const currentRampage =
      isRampageDecorationWeapon &&
      Weapon?.rampageDecorationSlots?.find((slot) => slot.isFilled)?.decoration;

    return currentRampage
      ? getRampageSkillData().find(
          (skill) => skill.id === currentRampage.skillId
        )?.name
      : '-';
  };

  return (
    <Container
      variant="flexCol"
      backgroundColor="background"
      padding="medium"
      borderRadius="medium"
    >
      <LongPane>
        <Container marginBottom="medium" variant="flexCol" alignItems="center">
          <SectionTitle>rise.gearset.co</SectionTitle>
          {qrCode ? <img height={100} width={100} src={qrCode} /> : null}
        </Container>
        <Container
          marginBottom="medium"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gridTemplateRows="1fr 1fr 1fr"
          gridColumnGap="1rem"
          gridRowGap="tiny"
          width="100%"
          margin="small"
        >
          <Container textAlign="center">
            {Weapon ? (
              <GenericStatDetails gear={Weapon}></GenericStatDetails>
            ) : (
              <StatSection textAlign="center">
                <Span opacity="50%">No weapon equipped</Span>
              </StatSection>
            )}
            {Weapon && (
              <Span fontSize="xSmall">
                <Span fontSize="xSmall" fontWeight="bold">
                  Rampage Deco:{' '}
                </Span>
                {getRampageSkillInfo()}
              </Span>
            )}
          </Container>

          <Container
            backgroundColor="detailsBackground"
            border="detailsBorder"
            borderRadius="medium"
            paddingTop="tiny"
            paddingX="small"
            paddingBottom="tiny"
            variant="flexCol"
            flexWrap="wrap"
          >
            <IconAndTextContainer>
              <Icon
                name={Weapon?.weaponType || 'SwordAndShield'}
                height="20"
                width="20"
              />
              <Span paddingLeft="small" fontSize="small">
                Switch Skills
              </Span>
            </IconAndTextContainer>
            {Weapon ? (
              <SwitchSkills weapon={Weapon.weaponType} interactive={false} />
            ) : (
              <Container textAlign="center" fontSize="small" opacity="50%">
                {'-'}
              </Container>
            )}
          </Container>
          <Container>
            {Weapon ? createMeleeWeaponProperties() : null}
            {Weapon ? createRangedWeaponProperties() : null}
          </Container>
          <Container
            backgroundColor="detailsBackground"
            border="detailsBorder"
            borderRadius="medium"
            paddingTop="tiny"
            paddingX="small"
            paddingBottom="tiny"
            variant="flexCol"
            flexWrap="wrap"
            gridRow="2/4"
            gridColumn="2"
          >
            <IconAndTextContainer>
              <Icon name="Talisman" height="20" width="20" />
              <Span paddingLeft="small" fontSize="small">
                Talisman
              </Span>
            </IconAndTextContainer>
            <Container
              variant="flexRow"
              fontSize="tiny"
              width="100%"
              justifyContent="space-evenly"
            >
              {Talisman.appliedSkills.map((appliedSkill, index) => {
                if (appliedSkill) {
                  return (
                    <Span key={index}>
                      {appliedSkill.name} {appliedSkill.appliedSkillLevel + 1}
                    </Span>
                  );
                } else {
                  return (
                    <Span key={index} opacity="50%">
                      Empty Skill
                    </Span>
                  );
                }
              })}
            </Container>
            <Container
              variant="flexRow"
              fontSize="tiny"
              width="100%"
              justifyContent="space-evenly"
            >
              {Talisman.decorationSlots.map((decorationSlot, index) => {
                return decorationSlot.isFilled ? (
                  <DecoNamePill key={index}>
                    {decorationSlot.decoration?.name.replace('Jewel', '')}
                  </DecoNamePill>
                ) : (
                  <DecoNamePill key={index} opacity="50%">
                    Empty Slot
                  </DecoNamePill>
                );
              })}
            </Container>
          </Container>
          <IconAndTextContainer justifyContent="center">
            <Icon name="Petalace" height={20} width={20} />
            <Container
              opacity={Petalace ? null : '50%'}
              marginLeft="1rem"
              fontSize="small"
            >
              {Petalace ? Petalace.name : 'No petalace selected'.toUpperCase()}
            </Container>
          </IconAndTextContainer>
        </Container>
      </LongPane>
      <TempContainer>
        <StatusPane>
          <Container marginBottom="medium">
            <SectionTitle>Attack Stats</SectionTitle>
            {getWeaponStatsSection(Weapon, attack, affinity)}
          </Container>
          {showWeaponQurio && (
            <Container marginBottom="medium">
              <SectionTitle>Weapon Qurio Augments</SectionTitle>
              <AugmentSlots currentConfig={augments} compact />
            </Container>
          )}
          <Container marginBottom="medium">
            <SectionTitle>Defense Stats</SectionTitle>
            {getDefenseStatsSection(loadout, totalDefense, totalResistances)}
          </Container>
        </StatusPane>
        <StatusPane>
          <Container marginBottom="medium">
            <SectionTitle>Equipment</SectionTitle>
            {getEquipmentInfo(loadout)}
          </Container>
        </StatusPane>
        <SkillsContainer interactive={false}></SkillsContainer>
      </TempContainer>
    </Container>
  );
};

const getWeaponStatsSection = (weapon: Weapon, attack: any, affinity: any) => {
  const getSpecials = (weapon: Weapon) => {
    let specials;

    if (weapon && (isMeleeWeapon(weapon) || isBow(weapon))) {
      specials = weapon.specialDamage;
    }
    return specials;
  };

  const specials = getSpecials(weapon);

  return (
    <StatSection>
      <StatSectionRow>
        <IconAndTextContainer>
          <StyledIcon height={20} width={20} name="Attack" />
          <div>Attack</div>
        </IconAndTextContainer>
        <div>{attack === '0' ? '-' : attack}</div>
      </StatSectionRow>
      <StatSectionRow>
        <IconAndTextContainer>
          <StyledIcon name="Element" height={20} width={20} />
          <div>Element</div>
        </IconAndTextContainer>
        <div>
          {specials
            ? specials.map((special, index) => (
                <IconAndTextContainer key={index}>
                  <StyledIcon
                    height={20}
                    width={20}
                    name={special.damageType}
                    marginRight="0"
                  />
                  <div>{special.damageValue}</div>
                </IconAndTextContainer>
              ))
            : '-'}
        </div>
      </StatSectionRow>
      <StatSectionRow>
        <IconAndTextContainer>
          <StyledIcon name="Affinity" height={20} width={20} />
          <div>Affinity</div>
        </IconAndTextContainer>
        <div>
          {affinity === '0%' ? (
            '-'
          ) : (
            <Span color={affinity.indexOf('-') !== -1 ? 'warning' : 'green'}>
              {affinity}
            </Span>
          )}
        </div>
      </StatSectionRow>
      <StatSectionRow>
        <div>Defense Bonus</div>
        <div>{weapon?.defenseBonus || '-'}</div>
      </StatSectionRow>
    </StatSection>
  );
};

const getDefenseStatsSection = (
  loadout: Loadout,
  totalDefense: any,
  totalResistances: any
) => {
  const placeholders = {
    FIR: '-',
    WAT: '-',
    THN: '-',
    ICE: '-',
    DRA: '-',
  };
  return (
    <StatSection>
      <StatSectionRow>
        <div>Defense</div>
        <div>{totalDefense}</div>
      </StatSectionRow>
      {Object.entries(totalResistances).map(
        ([resistance, resistanceValue], index) => {
          const stats = ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'] as const;

          return (
            <StatSectionRow key={`${index}_${stats[index]}`}>
              <IconAndTextContainer>
                <StyledIcon name={stats[index]} height={20} width={20} />
                <span>{_.capitalize(stats[index])} Res.</span>
              </IconAndTextContainer>
              <div>
                <Span
                  color={
                    resistanceValue.toString().indexOf('-') !== -1
                      ? 'warning'
                      : resistanceValue === 0
                      ? 'text'
                      : 'green'
                  }
                >
                  {resistanceValue}
                </Span>
              </div>
            </StatSectionRow>
          );
        }
      )}
      <StatSectionRow></StatSectionRow>
    </StatSection>
  );
};

const getEquipmentInfo = (loadout: Loadout) => {
  const { Talisman, Petalace, LoadoutSwitchSkills, Kinsect, ...Loadout } =
    loadout;
  return (
    <StatSection>
      {Object.entries(Loadout).map(([slotName, slotValue], index) => {
        return (
          <>
            <IconAndTextContainer key={index}>
              <StyledIcon
                name={
                  index === 0
                    ? slotValue?.weaponType || 'SwordAndShield'
                    : slotName
                }
                height={20}
                width={20}
              />
              {slotValue?.name ? (
                <Text width="100%">{slotValue.name}</Text>
              ) : (
                <Text opacity="50%">Empty Slot</Text>
              )}
            </IconAndTextContainer>
            <DecoNameContainer>
              {slotValue?.decorationSlots ? (
                slotValue.decorationSlots.map((decorationSlot) => {
                  return decorationSlot.isFilled ? (
                    <DecoNamePill>
                      {decorationSlot.decoration?.name.replace('Jewel', '')}
                    </DecoNamePill>
                  ) : decorationSlot.slotValue !== 0 ? (
                    <DecoNamePill opacity="50%">
                      {decorationSlot.slotValue} Slot
                    </DecoNamePill>
                  ) : (
                    <DecoNamePill opacity="50%">-</DecoNamePill>
                  );
                })
              ) : (
                <>
                  <DecoNamePill textAlign="center">-</DecoNamePill>
                </>
              )}
            </DecoNameContainer>
          </>
        );
      })}
    </StatSection>
  );
};

/**
 * <StatusPane>
          <Container marginBottom="medium">
            <SectionTitle>Active Skills</SectionTitle>
            <SkillsContainer
              interactive={false}
              layoutComponents={<SectionTitle />}
            ></SkillsContainer>
          </Container>
        </StatusPane>
        <StatusPane>
          <Container marginBottom="medium">
            <SectionTitle>Active Skills</SectionTitle>
            <SkillsContainer
              interactive={false}
              layoutComponents={<SectionTitle />}
            ></SkillsContainer>
          </Container>
        </StatusPane>
 */
