import { Sharpness } from 'models';
import { WeaponSharpnessContainer, SharpnessBar } from './styled';
import { Container } from 'ui/core';

interface SharpnessDisplayProps {
  sharpness: Sharpness;
  long?: boolean;
  bonusApplied?: boolean;
  className?: any;
  showValues?: boolean;
}

export const SharpnessDisplay: React.FC<SharpnessDisplayProps> = ({
  sharpness,
  long = false,
  bonusApplied = false,
  className,
  showValues = false,
}) => {
  const haveValues = Object.entries(sharpness).filter(
    ([color, value]) => value > 0
  );
  const [glowColor] = haveValues[haveValues.length - 1];
  const width = long ? 200 : 100;

  return (
    <Container variant="flexCol" alignItems="center">
      <WeaponSharpnessContainer width={`${width}px`} className={className}>
        {Object.entries(sharpness).map(([color, value]) => {
          return (
            <SharpnessBar
              key={color}
              boxShadow={
                bonusApplied && color === glowColor
                  ? '8px 0px 8px 6px #9eeafb'
                  : null
              }
              width={(width * value) / 100}
              backgroundColor={color}
            />
          );
        })}
      </WeaponSharpnessContainer>
      {showValues ? (
        <Container
          variant="flexRow"
          width={`${width}px`}
          textAlign="center"
          fontSize="small"
        >
          {Object.entries(sharpness).map(([color, value]) => {
            return value !== 0 ? (
              <Container key={color} width={(width * value) / 100}>
                {value * 5}
              </Container>
            ) : null;
          })}
        </Container>
      ) : null}
    </Container>
  );
};
