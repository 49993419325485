import {
  skills,
  sunbreakSkills,
  rampageSkills,
  armorRarity1,
  armorRarity2,
  armorRarity3,
  armorRarity4,
  armorRarity5,
  armorRarity6,
  armorRarity7,
  armorRarity8,
  armorRarity9,
  armorRarity10,
  decorations,
  rampageDecorations,
  sunbreakDecorations,
  bow,
  chargeBlade,
  dualBlades,
  greatSword,
  gunLance,
  hammer,
  heavyBowgun,
  huntingHorn,
  insectGlaive,
  lance,
  lightBowgun,
  longSword,
  sns,
  switchAxe,
} from 'data/refined';
import { DataObject } from 'models/data';

export const loadData = (): DataObject => {
  return {
    skills: [...skills, ...sunbreakSkills],
    armor: {
      rarity1: armorRarity1,
      rarity2: armorRarity2,
      rarity3: armorRarity3,
      rarity4: armorRarity4,
      rarity5: armorRarity5,
      rarity6: armorRarity6,
      rarity7: armorRarity7,
      rarity8: armorRarity8,
      rarity9: armorRarity9,
      rarity10: armorRarity10,
    },
    rampageSkills,
    decorations: [...decorations, ...sunbreakDecorations],
    rampageDecorations: [...rampageDecorations],
    weapons: {
      Bow: bow,
      ChargeBlade: chargeBlade,
      DualBlades: dualBlades,
      GreatSword: greatSword,
      Gunlance: gunLance,
      Hammer: hammer,
      HeavyBowgun: heavyBowgun,
      HuntingHorn: huntingHorn,
      InsectGlaive: insectGlaive,
      Lance: lance,
      LightBowgun: lightBowgun,
      LongSword: longSword,
      SwordAndShield: sns,
      SwitchAxe: switchAxe,
    },
  };
};
