import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
  variant,
} from 'styled-system';
import css from '@styled-system/css';

type SvgProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

export const Svg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  xmlSpace: 'preserve',
  strokeMiterlimit: '1.5',
  strokeLinejoin: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
})<SvgProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;

export const SimpleSvg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  xmlSpace: 'preserve',
  strokeMiterlimit: '8',
  strokeLinejoin: 'miter',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
})<SvgProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;
