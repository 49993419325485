import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M13 20H4L12.5 12L4 4H13L22 12L13 20Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </>
  ),
};
