import styled from 'styled-components';
import css from '@styled-system/css';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  position,
  border,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
  BorderProps,
  variant,
} from 'styled-system';

type TextProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  FlexboxProps &
  BorderProps &
  PositionProps & {
    variant?: 'body' | 'largeTitle' | 'smallBody';
  };

// TODO: rethink variant strategy
export const Text = styled.p<TextProps>`
  ${css({
    marginY: 0,
  })}
  ${compose(color, layout, typography, space, flexbox, position, border)}
  ${variant({
    variants: {
      body: {
        marginY: 0,
      },
      largeTitle: {
        fontSize: 'large',
        fontWeight: 'bold',
      },
      smallBody: {
        fontSize: 'small',
      },
    },
  })}
`;
