import { qurioWeaponSchema, QurioEffect } from './constants';
import { Weapon, isMeleeWeapon, isBow } from 'models/weapons';

type QurioAugment =
  | 'attack'
  | 'affinity'
  | 'element'
  | 'sharpness'
  | 'status'
  | 'rampageDecoration';

export const applyEffectToStat = (
  weapon: any,
  newWeapon: any,
  level: number,
  qurioAugment: QurioAugment
) => {
  const currentEffect = qurioWeaponSchema[qurioAugment].effects[level];
  const { calculationBonus } = currentEffect;

  switch (qurioAugment) {
    case 'attack':
      newWeapon.baseDamage = weapon.baseDamage + calculationBonus;
      break;
    case 'affinity':
      const currAffinity = parseInt(weapon.affinity, 10);
      newWeapon.affinity = `${currAffinity > 0 ? '+' : ''}${
        currAffinity + calculationBonus
      }%`;
      break;
    case 'element':
      if (isMeleeWeapon(weapon) || isBow(weapon)) {
        const elementalSpecialDamage = [] as any;
        const statusSpecialDamage = [] as any;
        // sort the types
        weapon?.specialDamage?.forEach((specialDamage) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            elementalSpecialDamage.push(specialDamage);
          } else {
            statusSpecialDamage.push(specialDamage);
          }
        });

        // only change elemental ones
        const newSpecialDamage = (elementalSpecialDamage || []).map(
          (specialDamage: any) => {
            const { damageType, damageValue } = specialDamage;
            return {
              damageType,
              damageValue: damageValue + calculationBonus,
            };
          }
        );

        newWeapon.specialDamage = [...newSpecialDamage, ...statusSpecialDamage];
      }
      break;
    case 'sharpness':
      if (isMeleeWeapon(weapon)) {
        const newBaseSharpness = { ...weapon.baseSharpness };
        newBaseSharpness[
          `${newBaseSharpness.purple >= 0 ? 'purple' : 'white'}` as
            | 'purple'
            | 'white'
        ] += calculationBonus;
        newWeapon.baseSharpness = newBaseSharpness;
      }
      break;
    case 'status':
      if (isMeleeWeapon(weapon) || isBow(weapon)) {
        const elementalSpecialDamage = [] as any;
        const statusSpecialDamage = [] as any;
        // sort the types
        weapon?.specialDamage?.forEach((specialDamage) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            elementalSpecialDamage.push(specialDamage);
          } else {
            statusSpecialDamage.push(specialDamage);
          }
        });

        // only change status ones
        const newSpecialDamage = (statusSpecialDamage || []).map(
          (specialDamage: any) => {
            const { damageType, damageValue } = specialDamage;
            return {
              damageType,
              damageValue: damageValue + calculationBonus,
            };
          }
        );

        newWeapon.specialDamage = [
          ...elementalSpecialDamage,
          ...newSpecialDamage,
        ];
      }
      break;
    case 'rampageDecoration':
      const newRampageDecorationSlots = weapon!.rampageDecorationSlots?.map(
        (decoSlot: any) => {
          if (decoSlot.slotValue > 0) {
            return {
              ...decoSlot,
              slotValue: decoSlot.slotValue + calculationBonus,
            };
          }
          return decoSlot;
        }
      );

      newWeapon.rampageDecorationSlots = newRampageDecorationSlots;
      break;
    default:
      break;
  }
};

export const resetStat = (
  weapon: any,
  newWeapon: any,
  qurioAugment: QurioAugment
) => {
  switch (qurioAugment) {
    case 'attack':
      newWeapon.baseDamage = weapon.baseDamage;
      break;
    case 'affinity':
      newWeapon.affinity = weapon.affinity;
      break;
    case 'element':
      if (isMeleeWeapon(weapon) || isBow(weapon)) {
        const baseElementalSpecialDamage = [] as any;
        const baseStatusSpecialDamage = [] as any;
        const mutatedElementalSpecialDamage = [] as any;
        const mutatedStatusSpecialDamage = [] as any;
        // sort the types
        weapon?.specialDamage?.forEach((specialDamage) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            baseElementalSpecialDamage.push(specialDamage);
          } else {
            baseStatusSpecialDamage.push(specialDamage);
          }
        });

        newWeapon?.specialDamage?.forEach((specialDamage: any) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            mutatedElementalSpecialDamage.push(specialDamage);
          } else {
            mutatedStatusSpecialDamage.push(specialDamage);
          }
        });

        newWeapon.specialDamage = [
          ...baseElementalSpecialDamage,
          ...mutatedStatusSpecialDamage,
        ];
      }
      break;
    case 'sharpness':
      if (isMeleeWeapon(weapon)) {
        newWeapon.baseSharpness = { ...weapon.baseSharpness };
      }
      break;
    case 'status':
      if (isMeleeWeapon(weapon) || isBow(weapon)) {
        const baseElementalSpecialDamage = [] as any;
        const baseStatusSpecialDamage = [] as any;
        const mutatedElementalSpecialDamage = [] as any;
        const mutatedStatusSpecialDamage = [] as any;
        // sort the types
        weapon?.specialDamage?.forEach((specialDamage) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            baseElementalSpecialDamage.push(specialDamage);
          } else {
            baseStatusSpecialDamage.push(specialDamage);
          }
        });

        newWeapon?.specialDamage?.forEach((specialDamage: any) => {
          if (
            ['Fire', 'Water', 'Thunder', 'Ice', 'Dragon'].includes(
              specialDamage.damageType
            )
          ) {
            mutatedElementalSpecialDamage.push(specialDamage);
          } else {
            mutatedStatusSpecialDamage.push(specialDamage);
          }
        });

        newWeapon.specialDamage = [
          ...mutatedElementalSpecialDamage,
          ...baseStatusSpecialDamage,
        ];
      }
      break;
    case 'rampageDecoration':
      newWeapon.rampageDecorationSlots = [
        ...(weapon?.rampageDecorationSlots || []),
      ];
      break;
    default:
      break;
  }
};
