import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon, Modal } from 'ui/core';

export const StyledArmorSelectContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  })}
`;

export const DataTableContainer = styled(Container)`
  ${css({
    margin: 'medium',
  })}
`;

export const ArmorModalHack = styled(Modal)`
  ${css({
    width: '100%',
  })}
`;
