import React, { useEffect, useState } from 'react';
import { useLoadoutStore, useExportStore, useIsMobile } from 'hooks';
import shallow from 'zustand/shallow';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { useMutation } from 'react-query';
import axios from 'axios';

import {
  GearGenerator,
  Exporter,
  SetPicker,
  ImportLoadout,
  TotalStatsCard,
  SummarySkillsCard,
} from 'components';
import {
  ToolingContainer,
  AppTitleText,
  TinyText,
  TitleContainer,
  ImportButton,
  SimpleGrid,
  ClearButton,
  SetPickerButton,
  TitleRow,
  OpenGenerateButton,
  ExportButton,
  ClearLoadoutConfirmationButton,
  ClearConfirmationContainer,
  ClearConfirmationButtonContainer,
  HelpIcon,
  MobileCollapseIconContainer,
  ColorModeContainer,
  ColorModeButton,
} from './styled';
import { Modal, Container, Icon, Button, Span } from 'ui/core';
import { AnimatePresence, useAnimation } from 'framer-motion';
import { Status } from 'components/Status';

interface ToolingProps {
  colorMode: any;
  handleChangeColorMode: any;
}
export const Tooling: React.FC<ToolingProps> = ({
  colorMode,
  handleChangeColorMode,
}) => {
  const controls = useAnimation();
  const { clearLoadout, loadout } = useLoadoutStore(
    (state) => ({
      assignLoadout: state.assignLoadout,
      clearLoadout: state.clearLoadout,
      loadout: state.loadout,
    }),
    shallow
  );
  const [showClearLoadout, setShowClearLoadout] = useState(false);
  const [showGearGenerator, setShowGearGenerator] = useState(false);
  const [exportLoadout, setExportLoadout] = useState(false);
  const [importLoadout, setImportLoadout] = useState(false);
  const [pickSet, setPickSet] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [createImage, setCreateImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const isMobile = useIsMobile();
  const [showMenu, setShowMenu] = useState(!isMobile);

  const { exportEncryption, setExportEncryption } = useExportStore(
    (state: any) => ({
      exportEncryption: state.exportEncryption,
      setExportEncryption: state.setExportEncryption,
    }),
    shallow
  );

  const { mutate, isLoading, isError, isIdle } = useMutation(
    (loadout: any) => {
      // return axios.post('/api/loadout/export', loadout);
      return axios.post('/.netlify/functions/export', loadout);
    },
    {
      onSuccess: (res) => {
        setExportEncryption(res.data.md5_loadout);
      },
    }
  );

  useEffect(() => {
    if (exportEncryption && createImage) {
      const node = document.getElementById('capture')!;
      const scale = 3;
      const style = {
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top left',
        width: node.offsetWidth + 'px',
        height: node.offsetHeight + 'px',
      };

      const param = {
        height: node.offsetHeight * scale,
        width: (node.offsetWidth - 1) * scale,
        quality: 1,
        style,
      };

      domtoimage
        .toPng(node as any, param)
        .then(function (dataUrl) {
          setImageUrl(dataUrl);

          // var img = new Image();
          // img.src = dataUrl;
          // document.getElementById('result')?.appendChild(img);
          // document.body.appendChild(img);
          //         setImageUrl(dataUrl);
          //         setCreateImage(true);
        })
        .catch(function (error) {
          setImageUrl('');
        });
    }
  }, [createImage, exportEncryption]);

  const handleOnClearConfirm = (e: any) => {
    e.preventDefault();
    setShowClearLoadout(true);
  };

  const handleOnClear = (e: any) => {
    e.preventDefault();
    clearLoadout();
    setShowClearLoadout(false);
  };

  const loadoutIsNull = () => {
    return Object.values(loadout).filter((slot) => slot !== null).length === 0;
  };

  const variants = {
    visible: {
      opacity: 1,
      height: 'auto',
    },
    hidden: {
      opacity: 0,
      height: 0,
    },
  };

  const handleSetShowMenu = () => {
    const direction = showMenu ? { rotate: 0 } : { rotate: -180 };
    controls.start(direction);
    setShowMenu(!showMenu);
  };

  // will always be rendered offscreen. must specify height & width with literals, not relative - so the final result will appear the same across devices
  const createImageExport = () => {
    return (
      <Container position="absolute" variant="flexRow" left="-9999px">
        <Container id="capture" variant="flexRow">
          <Status />
        </Container>
      </Container>
    );
  };
  const URL = process.env.REACT_APP_SITE_URL || 'http://localhost:3000/';
  const exportURL = `${URL}?loadout=`;

  const handlePng = () => {
    mutate(loadout);
    setCreateImage(true);
    //     const node = document.getElementById('capture')!;
    //     const scale = 3;
    //     const style = {
    //       transform: 'scale(' + scale + ')',
    //       transformOrigin: 'top left',
    //       width: node.offsetWidth + 'px',
    //       height: node.offsetHeight + 'px',
    //     };

    //     const param = {
    //       height: node.offsetHeight * scale,
    //       width: (node.offsetWidth - 1) * scale,
    //       quality: 1,
    //       style,
    //     };
    // domtoimage
    //       .toPng(node as any, param)
    //       .then(function (dataUrl) {
    //         setImageUrl(dataUrl);
    //         setCreateImage(true);
    //         // var img = new Image();
    //         // img.src = dataUrl;
    //         // document.getElementById('result')?.appendChild(img);
    //         // document.body.appendChild(img);
    //       })
    //       .catch(function (error) {
    //         setImageUrl('');
    //         setCreateImage(true);
    //       });
  };

  return (
    <ToolingContainer>
      <Container onClick={() => setShowHelp(true)} style={{ cursor: 'help' }}>
        <HelpIcon height={20} width={20} />
      </Container>
      <TitleRow>
        <TitleContainer>
          <AppTitleText>MH</AppTitleText>
          <TinyText>
            <Span
              backgroundColor="pillColor"
              borderRadius="medium"
              paddingX="tiny"
              marginX="tiny"
              paddingY="1px"
              color="white"
            >
              TitleUpdate1!
            </Span>
          </TinyText>
          <ColorModeContainer>
            <Container
              width="45px"
              border="1px solid"
              borderRadius="large"
              height="25px"
              position="relative"
              borderColor="border"
              bg="cardBackground"
              onClick={handleChangeColorMode}
              style={{ cursor: 'pointer' }}
            >
              <Container
                position="absolute"
                animate={{ left: colorMode === 'light' ? '1px' : '25px' }}
              >
                {colorMode === 'light' ? '🌝' : '🌚'}
              </Container>
            </Container>
            {/* <ColorModeButton onClick={handleChangeColorMode}></ColorModeButton> */}
          </ColorModeContainer>
        </TitleContainer>
        {isMobile ? (
          <MobileCollapseIconContainer
            onClick={handleSetShowMenu}
            animate={controls}
          >
            <Icon name={'Up'} />
          </MobileCollapseIconContainer>
        ) : null}
      </TitleRow>
      <AnimatePresence initial={false}>
        {showMenu && (
          <SimpleGrid
            variants={variants}
            animate="visible"
            initial="hidden"
            exit="hidden"
          >
            <ExportButton
              variants={variants}
              variant="primary"
              disabled={loadoutIsNull()}
              onClick={() => setExportLoadout(true)}
            >
              Export URL
            </ExportButton>
            <Button variant="primary" onClick={handlePng}>
              Export PNG
            </Button>
            <ImportButton
              variants={variants}
              variant="secondary"
              onClick={() => setImportLoadout(true)}
            >
              Import
            </ImportButton>
            <ClearButton
              variants={variants}
              variant="secondary"
              onClick={handleOnClearConfirm}
            >
              Clear
            </ClearButton>
            <SetPickerButton
              variants={variants}
              variant="tertiary"
              onClick={() => setPickSet(true)}
            >
              Sets
            </SetPickerButton>
            <OpenGenerateButton
              variants={variants}
              variant="primary"
              onClick={() => setShowGearGenerator(true)}
            >
              Generate
            </OpenGenerateButton>
            {/* hacky but works */}
            {createImageExport()}
          </SimpleGrid>
        )}
      </AnimatePresence>
      {createImage && (
        <Modal
          isOpen={true}
          setIsOpen={setCreateImage}
          modalTitle={"Here's your gearset!"}
          modalDescription="Congratulations, it's beautiful. (If you're using Chrome 🙈)"
        >
          {imageUrl ? (
            <Container variant="flexCol" justifyContent="center">
              <img src={imageUrl} />
              <Button
                disabled={isLoading}
                variant="primary"
                marginTop="1rem"
                onClick={() => saveAs(imageUrl, 'gearset.png')}
              >
                download
              </Button>
            </Container>
          ) : isLoading ? (
            'Loading.'
          ) : isError ? (
            'Sorry! There was an issue creating your image.'
          ) : (
            ''
          )}
        </Modal>
      )}
      {showHelp && (
        <Modal
          isOpen={true}
          setIsOpen={setShowHelp}
          modalTitle={'MH v1.3.0'}
          modalDescription="Hi! Spot any bugs or have an idea you think would be neat? Shoot an email to adrianr.dev@gmail.com."
        >
          <Container>
            Thanks for checking this tool out! Most of the core functionality is
            implemented, but there's a lot in the backlog, including:
          </Container>
          <ul>
            <li>new icons</li>
            <li>redesigned mobile UI</li>
            <li>sunbreak skill contribution to stats</li>
            <li>sunbreak master rank filter</li>
            <li>sunbreak Qurios crafting</li>
          </ul>
          enhancements:
          <ul>
            <li>monster name accounted for in search</li>
            <li>
              <span style={{ textDecoration: 'line-through' }}>
                Rampage weapon multiple ramp-up skill support
              </span>{' '}
              no longer necessary (thanks sunbreak!)
            </li>
            <li>Rampage weapon contribution to total stats</li>
            <li>Set bonus contribution to total stats</li>
          </ul>
          <h3>changelog:</h3>
          <h4>v1.3.0</h4>
          <ul>
            <li>add qurio crafting feature for weapons</li>
            <li>show qurio config in PNG & summary for weapons that qualify</li>
          </ul>
          <h4>v1.2.1</h4>
          <ul>
            <li>add sunbreak title update 1 weapons</li>
            <li>add sunbreak title update 1 decorations</li>
            <li>add rampage decorations to weapons and display</li>
            <li>add sunbreak kinsects</li>
          </ul>
          <h4>v1.2.0</h4>
          <ul>
            <li>add sunbreak title update 1 armors</li>
            <li>add sunbreak title update 1 skills</li>
          </ul>
          <h4>v1.1.1</h4>
          <ul>
            <li>add sunbreak switch skills</li>
            <li>fix dereliction description</li>
            <li>fix dober coil stats</li>
          </ul>
          <h4>v1.1.0</h4>
          <ul>
            <li>add gear generator, first pass!</li>
          </ul>
          <h4>v1.0.5</h4>
          <ul>
            <li>fix skill data on HQ armor</li>
          </ul>
          <h4>v1.0.4</h4>
          <ul>
            <li>skill interaction</li>
          </ul>
          <h4>v1.0.3</h4>
          <ul>
            <li>auto-focus search input in modals</li>
            <li>modal width increased for mobile view</li>
            <li>remove duplicate armor pieces</li>
            <li>more data fixes</li>
          </ul>
          <h4>v1.0.2</h4>
          <ul>
            <li>fix baulo wex skill</li>
          </ul>
          <h4>v1.0.1</h4>
          <ul>
            <li>add skills level to table search</li>
            <li>
              data fixes - armor skill points, decoration & skill relationships
            </li>
            <li>fixed talisman decoration interaction</li>
          </ul>
        </Modal>
      )}
      {showClearLoadout && (
        <Modal
          isOpen={true}
          setIsOpen={setShowClearLoadout}
          modalTitle="Clear Loadout"
        >
          <ClearConfirmationContainer>
            Are you sure you want to reset your build?
          </ClearConfirmationContainer>
          <ClearConfirmationButtonContainer>
            <ClearLoadoutConfirmationButton
              variant="warning"
              marginRight="xSmall"
              marginLeft={0}
              onClick={handleOnClear}
            >
              Reset
            </ClearLoadoutConfirmationButton>
            <ClearLoadoutConfirmationButton
              variant="secondary"
              marginLeft="xSmall"
              onClick={() => setShowClearLoadout(false)}
            >
              Cancel
            </ClearLoadoutConfirmationButton>
          </ClearConfirmationButtonContainer>
        </Modal>
      )}
      {showGearGenerator && (
        <Modal
          isOpen={true}
          setIsOpen={setShowGearGenerator}
          modalTitle="Gear Generator"
          modalDescription="Generate gearsets that meet the specified criteria."
        >
          <GearGenerator toggleModal={setShowGearGenerator} />
        </Modal>
      )}
      {exportLoadout && (
        <Modal
          isOpen={true}
          setIsOpen={setExportLoadout}
          modalTitle="Export Loadout"
          modalDescription="Generate a code to share your loadout."
        >
          <Exporter />
        </Modal>
      )}
      {pickSet && (
        <Modal
          isOpen={true}
          setIsOpen={setPickSet}
          modalTitle="Set Picker"
          modalDescription="Pick a set to auto populate your loadout as a jumping off point, or to preview its skills!"
        >
          <SetPicker toggleModal={setPickSet} />
        </Modal>
      )}
      {importLoadout && (
        <Modal
          isOpen={true}
          setIsOpen={setImportLoadout}
          modalTitle="Import"
          modalDescription="Paste a loadout code here to import a loadout."
        >
          <ImportLoadout toggleModal={setImportLoadout}></ImportLoadout>
        </Modal>
      )}
    </ToolingContainer>
  );
};
