import { DecorationSlot } from "models/DecorationSlot";
import { ArmorType } from "models/enums/ArmorType";
import { AppliedSkill } from "models/Skill";

export interface Armor {
  id: number;
  name: string;
  rarity: number;
  defenseStats: DefenseStats;
  decorationSlots: DecorationSlot[];
  armorType: ArmorType | string;
  appliedSkills: AppliedSkill[];
}

export interface DefenseStats {
  parsedDefenseValue: number | string;
  fireResistValue: number | string;
  waterResistValue: number | string;
  thunderResistValue: number | string;
  iceResistValue: number | string;
  dragonResistValue: number | string;
}

export const isArmor = (tbd: any): tbd is Armor => {
  return tbd.hasOwnProperty("armorType");
};
