import styled from 'styled-components';
import css from '@styled-system/css';
import { Span, Text } from 'ui/core';

export const ActiveSkillPill = styled(Span)`
  ${css({
    backgroundColor: 'decoBackground',
    borderRadius: 'medium',
    paddingX: 'tiny',
    marginRight: 'tiny',
    border: '1px solid transparent',
    fontSize: 'tiny',
    display: 'inline-block',
  })}
  ':hover': {
    ${css({
      transition: 'all .2s ease',
      border: '1px solid',
      borderColor: 'border',
    })}
  }
`;

export const NoSlotText = styled(Text)`
  ${css({
    marginLeft: 'tiny',
  })}
`;
