export enum Rarity {
  DEFAULT = "DEFAULT",
  RARITYONE = "RARITYONE",
  RARITYTWO = "RARITYTWO",
  RARITYTHREE = "RARITYTHREE",
  RARITYFOUR = "RARITYFOUR",
  RARITYFIVE = "RARITYFIVE",
  RARITYSIX = "RARITYSIX",
  RARITYSEVEN = "RARITYSEVEN",
}
