import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M8 13.5L9.5 11.5L10.5 5.5L12 11L13.5 8.5V2L15.5 5L16 9.5L17.5 8L18.5 9L21 4.5V17L16 21H8V13.5Z"
        fill="#C4C4C4"
        stroke="black"
        strokeMiterlimit="7.68167"
      />
      <path d="M8 14V19H15.5L19 16.5V11.5L16.5 14H8Z" fill="white" />
      <path d="M8 12.5H5V15V18V19.5H8V19V14V12.5Z" fill="white" />
      <path d="M5 18V15H2V18H5Z" fill="white" />
      <path
        d="M8 12.5H5M8 12.5V19.5M8 12.5V14M5 12.5V19.5M5 12.5V15M5 19.5H8M5 19.5V18M8 19.5V19M8 14H16.5L19 11.5V16.5L15.5 19H8M8 14V19M5 15H2V18H5M5 15V18"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
