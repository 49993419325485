// low rank
export const lowRankSets = [
  {
    rarity: 1,
    sets: [
      {
        name: 'Leather',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Chainmail',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Kamura',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Hunter',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Izuchi',
        monster: 'great izuchi',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Baggi',
        monster: 'great baggi',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Arzuros',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Lagombi',
        armorIds: [35, 36, 37, 38, 39],
      },
    ],
  },
  {
    rarity: 2,
    sets: [
      {
        name: 'Alloy',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Ingot',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Melahoa',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Death Stench',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Mosgharl',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Vaik',
        armorIds: [25, 26, 27, 28, 29],
      },
      { name: 'Chaos', armorIds: [30, 31] },
      {
        name: 'Edel',
        armorIds: [32, 33, 34, 35, 36],
      },
      {
        name: 'Skalda',
        armorIds: [37, 38, 39, 40, 41],
      },
      {
        name: 'Spio',
        armorIds: [42, 43, 44, 45, 46],
      },
      {
        name: 'Shell Studded',
        armorIds: [47, 48, 49, 50, 51],
      },
      {
        name: 'Bishaten',
        armorIds: [52, 53, 54, 55, 56],
      },
      {
        name: 'Aknosom',
        armorIds: [57, 58, 59, 60, 61],
      },
      {
        name: 'Tetranadon',
        armorIds: [62, 63, 64, 65, 66],
      },
      {
        name: 'Somnacanth',
        armorIds: [67, 68, 69, 70, 71],
      },
      { name: 'Gargwa', armorIds: [72] },
      { name: 'Slagtoth', armorIds: [73, 74] },
      {
        name: 'Rhenoplos',
        armorIds: [75, 76, 77, 78, 79],
      },
      { name: 'Jaggi', monster: 'great jaggi', armorIds: [80, 81, 153] },
      {
        name: 'Bnahabra',
        armorIds: [82, 83, 84, 85, 86],
      },
      { name: 'Bullfango', armorIds: [87] },
      {
        name: 'Remobra',
        armorIds: [88, 89, 90, 91, 92],
      },
      { name: 'Droth', armorIds: [93, 94, 95] },
      { name: 'Uroktor', armorIds: [96, 97] },
      {
        name: 'Wroggi',
        monster: 'great wroggi',
        armorIds: [98, 99, 100, 101, 102],
      },
      {
        name: 'Volvidon',
        armorIds: [103, 104, 105, 106, 107],
      },
      {
        name: 'Ludroth',
        monster: 'royal ludroth',
        armorIds: [108, 109, 110, 111, 112],
      },
      {
        name: 'Barroth',
        armorIds: [113, 114, 115, 116, 117],
      },
      {
        name: 'Khezu',
        armorIds: [118, 119, 120, 121, 122],
      },
      {
        name: 'Barioth',
        armorIds: [123, 124, 125, 126, 127],
      },
      {
        name: 'Rathian',
        armorIds: [128, 129, 130, 131, 132],
      },
      {
        name: 'Basarios',
        armorIds: [133, 134, 135, 136, 137],
      },
      {
        name: 'Kulu Ya Ku',
        armorIds: [138, 139, 140, 141, 142],
      },
      {
        name: 'Pukei Pukei',
        armorIds: [143, 144, 145, 146, 147],
      },
      {
        name: 'Kadachi',
        monster: 'tobi kadachi',
        armorIds: [148, 149, 150, 151, 152],
      },
      {
        name: 'Bone',
        armorIds: [154, 155, 156, 157, 158],
      },
    ],
  },
  {
    rarity: 3,
    sets: [
      {
        name: 'Makluva',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Aelucanth',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Rhopessa',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Utsushi Visible',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Channeler',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Utushi Hidden',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Medium',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Jelly',
        armorIds: [35, 36, 37, 38, 39],
      },
      {
        name: 'Goss Harag',
        armorIds: [40, 41, 42, 43, 44],
      },
      {
        name: 'Almudron',
        armorIds: [45, 46, 47, 48, 49],
      },
      {
        name: 'Nargacuga',
        armorIds: [50, 51, 52, 53, 54],
      },
      {
        name: 'Rathalos',
        armorIds: [55, 56, 57, 58, 59],
      },
      {
        name: 'Tigrex',
        armorIds: [60, 61, 62, 63, 64],
      },
      {
        name: 'Diablos',
        armorIds: [65, 66, 67, 68, 69],
      },
      {
        name: 'Zinogre',
        armorIds: [70, 71, 72, 73, 74],
      },
      {
        name: 'Anjanath',
        armorIds: [75, 76, 77, 78, 79],
      },
      { name: 'Skull', armorIds: [80] },
      {
        name: 'Sinister',
        monster: 'magnamalo',
        armorIds: [81, 82, 83, 84, 85],
      },
      {
        name: 'Mizutsune',
        armorIds: [86, 87, 88, 89, 90],
      },
      {
        name: 'Brigade',
        armorIds: [91, 92, 93, 94, 95],
      },
      {
        name: 'Kamurai',
        armorIds: [96, 97, 98, 99, 100],
      },
      {
        name: 'Sinister Seal',
        armorIds: [101, 102, 103, 104, 105],
      },
      { name: 'Fox', armorIds: [106] },
      { name: 'Theater', armorIds: [107] },
      {
        name: 'Swallow',
        armorIds: [108, 109, 110],
      },
      {
        name: 'Guild Cross',
        armorIds: [111, 112, 113, 114, 115],
      },
      {
        name: 'Black Belt',
        monster: 'starter',
        armorIds: [116, 117, 118, 119, 120],
      },
    ],
  },
];

// high rank
export const highRankSets = [
  {
    rarity: 4,
    sets: [
      {
        name: 'Kamura S',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Leather S',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Chainmail S',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Hunter S',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Alloy S',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Ingot S',
        armorIds: [25, 26, 27, 28, 29],
      },
      { name: 'Chaos Arch', armorIds: [30, 31] },
      {
        name: 'Edel S',
        armorIds: [32, 33, 34, 35, 36],
      },
      {
        name: 'Skalda S',
        armorIds: [37, 38, 39, 40, 41],
      },
      {
        name: 'Spio S',
        armorIds: [42, 43, 44, 45, 46],
      },
      {
        name: 'Aknosom S',
        armorIds: [47, 48, 49, 50, 51],
      },
      {
        name: 'Tetranadon S',
        armorIds: [52, 53, 54, 55, 56],
      },
      {
        name: 'Izuchi S',
        monster: 'great izuchi',
        armorIds: [57, 58, 59, 60, 61],
      },
      { name: 'Slagtoth S', armorIds: [62, 63] },
      {
        name: 'Rhenoplos S',
        armorIds: [64, 65, 66, 67, 68],
      },
      {
        name: 'Jaggi S',
        monster: 'great jaggi',
        armorIds: [69, 70, 120],
      },
      {
        name: 'Bnahabra S',
        armorIds: [71, 72, 73, 74, 75],
      },
      { name: 'Bullfango S', armorIds: [76] },
      { name: 'Droth S', monster: 'ludroth', armorIds: [77, 78, 79] },
      {
        name: 'Wroggi S',
        monster: 'great wroggi',
        armorIds: [80, 81, 82, 83, 84],
      },
      {
        name: 'Baggi S',
        monster: 'great baggi',
        armorIds: [85, 86, 87, 88, 89],
      },
      {
        name: 'Arzuros S',
        armorIds: [90, 91, 92, 93, 94],
      },
      {
        name: 'Volvidon S',
        armorIds: [95, 96, 97, 98, 99],
      },
      {
        name: 'Ludroth S',
        monster: 'royal ludroth',
        armorIds: [100, 101, 102, 103, 104],
      },
      {
        name: 'Barroth S',
        armorIds: [105, 106, 107, 108, 109],
      },
      {
        name: 'Khezu S',
        armorIds: [110, 111, 112, 113, 114],
      },
      {
        name: 'Kulu Ya Ku S',
        armorIds: [115, 116, 117, 118, 119],
      },
      {
        name: 'Lagombi S',
        armorIds: [120, 121, 122, 123, 124],
      },
      {
        name: 'Bone S',
        armorIds: [125, 126, 127, 128, 129],
      },
    ],
  },
  {
    rarity: 5,
    sets: [
      {
        name: 'Dober',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Melahoa S',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Makluva S',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Death Stench S',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Mosgharl S',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Vaik S',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Utsushi Visible S',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Channeler S',
        armorIds: [35, 36, 37, 38, 39],
      },
      {
        name: 'Utsushi Hidden S',
        armorIds: [40, 41, 42, 43, 44],
      },
      {
        name: 'Medium S',
        armorIds: [45, 46, 47, 48, 49],
      },
      {
        name: 'Shell Studded S',
        armorIds: [50, 51, 52, 53, 54],
      },
      {
        name: 'Bishaten S',
        armorIds: [55, 56, 57, 58, 59],
      },
      {
        name: 'Somnacanth S',
        armorIds: [60, 61, 62, 63, 64],
      },
      {
        name: 'Remobra S',
        armorIds: [65, 66, 67, 68, 69],
      },
      { name: 'Uroktor S', armorIds: [70, 71] },
      {
        name: 'Barioth S',
        armorIds: [72, 73, 74, 75, 76],
      },
      {
        name: 'Rathian S',
        armorIds: [77, 78, 79, 80, 81],
      },
      {
        name: 'Basarios S',
        armorIds: [82, 83, 84, 85, 86],
      },
      {
        name: 'Pukei Pukei S',
        armorIds: [87, 88, 89, 90, 91],
      },
      {
        name: 'Jyuratodus',
        armorIds: [92, 93, 94, 95, 96],
      },
      {
        name: 'Kadachi S',
        monster: 'tobi kadachi',
        armorIds: [97, 98, 99, 100, 101],
      },
      {
        name: 'Chrome Metal',
        armorIds: [102, 103],
      },
      { name: 'Skull S', armorIds: [104] },
    ],
  },
  {
    rarity: 6,
    sets: [
      {
        name: 'Aelucanth S',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Rhopessa S',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Jelly S',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Rakna',
        monster: 'rakna kadaki',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Goss Harag S',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Almudron S',
        armorIds: [25, 26, 27, 28, 29],
      },
      { name: 'Gargwa S', armorIds: [30] },
      {
        name: 'Nargacuga S',
        armorIds: [31, 32, 33, 34, 35],
      },
      {
        name: 'Rathalos S',
        armorIds: [36, 37, 38, 39, 40],
      },
      {
        name: 'Tigrex S',
        armorIds: [41, 42, 43, 44, 45],
      },
      {
        name: 'Diablos S',
        armorIds: [46, 47, 48, 49, 50],
      },
      {
        name: 'Zinogre S',
        armorIds: [51, 52, 53, 54, 55],
      },
      {
        name: 'Anjanath S',
        armorIds: [56, 57, 58, 59, 60],
      },
      { name: 'Cunning', armorIds: [61] },
      { name: 'Mighty Bow', armorIds: [62] },
      {
        name: 'Sinister S',
        monster: 'magnamalo',
        armorIds: [63, 64, 65, 66, 67],
      },
      {
        name: 'Mizutsune S',
        armorIds: [68, 69, 70, 71, 72],
      },
      {
        name: 'Brigade S',
        armorIds: [73, 74, 75, 76, 77],
      },
      { name: 'Canyne', armorIds: [78] },
      {
        name: 'Rider',
        armorIds: [79, 80, 81, 82, 83],
      },
    ],
  },
  {
    rarity: 7,
    sets: [
      {
        name: 'Damascus',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Ibushi',
        monster: 'wind serpent ibushi',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Narwa',
        monster: 'thunder serpent narwa',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Kushala',
        monster: 'kushala daora',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Mizuha',
        monster: 'chameleos',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Kaiser',
        monster: 'teostra',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Golden',
        monster: 'rajang',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Valstrax',
        armorIds: [35, 36, 37, 38, 39],
      },
      {
        name: 'Bazelgeuse',
        armorIds: [40, 41, 42, 43, 44],
      },
      { name: 'Flame', armorIds: [45] },
      { name: 'Feather', armorIds: [46] },
      {
        name: 'Azure Age',
        monster: 'event quest usj',
        armorIds: [47, 48, 49, 50, 51],
      },
      {
        name: 'Black Leather',
        monster: 'event quest remobra',
        armorIds: [51],
      },
      {
        name: 'Shadow',
        monster: 'event quest daora teostra',
        armorIds: [52],
      },
    ],
  },
];

// TODO: actual data
export const masterRankSets = [
  {
    rarity: 8,
    sets: [
      {
        name: 'Kamura Legacy',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Leather X',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Chainmail X',
        armorIds: [10, 11, 12, 13, 14],
      },
      {
        name: 'Hunter X',
        armorIds: [15, 16, 17, 18, 19],
      },
      {
        name: 'Alloy X',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Bone X',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Gargwa X',
        armorIds: [30],
      },
      {
        name: 'Rhenoplos X',
        armorIds: [31, 32, 33, 34, 35],
        monster: 'rhenoplos',
      },
      {
        name: 'Bullfango X',
        armorIds: [36],
      },
      {
        name: 'Droth X',
        armorIds: [37, 38, 39],
      },
      {
        name: 'Bnahabra X',
        armorIds: [40, 41, 42, 43, 44],
        monster: 'bnahabra',
      },
      {
        name: 'Hornetaur',
        armorIds: [45, 46, 47, 48, 49],
        monster: 'hornetaur',
      },
      {
        name: 'Vespoid',
        armorIds: [50, 51, 52, 53, 54],
        monster: 'vespoid',
      },
      {
        name: 'Jaggi X',
        armorIds: [55, 56, 57],
        monster: 'great jaggi',
      },
      {
        name: 'Velociprey',
        armorIds: [58, 59, 60, 61, 62],
        monster: 'velociprey',
      },
      {
        name: 'Izuchi X',
        armorIds: [63, 64, 65, 66, 67],
        monster: 'great izuchi',
      },
      {
        name: 'Baggi X',
        armorIds: [68, 69, 70, 71, 72],
        monster: 'great baggi',
      },
      {
        name: 'Kulu-Ya-Ku X',
        armorIds: [73, 74, 75, 76, 77],
      },
      {
        name: 'Wroggi X',
        armorIds: [78, 79, 80, 81, 82],
        monster: 'great wroggi',
      },
      {
        name: 'Arzuros X',
        armorIds: [83, 84, 85, 86, 87],
      },
      {
        name: 'Lagombi X',
        armorIds: [88, 89, 90, 91, 92],
      },
      {
        name: 'Volvidon X',
        armorIds: [93, 94, 95, 96, 97],
      },
      {
        name: 'Aknosom X',
        armorIds: [98, 99, 100, 101, 102],
      },
      {
        name: 'Ludroth X',
        armorIds: [103, 104, 105, 106, 107],
        monster: 'royal ludroth',
      },
      {
        name: 'Barroth X',
        armorIds: [108, 109, 110, 111, 112],
      },
      {
        name: 'Hermitaur',
        armorIds: [113, 114, 115, 116, 117],
        monster: 'daimyo',
      },
      {
        name: 'Tetranadon X',
        armorIds: [118, 119, 120, 121, 122],
      },
      {
        name: 'Ingot X',
        armorIds: [123, 124, 125, 126, 127],
      },
      {
        name: 'Skalda X',
        armorIds: [128, 129, 130, 131, 132],
      },
      {
        name: 'Spio X',
        armorIds: [133, 134, 135, 136, 137],
      },
      {
        name: 'Chaoshroom',
        armorIds: [138, 139],
        monster: 'mushroom',
      },
      {
        name: 'Edel X',
        armorIds: [140, 141, 142, 143, 144],
      },
      {
        name: 'Shell Studded X',
        armorIds: [145, 146, 147, 148, 149],
      },
      {
        name: 'Melahoa X',
        armorIds: [150, 151, 152, 153, 154],
      },
      {
        name: 'Five Element',
        armorIds: [155, 156, 157, 158, 159],
        monster: 'meowcenaries',
      },
      {
        name: 'Squire',
        armorIds: [160, 161, 162, 163, 164],
      },
      {
        name: 'Yukumo Sky',
        armorIds: [165, 166, 167, 168, 169],
      },
      {
        name: 'Cohoot',
        armorIds: [170],
      },
      {
        name: 'Slagtoth X',
        armorIds: [171, 172],
      },
      {
        name: 'Khezu X',
        armorIds: [173, 174, 175, 176, 177],
      },
      {
        name: 'Bishaten X',
        armorIds: [178, 179, 180, 181, 182],
      },
      {
        name: 'Orangaten',
        armorIds: [183, 184, 185, 186, 187],
      },
      {
        name: 'Pukei-Pukei X',
        armorIds: [188, 189, 190, 191, 192],
      },
      {
        name: 'Jyuratodus X',
        armorIds: [193, 194, 195, 196, 197],
      },
      {
        name: 'Basarios X',
        armorIds: [198, 199, 200, 201, 202],
      },
      {
        name: 'Somnacanth X',
        armorIds: [203, 204, 205, 206, 207],
      },
      {
        name: 'Auroracanth',
        armorIds: [208, 209, 210, 211, 212],
      },
      {
        name: 'Rathian X',
        armorIds: [213, 214, 215, 216, 217],
      },
      {
        name: 'Kadachi X',
        armorIds: [218, 219, 220, 221, 222],
        monster: 'tobi kadachi',
      },
      {
        name: 'Anjanath X',
        armorIds: [223, 224, 225, 226, 227],
      },
      {
        name: 'Skull X',
        armorIds: [228],
      },
      {
        name: 'Dober X',
        armorIds: [229, 230, 231, 232, 233],
      },
      {
        name: 'Vaik X',
        armorIds: [234, 235, 236, 237, 238],
      },
      {
        name: 'Mosgharl X',
        armorIds: [239, 240, 241, 242, 243],
      },
      {
        name: 'Death Stench X',
        armorIds: [249, 250, 251, 252, 253],
      },
      {
        name: 'Makluva X',
        armorIds: [254, 255, 256, 257, 258],
      },
      {
        name: 'Aelucanth X',
        armorIds: [259, 260, 261, 262, 263],
      },
      {
        name: 'Rhopessa X',
        armorIds: [264, 265, 266, 267, 268],
      },
      {
        name: 'Artillery',
        armorIds: [269, 270, 271, 272, 273],
      },
      {
        name: 'Guild Bard',
        armorIds: [274, 275, 276, 277, 278],
      },
      {
        name: 'Scholar',
        armorIds: [279, 280, 281, 282, 283],
      },
      {
        name: 'Guardian',
        armorIds: [284, 285, 286, 287, 288],
      },
      {
        name: 'Monksnail',
        armorIds: [289],
      },
      {
        name: 'Brigade X',
        armorIds: [290, 291, 292, 293, 294],
      },
      {
        name: 'Barioth X',
        armorIds: [295, 296, 297, 298, 299],
      },
      {
        name: 'Sinister Demon',
        armorIds: [300, 301, 302, 303, 304],
      },
      {
        name: 'Nargacuga X',
        armorIds: [305, 306, 307, 308, 309],
      },
      {
        name: 'Goss Harag X',
        armorIds: [310, 311, 312, 313, 314],
      },
      {
        name: 'Golm',
        armorIds: [315, 316, 317, 318, 319],
        monster: 'garangolm',
      },
      {
        name: 'Ceanataur',
        armorIds: [320, 321, 322, 323, 324],
      },
      {
        name: 'Almudron X',
        armorIds: [325, 326, 327, 328, 329],
      },
      {
        name: 'Rakna X',
        armorIds: [330, 331, 332, 333, 334],
        monster: 'rakna kadaki',
      },
    ],
  },
  {
    rarity: 9,
    sets: [
      {
        name: 'Magmadron',
        armorIds: [0, 1, 2, 3, 4],
      },
      {
        name: 'Pyre-Kadaki',
        armorIds: [5, 6, 7, 8, 9],
      },
      {
        name: 'Utsushi True (V)',
        armorIds: [15, 16, 17, 18, 19],
        monster: 'visible',
      },
      {
        name: 'Channeler (Spring)',
        armorIds: [20, 21, 22, 23, 24],
      },
      {
        name: 'Utsushi True (H)',
        armorIds: [25, 26, 27, 28, 29],
        monster: 'hidden',
      },
      {
        name: 'Medium (Light)',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Chrome Metal X',
        armorIds: [35, 36],
      },
      {
        name: 'Uroktor X',
        armorIds: [37, 38],
      },
      {
        name: 'Remobra X',
        armorIds: [39, 40, 41, 42, 43],
      },
      {
        name: 'Mizutsune X',
        armorIds: [44, 45, 46, 47, 48],
      },
      {
        name: 'Rathalos X',
        armorIds: [49, 50, 51, 52, 53],
      },
      {
        name: 'Zinogre X',
        armorIds: [54, 55, 56, 57, 58],
      },
      {
        name: 'Tigrex X',
        armorIds: [59, 60, 61, 62, 63],
      },
      {
        name: 'Diablos X',
        armorIds: [64, 65, 66, 67, 68],
      },
      {
        name: 'Gore',
        armorIds: [69, 70, 71, 72, 73],
        monster: 'gore magala',
      },
      {
        name: 'Regios',
        armorIds: [74, 75, 76, 77, 78],
        monster: 'seregios',
      },
      {
        name: 'Astalos',
        armorIds: [79, 80, 81, 82, 83],
      },
      {
        name: 'Lunagaron',
        armorIds: [84, 85, 86, 87, 88],
      },
      {
        name: 'Espinas',
        armorIds: [89, 90, 91, 92, 93],
      },
      {
        name: 'Hoplite',
        armorIds: [94, 95, 96, 97, 98],
      },
      {
        name: 'Hawk',
        armorIds: [99, 100, 101, 102, 103],
      },
      {
        name: 'Lecturer',
        armorIds: [104, 105, 106, 107, 108],
      },
      {
        name: 'Dignified',
        armorIds: [109, 110, 111, 112, 113],
      },
      {
        name: 'Barbania',
        armorIds: [114, 115, 116, 117, 118],
      },
      {
        name: 'Snowshear',
        armorIds: [119, 120, 121, 122, 123],
      },
      {
        name: "Grand God's Peer",
        armorIds: [124, 125, 126, 127, 128],
      },
      {
        name: 'Bazelgeuse X',
        armorIds: [129, 130, 131, 132, 133],
      },
      {
        name: 'Damascus X',
        armorIds: [134, 135, 136, 137, 138],
      },
      {
        name: 'Kushala X',
        armorIds: [139, 140, 141, 142, 143],
        monster: 'kushala daora',
      },
      {
        name: 'Grand Mizuha',
        armorIds: [144, 145, 146, 147, 148],
        monster: 'chameleos',
      },
      {
        name: 'Kaiser X',
        armorIds: [149, 150, 151, 152, 153],
        monster: 'teostra',
      },
      {
        name: 'Arc (M)',
        armorIds: [154, 155, 156, 157, 158],
        monster: 'shagaru magala male man boy',
      },
      {
        name: 'Storge (F)',
        armorIds: [159, 160, 161, 162, 163],
        monster: 'shagaru magala female woman girl',
      },
      {
        name: 'Malzeno Helm',
        armorIds: [164, 165, 166, 167, 168],
      },
      {
        name: 'Professor',
        armorIds: [169, 170, 171, 172, 173],
      },
      {
        name: 'Charite',
        armorIds: [174, 175, 176, 177, 178],
      },
      {
        name: 'Scholarly',
        armorIds: [179, 180, 181, 182, 183],
      },
      {
        name: 'Commission',
        armorIds: [184, 185, 186, 187, 188],
      },
      {
        name: 'Jelly X',
        armorIds: [189, 190, 191, 192, 193],
      },
      {
        name: 'Sailor',
        armorIds: [194, 195, 196, 197, 198],
      },
      {
        name: 'Guild Palace',
        armorIds: [199, 200, 201, 202, 203],
      },
    ],
  },
  {
    rarity: 10,
    sets: [
      {
        name: 'Archfiend Armor',
        armorIds: [0, 1, 2, 3, 4],
        monster: 'archfiend gaismagorm',
      },
      {
        name: "Ibushi's Pure",
        armorIds: [5, 6, 7, 8, 9],
        monster: 'wind serpent',
      },
      {
        name: "Narwa's Pure",
        armorIds: [10, 11, 12, 13, 14],
        monster: 'thunder serpent',
      },
      {
        name: 'Valstrax - Eclipse',
        armorIds: [15, 16, 17, 18, 19],
        monster: 'crimson',
      },
      {
        name: 'Divine Ire',
        armorIds: [20, 21, 22, 23, 24],
        monster: 'furious rajang',
      },
      {
        name: 'Sinister Grudge',
        armorIds: [25, 26, 27, 28, 29],
      },
      {
        name: 'Outpost HQ',
        armorIds: [30, 31, 32, 33, 34],
      },
      {
        name: 'Soaring Feather',
        armorIds: [40],
      },
      {
        name: 'Blessed Feather',
        armorIds: [41],
      },
      {
        name: 'Pride',
        armorIds: [42, 43, 44, 45, 46],
        monster: 'seething bazelgeuse',
      },
      {
        name: 'Golden',
        armorIds: [47, 48, 49, 50, 51],
        monster: 'golden rathalos',
      },
      {
        name: 'Silver',
        armorIds: [52, 53, 54, 55, 56],
        monster: 'silver rathian',
      },
      {
        name: 'Lambent',
        armorIds: [57, 58, 59, 60, 61],
        monster: 'lucent nargacuga',
      },
    ],
  },
];
