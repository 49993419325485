import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M3 14.5385V6.46154L5.28571 3H8.71429L11 6.46154V14.5385L8.71429 18H5.28571L3 14.5385Z"
        fill="#A0E6F4"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M16 19.6923V14.3077L17.4286 12H19.5714L21 14.3077V19.6923L19.5714 22H17.4286L16 19.6923Z"
        fill="#A0E6F4"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
