import { useEffect, useState } from 'react';

const MOBILE_WIDTH = 600;

export const useIsMobile = () => {
  const windowSize = window.innerWidth;
  const [isMobile, setIsMobile] = useState(windowSize < MOBILE_WIDTH);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      const currentWindowSize = window.innerWidth;
      setIsMobile(currentWindowSize < MOBILE_WIDTH);
    });
  }, [windowSize]);

  return isMobile;
};
