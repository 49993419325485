import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M13.5 8H5V10L4 11H14V7.5L13.5 8Z" fill="white" />
      <path d="M10 2L8 4L7 4.5H16.5L20 6.5V5L16.5 2H10Z" fill="white" />
      <path
        d="M5 8H13.5L14 7.5L14.5 7H17L18 8V8.5L20 8V6.5L16.5 4.5H7L5 5.5V8Z"
        fill="white"
      />
      <path
        d="M17 7H14.5L14 7.5V11H4V13L8 17H9H10.5L11.5 16L16 11.5H17L18 10V8.5V8L17 7Z"
        fill="white"
      />
      <path d="M18 10L17 11.5H16L11.5 16H16L20 12V8L18 8.5V10Z" fill="white" />
      <path d="M11.5 16L10.5 17H9V18V19H16V18.5V16H11.5Z" fill="white" />
      <path d="M16 19H9V18L7 20V22H17.5V20L16 18.5V19Z" fill="white" />
      <path d="M13.5 8H5V10L4 11H14V7.5L13.5 8Z" fill="black" />
      <path
        d="M5 8H13.5L14 7.5M5 8V10L4 11M5 8V5.5L7 4.5M4 11V13L8 17H9M4 11H14V7.5M16 16L20 12V8M16 16H11.5M16 16V18.5M11.5 16L16 11.5H17L18 10V8.5M11.5 16L10.5 17H9M9 17V18M16 18.5V19H9V18M16 18.5L17.5 20V22H7V20L9 18M7 4.5L8 4L10 2H16.5L20 5V6.5M7 4.5H16.5L20 6.5M20 6.5V8M18 8.5V8L17 7H14.5L14 7.5M18 8.5L20 8"
        stroke="black"
      />
      <path d="M8.5 21V22M10.5 21V22M14 21V22M16 21V22" stroke="black" />
      <path d="M16 9V10" stroke="black" />
    </>
  ),
};
