import {
  State,
  Loadout,
  Slot,
  Gear,
  SwitchSkill,
  Talisman,
  QurioWeaponConfig,
} from 'models/state';
import { DecorationSlot } from 'models';
import create from 'zustand';

// zustand experiment
const initialTalisman = {
  decorationSlots: [
    { slotPosition: 0, slotValue: 4, isFilled: false },
    { slotPosition: 1, slotValue: 4, isFilled: false },
    { slotPosition: 2, slotValue: 4, isFilled: false },
  ],
  appliedSkills: [null, null],
} as Talisman;

const MAX_SLOTS = 5;

const defaultQurioWeaponConfig = {
  availableSlots: MAX_SLOTS,
  attack: -1,
  affinity: -1,
  element: -1,
  sharpness: -1,
  status: -1,
  rampageDecoration: -1,
} as QurioWeaponConfig;

export const useLoadoutStore = create((set: any) => ({
  loadout: {
    Weapon: null,
    Head: null,
    Chest: null,
    Gloves: null,
    Waist: null,
    Feet: null,
    Talisman: initialTalisman,
    Petalace: null,
    LoadoutSwitchSkills: null,
    Kinsect: null,
    QurioWeaponConfig: defaultQurioWeaponConfig,
  } as Loadout,
  assignSlot: (slot: Slot, gear: Gear) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };
      newLoadout[slot] = gear;

      return {
        loadout: newLoadout,
      };
    }),
  clearSlot: (slot: Slot) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };
      newLoadout[slot] = null;
      if (slot === 'Weapon') {
        // clear qurio config for weapons
        newLoadout['QurioWeaponConfig'] = defaultQurioWeaponConfig;
      }
      return {
        loadout: newLoadout,
      };
    }),
  assignDecoration: (slot: Slot, decorationSlots: DecorationSlot[]) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };

      newLoadout[slot].decorationSlots = decorationSlots;

      return {
        loadout: newLoadout,
      };
    }),
  assignRampageDecoration: (
    slot: Slot,
    rampageDecorationSlots: DecorationSlot[]
  ) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };

      newLoadout[slot].rampageDecorationSlots = rampageDecorationSlots;

      return {
        loadout: newLoadout,
      };
    }),
  assignSkills: (appliedSkills: any[]) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };

      newLoadout['Talisman'].appliedSkills = appliedSkills;
      return {
        loadout: newLoadout,
      };
    }),
  assignLoadout: (loadout: any, generated: boolean = false) =>
    set((state: State) => {
      const {
        Weapon,
        Talisman,
        LoadoutSwitchSkills,
        Petalace,
        Kinsect,
        QurioWeaponConfig,
      } = state.loadout;
      const newLoadout = !generated
        ? {
            ...loadout,
          }
        : {
            Weapon,
            ...loadout,
            Talisman,
            Petalace,
            LoadoutSwitchSkills,
            Kinsect,
            QurioWeaponConfig,
          };
      return {
        loadout: newLoadout,
      };
    }),
  clearLoadout: () =>
    set(() => {
      const loadout = {
        Weapon: null,
        Head: null,
        Chest: null,
        Gloves: null,
        Waist: null,
        Feet: null,
        Talisman: initialTalisman,
        Petalace: null,
        LoadoutSwitchSkills: null,
        Kinsect: null,
        QurioWeaponConfig: defaultQurioWeaponConfig,
      };
      return {
        loadout,
      };
    }),
  setSwitchSkills: (switchSkills: SwitchSkill[]) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };

      newLoadout['LoadoutSwitchSkills'] = switchSkills;

      return {
        loadout: newLoadout,
      };
    }),
  setPetalace: (petalace: any) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };

      newLoadout['Petalace'] = petalace;

      return {
        loadout: newLoadout,
      };
    }),
  setKinsect: (kinsect: any) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };
      newLoadout['Kinsect'] = kinsect;

      return {
        loadout: newLoadout,
      };
    }),
  setQurioWeaponConfig: (config: QurioWeaponConfig) =>
    set((state: State) => {
      const newLoadout = { ...state.loadout };
      newLoadout['QurioWeaponConfig'] = config;

      return {
        loadout: newLoadout,
      };
    }),
}));
