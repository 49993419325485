import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const HeaderBarContainer = styled(Container)`
  ${css({
    textAlign: 'center',
    padding: 'xSmall',
    backgroundColor: 'gainsboro',
  })}
`;
