import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M13 3H3V5.53846H9.5L3 11.4615V14H13V11.4615H6.5L13 5.53846V3Z"
        fill="#6A91F5"
        stroke="black"
      />
      <path
        d="M21 11H15V12.6154H18.9L15 16.3846V18H21V16.3846H17.1L21 12.6154V11Z"
        fill="#6A91F5"
        stroke="black"
      />
      <path
        d="M12 16H7V17.3846H10.25L7 20.6154V22H12V20.6154H8.75L12 17.3846V16Z"
        fill="#6A91F5"
        stroke="black"
      />
    </>
  ),
};
