import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';
import css from '@styled-system/css';

import icons from './svgs';

type SvgProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

const Svg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  xmlSpace: 'preserve',
  strokeMiterlimit: '8',
  strokeLinejoin: 'miter',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  fill: 'none',
})<SvgProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;

const OldSvg = styled.svg.attrs({
  xmlns: 'http://www.w3.org/2000/svg',
  xmlSpace: 'preserve',
  strokeMiterlimit: '1.5',
  strokeLinejoin: 'round',
  fillRule: 'evenodd',
  clipRule: 'evenodd',
})<SvgProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
  ${css({
    '#baseColor': {
      fill: 'white',
    },
    '#lightColor': {
      fill: '#C7C7C7',
    },
    '#mediumColor': {
      fill: '#9D9D9D',
    },
    '#darkColor': {
      fill: '#545454',
    },
  })}
`;

interface IconProps extends React.HTMLProps<SVGElement> {
  height?: number;
  width?: number;
  name: keyof typeof icons;
  className?: any;
}

export const Icon: React.FC<IconProps> = ({
  height = 24,
  width = 24,
  name,
  className,
}) => {
  // old svgs rely on different properties, temporary hack
  const oldSvgs = [
    'GreatSword',
    'LongSword',
    'SwordAndShield',
    'DualBlades',
    'Hammer',
    'HuntingHorn',
    'Lance',
    'Gunlance',
    'SwitchAxe',
    'ChargeBlade',
    'InsectGlaive',
    'Bow',
    'LightBowgun',
    'HeavyBowgun',
    'OneSlot',
    'TwoSlot',
    'ThreeSlot',
  ];
  const nullIcon = {
    viewBox: null,
    paths: null,
  };
  // @ts-ignore
  const newName = name === 'Paralysis' ? 'Para' : name;
  const icon = icons[newName] || nullIcon;

  return oldSvgs.includes(name) ? (
    <OldSvg
      className={className}
      viewBox={icon.viewBox}
      height={height}
      width={width}
    >
      {icon.paths}
    </OldSvg>
  ) : (
    <Svg
      className={className}
      viewBox={icon.viewBox}
      height={height}
      width={width}
    >
      {icon.paths}
    </Svg>
  );
};
