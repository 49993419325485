import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const SkillsContainerWrapper = styled(Container)`
  ${css({
    textAlign: 'left',
    marginY: 'medium',
    overflow: 'auto',
  })}
`;
