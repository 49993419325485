import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M3.5 13L2 14.5V16V17.5V19V20L3.5 21H7.25V13H3.5Z" fill="white" />
      <path d="M11 16V21H13.5V16H11Z" fill="white" />
      <path
        d="M21.5 15.5L18.5 13V15.5L17 17V21H19.5L21.5 19V15.5Z"
        fill="white"
      />
      <path d="M18.5 13H11V16H13.5V21H17V17L18.5 15.5V13Z" fill="white" />
      <path d="M11 13H7.25V21H11V16V13Z" fill="white" />
      <path d="M11 13H18.5V8.5H11V13Z" fill="white" />
      <path d="M18.5 5H11V8.5H18.5V5Z" fill="white" />
      <path d="M11 5H18.5L19.5 4.5V2.5H10V4.5L11 5Z" fill="white" />
      <path
        d="M18.5 13L21.5 15.5V19L19.5 21H17M18.5 13H11M18.5 13V8.5M18.5 13V15.5L17 17V21M11 13V8.5M11 13H7.25M11 13V16M11 5H18.5M11 5L10 4.5V2.5H19.5V4.5L18.5 5M11 5V8.5M18.5 5V8.5M11 8.5H18.5M7.25 13H3.5L2 14.5V16M7.25 13V21M7.25 21H3.5L2 20V19M7.25 21H11M11 21V16M11 21H13.5M17 21H13.5M11 16H13.5V21M2 16H4.5M2 16V17.5M2 17.5H4.5M2 17.5V19M2 19H4.5M13 6V7M9 17V18M9 19V20M15 19V20M13 10V11M9 15V16M16.5 10V11M16.5 6V7M14 10.5H15.5M14 6.5H15.5"
        stroke="black"
      />
      <path
        d="M3.5 13L2 14.5V16V17.5V19V20L3.5 21H7.25V13H3.5Z"
        fill="#C4C4C4"
      />
      <path d="M11 16V21H13.5V16H11Z" fill="#C4C4C4" />
      <path
        d="M21.5 15.5L18.5 13V15.5L17 17V21H19.5L21.5 19V15.5Z"
        fill="#C4C4C4"
      />
      <path
        d="M18.5 13L21.5 15.5V19L19.5 21H17M18.5 13H11M18.5 13V8.5M18.5 13V15.5L17 17V21M11 13V8.5M11 13H7.25M11 13V16M11 5H18.5M11 5L10 4.5V2.5H19.5V4.5L18.5 5M11 5V8.5M18.5 5V8.5M11 8.5H18.5M7.25 13H3.5L2 14.5V16M7.25 13V21M7.25 21H3.5L2 20V19M7.25 21H11M11 21V16M11 21H13.5M17 21H13.5M11 16H13.5V21M2 16H4.5M2 16V17.5M2 17.5H4.5M2 17.5V19M2 19H4.5M13 6V7M9 17V18M9 19V20M15 19V20M13 10V11M9 15V16M16.5 10V11M16.5 6V7M14 10.5H15.5M14 6.5H15.5"
        stroke="black"
      />
    </>
  ),
};
