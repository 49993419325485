export const ArmorTypeObject = {
  Head: "Head",
  Chest: "Chest",
  Gloves: "Gloves",
  Waist: "Waist",
  Feet: "Feet",
} as const;

export const ArmorTypeValues = Object.values(ArmorTypeObject);

export const isArmorType = (tbd: any): tbd is ArmorType => {
  return ArmorTypeValues.includes(tbd);
};

export type ArmorType = typeof ArmorTypeObject[keyof typeof ArmorTypeObject];
