import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';
import { ActiveSkillPill } from './config/components/ActiveSkillsCell/styled';

/**
 * core table styles
 */
export const TableContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'stretch',
    border: 'solid 1px',
    borderColor: 'selectBorder',
    borderRadius: 'medium',
    backgroundColor: 'tableHeaderBackground',
  })}
`;

// backgroundColor: 'rgb(211,211,211)'
export const HeaderGroup = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderBottom: '1px solid',
    borderColor: 'selectBorder',
    '& div + div': {
      borderLeft: '1px solid',
      borderColor: 'selectBorder',
    },
  })}
`;

export const Header = styled(Container)`
  ${css({
    fontWeight: 'bold',
    paddingY: 'xSmall',
    paddingLeft: 'xSmall',
    fontSize: 'small',
    marginY: 'tiny',
  })}
`;

export const TableBody = styled(Container)`
  ${css({
    backgroundColor: 'cardBackground',
    marginBottom: '1rem',
  })}
`;

export const Row = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    paddingY: 'tiny',
    alignItems: 'stretch',
    alignContent: 'center',
    transition: 'all .3s ease',
    cursor: 'pointer',
    borderRadius: 0,
    // left border for all elements except the first
    '& > div + div': {
      borderLeft: '1px solid transparent',
    },
    ':hover': {
      backgroundColor: 'tableHeaderBackground',
      borderRadius: 'medium',
      '& > div + div': {
        transition: 'all .2s ease',
        borderLeft: '1px solid',
        borderColor: 'decoBackground',
      },
    },
  })}
`;

export const CellWrapper = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const Cell = styled(Container)`
  ${css({
    width: '100%',
  })}
`;
