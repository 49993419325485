import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M8.3999 13.375L11.9999 16L15.5999 13.375V9.875L11.9999 2L8.3999 9.875V13.375Z"
        fill="white"
        stroke="black"
      />
      <path
        d="M20.9999 12.5L16.4999 16H12.8999L16.4999 13.375V9.875L20.9999 8.125V12.5Z"
        fill="white"
        stroke="black"
      />
      <path
        d="M3 12.5L7.5 16H11.1L7.5 13.375V9.875L3 8.125V12.5Z"
        fill="white"
        stroke="black"
      />
      <path d="M4 18L12 16.5L20 18" stroke="black" />
      <path
        d="M11.9999 21.5C6.99982 21.5 7.99985 16 7.99985 16H8.5C8.5 16 8.49996 20 11.9999 20C15.4999 20 15.4999 16 15.4999 16H16C16 16 16.9999 21.5 11.9999 21.5Z"
        fill="white"
        stroke="black"
      />
    </>
  ),
};
