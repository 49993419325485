import { Text } from 'ui/core';
import { matchSorter } from 'match-sorter';
import { NameFilter } from './components/filters';

const defaultColumn = {
  Filter: () => <></>,
};

const kinsectColumns = [
  {
    id: 'name',
    Header: 'NAME',
    accessor: 'name',
    width: 220,
  },
  {
    id: 'power',
    Header: 'POWER',
    accessor: 'power',
    width: 66,
  },
  {
    id: 'speed',
    Header: 'SPEED',
    accessor: 'speed',
    width: 66,
  },
  {
    id: 'heal',
    Header: 'HEAL',
    accessor: 'heal',
    width: 66,
  },
  {
    id: 'kinsectTypes',
    Header: 'TYPES',
    accessor: (originalRow: any) => {
      return originalRow.kinsectTypes.join(', ');
    },
    width: 320,
  },
];

const mobileKinsectColumns = [
  {
    id: 'name',
    Header: 'NAME',
    accessor: 'name',
  },
  {
    id: 'slotValue',
    Header: 'SLOT VALUE',
    accessor: 'slotValue',
    width: 66,
  },
];

const initialGlobalFilterState = [
  {
    id: 'name',
    value: '',
  },
  {
    id: 'kinsectTypes',
    value: '',
  },
];

const globalFilterResolver = (
  rows: any,
  columnIds: any,
  globalFilterValue: any
) => {
  const nameMatches = matchSorter(rows, globalFilterValue, {
    keys: [
      (row: any) => row.original.name,
      (row: any) => row.original.kinsectTypes.map((type: any) => type),
    ],
    // keys: ['original.name', 'original.appliedSkills.*.name'],
  });

  return globalFilterValue === '' ? rows : nameMatches;
};

export const getKinsectProperties = () => {
  const baseKinsectOptions = {
    defaultColumn,
    initialFilterState: [
      {
        id: 'slotValue',
        value: [],
      },
    ],
    initialGlobalFilterState,
  };

  const getGlobalFilterComponent = (setFilter: any) => {
    return <NameFilter setFilter={setFilter}></NameFilter>;
  };

  return {
    getColumns: () => kinsectColumns,
    getMobileColumns: () => mobileKinsectColumns,
    getOptions: () => ({
      ...baseKinsectOptions,
      globalFilterResolver,
    }),
    getFilterComponents: (
      setFilter: any,
      setAllFilters: any,
      filterValues: any
    ) => <></>,
    getGlobalFilterComponent: (setFilter: any) =>
      getGlobalFilterComponent(setFilter),
  };
};
