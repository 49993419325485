import { AppliedSkill, Decoration, DecorationSlot, Skill } from 'models';
import { Armor } from 'models/Armor';
import { Weapon } from 'models/weapons';
import { Loadout } from 'models/state';

/**
 * Retrieves AppliedSkills from a loadout.
 * @param loadout - The loadout to extract applied skills from.
 * @returns An array of AppliedSkills.
 */
export const getAppliedSkillsFromLoadout = (
  loadout: Loadout
): AppliedSkill[] => {
  return Object.values(loadout).reduce(
    (appliedSkills, slot: Armor | Weapon) => {
      return slot?.appliedSkills
        ? [...appliedSkills, ...slot.appliedSkills]
        : [...appliedSkills];
    },
    []
  );
};

/**
 * Retrieves Decorations from a loadout.
 * @param loadout - The loadout to extract decorations from.
 * @returns An array of Decorations.
 */
export const getDecorationsFromLoadout = (loadout: Loadout) => {
  return Object.values(loadout).reduce(
    (allDecorations, slot: Armor | Weapon) => {
      if (slot?.decorationSlots) {
        const decorationsSlotsWithDecorations = slot?.decorationSlots.filter(
          (decorationSlot: DecorationSlot) => {
            return decorationSlot.decoration;
          }
        );
        const decorations = decorationsSlotsWithDecorations.map(
          (decorationSlot: DecorationSlot) => {
            return decorationSlot.decoration;
          }
        );
        return [...allDecorations, ...decorations];
      } else {
        return [...allDecorations];
      }
    },
    []
  );
};

/**
 * Creates AppliedSkill[] from input Decoration[].
 * @param decorations - An array of Decorations to match with skillData.
 * @param skillData - An array containing all skill details.
 * @returns An array of AppliedSkills constructed from input.
 */
export const getSkillsFromDecorations = (
  decorations: Decoration[],
  skillData: Skill[]
): AppliedSkill[] => {
  return decorations.map((decoration: Decoration) => {
    const { name } = decoration;
    const levels = (name.match(new RegExp(/\+/, 'g')) || []).length;

    return {
      ...skillData.find((skill) => skill.id === decoration.skillId)!,
      appliedSkillLevel: levels,
    };
  });
};

/**
 * Iterate through AppliedSkill[] and aggregate duplicates, modifying appliedSkillLevel.
 * @param repeatedAppliedSkills - An array of AppliedSkills which contains duplicates.
 * @returns An array of AppliedSkills with aggregated levels and no duplicates.
 */
export const condenseAppliedSkills = (
  repeatedAppliedSkills: AppliedSkill[]
): AppliedSkill[] => {
  return repeatedAppliedSkills.reduce(
    (appliedSkills: AppliedSkill[], appliedSkill: AppliedSkill) => {
      const existingSkillIndex = appliedSkills.findIndex((accAppliedSkill) => {
        if (appliedSkill !== null) {
          return accAppliedSkill
            ? accAppliedSkill.id === appliedSkill.id
            : false;
        }
      });

      if (existingSkillIndex >= 0) {
        const currentSkill = { ...appliedSkills[existingSkillIndex] };

        currentSkill.appliedSkillLevel += appliedSkill.appliedSkillLevel;
        currentSkill.appliedSkillLevel++;
        appliedSkills[existingSkillIndex] = currentSkill;
        return [...appliedSkills];
      } else {
        return [...appliedSkills, appliedSkill];
      }
    },
    []
  );
};
