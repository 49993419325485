import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Button } from 'ui/core';

export const DecorationSelectWrapper = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    margin: 'tiny',
    justifyContent: 'center',
    alignItems: 'center',
    marginX: 'xSmall',
  })}
`;

export const DecorationButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    width: '100%',
    marginLeft: 'xSmall',
    borderRadius: 'large',
    backgroundColor: 'decoBackground',
  })}
`;
