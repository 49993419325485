import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const PetalaceControlsContainer = styled(Container).attrs({
  variant: 'flexRow',
})``;

export const PetalaceStatsContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    height: '100%',
    marginTop: 'xLarge',
    marginBottom: 'xSmall',
    backgroundColor: 'detailsBackground',
    borderRadius: 'medium',
    padding: 'tiny',
    justifyItems: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'border',
  })}
`;

export const PetalaceStatContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const StatsContainer = styled(Container)`
  ${css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  })}
`;
