import { Fragment } from 'react';
import { Listbox } from '@headlessui/react';
import css from '@styled-system/css';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';
import { Icon, Container } from 'ui/core';

type SelectProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

interface PrimitiveSelectProps {
  className?: any;
  options: any[];
  selectedOption: any;
  setSelectedOption: any;
  icon?: any;
  labelText?: string;
  isSmall?: boolean;
}

export const PrimitiveSelect: React.FC<PrimitiveSelectProps> = ({
  className,
  options,
  selectedOption,
  setSelectedOption,
  icon,
  labelText,
  isSmall = false,
}) => {
  const getCorrectAnimationValue = (isOpen: boolean) => {
    return isOpen
      ? {
          rotate: -180,
        }
      : {};
  };
  return (
    <SelectContainer className={className}>
      <Listbox value={selectedOption} onChange={setSelectedOption}>
        {({ open }) => (
          <>
            {labelText && <Listbox.Label>{labelText}</Listbox.Label>}
            <SelectButton>
              {icon ? icon : null}
              <OptionText icon={icon ? true : false}>
                {selectedOption ? selectedOption.name : 'Make a selection.'}
              </OptionText>
              <Container animate={getCorrectAnimationValue(open)}>
                <StyledIcon name={'Down'} height={20} width={20}></StyledIcon>
              </Container>
            </SelectButton>
            {open && (
              <SelectOptions>
                {options.map((option, index) => (
                  <Listbox.Option key={option.id} value={option} as={Fragment}>
                    {(renderProps: any) => {
                      const { active, selected, disabled } = renderProps;
                      return (
                        <SelectOption
                          first={index === 0}
                          last={index === options.length - 1}
                          active={active}
                          selected={selected}
                        >
                          {selected && !isSmall && (
                            <SelectedOptionIconContainer>
                              <StyledIcon
                                name="Shuriken"
                                height={15}
                                width={15}
                              />
                            </SelectedOptionIconContainer>
                          )}
                          <OptionListText isSmall={isSmall}>
                            {option.name}
                          </OptionListText>
                        </SelectOption>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </SelectOptions>
            )}
          </>
        )}
      </Listbox>
    </SelectContainer>
  );
};

const StyledIcon = styled(Icon)`
  ${css({
    '& path': {
      stroke: 'text',
    },
  })}
`;

const SelectContainer = styled.div`
  ${css({
    position: 'relative',
  })}
`;
//#4d4b60 - bg
//#242230 - border color

const SelectButton = styled(Listbox.Button)`
  ${css({
    border: '1px solid',
    borderColor: 'selectBorder',
    width: '100%',
    borderRadius: 'small',
    textAlign: 'left',
    display: 'flex',
    backgroundColor: 'selectBackground',
    alignItems: 'center',
    position: 'relative',
    color: 'text',
    cursor: 'pointer',
  })}
`;

const SelectOptions = styled<any>(Listbox.Options).attrs({
  static: true,
})`
  ${css({
    marginTop: 'tiny',
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'selectBorder',
    padding: 0,
    position: 'absolute',
    backgroundColor: 'selectBackground',
    width: '100%',
    zIndex: 1,
    boxShadow: 'experimental',
    overflow: 'auto',
    maxHeight: '9rem',
  })}
`;

const SelectOption = styled.li<{
  first?: boolean;
  last?: boolean;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
}>`
  ${({ first, last, active, selected, disabled }) =>
    css({
      listStyleType: 'none',
      paddingX: 'small',
      backgroundColor: active || selected ? 'background' : 'selectBackground',
      position: 'relative',
      borderTopLeftRadius: first ? 'medium' : null,
      borderTopRightRadius: first ? 'medium' : null,
      borderBottomRightRadius: last ? 'medium' : null,
      borderBottomLeftRadius: last ? 'medium' : null,
      borderTop: first ? 'none' : 'faint',
      opacity: disabled ? '30%' : '100%',
      cursor: 'pointer',
    })}
`;

const OptionText = styled.span<{ icon: boolean }>`
  ${({ icon }) =>
    css({
      flex: 1,
      marginLeft: icon ? 'medium' : 'tiny',
    })}
`;

const OptionListText = styled.span<{ isSmall: boolean }>`
  ${({ isSmall }) =>
    css({
      marginLeft: isSmall ? null : 'large',
    })}
`;

const SelectedOptionIconContainer = styled.span`
  ${css({
    position: 'absolute',
    top: '0.3rem',
    left: '0.6rem',
  })}
`;

export const Select = styled(PrimitiveSelect)<
  SelectProps & PrimitiveSelectProps
>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;

// general idea: Listbox has a wrapper - relative.
// the button is - relative.
// then the options are - absolute, overflow auto, maxheight 15rem.
