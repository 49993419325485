import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon, Text } from 'ui/core';

export const KinsectContainer = styled(Container)``;

export const KinsectButton = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    border: '1px solid',
    borderColor: 'border',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'medium',
    ':hover': {
      boxShadow: 'kinsectShadow',
      transition: 'all 0.35s ease-in-out',
    },
    ':active': {
      backgroundColor: 'background',
    },
    padding: ['medium', null, 0, null, null],
  })}
`;

export const KinsectIcon = styled(Icon)``;

export const KinsectDetails = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    border: '1px solid',
    borderColor: 'border',
    height: '100%',
    borderRadius: 'medium',
    position: 'relative',
    padding: ['tiny', null, 0, null, null],
  })}
`;

export const CancelIcon = styled(Icon)``;

export const CancelIconContainer = styled(Container)`
  ${css({
    position: 'absolute',
    top: 'tiny',
    right: 'tiny',
  })}
`;

export const KinsectName = styled(Text)`
  ${css({
    marginTop: 'tiny',
    marginLeft: 'tiny',
    fontWeight: 'bold',
    fontSize: 'xSmall',
  })}
`;

export const KinsectDetailsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginX: 'tiny',
    fontSize: 'xSmall',
  })}
`;

export const KinsectDetailContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 'xSmall',
  })}
`;

export const KinsectDetailValue = styled(Container)``;
