import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon, Span, Button, Text } from 'ui/core';
import { KinsectPicker } from 'components';

export const WeaponDetailsContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    height: '100%',
    width: '100%',
    padding: 'xSmall',
  })}
`;

export const WeaponDetailsBody = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: ['column', null, 'row', null, null],
    width: '100%',
    height: '100%',
    paddingTop: 'medium',
    paddingBottom: 'small',
  })}
`;

export const ButtonContainer = styled(Container)`
  ${css({
    marginY: 'tiny',
    textAlign: 'center',
    width: '100%',
  })}
`;

export const StatsAndPropertiesContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    width: ['100%', null, '50%', null, null],
    height: '100%',
    marginRight: 'tiny',
  })}
`;

export const WeaponPropertiesContainer = styled(Container)`
  ${css({
    alignItems: 'center',
    height: '100%',
    marginTop: ['1rem', null, 0, null, null],
  })}
`;

export const SongsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    fontSize: 'tiny',
    flexDirection: ['column', null, 'row', null, null],
    marginTop: ['1rem', null, 0, null, null],
    width: '100%',
    justifyContent: 'center',
  })}
`;

export const SongSpan = styled(Span)`
  ${css({
    fontSize: 'xSmall',
    marginX: 'tiny',
    border: '1px solid',
    borderColor: 'border',
    paddingX: 'medium',
    borderRadius: 'large',
    marginBottom: ['tiny', null, 0, null, null],
  })}
`;

const coatings = {
  CloseRange: 'white',
  Power: '#CE585E',
  Poison: '#968ACC',
  Para: '#DFE34A',
  Sleep: '#6EC0E1',
  Blast: '#90B24A',
  Exhaust: '#4C68E3',
} as const;

type coatingType = keyof typeof coatings;

export const CoatingIcon = styled(Icon).attrs({ name: 'Phial' })<{
  coating: coatingType;
  isEnabled: boolean;
}>`
  ${({ coating, isEnabled }) =>
    css({
      fill: coatings[coating],
      opacity: isEnabled ? '100%' : '25%',
    })}
`;

export const CoatingContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginTop: ['1rem', null, 0, null, null],
  })}
`;

export const ShotsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    flexDirection: ['column', null, 'row', null, null],
    width: '100%',
  })}
`;

export const ShotSpan = styled(Span)`
  ${css({
    marginX: 'tiny',
    border: '1px solid',
    borderColor: 'border',
    paddingX: 'medium',
    borderRadius: 'large',
    fontSize: 'small',
    marginTop: ['tiny', null, 0, null, null],
  })}
`;

export const SectionLabelSpan = styled(Span)`
  ${css({
    alignSelf: 'flex-start',
    fontSize: 'small',
    fontWeight: 'bold',
  })}
`;

export const RampageAndDecorationsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginLeft: [0, null, 'xSmall', null, null],
    width: ['100%', null, '50%', null, null],
    height: '100%',
  })}
`;

export const WeaponDecorationContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    width: '50%',
    height: '100%',
    justifyContent: 'center',
    backgroundColor: 'detailsBackground',
    border: '1px solid',
    borderColor: 'detailsBorder',
    marginX: 'tiny',
    borderRadius: 'medium',
    position: 'relative',
  })}
`;

export const WeaponViewControls = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    margin: 'auto',
    marginTop: 'medium',
  })}
`;

export const WeaponViewButtonContainer = styled(Button).attrs({
  variant: 'circle',
})`
  ${css({})}
`;

export const SubSectionText = styled(Text)`
  ${css({
    fontSize: 'xSmall',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    position: 'absolute',
    top: 'tiny',
    left: 'tiny',
  })}
`;

export const StyledKinsectPicker = styled(KinsectPicker)`
  ${css({
    height: '100%',
    width: ['100%', null, '75%', null, null],
    padding: 'small',
    paddingBottom: 0,
    paddingRight: 0,
  })}
`;
