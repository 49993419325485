import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M12 3L9 9L3 12L9 15L12 21L15 15L21 12L15 9L12 3Z"
        stroke="black"
      />
    </>
  ),
};
