import { SlotValue } from 'models/enums';

export const getSkillRomanNumeral = (baseTen: number) => {
  switch (baseTen) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    case 4:
      return 'IV';
    case 5:
      return 'V';
    case 6:
      return 'VI';
    case 7:
      return 'VII';
  }
};

export const getIconName = (slotValue: SlotValue) => {
  switch (slotValue) {
    case SlotValue.OneSlot:
      return 'OneSlot';
    case SlotValue.TwoSlot:
      return 'TwoSlot';
    case SlotValue.ThreeSlot:
      return 'ThreeSlot';
    case SlotValue.FourSlot:
      return 'ThreeSlot';
    default:
      return 'OneSlot';
  }
};
