import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M4.5 8V10H6.5V11.25H17.3205V10H19.5V8H18.5L16 5.91667L15.5 5.5V4.32615H8.375V5.5L8 5.91667L5.5 8H4.5Z"
        fill="white"
      />
      <path
        d="M2.5 8H4.5H5.5L8 5.91667L8.375 5.5V4.32615V2.625L7.95609 1.92682H3.5L2.5 3V8Z"
        fill="white"
      />
      <path
        d="M21 2H16L15.5 2.625V4.32615V5.5L16 5.91667L18.5 8H19.5H22V3L21 2Z"
        fill="white"
      />
      <path
        d="M5 17.5H19V15L19.5 14V12.5H17.3205V11.25H6.5V12.5H4.5V14L5 15V17.5Z"
        fill="white"
      />
      <path d="M4.5 12.5H6.5V11.25V10H4.5V12.5Z" fill="white" />
      <path d="M17.3205 10V11.25V12.5H19.5V10H17.3205Z" fill="white" />
      <path d="M4 19.5L20 19.5L19 17.5H5L4 19.5Z" fill="white" />
      <path d="M4 22L20 22V19.5L4 19.5V22Z" fill="white" />
      <path
        d="M20 19.5V22L4 22V19.5M20 19.5L4 19.5M20 19.5L19 17.5M4 19.5L5 17.5M5 17.5H19M5 17.5V15L4.5 14V12.5M19 17.5V15L19.5 14V12.5M4.5 8H2.5V3L3.5 1.92682H7.95609L8.375 2.625V4.32615M4.5 8V10M4.5 8H5.5L8 5.91667L8.375 5.5V4.32615M19.5 8H22V3L21 2H16L15.5 2.625V4.32615M19.5 8V10M19.5 8H18.5L16 5.91667L15.5 5.5V4.32615M4.5 12.5H6.5V11.25M4.5 12.5V10M4.5 10H6.5V11.25M6.5 11.25H17.3205M17.3205 11.25V10H19.5M17.3205 11.25V12.5H19.5M19.5 10V12.5M8.375 4.32615H15.5"
        stroke="black"
      />
      <path
        d="M4.5 8V10H6.5V11.25H17.3205V10H19.5V8H18.5L16 5.91667L15.5 5.5V4.32615H8.375V5.5L8 5.91667L5.5 8H4.5Z"
        fill="#C4C4C4"
      />
      <path
        d="M20 19.5V22L4 22V19.5M20 19.5L4 19.5M20 19.5L19 17.5M4 19.5L5 17.5M5 17.5H19M5 17.5V15L4.5 14V12.5M19 17.5V15L19.5 14V12.5M4.5 8H2.5V3L3.5 1.92682H7.95609L8.375 2.625V4.32615M4.5 8V10M4.5 8H5.5L8 5.91667L8.375 5.5V4.32615M19.5 8H22V3L21 2H16L15.5 2.625V4.32615M19.5 8V10M19.5 8H18.5L16 5.91667L15.5 5.5V4.32615M4.5 12.5H6.5V11.25M4.5 12.5V10M4.5 10H6.5V11.25M6.5 11.25H17.3205M17.3205 11.25V10H19.5M17.3205 11.25V12.5H19.5M19.5 10V12.5M8.375 4.32615H15.5"
        stroke="black"
      />
      <path
        d="M4.5 3.5V4.5M4.5 5.5V6.5M20 3.5V4.5M20 5.5V6.5M7.5 15.5V16.5M9.5 15.5V16.5M12 15.5V16.5M14.5 15.5V16.5M16.5 15.5V16.5"
        stroke="black"
      />
      <path
        d="M8 5.91665L8.375 5.49998V3.32874L10 4.49998V7.49998H8V5.91665Z"
        fill="black"
        stroke="black"
      />
      <path
        d="M14 4.5L15.5 3.5V5.5L16 5.91667V7.5H14V4.5Z"
        fill="black"
        stroke="black"
      />
    </>
  ),
};
