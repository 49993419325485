import {
  WeaponStatsCellContainer,
  WeaponStatContainer,
  SpecialDamageContainer,
  SpecialDamageIcon,
  SpecialDamageValuesContainer,
  StatIcon,
} from './styled';

export const weaponStatsAccessor = (originalRow: any) => {
  const { baseDamage, affinity, specialDamage = [] } = originalRow;
  return {
    baseDamage,
    affinity: affinity === '0' ? '-' : affinity,
    specialDamage,
  };
};

export const WeaponStatsCell = ({ value }: { value: any }) => {
  const statNames = ['Attack', 'Affinity', 'Element'] as const;
  // remove after adding corresponding SVGs
  const statusNames = [
    'Poison',
    'Stun',
    'Paralysis',
    'Sleep',
    'Blast',
  ] as const;

  const statValues = Object.values(value);

  // for element/status damage
  // TODO: EXTRACT INTO TOP LEVEL COMPONENT. currently repeating code in genericstatdetails
  const createSpecialDamageComponent = (specialDamageArray: any[]) => {
    return specialDamageArray.length === 0 ? (
      '-'
    ) : (
      <SpecialDamageContainer>
        {specialDamageArray.map((specialDamageObject: any) => {
          const { damageType, damageValue } = specialDamageObject;
          return (
            <SpecialDamageValuesContainer
              lineHeight={specialDamageArray.length > 1 ? 1 : 'initial'}
            >
              <SpecialDamageIcon name={damageType} width={10} height={10} />
              {damageValue}
            </SpecialDamageValuesContainer>
          );
        })}
      </SpecialDamageContainer>
    );
  };

  return (
    <WeaponStatsCellContainer>
      {statValues.map((weaponStatValue: any, index: number) => {
        const currentStat = statNames[index];

        return (
          <WeaponStatContainer>
            <StatIcon name={statNames[index]} height={16} width={16}></StatIcon>
            {currentStat === 'Element'
              ? createSpecialDamageComponent(weaponStatValue)
              : weaponStatValue}
          </WeaponStatContainer>
        );
      })}
    </WeaponStatsCellContainer>
  );
};
