import React from 'react';
import { useDataContext, useLoadoutStore } from 'hooks';

import { SkillDisplay } from './components';
import { SkillsContainerWrapper } from './styled';

import {
  getAppliedSkillsFromLoadout,
  getDecorationsFromLoadout,
  getSkillsFromDecorations,
  condenseAppliedSkills,
} from 'utils/skills';

import { AppliedSkill } from 'models';
import { Container, Span, Text } from 'ui/core';
import styled from 'styled-components';
import css from '@styled-system/css';

const StatusPane = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    padding: 'medium',
    paddingX: 'huge',
    maxWidth: '300px',
    minWidth: '300px',
    wordWrap: 'break-word',
    border: '1px solid',
    borderColor: 'cardBorder',
    borderRadius: 'medium',
    margin: 'tiny',
    backgroundColor: 'cardBackground',
  })}
`;

const SectionTitle = styled(Text)`
  ${css({
    textAlign: 'center',
    marginBottom: 'small',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xSmall',
    color: 'secondaryText',
    letterSpacing: '1px',
  })}
`;

const StatSection = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    backgroundColor: 'detailsBackground',
    borderColor: 'detailsBorder',
    padding: 'medium',
    borderRadius: 'medium',
  })}
`;

interface SkillsContainerProps {
  interactive?: boolean;
}

export const SkillsContainer: React.FC<SkillsContainerProps> = ({
  interactive = true,
}) => {
  const loadout = useLoadoutStore((state: any) => state.loadout);
  const { getSkillData } = useDataContext();

  const allDecorations = getDecorationsFromLoadout(loadout);
  const allAppliedSkills = getAppliedSkillsFromLoadout(loadout);

  const allDecorationSkills = getSkillsFromDecorations(
    allDecorations,
    getSkillData()
  );

  const condensedAppliedSkills = condenseAppliedSkills([
    ...allDecorationSkills,
    ...allAppliedSkills,
  ]);

  const skills = condensedAppliedSkills
    .filter((a: any) => a !== null)
    .sort((a: AppliedSkill, b: AppliedSkill) => {
      const skillLevelSort = b.appliedSkillLevel - a.appliedSkillLevel;
      if (skillLevelSort === 0) {
        if (b.appliedSkillLevel >= b.maxSkillLevel) {
          return 1;
        } else if (a.appliedSkillLevel >= a.maxSkillLevel) {
          return -1;
        } else {
          return b.maxSkillLevel - a.maxSkillLevel;
        }
      }
      return skillLevelSort;
    })
    .map((appliedSkill: AppliedSkill) => {
      return <SkillDisplay interactive={interactive} skill={appliedSkill} />;
    });

  const getNoninteractiveSkills = () => {
    const skillsLength = skills.length;
    const panesToMake = Math.ceil(skillsLength / 6);

    //@ts-ignore
    return [...Array(panesToMake).keys()].map((index) => {
      return (
        <StatusPane>
          <Container marginBottom="medium">
            <SectionTitle>Active Skills</SectionTitle>
            <StatSection>
              {skills.slice(index * 6, Math.min((index + 1) * 6, skillsLength))}
            </StatSection>
          </Container>
        </StatusPane>
      );
    });
  };

  return interactive ? (
    <SkillsContainerWrapper>
      {skills.length > 0 ? skills : null}
    </SkillsContainerWrapper>
  ) : skills.length > 0 ? (
    <>{getNoninteractiveSkills()}</>
  ) : (
    <StatusPane
      backgroundColor="detailsBackground"
      borderColor="detailsBorder"
      padding="medium"
      borderRadius="medium"
    >
      <Container marginBottom="medium">
        <SectionTitle>Active Skills</SectionTitle>
        <StatSection>
          <Span opacity="50%">No skills</Span>
        </StatSection>
      </Container>
    </StatusPane>
  );
};
