import React, { useState, useRef, useEffect } from 'react';
import { useLoadoutStore, useExportStore } from 'hooks';
import shallow from 'zustand/shallow';
import { useMutation } from 'react-query';
import axios from 'axios';

import {
  ExportText,
  ExportContainer,
  CopyLinkContainer,
  CopyButton,
  LinkText,
  HelperText,
} from './styled';

interface ExporterProps {}
export const Exporter: React.FC<ExporterProps> = ({}) => {
  // change to env var prior to deployment
  // TODO: move to util?
  const URL = process.env.REACT_APP_SITE_URL || 'http://localhost:3000/';
  const exportURL = `${URL}?loadout=`;
  const exportTextRef = useRef<HTMLInputElement>(null);

  const { loadout } = useLoadoutStore(
    (state: any) => ({
      loadout: state.loadout,
    }),
    shallow
  );
  const { exportEncryption, setExportEncryption } = useExportStore(
    (state: any) => ({
      exportEncryption: state.exportEncryption,
      setExportEncryption: state.setExportEncryption,
    }),
    shallow
  );

  const { mutate, isLoading, isError, isIdle } = useMutation(
    (loadout: any) => {
      // return axios.post('/api/loadout/export', loadout);
      return axios.post('/.netlify/functions/export', loadout);
    },
    {
      onSuccess: (res) => {
        setExportEncryption(res.data.md5_loadout);
      },
    }
  );

  useEffect(() => {
    mutate(loadout);
  }, []);

  const copyText = () => {
    window.navigator.clipboard.writeText(`${exportURL}${exportEncryption}`);
    if (exportTextRef && exportTextRef.current !== null) {
      exportTextRef.current.select();
    }
  };

  return (
    <>
      <ExportContainer>
        {isError && (
          <HelperText color="red">
            There was an error exporting. Try again.
          </HelperText>
        )}
        <CopyLinkContainer>
          <CopyButton
            disabled={isLoading || isIdle}
            onClick={isError ? () => mutate(loadout) : () => copyText()}
          >
            {isError ? 'Try Again' : isLoading ? 'Generating...' : 'Copy'}
          </CopyButton>
          <LinkText
            value={`${URL}?loadout=${exportEncryption}`}
            disabled={isLoading || isIdle}
            chars={`${exportURL.length + exportEncryption.length - 4}ch`}
            ref={exportTextRef}
          />
        </CopyLinkContainer>
      </ExportContainer>
    </>
  );
};
