import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M14.6316 5H9.89474V7.06897H8V20H17V7.06897H14.6316V5Z"
        fill="white"
      />
      <path
        d="M17 4H14.7778V7.4H17V21H7V7.4H9.22222V4L7 4"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
