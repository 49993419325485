import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M8 3L9.12257 6.45492H12.7553L9.81636 8.59017L10.9389 12.0451L8 9.90983L5.06107 12.0451L6.18364 8.59017L3.24472 6.45492H6.87743L8 3Z"
        fill="#FDED5D"
        stroke="black"
      />
      <path
        d="M16 13L16.8981 15.7639H19.8042L17.4531 17.4721L18.3511 20.2361L16 18.5279L13.6489 20.2361L14.5469 17.4721L12.1958 15.7639H15.1019L16 13Z"
        fill="#FDED5D"
        stroke="black"
      />
    </>
  ),
};
