export default {
  viewBox: '0 0 583 513',
  paths: (
    <>
      <g id="baseColor">
        <path d="M193.535,503.87l-0,-162.818l204.508,0l0,162.818l-204.508,-0Zm30.471,-162.818l0,-118.316l139.01,-0l-0,118.316l-139.01,0Zm15.224,-118.316l0.448,-45.75l106.602,-0l-0,45.75l-107.05,-0Zm-19.14,-45.75l0,-111.848l143.614,-0l-0,111.848l-143.614,-0Zm20.88,-111.848l0.274,-56.18l103.507,0l-0,56.18l-103.781,-0Zm-22.939,110.91l-0,-96.137l-16.091,-13.431l-63.242,-0c-31.028,7.324 -58.539,17.833 -81.016,32.899l0,86.884c3.526,13.554 9.289,25.076 17.289,34.565l0,30.668c-7.37,5.861 -13.347,12.192 -17.289,19.211c-6.615,-3.184 -18.128,-1.852 -22.288,4.445c-9.647,14.604 1.618,28.754 6.611,32.488c5.713,4.273 38.56,1.489 60.021,0c11.08,-4.499 19.397,-13.003 25.197,-25.154l-0,-133.816l11.475,-5.187l25.247,0c15.003,19.495 33.462,33.815 54.086,32.565Zm145.953,-0.264l0,-96.137l16.091,-13.431l63.242,-0c31.028,7.324 58.539,17.833 81.016,32.899l-0,86.884c-3.526,13.554 -9.289,25.076 -17.289,34.565l-0,30.668c7.37,5.861 13.347,12.192 17.289,19.211c6.615,-3.184 18.128,-1.852 22.288,4.445c9.647,14.604 -1.618,28.754 -6.611,32.488c-5.713,4.273 -38.561,1.489 -60.021,-0c-11.08,-4.499 -19.397,-13.003 -25.197,-25.154l0,-133.816l-11.475,-5.187l-25.247,0c-15.003,19.495 -33.462,33.815 -54.086,32.565Zm0,67.186l0,47.058l50.168,-0l0,118.657l77.692,-0l0,-45.925l-40.58,0l-0,-119.79l-87.28,-0Zm-95.169,165.715l0,-118.657c6.459,-11.061 14.75,-18.247 23.088,-18.211c9.431,0.039 19.481,8.122 25.161,18.211l-0,118.657l-48.249,-0Z" />
      </g>
      <g id="lightColor">
        <path
          id="light"
          d="M193.535,503.87l-0,-162.818l204.508,0l0,162.818l-204.508,-0Zm30.471,-162.818l0,-118.316l139.01,-0l-0,118.316l-139.01,0Zm-3.916,-164.066l0,-111.848l143.614,-0l-0,111.848l-143.614,-0Zm20.88,-111.848l0.274,-56.18l103.507,0l-0,56.18l-103.781,-0Zm123.014,177.832l0,47.058l50.168,-0l0,118.657l77.692,-0l0,-45.925l-40.58,0l-0,-119.79l-87.28,-0Z"
        />
      </g>
      <g id="darkColor">
        <path d="M268.815,408.685l0,-118.657c6.459,-11.061 14.75,-18.247 23.088,-18.211c9.431,0.039 19.481,8.122 25.161,18.211l-0,118.657l-48.249,-0Z" />
      </g>
      <g id="outline">
        <path d="M184.576,503.87l8.959,8.958l204.508,0l8.959,-8.958l-0,-162.818l-8.959,-8.958l-204.508,0l-8.959,8.958l0,162.818Zm17.917,-8.959l0,-144.9c0,-0 186.592,-0 186.592,-0c-0,-0 -0,144.9 -0,144.9l-186.592,0Z" />
        <path d="M215.048,341.052l8.958,8.959l139.01,-0l8.958,-8.959l-0,-118.316l-8.958,-8.959l-139.01,0l-8.958,8.959l-0,118.316Zm17.916,-8.958l0,-100.4c0,0 121.093,0 121.093,0c0,0 0,100.4 0,100.4l-121.093,0Z" />
        <path d="M230.272,222.648l8.958,9.046l107.05,0l8.958,-8.958l-0,-45.75l-8.958,-8.959l-106.602,0l-8.958,8.871l-0.448,45.75Zm18.005,-8.871l0.272,-27.833c-0,0 88.772,0 88.772,0c0,0 0,27.833 0,27.833l-89.044,0Z" />
        <path d="M211.132,176.986l8.958,8.958l143.614,0l8.958,-8.958l0,-111.848l-8.958,-8.958l-143.614,-0l-8.958,8.958l0,111.848Zm17.917,-8.959l-0,-93.931c-0,0 125.696,0 125.696,0c0,0 0,93.931 0,93.931l-125.696,0Z" />
        <path d="M232.012,65.094l8.958,9.002l103.781,0l8.958,-8.958l-0,-56.18l-8.958,-8.958l-103.507,-0l-8.958,8.915l-0.274,56.179Zm17.96,-8.914l0.187,-38.263c-0,-0 85.633,-0 85.633,-0c0,-0 0,38.263 0,38.263l-85.82,-0Z" />
        <path d="M218.573,184.99l8.416,-0.509l0,-108.761l-21.802,-18.198l-67.532,-0l-1.015,0.239c-32.162,7.592 -60.647,18.56 -83.946,34.177l-3.97,2.661l-0,92.81l0.288,1.109c3.571,13.726 9.263,25.54 17.001,35.481c-0,-0.001 -0,23.288 -0,23.288c-4.786,4.134 -8.935,8.495 -12.315,13.109c-4.28,-0.563 -8.923,-0.277 -13.114,0.965c-5.358,1.59 -9.907,4.679 -12.664,8.853c-13.224,20.019 1.876,39.481 8.72,44.6c2.487,1.86 8.346,3.598 16.411,3.942c13.236,0.564 34.263,-1.116 49.595,-2.179l1.426,-0.099l1.324,-0.538c13.095,-5.317 23.056,-15.234 29.911,-29.595l0.874,-1.83l0,-130.063c0,-0 4.448,-2.011 4.448,-2.011c-0,0 18.99,0 18.99,0c16.515,20.181 36.856,33.888 58.954,32.549Zm-9.501,-18.421c-14.391,-2.716 -27.09,-14.337 -38.027,-28.55l-2.69,-3.494l-31.587,-0l-18.504,8.363l0,137.495c-4.45,8.58 -10.46,14.854 -18.366,18.424c-14.561,1.027 -33.811,2.572 -46.083,2.048c-2.698,-0.115 -6.045,-0.66 -6.979,-0.82c-3.361,-2.86 -9.716,-11.242 -3.967,-19.946c0.825,-1.248 2.582,-1.598 4.258,-1.885c2.33,-0.398 4.846,-0.303 6.67,0.575l7.578,3.647l4.118,-7.333c3.416,-6.083 8.667,-11.506 15.054,-16.585l3.382,-2.69l0,-38.263l-2.109,-2.501c-6.965,-8.262 -11.995,-18.262 -15.178,-29.958c-0.002,-0.005 -0.002,-80.853 -0.002,-80.853c20.648,-12.981 45.422,-22.19 73.106,-28.804c0.002,-0.001 58.946,-0.001 58.946,-0.001c0,0 10.38,8.665 10.38,8.665l0,82.466Z" />
        <path d="M355.026,75.456l-0,108.761l8.416,0.509c22.098,1.339 42.439,-12.368 58.955,-32.549c-0.001,0 18.989,0 18.989,0c0,0 4.448,2.011 4.448,2.011c-0,-0 -0,130.063 -0,130.063l0.874,1.83c6.855,14.361 16.816,24.278 29.911,29.595l1.324,0.538l1.426,0.099c15.332,1.063 36.359,2.743 49.595,2.179c8.065,-0.344 13.924,-2.082 16.411,-3.942c6.844,-5.119 21.944,-24.581 8.72,-44.6c-2.757,-4.174 -7.306,-7.263 -12.664,-8.853c-4.191,-1.242 -8.835,-1.528 -13.116,-0.966c-3.378,-4.613 -7.527,-8.974 -12.314,-13.109c0.001,0 0.001,-23.288 0.001,-23.288c7.738,-9.94 13.43,-21.754 17.001,-35.48l0.288,-1.109l0,-92.81l-3.97,-2.661c-23.299,-15.617 -51.784,-26.585 -83.946,-34.177l-1.015,-0.239l-67.532,-0l-21.802,18.198Zm180.153,224.315c-0.934,0.16 -4.281,0.705 -6.979,0.82c-12.272,0.524 -31.521,-1.021 -46.08,-2.046c-7.91,-3.573 -13.919,-9.847 -18.369,-18.424c-0,-0.002 -0,-137.497 -0,-137.497l-18.504,-8.363l-31.587,-0l-2.69,3.494c-10.937,14.213 -23.636,25.834 -38.028,28.55l0,-82.466c0.001,-0 10.381,-8.665 10.381,-8.665c-0,0 58.944,0 58.944,0c27.688,6.615 52.461,15.824 73.107,28.805c0.001,0 0.001,80.848 0.001,80.848c-3.185,11.7 -8.215,21.701 -15.18,29.963l-2.109,2.501l-0,38.263l3.382,2.69c6.387,5.079 11.638,10.502 15.054,16.585l4.118,7.333l7.578,-3.647c1.824,-0.878 4.34,-0.973 6.67,-0.575c1.676,0.287 3.433,0.637 4.258,1.885c5.749,8.704 -0.606,17.086 -3.967,19.946Z" />
        <path d="M363.984,234.011l-8.958,8.959l-0,47.058l8.958,8.958l41.21,0c-0,0 -0,109.699 -0,109.699l8.958,8.958l77.692,0l8.959,-8.958l-0,-45.925l-8.959,-8.958l-31.622,0c-0,0 -0,-110.832 -0,-110.832l-8.958,-8.959l-87.28,0Zm8.959,17.917l69.362,-0c0,-0 0,110.832 0,110.832l8.959,8.959l31.622,-0c-0,-0 -0,28.007 -0,28.007c-0,0 -59.776,0 -59.776,0c0,0 0,-109.698 0,-109.698l-8.958,-8.958l-41.21,-0l0.001,-29.142Z" />
        <path d="M259.857,287.604l0,130.039l66.165,0l0,-129.964l-1.152,-2.046c-7.322,-13.005 -20.772,-22.723 -32.929,-22.775c-10.855,-0.046 -22.453,8.252 -30.862,22.653l-1.222,2.093Zm17.917,112.122l-0,-107.184c2.301,-3.606 4.818,-6.634 7.516,-8.818c2.14,-1.731 4.301,-2.959 6.575,-2.949c6.109,0.026 12.154,5.359 16.239,11.73c0.002,0.002 0.002,107.221 0.002,107.221l-30.332,0Z" />
      </g>
    </>
  ),
};
