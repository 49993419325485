import React from 'react';
import { useDataContext } from 'hooks';
import {
  GenericStatDetailsContainer,
  SpecialDamageContainer,
  SpecialDamageValuesContainer,
  SpecialDamageIcon,
  StatContainer,
} from './styled';

import { Icon, Text } from 'ui/core';

import { SpecialDamage } from 'models';
import { Armor, isArmor } from 'models/Armor';
import {
  Weapon,
  isWeapon,
  isMeleeWeapon,
  isBow,
  InsectGlaive,
} from 'models/weapons';

interface GenericStatDetailsProps {
  gear: Armor | Weapon;
}

export const GenericStatDetails: React.FC<GenericStatDetailsProps> = ({
  gear,
}) => {
  const specialWeapons = [
    'SwitchAxe',
    'ChargeBlade',
    'Gunlance',
    'InsectGlaive',
    'Bow',
  ] as const;
  const { getWeaponData } = useDataContext();

  // create armor resistance stats
  const createArmorStats = (gear: Armor) => {
    const {
      defenseStats: {
        fireResistValue,
        parsedDefenseValue,
        waterResistValue,
        thunderResistValue,
        iceResistValue,
        dragonResistValue,
      },
    } = gear;

    const stats = [
      'Defense',
      'Fire',
      'Water',
      'Thunder',
      'Ice',
      'Dragon',
    ] as const;

    const statValues = [
      parsedDefenseValue,
      fireResistValue,
      waterResistValue,
      thunderResistValue,
      iceResistValue,
      dragonResistValue,
    ];

    return (
      <>
        {stats.map((stat, index) => {
          return (
            <StatContainer>
              <Icon name={stat} />
              {statValues[index]}
            </StatContainer>
          );
        })}
      </>
    );
  };

  // create base weapon stats
  const createWeaponStats = (gear: Weapon) => {
    const { baseDamage, affinity, defenseBonus } = gear;
    const specialDamage = isMeleeWeapon(gear)
      ? gear.specialDamage
      : isBow(gear) && gear.specialDamage
      ? gear.specialDamage
      : [];

    return (
      <>
        <StatContainer>
          <Icon name="Attack" />
          {baseDamage}
        </StatContainer>
        <StatContainer>
          <Icon name="Affinity" />
          {affinity === '0' ? '-' : affinity}
        </StatContainer>
        <StatContainer>
          <Icon name="Element" />
          {getElementalDamage(specialDamage as SpecialDamage[])}
        </StatContainer>
        {defenseBonus && (
          <StatContainer>
            <Icon name="Defense" />
            {defenseBonus}
          </StatContainer>
        )}
      </>
    );
  };

  // create additional weapon-specific stats
  const createSpecialWeaponStats = (specialWeapon: any) => {
    const { weaponType } = specialWeapon;
    let specialStat: any;

    if (weaponType === 'SwitchAxe' || weaponType === 'ChargeBlade') {
      const { type, damage } = specialWeapon.phial;

      specialStat = (
        <StatContainer>
          <Icon name="Phial" />
          {damage
            ? getElementalDamage([{ damageType: type, damageValue: damage }])
            : type}
        </StatContainer>
      );
    } else if (weaponType === 'Gunlance') {
      const { type, level } = specialWeapon.shelling;
      specialStat = (
        <StatContainer fontSize="small">
          <Icon name="Shelling" />
          {`${type} ${level}`}
        </StatContainer>
      );
    } else if (weaponType === 'InsectGlaive') {
      const insectGlaives = getWeaponData('InsectGlaive') as InsectGlaive[];

      const kinsectLevel = specialWeapon.kinsectLevel
        ? specialWeapon.kinsectLevel
        : insectGlaives.find(
            (insectGlaive) => insectGlaive.id === specialWeapon.id
          )?.kinsectLevel;
      specialStat = (
        <StatContainer fontSize="small">
          <Icon name="Kinsect" />
          {`LEVEL ${kinsectLevel}`}
        </StatContainer>
      );
    } else if (weaponType === 'Bow') {
      const [arc] = specialWeapon.shots;
      const { type } = arc;

      specialStat = (
        <StatContainer fontSize="small">
          <Text fontWeight="bold">ARC</Text>
          {type}
        </StatContainer>
      );
    }
    return specialStat;
  };

  // create elemental damage stat
  const getElementalDamage = (specialDamage: SpecialDamage[]) => {
    const statusNames = [
      'Poison',
      'Stun',
      'Paralysis',
      'Sleep',
      'Blast',
      'Exhaust',
    ] as const;

    return specialDamage.length === 0 ? (
      '-'
    ) : (
      <SpecialDamageContainer>
        {specialDamage.map((specialDamageObject) => {
          const { damageType, damageValue } = specialDamageObject;

          return (
            <SpecialDamageValuesContainer
              lineHeight={specialDamage.length > 1 ? 1 : 'initial'}
            >
              <SpecialDamageIcon
                name={damageType as any}
                height={14}
                width={14}
              />
              {damageValue}
            </SpecialDamageValuesContainer>
          );
        })}
      </SpecialDamageContainer>
    );
  };

  return (
    <GenericStatDetailsContainer>
      {isArmor(gear) && createArmorStats(gear)}
      {isWeapon(gear) && createWeaponStats(gear)}
      {isWeapon(gear) && specialWeapons.includes(gear.weaponType as any)
        ? createSpecialWeaponStats(gear)
        : null}
    </GenericStatDetailsContainer>
  );
};
