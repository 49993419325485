import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLoadoutStore } from 'hooks';
import SpinnerSvg from 'components/Spinner/spinner.svg';
import axios from 'axios';

import {
  ImportLoadoutContainer,
  ImportButton,
  ImportHelperText,
  ImportFormContainer,
  ImportForm,
  ImportInput,
} from './styled';

interface ImportLoadoutProps {
  toggleModal: (toggle: false) => void;
}
export const ImportLoadout: React.FC<ImportLoadoutProps> = ({
  toggleModal,
}) => {
  const [formData, setFormData] = useState('');
  const [isValidCode, setIsValidCode] = useState(false);
  const [importActionFired, setImportActionFired] = useState(false);

  const assignLoadout = useLoadoutStore((state) => state.assignLoadout);
  const { isLoading, isSuccess, data, isError } = useQuery(
    ['getLoadoutImport', formData],
    () => {
      // return axios.get(`/api/loadout/${formData}`);
      return axios.post(`/.netlify/functions/import`, formData);
    },
    {
      enabled: importActionFired,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        assignLoadout(data.data);
        setImportActionFired(false);
        setFormData('');
        toggleModal(false);
      }
    } else if (isError) {
      setImportActionFired(false);
    }
  });

  const validateCodeInput = (input: string) => {
    const regexExp = /^[a-f0-9]{32}$/gi;
    setIsValidCode(regexExp.test(input));
  };

  const handleFormChange = (e: any) => {
    setFormData(e.target.value);
    validateCodeInput(e.target.value);
  };

  const handleImportClick = (e: any) => {
    e.preventDefault();
    setImportActionFired(true);
  };

  return (
    <ImportLoadoutContainer>
      {isError ? (
        <ImportHelperText color="red">
          Could not find associated loadout. Try another code.
        </ImportHelperText>
      ) : null}
      <ImportFormContainer>
        <ImportButton
          disabled={!isValidCode || formData === ''}
          onClick={handleImportClick}
        >
          {isLoading ? (
            <img
              style={{ height: '20px', width: '20px', margin: 'auto' }}
              src={SpinnerSvg}
            />
          ) : (
            'Import'
          )}
        </ImportButton>
        <ImportForm>
          <ImportInput
            placeholder="paste code"
            value={formData}
            onChange={handleFormChange}
          ></ImportInput>
        </ImportForm>
      </ImportFormContainer>
    </ImportLoadoutContainer>
  );
};
