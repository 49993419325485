import React, { useState, useEffect } from 'react';

import { AccessoriesContainer, AccesoryContainer } from './styled';
import { Petalace, Talisman } from './components';
import { useIsMobile } from 'hooks';

// rename to trinkets container
interface AccessoriesProps {}
export const Accessories: React.FC<AccessoriesProps> = ({}) => {
  const isMobile = useIsMobile();

  return (
    <AccessoriesContainer gridArea={isMobile ? 'slot' : 'accessories'}>
      <AccesoryContainer marginRight="tiny">
        <Petalace />
      </AccesoryContainer>
      <AccesoryContainer>
        <Talisman />
      </AccesoryContainer>
    </AccessoriesContainer>
  );
};
