import {
  PhialTypeSpan,
  PhialDamageContainer,
  DamageIcon,
  PhialCellContainer,
} from './styled';

export const phialAccessor = (originalRow: any) => {
  return originalRow.phial;
};

export const PhialCell = ({ value }: { value: any }) => {
  const { type, damage } = value;
  return (
    <PhialCellContainer>
      {damage ? (
        <PhialDamageContainer>
          <DamageIcon name={type} height={16} width={16} />
        </PhialDamageContainer>
      ) : (
        <PhialTypeSpan>{type}</PhialTypeSpan>
      )}
    </PhialCellContainer>
  );
};
