import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M12 21H4L11 3H19L12 21Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
