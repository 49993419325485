import { isArmor, DefenseStats } from 'models/Armor';
import {
  isBow,
  isMeleeWeapon,
  MeleeWeapon,
  RangedWeapon,
  Weapon,
} from 'models/weapons';
import { Loadout } from 'models/state';
import { Element } from 'models/enums/Element';
import { Status } from 'models/enums/Status';

import { statBlockSkillsAndModifiers } from './constants';

type OnlyArmor = Omit<
  Loadout,
  | 'Weapon'
  | 'Talisman'
  | 'LoadoutSwitchSkills'
  | 'Petalace'
  | 'Kinsect'
  | 'QurioWeaponConfig'
>;
type Resistances = Omit<DefenseStats, 'parsedDefenseValue'>;

export interface ResistsDisplay {
  FIR: number;
  ICE: number;
  WTR: number;
  THN: number;
  DRA: number;
}

type StatBlock = 'attack' | 'affinity' | 'defense' | 'resists' | 'elements';

interface StatTuple {
  statBlock: StatBlock;
  statValue: number | ResistsDisplay | any;
}

const keyToResistanceName = {
  fireResistValue: 'FIR',
  waterResistValue: 'WTR',
  thunderResistValue: 'THN',
  iceResistValue: 'ICE',
  dragonResistValue: 'DRA',
};

const emptyDefenseStats = {
  fireResistValue: 0,
  waterResistValue: 0,
  thunderResistValue: 0,
  iceResistValue: 0,
  dragonResistValue: 0,
} as Resistances;

/**
 * Sum the defense values of each Armor piece.
 * @param set - A set generated from the GearGenerator.
 * @returns The summed defense of all armor pieces.
 */
export const sumArmorDefense = (set: OnlyArmor) => {
  return Object.values(set).reduce((totalDefense, piece) => {
    const pieceDefenseValue =
      piece && isArmor(piece)
        ? parseInt(piece.defenseStats['parsedDefenseValue'] as string, 10)
        : 0;
    return totalDefense + pieceDefenseValue;
  }, 0);
};

/**
 * Sum the resistance values of each Armor piece.
 * @param set - A set generated from the GearGenerator.
 * @returns An object of summed resists of all armor pieces with the abbreviated resistance as the key.
 */
export const sumArmorResistances = (set: OnlyArmor): ResistsDisplay => {
  return Object.values(set)
    .filter((piece) => piece !== null)
    .map((piece) => {
      return piece ? piece.defenseStats : emptyDefenseStats;
    })
    .reduce(
      (totalDefenseStats, pieceDefenseStats) => {
        Object.entries(pieceDefenseStats).forEach(([stat, statValue]) => {
          if (stat !== 'parsedDefenseValue') {
            // @ts-ignore
            const resistName = keyToResistanceName[stat];
            // @ts-ignore
            totalDefenseStats[resistName] += parseInt(statValue, 10);
          }
        });
        return totalDefenseStats;
      },
      {
        FIR: 0,
        WTR: 0,
        THN: 0,
        ICE: 0,
        DRA: 0,
      }
    );
};

/**
 *
 * @param weapon - The Weapon to extract defense from.
 * @returns The defense of the Weapon or 0 if no such value exists.
 */
export const getWeaponDefense = (weapon: Weapon | null) => {
  return weapon && weapon.defenseBonus ? weapon.defenseBonus : 0;
};

/**
 *
 * @param weapon - The Weapon to extract attack from.
 * @returns The attack of the Weapon or 0 if no such value exists.
 */
export const getWeaponAttack = (weapon: Weapon | null) => {
  return weapon && weapon.baseDamage ? weapon.baseDamage : 0;
};

/**
 *
 * @param weapon - The Weapon to extract SpecialDamage from.
 * @returns The SpecialDamage from the Weapon, null if no such value exists or if the weapon is ranged.
 */
export const getWeaponSpecialDamage = (
  weapon: MeleeWeapon | RangedWeapon | null
) => {
  return (weapon && isMeleeWeapon(weapon)) || (weapon && isBow(weapon))
    ? weapon.specialDamage && weapon.specialDamage.length !== 0
      ? weapon.specialDamage
      : null
    : null;
};

/**
 *
 * @param weapon - The Weapon to extract affinity from.
 * @returns The Affinity from the Weapon, or 0 if no such value exists.
 */
export const getWeaponAffinity = (weapon: Weapon | null) => {
  return weapon && weapon.affinity ? weapon.affinity : 0;
};

/**
 *
 * @param specialDamage An element or status value.
 * @returns An emoji corresponding to input.
 */
export const getElementEmoji = (specialDamage: Element | Status) => {
  const elementToEmoji = {
    Fire: '🔥',
    Water: '💧',
    Thunder: '⚡',
    Dragon: '🐲',
    Ice: '❄️',
    Poison: '☠️',
    Stun: '💫',
    Paralysis: '♒',
    Sleep: '💤',
    Blast: '💣',
    Exhaust: '💢',
  };

  return elementToEmoji[specialDamage];
};

/*
  TO BE ACCOUNTED FOR:
  Handicraft
  Bludgeoner
*/
const sharpnessSkillIds = [21];

// not sure if i like this implementation.
// trying to abstract everything into a single function makes for a huge messy function
// but reduces the amount of times we have to process a complete set, and handles the scenarios where a skill modifies 2 stats, e.g. defense and resists.
// it also simplifies adding new skills that modify a stat

/**
 * Apply skill modifiers to corresponding StatBlocks.
 *
 * skillIds ith member corresponds to the ith index of appliedSkillLevels.
 * e.g. skillIds = [0,5,10], appliedSkillLevels = [1,2,0], i = 1, skill 5 has a level of 2.
 *
 * 1. Each StatBlock has its corresponding configs pulled in.
 * 2. For each skill, check if its present in the config.
 * 3. If present, iterate through the modifiers and apply the corresponding calculations to the corresponding stat, using the appliedSkillLevel to access the right modifier.
 * @param statTuples - An array of StatTuples.
 * @param skillIds - An array of skill ids.
 * @param appliedSkillLevels An array of appliedSkillLevels.
 * @returns An array of mutated StatTuples.
 */
export const applySkillModifiers = (
  statTuples: StatTuple[],
  skillIds: number[],
  appliedSkillLevels: number[]
) => {
  return statTuples.map((statTuple: StatTuple) => {
    const { statBlock, statValue } = statTuple;
    let newStatValue =
      typeof statValue === 'object' && !Array.isArray(statValue)
        ? { ...statValue }
        : Array.isArray(statValue)
        ? [...statValue]
        : statValue;

    const statBlockConfig = statBlockSkillsAndModifiers[statBlock];
    const { statBlockSkillIds } = statBlockConfig;

    for (let i = 0; i < skillIds.length; i++) {
      const currentSkill = skillIds[i];
      const appliedSkillLevel = appliedSkillLevels[i];
      if (statBlockSkillIds.includes(currentSkill)) {
        const { statBlockSteps } = statBlockConfig;
        const skillCalculations = statBlockSteps[currentSkill];

        skillCalculations.forEach((skillSteps: any) => {
          skillSteps.forEach((skillStep: any) => {
            const { stat: stepStat, breakpoint, statModifiers } = skillStep;
            const maxModifier = statModifiers.length - 1;
            const currLevel =
              appliedSkillLevel > maxModifier ? maxModifier : appliedSkillLevel;

            if (stepStat === 'attack') {
              if (typeof newStatValue === 'number') {
                if (currLevel <= breakpoint) {
                  newStatValue += statModifiers[currLevel];
                } else {
                  const staticModifier = statModifiers[breakpoint];
                  newStatValue += staticModifier;
                  newStatValue += newStatValue * statModifiers[currLevel];
                }
              }
            } else if (stepStat === 'affinity') {
              if (typeof newStatValue === 'number') {
                newStatValue += statModifiers[currLevel];
              }
            } else if (stepStat === 'defense') {
              if (typeof newStatValue === 'number') {
                if (breakpoint) {
                  // either add or add then multiply
                  if (currLevel <= breakpoint) {
                    newStatValue += statModifiers[currLevel];
                  } else {
                    const staticModifier = statModifiers[breakpoint];
                    newStatValue += staticModifier;
                    newStatValue += newStatValue * statModifiers[currLevel];
                  }
                } else {
                  // just add the bonus
                  newStatValue += statModifiers[currLevel];
                }
              }
            } else if (stepStat === 'resists') {
              const { resistance } = skillStep;

              if (resistance === 'all') {
                Object.entries(newStatValue).forEach((resistAndResistVal) => {
                  const resist = resistAndResistVal[0] as
                    | 'FIR'
                    | 'WTR'
                    | 'THN'
                    | 'ICE'
                    | 'DRA';

                  (newStatValue as ResistsDisplay)[resist] +=
                    statModifiers[currLevel];
                });
              } else {
                (newStatValue as ResistsDisplay)[
                  resistance as 'FIR' | 'WTR' | 'THN' | 'ICE' | 'DRA'
                ] += statModifiers[currLevel];
              }
            } else if (stepStat === 'elements') {
              const { element } = skillStep;
              newStatValue = newStatValue.map((specialDamage: any) => {
                const { damageType, damageValue } = specialDamage;

                if (damageType === element) {
                  // element
                  if (breakpoint) {
                    if (currLevel <= breakpoint) {
                      return {
                        damageType,
                        damageValue: Math.ceil(
                          damageValue + statModifiers[currLevel]
                        ),
                      };
                    } else {
                      return {
                        damageType,
                        damageValue: Math.ceil(
                          damageValue +
                            statModifiers[breakpoint] +
                            (damageValue + statModifiers[breakpoint]) *
                              statModifiers[currLevel]
                        ),
                      };
                    }
                  } else {
                    // status
                    return {
                      damageType,
                      damageValue: Math.ceil(
                        damageValue + damageValue * statModifiers[currLevel]
                      ),
                    };
                  }
                } else {
                  // no match
                  return {
                    damageType,
                    damageValue,
                  };
                }
              });
            }
          });
        });
      }
    }
    return {
      statBlock,
      statValue:
        typeof newStatValue === 'number'
          ? Math.ceil(newStatValue)
          : newStatValue,
    };
  });
};
