import { useState } from 'react';
import { Dialog } from '@headlessui/react';

import css from '@styled-system/css';
import styled from 'styled-components';
import { Icon, Button, Container } from 'ui/core';

import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';

interface PrimitiveModalProps {
  className?: any;
  isOpen: any;
  setIsOpen: any;
  modalTitle?: string;
  modalDescription?: string;
  children?: any;
}

type ModalProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

// use rem to restrict width
const PrimitiveModal: React.FC<PrimitiveModalProps> = ({
  className,
  isOpen,
  setIsOpen,
  modalTitle,
  modalDescription,
  children = <div>pass me in some content.</div>,
}) => {
  return (
    <ModalContainer open={isOpen} onClose={() => setIsOpen(false)}>
      <FullHeightContainer>
        <ModalOverlay />
        <SpanToCenterTheModal>&#8203;</SpanToCenterTheModal>
        <ModalContentContainer
          initial={{ y: -10 }}
          animate={{ y: 0 }}
          className={className}
        >
          <IconButton onClick={() => setIsOpen(false)}>
            <Icon name="X" height={15} width={15}></Icon>
          </IconButton>
          {modalTitle && <Dialog.Title>{modalTitle}</Dialog.Title>}
          {modalDescription && (
            <Dialog.Description>{modalDescription}</Dialog.Description>
          )}
          <ModalContent className={className}>{children}</ModalContent>
        </ModalContentContainer>
      </FullHeightContainer>
    </ModalContainer>
  );
};

const ModalContainer = styled(Dialog)<any>`
  ${css({
    position: 'fixed',
    inset: 0,
    zIndex: 10,
    overflowY: 'auto',
  })}
`;

const FullHeightContainer = styled.div`
  ${css({
    paddingX: 'medium',
    minHeight: '100vh',
    textAlign: 'center',
  })}
`;

const ModalOverlay = styled(Dialog.Overlay)`
  ${css({
    position: 'fixed',
    inset: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
  })}
`;

const SpanToCenterTheModal = styled.span.attrs({
  'aria-hidden': 'true',
})`
  ${css({
    display: 'inline-block',
    height: '100vh',
    verticalAlign: 'middle',
  })}
`;

const ModalContentContainer = styled(Container)`
  ${css({
    display: 'inline-block',
    maxWidth: '50rem',
    padding: 'xLarge',
    marginY: 'xLarge',
    overflow: 'hidden',
    verticalAlign: 'middle',
    backgroundColor: 'cardBackground',
    transform: 'scaleX(1) scaleY(1)',
    boxShadow: 'diffused',
    borderRadius: 'medium',
    textAlign: 'left',
    position: 'relative',
    color: 'text',
  })}
`;

const ModalContent = styled.div`
  ${css({
    marginTop: 'xLarge',
    marginBottom: 'small',
  })}
`;

const IconButton = styled(Button).attrs({ variant: 'circle' })`
  ${css({
    position: 'absolute',
    top: 'xLarge',
    right: 'xLarge',
    cursor: 'pointer',
  })}
`;

export const Modal = styled(PrimitiveModal)<ModalProps & PrimitiveModalProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
  width: 100%;
`;
