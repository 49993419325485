import React from 'react';

import { SimpleGearCardContainer, StyledIcon } from './styled';

import { Weapon } from 'models/weapons';
import { Armor, isArmor } from 'models/Armor';
import { ArmorType, WeaponType } from 'models/enums';
import { Span } from 'ui/core';

interface SimpleGearCard {
  gear: Armor | Weapon;
  slot: any;
}

export const SimpleGearCard: React.FC<SimpleGearCard> = ({ gear, slot }) => {
  const { name } = gear ?? {};

  return (
    <SimpleGearCardContainer>
      <StyledIcon
        name={slot as WeaponType | ArmorType}
        height={20}
        width={20}
      />
      {name ?? <Span opacity="50%">Empty</Span>}
    </SimpleGearCardContainer>
  );
};
