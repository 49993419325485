export const WeaponTypeObject = {
  GreatSword: "GreatSword",
  LongSword: "LongSword",
  SwordAndShield: "SwordAndShield",
  DualBlades: "DualBlades",
  Hammer: "Hammer",
  HuntingHorn: "HuntingHorn",
  Lance: "Lance",
  Gunlance: "Gunlance",
  SwitchAxe: "SwitchAxe",
  ChargeBlade: "ChargeBlade",
  InsectGlaive: "InsectGlaive",
  Bow: "Bow",
  LightBowgun: "LightBowgun",
  HeavyBowgun: "HeavyBowgun",
} as const;

export const WeaponTypeValues = Object.values(WeaponTypeObject);

export const isWeaponType = (tbd: any): tbd is WeaponType => {
  return WeaponTypeValues.includes(tbd);
};

export type WeaponType = typeof WeaponTypeObject[keyof typeof WeaponTypeObject];
