interface StatBlock {
  statBlockSkillIds: number[];
  statBlockSteps: any;
}
// each id maps to an array of 'steps' to apply to stats at the same time
// TODO: stormsoul skill bonus (add 1/2 levels to all other skills)
// TODO: sharpness skill bonus (handicraft)
// TODO: Defiance
export const statBlockSkillsAndModifiers = {
  attack: {
    statBlockSkillIds: [0],
    statBlockSteps: {
      0: [
        [
          {
            breakpoint: 2,
            stat: 'attack',
            statModifiers: [3, 6, 9, 0.05, 0.06, 0.08, 0.1],
          },
        ],
      ],
    },
  },
  affinity: {
    statBlockSkillIds: [5],
    statBlockSteps: {
      5: [
        [
          {
            breakpoint: null,
            stat: 'affinity',
            statModifiers: [5, 10, 15, 20, 25, 30, 40],
          },
        ],
      ],
    },
  },
  defense: {
    statBlockSkillIds: [55, 66, 67, 68, 69, 70],
    statBlockSteps: {
      55: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10, 20, 20, 35, 35],
          },
          {
            breakpoint: 1,
            stat: 'defense',
            statModifiers: [5, 10, 0.05, 0.05, 0.08, 0.08, 0.1],
          },
        ],
      ],
      66: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10],
          },
        ],
      ],
      67: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10],
          },
        ],
      ],
      68: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10],
          },
        ],
      ],
      69: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10],
          },
        ],
      ],
      70: [
        [
          {
            breakpoint: null,
            stat: 'defense',
            statModifiers: [0, 0, 10],
          },
        ],
      ],
    },
  },
  resists: {
    statBlockSkillIds: [55, 66, 67, 68, 69, 70, 108, 109],
    statBlockSteps: {
      55: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'all',
            statModifiers: [0, 0, 0, 3, 3, 5, 5],
          },
        ],
      ],
      66: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'FIR' as const,
            statModifiers: [6, 12, 20],
          },
        ],
      ],
      67: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'WTR',
            statModifiers: [6, 12, 20],
          },
        ],
      ],
      68: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'ICE',
            statModifiers: [6, 12, 20],
          },
        ],
      ],
      69: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'THN',
            statModifiers: [6, 12, 20],
          },
        ],
      ],
      70: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'DRA',
            statModifiers: [6, 12, 20],
          },
        ],
      ],
      108: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'DRA',
            statModifiers: [1, 2, 3, 4, 4],
          },
        ],
      ],
      109: [
        [
          {
            breakpoint: null,
            stat: 'resists',
            resistance: 'THN',
            statModifiers: [1, 2, 3, 4, 4],
          },
        ],
      ],
    },
  },
  elements: {
    statBlockSkillIds: [12, 13, 14, 15, 16, 17, 18, 19, 20, 100, 101, 110],
    statBlockSteps: {
      12: [
        [
          {
            breakpoint: 1,
            stat: 'elements',
            element: 'Fire',
            statModifiers: [2, 3, 0.05, 0.1, 0.2],
          },
        ],
      ],
      13: [
        [
          {
            breakpoint: 1,
            stat: 'elements',
            element: 'Water',
            statModifiers: [2, 3, 0.05, 0.1, 0.2],
          },
        ],
      ],
      14: [
        [
          {
            breakpoint: 1,
            stat: 'elements',
            element: 'Ice',
            statModifiers: [2, 3, 0.05, 0.1, 0.2],
          },
        ],
      ],
      15: [
        [
          {
            breakpoint: 1,
            stat: 'elements',
            element: 'Thunder',
            statModifiers: [2, 3, 0.05, 0.1, 0.2],
          },
        ],
      ],
      16: [
        [
          {
            breakpoint: 1,
            stat: 'elements',
            element: 'Dragon',
            statModifiers: [2, 3, 0.05, 0.1, 0.2],
          },
        ],
      ],
      17: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Poison',
            statModifiers: [0.05, 0.1, 0.2],
          },
        ],
      ],
      18: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Paralysis',
            statModifiers: [0.05, 0.1, 0.2],
          },
        ],
      ],
      19: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Sleep',
            statModifiers: [0.05, 0.1, 0.2],
          },
        ],
      ],
      20: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Blast',
            statModifiers: [0.05, 0.1, 0.2],
          },
        ],
      ],
      100: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Water',
            statModifiers: [0.05, 0.1, 0.1, 0.1],
          },
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Ice',
            statModifiers: [0.05, 0.1, 0.1, 0.1],
          },
        ],
      ],
      101: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Fire',
            statModifiers: [0.05, 0.1, 0.1, 0.1],
          },
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Blast',
            statModifiers: [0.05, 0.1, 0.1, 0.1],
          },
        ],
      ],
      110: [
        [
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Thunder',
            statModifiers: [0.05, 0.1, 0.15, 0.15, 0.15],
          },
          {
            breakpoint: null,
            stat: 'elements',
            element: 'Dragon',
            statModifiers: [0.05, 0.1, 0.15, 0.15, 0.15],
          },
        ],
      ],
    },
  },
} as {
  attack: StatBlock;
  defense: StatBlock;
  affinity: StatBlock;
  resists: StatBlock;
  elements: StatBlock;
};
