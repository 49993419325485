import React, { useState } from 'react';
import { useDataContext } from 'hooks';

import { DataTable } from 'components';
import { Icon, Modal } from 'ui/core';
import { DecorationSelectWrapper, DecorationButton } from './styled';

import { getIconName } from 'utils/formatting';
import { Decoration, DecorationSlot } from 'models';

interface DecorationSelectProps {
  decorationSlot: DecorationSlot;
  onSelectDecoration: (slotPosition: number, decoration: Decoration) => void;
  isRampage?: boolean;
}

export const DecorationSelect: React.FC<DecorationSelectProps> = ({
  decorationSlot,
  onSelectDecoration,
  isRampage = false,
}) => {
  const { slotValue, slotPosition } = decorationSlot;
  const { getDecorationData, getRampageDecorationData } = useDataContext();
  const dropdownData = isRampage
    ? getRampageDecorationData(slotValue)
    : getDecorationData(slotValue);

  const [openModal, setOpenModal] = useState(false);

  const onSelectWrapper = (decoration: any) => {
    onSelectDecoration(slotPosition, decoration);
  };

  return (
    <DecorationSelectWrapper fontSize={['tiny', null, 'medium', null, null]}>
      <Icon name={getIconName(slotValue)} height={25} width={25} />
      <DecorationButton onClick={() => setOpenModal(true)}>
        {slotValue} Slot
      </DecorationButton>
      {openModal && (
        <Modal
          isOpen={true}
          setIsOpen={setOpenModal}
          modalTitle={`Decorations`}
          modalDescription={`Select a ${slotValue} slot decoration.`}
        >
          <DataTable
            onClickRow={onSelectWrapper}
            slot="Decorations"
            tableData={dropdownData}
          ></DataTable>
        </Modal>
      )}
    </DecorationSelectWrapper>
  );
};
