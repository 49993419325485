import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon, Span, Text } from 'ui/core';

export const TempContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'center',
    fontFamily: 'Arial',
  })}
`;

export const StatusPane = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    padding: 'medium',
    paddingX: 'huge',
    maxWidth: '300px',
    minWidth: '300px',
    wordWrap: 'break-word',
    border: '1px solid',
    borderColor: 'cardBorder',
    borderRadius: 'medium',
    margin: 'tiny',
    backgroundColor: 'cardBackground',
  })}
`;

export const LongPane = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    padding: 'medium',
    paddingX: 'huge',
    maxHeight: '200px',
    minHeight: '200px',
    wordWrap: 'break-word',
    border: '1px solid',
    borderColor: 'cardBorder',
    borderRadius: 'medium',
    margin: 'tiny',
    backgroundColor: 'cardBackground',
    maxWidth: '1200px',
    minWidth: '900px',
    alignSelf: 'center',
    fontFamily: 'Arial',
  })}
`;

export const SectionTitle = styled(Text)`
  ${css({
    textAlign: 'center',
    marginBottom: 'small',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'xSmall',
    color: 'secondaryText',
    letterSpacing: '1px',
  })}
`;

export const StatSection = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    backgroundColor: 'detailsBackground',
    borderColor: 'detailsBorder',
    padding: 'medium',
    borderRadius: 'medium',
  })}
`;

export const StatSectionRow = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    width: '100%',
    justifyContent: 'space-between',
    // paddingLeft: 'medium',
  })}
`;

export const IconAndTextContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const StyledIcon = styled(Icon)`
  ${css({
    marginRight: 'tiny',
  })}
`;

export const DecoNameContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    flexWrap: 'wrap',
    // justifyContent: 'space-evenly',
  })}
`;

export const DecoNamePill = styled(Span)`
  ${css({
    // border: '1px solid',
    // borderColor: 'border',
    // borderRadius: 'large',
    paddingX: 'small',
    fontSize: 'tiny',
    marginLeft: 'tiny',
    marginBottom: 'tiny',
  })}
`;
export const SongsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    fontSize: 'tiny',
    flexDirection: ['column', null, 'row', null, null],
    marginTop: ['1rem', null, 0, null, null],
    width: '100%',
    justifyContent: 'center',
  })}
`;

export const SongSpan = styled(Span)`
  ${css({
    fontSize: 'xSmall',
    marginX: 'tiny',
    border: '1px solid',
    borderColor: 'border',
    paddingX: 'medium',
    borderRadius: 'large',
    marginBottom: ['tiny', null, 0, null, null],
  })}
`;

const coatings = {
  CloseRange: 'white',
  Power: '#CE585E',
  Poison: '#968ACC',
  Para: '#DFE34A',
  Sleep: '#6EC0E1',
  Blast: '#90B24A',
  Exhaust: '#4C68E3',
} as const;

type coatingType = keyof typeof coatings;

export const CoatingIcon = styled(Icon).attrs({ name: 'Phial' })<{
  coating: coatingType;
  isEnabled: boolean;
}>`
  ${({ coating, isEnabled }) =>
    css({
      fill: coatings[coating],
      opacity: isEnabled ? '100%' : '25%',
    })}
`;

export const CoatingContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginTop: ['1rem', null, 0, null, null],
  })}
`;

export const ShotsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    flexDirection: ['column', null, 'row', null, null],
    width: '100%',
  })}
`;

export const ShotSpan = styled(Span)`
  ${css({
    marginX: 'tiny',
    border: '1px solid',
    borderColor: 'border',
    paddingX: 'medium',
    borderRadius: 'large',
    fontSize: 'small',
    marginTop: ['tiny', null, 0, null, null],
  })}
`;

export const WeaponPropertiesContainer = styled(Container)`
  ${css({
    alignItems: 'center',
    height: '100%',
    marginTop: ['1rem', null, 0, null, null],
  })}
`;
