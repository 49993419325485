import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M20 16.5V19.5H16V22H9V19.5H5V16.5H7V5.5L5 3H15L18 5.5V16.5H20Z"
        fill="white"
      />
      <path
        d="M9 19.5H5V16.5H7M9 19.5V22H16V19.5M9 19.5H16M16 19.5H20V16.5H18M7 16.5V5.5L5 3H15L18 5.5V16.5M7 16.5H18"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M20 19.5V16.5H18H7H5V19.5H9H16H20Z"
        fill="#C4C4C4"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M9 22V19.5H16V22H9Z"
        fill="#C4C4C4"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
