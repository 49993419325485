import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M17 3.5H12L16 9.5L13.5 13.5L16 17.5L15 22L19 17.5L17 13.5L20 9.5L17 3.5Z"
        fill="#FDED5D"
        stroke="black"
      />
      <path
        d="M8 2H3L7 8L4.5 12L7 16L6 20.5L10 16L8 12L11 8L8 2Z"
        fill="#FDED5D"
        stroke="black"
      />
    </>
  ),
};
