import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
} from 'styled-system';

type ContainerProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps;

// TODO: remove or make more robust
export const Card = styled.div<ContainerProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow
  )}
  border: 1px solid rgba(0,0,0,15%);
`;
