import { SongNamePill } from './styled';
import { Tooltip } from 'ui/core';

export const songsAccessor = (originalRow: any) => {
  return originalRow.songs.map((song: any) => ({
    name: song.name,
  }));
};

export const SongsCell = ({ value }: { value: any }) => {
  return (
    <>
      {value.map((skill: any) => {
        return <SongNamePill>{skill.name}</SongNamePill>;
      })}
    </>
  );
};
