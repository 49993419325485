import React from 'react';
import { CheckBoxContainer } from './styled';

interface CheckBoxProps {
  isChecked: boolean;
  setIsChecked: any;
  label: string;
  className?: any;
}
export const CheckBox: React.FC<CheckBoxProps> = ({
  isChecked,
  setIsChecked,
  label,
  className,
}) => {
  return (
    <CheckBoxContainer className={className}>
      <label>{label}</label>
      <input
        checked={isChecked}
        type="checkbox"
        onClick={() => setIsChecked(!isChecked)}
        style={{ cursor: 'pointer' }}
        color="red"
      ></input>
    </CheckBoxContainer>
  );
};
