import React from 'react';

import { SpinnerContainer, SpinnerText } from './styled';

import SpinnerSvg from './spinner.svg';

interface SpinnerProps {
  spinnerText?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ spinnerText }) => {
  return (
    <SpinnerContainer>
      <img src={SpinnerSvg} />
      <SpinnerText>{spinnerText}</SpinnerText>
    </SpinnerContainer>
  );
};
