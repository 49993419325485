import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon } from 'ui/core';

export const WeaponStatsCellContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    width: '100%',
    alignItems: 'baseline',
  })}
`;

export const WeaponStatContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 0',
    fontSize: 'xSmall',
  })}
`;

export const SpecialDamageContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const SpecialDamageValuesContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const StatIcon = styled(Icon)``;

export const SpecialDamageIcon = styled(Icon)`
  ${css({
    marginRight: '2px',
  })}
`;
