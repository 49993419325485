import styled from 'styled-components';
import css from '@styled-system/css';
import { Button, Container, Text, Icon } from 'ui/core';

export const TalismanTitleContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;
export const TalismanTitleText = styled(Text)`
  ${css({
    marginLeft: 'tiny',
    fontWeight: 'bold',
    fontSize: 'medium',
  })}
`;

export const TalismanButtonControls = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'space-evenly',
    opacity: '70%',
  })}
`;

export const IconButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    display: 'flex',
    textTransform: 'uppercase',
    letterSpacing: 0,
    alignItems: 'center',
    paddingY: 0,
    borderRadius: 'small',
    width: 'fit-content',
    margin: 0,
  })}
`;

export const TalismanSelectionText = styled(Text)`
  ${css({
    fontSize: 'tiny',
  })}
`;

export const SkillPickerContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    flexGrow: 1,
    justifyContent: 'center',
  })}
`;

export const MotionDecorationContainer = styled(Container)`
  ${css({
    width: '100%',
    height: '100%',
    display: 'flex',
  })}
`;

export const StyledIcon = styled(Icon)`
  ${css({
    '& path': {
      stroke: 'text',
    },
  })}
`;
