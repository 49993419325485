import React from 'react';
import { Container, Span } from 'ui/core';
import { qurioWeaponSchema } from 'components/QurioWeapon/constants';

const MAX_SLOTS = 5;
type QurioAugment =
  | 'attack'
  | 'affinity'
  | 'element'
  | 'sharpness'
  | 'status'
  | 'rampageDecoration';
interface AugmentSlotsProps {
  currentConfig: any;
  compact?: boolean;
}
export const AugmentSlots: React.FC<AugmentSlotsProps> = ({
  currentConfig,
  compact = false,
}) => {
  const { availableSlots, ...augments } = currentConfig;
  const augmentsWithCost = Object.entries(augments)
    .filter((kv) => {
      const [augment, level] = kv;
      return (level as number) >= 0;
    })
    .map((kv) => {
      const [augment, level] = kv;

      const { color, effects } = qurioWeaponSchema[augment as QurioAugment];

      return [color, effects[level as number].cost];
    });

  let colorRef = [] as any[];

  augmentsWithCost.forEach((augmentWithCost) => {
    const [color, cost] = augmentWithCost;

    Array.from(Array(cost).keys()).forEach(() => {
      colorRef.push(color);
    });
  });

  return (
    <Container
      display="flex"
      flexDirection="row"
      style={{ gap: '1rem' }}
      marginY="tiny"
      borderRadius="medium"
      backgroundColor="detailsBackground"
      padding="small"
      justifyContent="center"
    >
      {Array.from(Array(MAX_SLOTS).keys()).map((tick) => {
        return (
          <Span
            height={compact ? '22.5px' : '45px'}
            width={compact ? '15px' : '30px'}
            backgroundColor={
              tick <= colorRef.length - 1 ? colorRef[tick] : 'emptyAugment'
            }
            style={{
              transition: 'all 0.3s ease-in-out',
              clipPath:
                'polygon(50% 0, 0 35%, 0 40%, 50% 100%, 100% 40%, 100% 35%)',
            }}
          />
        );
      })}
    </Container>
  );
};
