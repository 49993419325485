import { Span } from 'ui/core';

import { DecorationSlot } from 'models';

export const decorationSlotsAccessor = (originalRow: any) => {
  return originalRow.decorationSlots.map((decorationSlot: DecorationSlot) => {
    return decorationSlot.slotValue;
  });
};

export const DecorationSlotsCell = ({ value }: { value: any }) => {
  return (
    <>
      {value.map((decorationSlotValue: any, index: number) => {
        return (
          <Span marginRight={index !== 2 ? 'tiny' : null}>
            {decorationSlotValue === 0 ? '-' : decorationSlotValue}
          </Span>
        );
      })}
    </>
  );
};
