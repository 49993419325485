import React from 'react';

export default {
  viewBox: '0 0 523 562',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M383 242l-270 3-86 85v61l104 105h41v-38h69v38h76l37-38v-76h71v-76h28V93h-70v149zM167 129v75c-3 11-11 23-20 28H77c-10-4-20-12-24-28-3-9-2-31 11-40 9-5 13-5 21-8 4-2 26 0 38 0V74c10-9 17-9 24-9 7 1 14 4 20 9s71 49 68 55c-12 27-47 55-68 73 9-49 15-70 0-73z"
        />
      </g>
      <g id="mediumColor">
        <path
          id="medium"
          stroke="#000"
          strokeWidth="17.9"
          d="M453 93l22-28V27h-44l-6 19h-11l-7-19h-45v38l26 28h65zm-28 213h71v29l-60 56-11-9v-76zm11 85v77h-74l-8-10v-76h71l11 9zm-264 67v65l19 11 50-51v-25h-69zM41 421v72h72l-72-72z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
          d="M383 242l-270 3-86 85v61l104 105h41v-38h69v38h76l37-38v-76h71v-76h28V93h-70v149zM167 129v75c-3 11-11 23-20 28H77c-10-4-20-12-24-28-3-9-2-31 11-40 9-5 13-5 21-8 4-2 26 0 38 0V74c10-9 17-9 24-9 7 1 14 4 20 9s71 49 68 55c-12 27-47 55-68 73 9-49 15-70 0-73zm216 113v64L279 411H147l-53-52 73-65 74 73h-74m216-187h70m0-87l22-28V27h-44l-6 19h-11l-7-19h-45v38l26 28h65zm-28 213h71v29l-60 56-11-9v-76zm11 85v77h-74l-8-10v-76h71l11 9zm-264 67v65l19 11 50-51v-25h-69zM41 421v72h72l-72-72z"
        />
      </g>
    </>
  ),
};
