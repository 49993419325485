import React from 'react';

export default {
  viewBox: '0 0 530 554',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M82 529v-44h26v-58h82v75l-27 27H82zm176-102H25l57-74h130l46 74zm-24-74H38v-81h196v81zm-44-81V137h-82v135h82zM25 215h83V25L25 88v127zm83-120l104 1v41H108V95zm135 79v52l40 56v134l44 61h87l48-61V282l43-56v-52c-41-3-70-19-91-45h-87c-23 28-51 43-84 45z"
        />
      </g>
      <g id="lightColor">
        <path
          id="light"
          d="M82 529v-44h26v-58h82v75l-27 27H82zm152-176H38v-81h196v81zm-44-81V137h-82v135h82zM108 95l104 1v41H108V95zm263 187h-88v134l44 61h44V282zm0-153v153h91l43-56v-52c-31-1-62-13-91-45h-43z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          d="M117 86V7L16 84v140h83v39H29v99h34L7 436h92v40H73v62h94l32-32v-70h75l-46-74h15v-99h-44V146h22V87l-104-1zM90 520v-26h27v-58h64v62l-22 22H90zm184-235v133l48 68h96l53-67V285l43-55v-65h-9c-15-1-29-5-41-10-15-6-29-17-43-32l-3-3h-95l-3 4c-21 25-46 40-78 41h-8v64l40 56zm18 128V291h70v177h-30l-40-55zm161-122v122l-43 55h-30V291h73zM86 362h121l35 56H43l43-56zm319-47v46h18v-46h-18zm-87 0v46h18v-46h-18zm-93 30H47v-64h178v64zm185-207c12 15 28 26 46 34 13 6 26 9 40 10v41l-39 50h-77V138h30zm-48 0v135h-75l-35-49v-42c31-3 57-19 79-44h31zm-245 8h64v117h-64V146zm201 55v45h18v-45h-18zm87 0v43h18v-43h-18zM99 86v120H34V93l65-50v43zm18 42v-24l86 1v23h-4-82z"
        />
        <path d="M72 272v46h36v-46H72zm95 0v46h35v-46h-35z" />
        <path d="M116 263H64v63h52v-63zm95 0h-53v63h53v-63zM81 281h18v28H81v-28zm94 0h18v28h-18v-28z" />
      </g>
    </>
  ),
};
