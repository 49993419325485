import { WeaponTypeValues } from 'models/enums/WeaponType';
import { ArmorTypeValues } from 'models/enums/ArmorType';
import {
  getArmorProperties,
  getWeaponProperties,
  getSkillProperties,
  getDecorationProperties,
  getKinsectProperties,
} from '../config';

// TODO: completely refactor below code config
// e.g. break into separate files
// const getFilterComponents = (
//   setFilter: any,
//   setAllFilters: any,
//   filterValues: any
// ) => {
//   return (
//     <Container textAlign="center">
//       <NameAndAttackTextFilter
//         setFilter={setFilter}
//         setAllFilters={setAllFilters}
//         filterValues={filterValues}
//       />
//       <DecorationSlotFilter setFilter={setFilter} />
//     </Container>
//   );
// };

// const getWeaponProperties = (type: any) => {
//   const baseWeaponOptions = {
//     defaultColumn,
//     initialFilterState: [
//       {
//         id: 'decorationSlots',
//         value: [],
//       },
//     ],
//   };

//   return {
//     getColumns: () => [
//       ...getBaseWeaponColumns(),
//       ...getWeaponSpecificColumns(type),
//     ],
//     getOptions: () => ({
//       ...baseWeaponOptions,
//       ...getWeaponSpecificOptions(type),
//     }),
//     getFilterComponents: (
//       setFilter: any,
//       setAllFilters: any,
//       filterValues: any
//     ) => getFilterComponents(setFilter, setAllFilters, filterValues),
//   };
// };

const getTableProperties = () => {
  const tableProperties = { Weapon: {} } as any;

  for (const weaponType of WeaponTypeValues) {
    // tableProperties['Weapon'][weaponType] = getWeaponProperties(weaponType);
    tableProperties['Weapon'][weaponType] = getWeaponProperties(weaponType);
  }

  for (const armorType of ArmorTypeValues) {
    tableProperties[armorType] = getArmorProperties();
  }

  tableProperties['Decorations'] = getDecorationProperties();

  tableProperties['Skills'] = getSkillProperties();
  tableProperties['Kinsect'] = getKinsectProperties();
  return tableProperties;
};

export const tableProperties = getTableProperties();
