import { CoatingsCellContainer, CoatingIcon } from './styled';

export const coatingsAccessor = (originalRow: any) => {
  return originalRow.coatings;
};

export const CoatingsCell = ({ value }: { value: any }) => {
  return (
    <CoatingsCellContainer>
      {value.map((coating: any) => {
        const { type, isEnabled } = coating;

        return (
          <CoatingIcon
            coating={type}
            isEnabled={isEnabled}
            height={16}
            width={16}
          />
        );
      })}
    </CoatingsCellContainer>
  );
};
