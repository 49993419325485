import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const ArmorContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '120px',
    minHeight: ['12rem', null, '120px', null, null],
    width: '100%',
    justifyContent: 'center',
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'cardBorder',
    transition: 'all 0.1s ease-in',
    backgroundColor: 'cardBackground',
    // ':hover': {
    //   boxShadow: '0px 0px 8px 2px #d1ebf1',
    // },
    overflow: 'auto',
  })}
`;
