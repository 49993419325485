import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const SpinnerContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })}
`;

export const SpinnerText = styled(Text)`
  ${css({
    fontSize: 'large',
  })}
`;
