import {
  ShellingCellContainer,
  ShellingTypeSpan,
  ShellingIcon,
} from './styled';

export const shellingAccessor = (originalRow: any) => {
  return originalRow.shelling;
};

export const ShellingCell = ({ value }: { value: any }) => {
  const { type, level } = value;

  return (
    <ShellingCellContainer>
      <ShellingTypeSpan>{`${type} ${level}`}</ShellingTypeSpan>
    </ShellingCellContainer>
  );
};
