import { Gear } from 'models/state';
import { Armor } from 'models';
import { Weapon } from 'models/weapons';
import create from 'zustand';

export const useFocusStore = create((set: any) => ({
  focusedGear: null as Weapon | Armor | null,
  unfocusGear: () => set({ focusedGear: null }),
  focusGear: (gear: Gear) => set({ focusedGear: gear }),
}));
