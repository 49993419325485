import React from 'react';

export default {
  viewBox: '0 0 307 255',
  paths: (
    <>
      <path
        d="m31 223 49-74-49-65 49-53h148l47 53-47 65 47 74H31Z"
        fill="#8e8e8e"
      />
      <g stroke="#d4d4d4">
        <path
          d="m191 214 32-45 33 45h-65ZM52 213l32-45 33 45H52ZM118 150h75l50-66-50-53h-75L65 84l53 66Z"
          strokeWidth="17.9"
        />
        <path d="m114 84 43-47 40 47-40 57-43-57Z" stroke-width="8.8" />
      </g>
      <path
        d="m31 223 49-74-49-65 49-53h148l47 53-47 65 47 74H31Z"
        fill="none"
        stroke="#000"
        strokeWidth="17.9"
      />
    </>
  ),
};
