import React, { useState, useEffect } from 'react';
import { useLoadoutStore, useIsMobile } from 'hooks';
import shallow from 'zustand/shallow';

import { ArmorSelect, ArmorDetails } from './components';
import { ArmorContainer } from './styled';

import { Armor } from 'models/Armor';
import { ArmorType } from 'models/enums';
import { AnimatePresence, motion } from 'framer-motion';

interface ArmorGearContainerProps {
  armorType: ArmorType;
}

export const ArmorGearContainer: React.FC<ArmorGearContainerProps> = ({
  armorType,
}) => {
  const isMobile = useIsMobile();
  const { loadout, assignSlot, clearSlot } = useLoadoutStore(
    (state: any) => ({
      loadout: state.loadout,
      assignSlot: state.assignSlot,
      clearSlot: state.clearSlot,
    }),
    shallow
  );

  const selectedArmor = loadout[armorType];

  const onSelectHandler = (gear: Armor) => {
    assignSlot(armorType, gear);
  };

  const onCancelHandler = () => {
    clearSlot(armorType);
  };

  return (
    <ArmorContainer gridArea={isMobile ? 'slot' : armorType.toLowerCase()}>
      {selectedArmor ? (
        <ArmorDetails
          selectedArmor={selectedArmor}
          onCancelArmor={onCancelHandler}
          armorType={armorType}
          isMobile={isMobile}
        ></ArmorDetails>
      ) : (
        <ArmorSelect
          armorType={armorType}
          onClickArmor={onSelectHandler}
        ></ArmorSelect>
      )}
    </ArmorContainer>
  );
};
