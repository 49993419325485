import React from 'react';

import { IconContainer, StyledWeaponTypeSelectContainer } from './styled';
import { Icon } from 'ui/core';
import { Rarity, WeaponTypeValues } from 'models/enums';
import { WeaponType } from 'models/enums';

interface WeaponTypeSelectProps {
  onClickWeaponType: (weap: WeaponType) => void;
}

// select a weapon TYPE
export const WeaponTypeSelect: React.FC<WeaponTypeSelectProps> = ({
  onClickWeaponType,
}) => {
  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.01,
      },
    },
  };
  return (
    <StyledWeaponTypeSelectContainer
      variants={variants}
      animate="visible"
      initial="hidden"
    >
      {WeaponTypeValues.map((weapon) => (
        <IconContainer
          variants={variants}
          key={weapon}
          onClick={() => onClickWeaponType(weapon)}
        >
          <Icon name={weapon} height={35} width={35} />
        </IconContainer>
      ))}
    </StyledWeaponTypeSelectContainer>
  );
};
