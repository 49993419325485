import styled from 'styled-components';
import { Container, Text, Icon } from 'ui/core';
import { css } from '@styled-system/css';

export const SkillDisplayContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    marginY: 'small',
    border: '1px solid',
    borderColor: 'detailsBorder',
    borderRadius: 'medium',
    backgroundColor: 'detailsBackground',
    padding: 'small',
    cursor: 'pointer',
  })}
`;

export const NameAndLevelContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
  })}
`;

export const SkillName = styled(Text)`
  ${css({
    fontWeight: 'bold',
  })}
`;

export const SkillLevel = styled(Text)<{ max: boolean }>`
  ${({ max }) =>
    css({
      marginLeft: 'auto',
      fontSize: 'xSmall',
      color: max ? 'orange' : 'secondaryText',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      letterSpacing: '1px',
    })}
`;

export const IndicatorContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  ${css({
    marginLeft: 'small',
    marginTop: 'tiny',
  })}
`;

export const Indicator = styled(Icon)<{
  activated: boolean;
  exceeded: boolean;
}>`
  ${({ activated, exceeded }) =>
    css({
      transition: 'all 0.1s ease',

      fill: activated ? (exceeded ? 'warning' : 'primary') : 'background',
      '& path': {
        stroke: activated
          ? exceeded
            ? 'crimson'
            : 'primaryDark'
          : 'indicatorBorder',
        strokeWidth: 1,
      },
    })}
`;
export const ExpandedIndicatorContainer = styled(Container)`
  ${css({
    marginLeft: 'auto',
  })}
`;
export const ExpandedIndicator = styled(Icon)`
  ${css({
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const SkillDescriptionContainer = styled(Container)`
  ${css({
    padding: 'medium',
  })}
`;

export const SkillDescription = styled(Text)`
  ${css({ fontSize: 'small' })}
`;

export const SkillLevelIconContainer = styled(Container)`
  ${css({
    marginLeft: -6,
  })}
`;
