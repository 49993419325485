import React, { useEffect, useState } from 'react';
import {
  useFocusStore,
  useIsMobile,
  useLoadoutStore,
  useDataContext,
} from 'hooks';
import shallow from 'zustand/shallow';

import { WeaponTypeSelect, WeaponSelect, WeaponDetails } from './components';
import {
  StyledWeaponTypeSelectContainer,
  StyledWeaponGearContainer,
} from './styled';

import { applyEffectToStat, resetStat } from 'components/QurioWeapon/utils';
import { WeaponType } from 'models/enums';
import { Weapon } from 'models/weapons';
type QurioAugment =
  | 'attack'
  | 'affinity'
  | 'element'
  | 'sharpness'
  | 'status'
  | 'rampageDecoration';
interface WeaponGearContainerProps {}
export const WeaponGearContainer: React.FC<WeaponGearContainerProps> = () => {
  const isMobile = useIsMobile();
  const { unfocusGear } = useFocusStore(
    (state) => ({ unfocusGear: state.unfocusGear }),
    shallow
  );

  const { loadout, assignSlot, clearSlot, setSwitchSkills, setKinsect } =
    useLoadoutStore(
      (state: any) => ({
        loadout: state.loadout,
        assignSlot: state.assignSlot,
        clearSlot: state.clearSlot,
        setSwitchSkills: state.setSwitchSkills,
        setKinsect: state.setKinsect,
      }),
      shallow
    );

  const { getWeaponData } = useDataContext();

  const { Weapon, QurioWeaponConfig } = loadout;

  const [selectedWeaponType, setSelectedWeaponType] =
    useState<WeaponType | null>(null);

  // whenever Weapon changes, make sure we either get its type or whatever the current selected weapon type is
  useEffect(() => {
    const loadoutSelectedWeaponType = Weapon
      ? Weapon.weaponType
      : selectedWeaponType;

    setSelectedWeaponType(loadoutSelectedWeaponType);
  }, [Weapon]);

  const originalWeapon = Weapon
    ? (getWeaponData(Weapon.weaponType as WeaponType) as any[]).find(
        (aWeapon: any) => aWeapon.name === Weapon.name
      )
    : {};
  // ensure that we apply the qurio configs if present, then save to store
  useEffect(() => {
    const { availableSlots, ...config } = QurioWeaponConfig;

    if (Weapon) {
      Object.entries(
        config as Omit<typeof QurioWeaponConfig, 'availableSlots'>
      ).forEach((kv) => {
        const [augment, level] = kv;
        if (level >= 0) {
          applyEffectToStat(
            originalWeapon,
            Weapon,
            level,
            augment as QurioAugment
          );
        } else {
          // reset to base
          resetStat(originalWeapon, Weapon, augment as QurioAugment);
        }
      });
      assignSlot('Weapon', Weapon);
    }
  }, [QurioWeaponConfig, Weapon]);

  const onClickWeaponType = (weapon: WeaponType) => {
    setSelectedWeaponType(weapon);
  };

  const onClickCancelWeaponType = () => {
    setSelectedWeaponType(null);
    setSwitchSkills(null);
    unfocusGear();
    clearSlot('Weapon');
    setKinsect(null);
  };

  const onClickWeapon = (weapon: Weapon) => {
    assignSlot('Weapon', weapon);
  };

  const onClickCancelWeapon = () => {
    unfocusGear();
    clearSlot('Weapon');
    setKinsect(null);
  };

  return (
    <>
      <StyledWeaponGearContainer gridArea={isMobile ? 'slot' : 'weapon'}>
        {selectedWeaponType ? (
          Weapon ? (
            <WeaponDetails
              weapon={Weapon}
              onClickChangeWeapon={onClickCancelWeapon}
              onClickCancelWeaponType={onClickCancelWeaponType}
              isMobile={isMobile}
            />
          ) : (
            <WeaponSelect
              selectedWeaponType={selectedWeaponType}
              onClickWeapon={onClickWeapon}
              onClickCancelWeaponType={onClickCancelWeaponType}
            />
          )
        ) : (
          <WeaponTypeSelect onClickWeaponType={onClickWeaponType} />
        )}
      </StyledWeaponGearContainer>
    </>
  );
};
