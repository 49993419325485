import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

// 4px 0px 8px 6px #9eeafb
export const WeaponSharpnessContainer = styled(Container)`
  ${css({
    height: '15px',
    backgroundColor: 'background',
    border: '2px solid',
    borderColor: 'sharpnessBorder',
    display: 'flex',
    margin: 'auto',
    overflow: 'auto',
    borderRadius: 'medium',
  })}
`;

export const SharpnessBar = styled(Container)`
  ${css({})}
`;
