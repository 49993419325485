import React, { useState, useEffect } from 'react';
import { useLoadoutStore, useDataContext, useIsMobile } from 'hooks';
import { Loadout } from 'models/state';
import shallow from 'zustand/shallow';

import {
  TotalStatsCardContainer,
  CardTitleText,
  StatContainer,
  DetailText,
  DetailsContainer,
  IconAndStatNameContainer,
  StatNameSpan,
  ScrollHelperContainer,
  StyledSharpness,
  SpecialDamageContainer,
  SpecialDamageIcon,
  LevelCapacityContainer,
  NonLeveledAmmoContainer,
  AmmoContainer,
} from './styled';
import { SharpnessDisplay, SwitchSkills } from 'components';
import { AugmentSlots } from 'components/QurioWeapon/components';
import { HorizontalRule, Icon, Select, Modal } from 'ui/core';

import {
  sumArmorDefense,
  getWeaponDefense,
  sumArmorResistances,
  getWeaponAttack,
  getWeaponSpecialDamage,
  getWeaponAffinity,
  applySkillModifiers,
  ResistsDisplay,
} from 'utils/stats';
import {
  getAppliedSkillsFromLoadout,
  getDecorationsFromLoadout,
  getSkillsFromDecorations,
  condenseAppliedSkills,
} from 'utils/skills';
import _ from 'lodash';

import { Element } from 'models/enums/Element';
import {
  isBowgun,
  isInsectGlaive,
  isMeleeWeapon,
  isRangedWeapon,
  MeleeWeapon,
} from 'models/weapons';
import { WeaponType } from 'models/enums';

interface TotalStatsCardProps {}

export const TotalStatsCard: React.FC<TotalStatsCardProps> = () => {
  const { loadout }: { loadout: Loadout } = useLoadoutStore((state: any) => {
    return {
      loadout: state.loadout,
    };
  }, shallow);
  const [attackBonusActivated, setAttackBonusActivated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const { getSkillData, getRampageSkillData } = useDataContext();

  const {
    Weapon,
    Talisman,
    LoadoutSwitchSkills,
    Petalace,
    Kinsect,
    QurioWeaponConfig,
    ...Armors
  } = loadout;

  // calculate defenses & resists from armorset
  let totalDefense = sumArmorDefense(Armors) + getWeaponDefense(Weapon);
  let totalResistances = sumArmorResistances(Armors);

  // calculate offenses from weapon
  let attack = getWeaponAttack(Weapon);
  let specialDamage = getWeaponSpecialDamage(Weapon);
  let affinity = getWeaponAffinity(Weapon);

  // get skills from armor and decorations to factor into calculations
  const allDecorations = getDecorationsFromLoadout(loadout);
  const allAppliedSkills = getAppliedSkillsFromLoadout(loadout);

  const allDecorationSkills = getSkillsFromDecorations(
    allDecorations,
    getSkillData()
  );

  const condensedAppliedSkills = condenseAppliedSkills([
    ...allDecorationSkills,
    ...allAppliedSkills,
  ]);

  const skillAndLevelArray = condensedAppliedSkills
    .filter((appliedSkill) => appliedSkill !== null)
    .map(({ id, appliedSkillLevel }) => {
      return {
        id,
        appliedSkillLevel,
      };
    });

  const statTuples = [
    {
      statBlock: 'attack' as const,
      stat: 'attack' as const,
      statValue: attack,
    },
    {
      statBlock: 'affinity' as const,
      stat: 'affinity' as const,
      statValue: parseInt(affinity as string, 10),
    },
    {
      statBlock: 'defense' as const,
      stat: 'defense' as const,
      statValue: totalDefense,
    },
    {
      statBlock: 'resists' as const,
      stat: 'resists' as const,
      statValue: totalResistances,
    },
  ];

  if (specialDamage) {
    const spD = {
      statBlock: 'elements' as const,
      stat: 'elements' as const,
      statValue: specialDamage,
    };
    // @ts-ignore
    statTuples.push(spD);
  }

  const calculatedStatTuples = applySkillModifiers(
    statTuples,
    skillAndLevelArray.map((skill) => skill.id),
    skillAndLevelArray.map((skill) => skill.appliedSkillLevel)
  );

  attack = calculatedStatTuples[0].statValue.toString();
  affinity = `${calculatedStatTuples[1].statValue}%`;
  totalDefense = calculatedStatTuples[2].statValue as number;
  totalResistances = calculatedStatTuples[3].statValue as ResistsDisplay;
  if (calculatedStatTuples[4]) {
    specialDamage = calculatedStatTuples[4].statValue;
  }

  /**
   * Applies handicraft bonus to sharpness
   * @returns sharpness component
   */
  const getSharpnessComponent = () => {
    const sharpnessLevels = [
      'red',
      'orange',
      'yellow',
      'green',
      'blue',
      'white',
      'purple',
    ] as const;
    const sharpnessPoints = [2, 4, 6, 8, 10];
    const { baseSharpness, maxSharpness } = Weapon! as MeleeWeapon;
    let modifiedSharpness = { ...baseSharpness };
    const handicrafted = skillAndLevelArray.some((skill) => skill.id === 21);
    if (handicrafted) {
      const { appliedSkillLevel } = skillAndLevelArray.find(
        (skill) => skill.id === 21
      )!;
      const baseSharpnessArray = Object.entries(baseSharpness);
      const maxSharpnessArray = Object.entries(maxSharpness);

      let sharpnessBonus = sharpnessPoints[Math.min(appliedSkillLevel, 4)];

      for (let i = 0; i < 7; i++) {
        const currentSharpnessColor = sharpnessLevels[i];

        const [_, baseValue] = baseSharpnessArray[i];
        const [__, maxValue] = maxSharpnessArray[i];

        const amountToAdd = maxValue - baseValue;

        if (amountToAdd > 0) {
          // if we have more sharpness to fill but not enough sharpnessBonus
          const actualAmountToAdd = Math.min(amountToAdd, sharpnessBonus);

          modifiedSharpness[currentSharpnessColor] =
            baseValue + actualAmountToAdd;

          sharpnessBonus = sharpnessBonus - actualAmountToAdd;
        } else {
          modifiedSharpness[currentSharpnessColor] = baseValue;
        }
      }
    }

    return (
      <>
        <HorizontalRule
          margin="auto"
          marginTop="medium"
          marginBottom="xSmall"
        />
        <DetailsContainer>
          <CardTitleText>CALCULATED SHARPNESS (HITS)</CardTitleText>
          <StyledSharpness
            bonusApplied={handicrafted}
            sharpness={modifiedSharpness}
            showValues
            long
          />
        </DetailsContainer>
      </>
    );
  };

  const getBowgunDetails = () => {
    // filter ammos that the bowgun has
    const leveledAmmos = isBowgun(Weapon)
      ? Weapon.leveledAmmo.filter((ammo: any) => {
          return ammo.isHiddens.includes(false);
        })
      : [];

    const nonLeveledAmmos = isBowgun(Weapon) ? Weapon.nonLeveledAmmo : [];

    return (
      <>
        <HorizontalRule
          margin="auto"
          marginTop="medium"
          marginBottom="xSmall"
        />
        <DetailsContainer>
          <CardTitleText>BOWGUN DETAILS (Level/Capacity)</CardTitleText>
          <DetailText marginBottom="medium" marginTop="tiny">
            {leveledAmmos.map((ammo: any) => (
              <DetailText display="flex" alignItems="center">
                {ammo.type}{' '}
                {ammo.levels.map((level: any, idx: number) => (
                  <LevelCapacityContainer
                    opacity={ammo.isHiddens[idx] ? '50%' : '100%'}
                    marginLeft={idx === 0 ? 'auto' : '0'}
                  >
                    {level}/{ammo.capacities[idx]}
                  </LevelCapacityContainer>
                ))}
              </DetailText>
            ))}
          </DetailText>
          <NonLeveledAmmoContainer fontSize="small" gridGap="tiny">
            {nonLeveledAmmos.slice(0, 10).map((ammo: any, idx: number) => (
              <>
                <AmmoContainer
                  alignItems="center"
                  border="1px solid"
                  borderColor="border"
                  borderRadius="medium"
                  opacity={ammo.isHidden ? '50%' : '100%'}
                >
                  <span>
                    {ammo.type.indexOf('Piercing') !== -1
                      ? `P. ${ammo.type.split('Piercing')[1]}`
                      : ammo.type}
                  </span>
                  <span>{ammo.capacity}</span>
                </AmmoContainer>
                {idx % 2 === 1 ? (
                  <AmmoContainer
                    alignItems="center"
                    border="1px solid"
                    borderColor="border"
                    borderRadius="medium"
                    opacity={
                      nonLeveledAmmos[9 + Math.ceil(idx / 2)].isHidden
                        ? '50%'
                        : '100%'
                    }
                  >
                    <span>
                      {nonLeveledAmmos[9 + Math.ceil(idx / 2)].type.indexOf(
                        'Piercing'
                      ) !== -1
                        ? `P. ${
                            nonLeveledAmmos[9 + Math.ceil(idx / 2)].type.split(
                              'Piercing'
                            )[1]
                          }`
                        : nonLeveledAmmos[9 + Math.ceil(idx / 2)].type}
                    </span>
                    <span>
                      {nonLeveledAmmos[9 + Math.ceil(idx / 2)].capacity}
                    </span>
                  </AmmoContainer>
                ) : null}
              </>
            ))}
          </NonLeveledAmmoContainer>
        </DetailsContainer>
      </>
    );
  };

  const additionalProps = {} as any;

  if (isMobile) {
    additionalProps['animate'] = {
      x: 0,
    };
    additionalProps['initial'] = {
      x: -80,
    };
  }

  const showAugmentInfo = Weapon && Weapon.rarity === 10;
  const { availableSlots, ...augments } = QurioWeaponConfig;
  const isRampageDecorationWeapon =
    Weapon &&
    Weapon.rampageDecorationSlots!.some((slot) => slot.slotValue !== 0);

  const getRampageSkillInfo = () => {
    const currentRampage =
      isRampageDecorationWeapon &&
      Weapon?.rampageDecorationSlots?.find((slot) => slot.isFilled)?.decoration;

    return currentRampage
      ? getRampageSkillData().find(
          (skill) => skill.id === currentRampage.skillId
        )?.description
      : 'Pick a rampage decoration to see details.';
  };

  return (
    <TotalStatsCardContainer
      gridArea={isMobile ? 'info' : 'summary'}
      {...additionalProps}
    >
      {isMobile && (
        <ScrollHelperContainer
          animate={{ y: [0, 5, 0] }}
          transition={{ repeat: Infinity, repeatDelay: 0.5 }}
        >
          <Icon name="Down" height={15} width={15} />
        </ScrollHelperContainer>
      )}
      <DetailsContainer>
        <CardTitleText>OFFENSE</CardTitleText>
        <StatContainer>
          <IconAndStatNameContainer>
            <Icon name="Attack" height={20} width={20} />
            <StatNameSpan>ATTACK</StatNameSpan>
          </IconAndStatNameContainer>
          <DetailText color={attackBonusActivated ? 'green' : 'text'}>
            {attack && attack === 0 ? '-' : attack}
          </DetailText>
        </StatContainer>
        <StatContainer>
          <IconAndStatNameContainer>
            <Icon name="Element" height={20} width={20} />
            <StatNameSpan>ELEMENT</StatNameSpan>
          </IconAndStatNameContainer>
          <DetailText>
            {specialDamage && specialDamage.length > 0
              ? specialDamage.map(({ damageType, damageValue }) => {
                  return (
                    <SpecialDamageContainer>
                      <SpecialDamageIcon
                        name={damageType as any}
                        height={14}
                        width={14}
                      />
                      {damageValue}
                    </SpecialDamageContainer>
                  );
                })
              : '-'}
          </DetailText>
        </StatContainer>
        <StatContainer>
          <IconAndStatNameContainer>
            <Icon name="Affinity" height={20} width={20} />
            <StatNameSpan>AFFINITY</StatNameSpan>
          </IconAndStatNameContainer>
          <DetailText
            color={
              affinity !== '0%'
                ? affinity.indexOf('-') > -1
                  ? 'red'
                  : 'green'
                : 'text'
            }
          >
            {affinity && affinity === '0%' ? '-' : affinity}
          </DetailText>
        </StatContainer>
      </DetailsContainer>

      <HorizontalRule margin="auto" marginY="xSmall" />
      <DetailsContainer>
        <CardTitleText>DEFENSE</CardTitleText>
        <StatContainer>
          <IconAndStatNameContainer>
            <Icon name="Defense" height={20} width={20} />
            <StatNameSpan>DEFENSE</StatNameSpan>
          </IconAndStatNameContainer>
          <DetailText>{totalDefense}</DetailText>
        </StatContainer>
        {Object.entries(totalResistances).map(
          ([resistance, resistanceValue], index) => {
            const stats = [
              'Fire',
              'Water',
              'Thunder',
              'Ice',
              'Dragon',
            ] as const;

            return (
              <StatContainer
                marginTop={index === 0 ? 'tiny' : 0}
                key={`${index}_${stats[index]}`}
              >
                <IconAndStatNameContainer>
                  <Icon name={stats[index]} height={20} width={20} />
                  <StatNameSpan>{_.upperCase(stats[index])} RES.</StatNameSpan>
                </IconAndStatNameContainer>
                <DetailText>{resistanceValue}</DetailText>
              </StatContainer>
            );
          }
        )}
      </DetailsContainer>
      <HorizontalRule margin="auto" marginY="xSmall" />
      {showAugmentInfo && (
        <>
          <DetailsContainer>
            <CardTitleText>WEAPON QURIO AUGMENTS</CardTitleText>
            <AugmentSlots currentConfig={augments} compact />
          </DetailsContainer>
          <HorizontalRule margin="auto" marginY="xSmall" />
        </>
      )}
      {isRampageDecorationWeapon && (
        <>
          <DetailsContainer>
            <CardTitleText>RAMPAGE DECORATION EFFECT</CardTitleText>
            <DetailText>{getRampageSkillInfo()}</DetailText>
          </DetailsContainer>
          <HorizontalRule margin="auto" marginY="xSmall" />
        </>
      )}
      <DetailsContainer>
        <CardTitleText>SWITCH SKILLS</CardTitleText>
        {Weapon ? (
          <SwitchSkills weapon={Weapon.weaponType as WeaponType}></SwitchSkills>
        ) : (
          <DetailText textAlign="center" marginY="medium">
            Pick a weapon to select switch skills.
          </DetailText>
        )}
      </DetailsContainer>
      {Weapon && isMeleeWeapon(Weapon) ? getSharpnessComponent() : null}
      {Weapon && isBowgun(Weapon) ? getBowgunDetails() : null}
    </TotalStatsCardContainer>
  );
};

/**
 * insert later when design is done
  {Weapon && isInsectGlaive(Weapon) ? getInsectGlaiveSelect() : null}
 */
