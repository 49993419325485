import React, { useState, useEffect } from 'react';

import { SkillsContainer } from 'components';
import {
  SummarySkillsCardContainer,
  CardTitleText,
  CardTitleContainer,
} from './styled';
import { useIsMobile } from 'hooks';

interface SummarySkillsCardProps {}

export const SummarySkillsCard: React.FC<SummarySkillsCardProps> = () => {
  const isMobile = useIsMobile();
  const additionalProps = {} as any;

  if (isMobile) {
    additionalProps['animate'] = {
      x: 0,
    };
    additionalProps['initial'] = {
      x: 80,
    };
  }
  return (
    <SummarySkillsCardContainer
      gridArea={isMobile ? 'info' : 'skills'}
      {...additionalProps}
    >
      <CardTitleContainer>
        <CardTitleText>{isMobile ? 'ACTIVE' : 'ACTIVE SKILLS'}</CardTitleText>
      </CardTitleContainer>
      <SkillsContainer></SkillsContainer>
    </SummarySkillsCardContainer>
  );
};
