import React from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';

import { Container, Button, Icon, Span } from 'ui/core';
// TODO: move this to a shared component?
import { SkillSelect } from 'components/SkillPicker/components/SkillSelect';
import { partial } from 'lodash';

interface SkillRequirementSelectProps {
  requiredSkills: any[];
  setRequiredSkills: any;
}

const StyledIcon = styled(Icon)`
  ${css({
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const SkillRequirementSelect: React.FC<SkillRequirementSelectProps> = ({
  requiredSkills,
  setRequiredSkills,
}) => {
  const setCorrectSkill = (index: number, appliedSkill: any) => {
    const newRequiredSkills = [...requiredSkills];
    newRequiredSkills[index] = appliedSkill;
    setRequiredSkills(newRequiredSkills);
  };
  const setCorrectSkillLevel = (index: number, appliedSkillLevel: any) => {
    const newRequiredSkills = [...requiredSkills];
    newRequiredSkills[index]!.appliedSkillLevel = appliedSkillLevel.index;
    setRequiredSkills(newRequiredSkills);
  };
  const removeSkill = (removeIndex: number) => {
    const newRequiredSkills = requiredSkills.filter(
      (requiredSkill: any, index: number) => index !== removeIndex
    );
    setRequiredSkills(newRequiredSkills);
  };
  const addSkill = () => {
    const newRequiredSkills = [...requiredSkills, null];
    setRequiredSkills(newRequiredSkills);
  };

  const requiredSkillIds = requiredSkills
    .filter((skill) => skill !== null)
    .map((skill) => skill.id);

  return (
    <>
      <Container
        borderBottom="1px solid"
        borderColor="selectBorder"
        paddingX="large"
        overflow="auto"
        variant="flexCol"
        flex="1"
        maxHeight="10rem"
        minHeight="10rem"
      >
        {requiredSkills.map((requiredSkill, index) => {
          const setCorrectSkillPartial = partial(setCorrectSkill, index);
          const setCorrectSkillLevelPartial = partial(
            setCorrectSkillLevel,
            index
          );
          // @ts-ignore
          const requiredSkillLevel = requiredSkill
            ? // @ts-ignore
              requiredSkill.skillLevels[requiredSkill.appliedSkillLevel]
            : null;
          return (
            <Container key={`${index}`} variant="flexRow">
              {index !== 0 && (
                <Span
                  display="flex"
                  alignItems="center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeSkill(index)}
                >
                  <StyledIcon name="X" />
                </Span>
              )}
              <SkillSelect
                selectedSkill={requiredSkill}
                setSelectedSkill={setCorrectSkillPartial}
                selectedSkillLevel={requiredSkillLevel}
                setSelectedSkillLevel={setCorrectSkillLevelPartial}
                selectedSkills={requiredSkillIds}
              ></SkillSelect>
            </Container>
          );
        })}
      </Container>
      <Container variant="flexRow" style={{ gap: '1rem' }}>
        <Button marginTop="small" variant="primary" onClick={addSkill}>
          add
        </Button>
        <Button
          marginTop="small"
          variant="secondary"
          onClick={() => setRequiredSkills([null])}
        >
          clear
        </Button>
      </Container>
    </>
  );
};
