import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M11.5 9V7H7V9H9.50011H11.5Z" fill="white" />
      <path
        d="M9.50011 9C6.46255 9 4.65197 10.6471 4.65197 13.2632L7 11L9.5 14L12 11L14.1079 13.2632C14.1079 10.6471 12.5377 9 9.50011 9Z"
        fill="white"
      />
      <path
        d="M9.50011 18C12.5377 18 14.1079 15.8792 14.1079 13.2632L12 11L9.5 14L7 11L4.65197 13.2632C4.65197 15.8792 6.46255 18 9.50011 18Z"
        fill="white"
      />
      <path d="M7.5 21.5H11.5V20.5L9.5 19.5L7.5 20.5V21.5Z" fill="white" />
      <path d="M13 18.5H16.5V19.5L14 21.5H13V18.5Z" fill="white" />
      <path d="M2.5 18.5H6V21.5H5L2.5 19.5V18.5Z" fill="white" />
      <path d="M11.5 9V7H7V9H9.50011H11.5Z" fill="#C4C4C4" />
      <path
        d="M9.50011 9C6.46255 9 4.65197 10.6471 4.65197 13.2632L7 11L9.5 14L12 11L14.1079 13.2632C14.1079 10.6471 12.5377 9 9.50011 9Z"
        fill="#C4C4C4"
      />
      <path
        d="M11.5 9V7H7V9M11.5 9H7M11.5 9H9.50011M7 9H9.50011M14.1079 13.2632C14.1079 15.8792 12.5377 18 9.50011 18C6.46255 18 4.65197 15.8792 4.65197 13.2632M14.1079 13.2632C14.1079 10.6471 12.5377 9 9.50011 9M14.1079 13.2632L12 11L9.5 14L7 11L4.65197 13.2632M4.65197 13.2632C4.65197 10.6471 6.46255 9 9.50011 9M7.5 13.5V14.5M7.5 15.5V14.5M7.5 14.5H6.5M7.5 14.5H8.5M2.5 18.5H6V21.5H5L2.5 19.5V18.5ZM13 18.5H16.5V19.5L14 21.5H13V18.5ZM7.5 21.5H11.5V20.5L9.5 19.5L7.5 20.5V21.5Z"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3H8.5V5V7H10.5V6H11.5H13H15H16V7V8.5V10V11.5V13.5H18.5H19.5H22V11.5V10V8.5V7V5.5V4H20.5V2H18.75H17H15H13H11H9V3ZM10 4.5V4H11H13H15H17H18.75H19.5V5.5V7V8.5V10V11.5H18.5V10V8.5V7V5.5H17V4.5H15H13H11.5H10Z"
        fill="#C4C4C4"
      />
      <path
        d="M9 3H8.5V5M9 3V2H11M9 3L10 4M20.5 4V2H18.75M20.5 4H22V5.5M20.5 4H19.5M16 6V7M16 6H17V5.5M16 6H15M10.5 6V7H8.5V5M10.5 6H11.5M10.5 6H10V5M19.5 11.5H18.5M19.5 11.5V10M19.5 11.5H22M19.5 11.5V13.5M19.5 4H18.75M19.5 4V5.5M10 4V4.5M10 4H11M10 4.5H11.5M10 4.5V5M17 5.5V4.5H15M17 5.5H18.5V7M18.5 11.5V13.5M18.5 11.5H16M18.5 11.5V10M11 2V4M11 2H13M11 4H13M13 2V4M13 2H15M13 4H15M15 2V4M15 2H17M15 4H17M17 2V4M17 2H18.75M17 4H18.75M18.75 2V4M19.5 5.5H22M19.5 5.5V7M22 5.5V7M19.5 7H22M19.5 7V8.5M22 7V8.5M19.5 8.5H22M19.5 8.5V10M22 8.5V10M19.5 10H22M22 10V11.5M22 11.5V13.5H19.5M19.5 13.5H18.5M18.5 13.5H16V11.5M16 11.5V10M18.5 10H16M18.5 10V8.5M16 10V8.5M16 8.5H18.5M16 8.5V7M18.5 8.5V7M16 7H18.5M15 4.5V6M15 4.5H13M15 6H13M13 4.5V6M13 4.5H11.5M13 6H11.5M11.5 4.5V6M8.5 5H10"
        stroke="black"
        strokeWidth="0.5"
      />
    </>
  ),
};
