import React, { useState } from 'react';
import {
  KinsectContainer,
  KinsectButton,
  KinsectIcon,
  KinsectDetails,
  CancelIcon,
  CancelIconContainer,
  KinsectName,
  KinsectDetailsContainer,
  KinsectDetailContainer,
  KinsectDetailValue,
} from './styled';
import { kinsectData } from './constants';
import { Modal } from 'ui/core';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';
import _ from 'lodash';
import { DataTable } from 'components';

interface KinsectPickerProps {
  className?: any;
  props?: any;
}

export const KinsectPicker: React.FC<KinsectPickerProps> = ({ className }) => {
  const [openModal, setOpenModal] = useState(false);
  const { setKinsect, loadout } = useLoadoutStore(
    (state: any) => ({ setKinsect: state.setKinsect, loadout: state.loadout }),
    shallow
  );
  const kinsect = loadout.Kinsect ? loadout.Kinsect : null;

  const handleClickKinsect = (kinsect: any) => {
    setKinsect(kinsect);
    setOpenModal(false);
  };

  const handleCancelKinsect = () => {
    setKinsect(null);
  };
  return (
    <>
      <KinsectContainer className={className}>
        {kinsect ? (
          <KinsectDetails>
            <KinsectName>
              {kinsect.name} | {_.upperCase(kinsect.attackType)}
            </KinsectName>
            <KinsectDetailsContainer justifyContent="space-between">
              <KinsectDetailContainer>
                <KinsectDetailValue fontWeight="bold">P</KinsectDetailValue>
                <KinsectDetailValue>{kinsect.power}</KinsectDetailValue>
              </KinsectDetailContainer>
              <KinsectDetailContainer>
                <KinsectDetailValue fontWeight="bold">S</KinsectDetailValue>
                <KinsectDetailValue>{kinsect.speed}</KinsectDetailValue>
              </KinsectDetailContainer>
              <KinsectDetailContainer>
                <KinsectDetailValue fontWeight="bold">H</KinsectDetailValue>
                <KinsectDetailValue>{kinsect.heal}</KinsectDetailValue>
              </KinsectDetailContainer>
              <KinsectDetailsContainer>
                <KinsectDetailContainer>
                  <KinsectDetailValue>
                    {kinsect.kinsectBonus}
                  </KinsectDetailValue>
                  <KinsectDetailValue>
                    {kinsect.kinsectTypes.join(', ')}
                  </KinsectDetailValue>
                </KinsectDetailContainer>
              </KinsectDetailsContainer>
            </KinsectDetailsContainer>
            <CancelIconContainer onClick={handleCancelKinsect}>
              <CancelIcon name="X" height={15} width={15}></CancelIcon>
            </CancelIconContainer>
          </KinsectDetails>
        ) : (
          <KinsectButton onClick={() => setOpenModal(true)}>
            <KinsectIcon name="Kinsect" />
          </KinsectButton>
        )}
      </KinsectContainer>
      {openModal && (
        <Modal
          isOpen={openModal}
          setIsOpen={setOpenModal}
          modalTitle="Kinsects"
          modalDescription="Pick a kinsect."
        >
          <DataTable
            tableData={kinsectData}
            slot="Kinsect"
            onClickRow={handleClickKinsect}
          />
        </Modal>
      )}
    </>
  );
};

// extract to own wrapper component, maybe abstract?
// const getInsectGlaiveSelect = () => {
//   const selectedKinsect = loadout.Kinsect ? loadout.Kinsect : null;

//   return (
//     <>
//       <HorizontalRule margin="auto" marginY="xSmall" />
//       <DetailsContainer>
//         <CardTitleText>KINSECT</CardTitleText>
//         <Select
//           options={kinsectData}
//           selectedOption={selectedKinsect}
//           setSelectedOption={setKinsect}
//           icon={<Icon name="Kinsect" height={20} width={20} />}
//         ></Select>
//       </DetailsContainer>
//     </>
//   );
// };
