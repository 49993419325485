import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M10 18V21H14V18L21 15V10H17V12L14 13.5V8H10V13.5L7 12V10H3V15L10 18Z"
        fill="#BB97D7"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M3 6.72727V10H7V4L3 6.72727Z"
        fill="#8C488A"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M14 8H10V5L12 2.5L14 5V8Z"
        fill="#8C488A"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M21 10H17V4L21 6.5V10Z"
        fill="#8C488A"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
