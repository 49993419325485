import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const IconContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    transition: 'all .3s ease',
    width: '100%',
    justifyContent: 'center',
    paddingY: 'xSmall',
    border: '1px solid transparent',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid',
      borderColor: 'secondaryText',
      borderRadius: 'medium',
      boxShadow: 'weaponSelectShadow',
    },
  })}
`;

export const StyledWeaponTypeSelectContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    justifyItems: 'center',
    padding: 'medium',
  })}
`;
