//@ts-nocheck
import React, { useState, useEffect } from 'react';
import {
  useTable,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
} from 'react-table';
import { FixedSizeList } from 'react-window';
import { tableProperties } from './utils';
import {
  TableContainer,
  HeaderGroup,
  Header,
  TableBody,
  Row,
  CellWrapper,
  Cell,
} from './styled';
import { isWeaponType } from 'models/enums/WeaponType';
import { useIsMobile } from 'hooks';

interface DataTableProps {
  tableData: any;
  slot: any;
  onClickRow: any;
}

// TODO: The core table should be its own component.
// TODO: each configurations should technically be its own component.
export const DataTable: React.FC<DataTableProps> = ({
  tableData,
  slot,
  onClickRow,
}) => {
  const isMobile = useIsMobile();

  const slotTableProperties = isWeaponType(slot)
    ? tableProperties['Weapon'][slot]
    : tableProperties[slot];

  const {
    defaultColumn,
    initialFilterState,
    initialGlobalFilterState,
    globalFilterResolver,
  } = slotTableProperties.getOptions();

  const data = React.useMemo<any>(() => tableData, [tableData]);
  const columns = React.useMemo<any>(
    () => slotTableProperties.getColumns(),
    []
  );
  const mobileColumns = React.useMemo<any>(
    () => slotTableProperties.getMobileColumns(),
    []
  );
  const initialFilter = React.useMemo<any>(() => initialFilterState);
  const initialGlobalFilter = React.useMemo<any>(
    () => initialGlobalFilterState
  );

  const globalFilter = React.useMemo<any>(() => globalFilterResolver);
  const tableInstance = useTable(
    {
      columns: isMobile ? mobileColumns : columns,
      data,
      defaultColumn,
      initialState: {
        filters: initialFilter,
        globalFilters: initialGlobalFilter,
      },
      globalFilter: globalFilter,
    },
    useFilters,
    useFlexLayout,
    useGlobalFilter
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setFilter,
    setAllFilters,
    setGlobalFilter,
  } = tableInstance;

  const handleChange = (name: any) => {
    const value = tableData.find((data: any) => data.name === name);
    // console.log('fired', value);
    onClickRow(value);
  };

  // https://github.com/tannerlinsley/react-table/tree/master/examples/virtualized-rows
  // for use with react-window
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const noPaddingCells = ['weaponStats', 'baseSharpness', 'phial'];
      const row = rows[index];
      prepareRow(row);

      return (
        <Row
          {...row.getRowProps({
            style: { ...style },
          })}
          onClick={() => handleChange(row.original.name)}
          key={row.original.name}
        >
          {row.cells.map((cell, i) => {
            return (
              <CellWrapper {...cell.getCellProps()}>
                <Cell
                  paddingLeft={
                    noPaddingCells.includes(cell.column.id) ? null : 'small'
                  }
                  fontSize={'small'}
                >
                  {cell.render('Cell')}
                </Cell>
              </CellWrapper>
            );
          })}
        </Row>
      );
    },
    [prepareRow, rows]
  );

  /*
    {slotTableProperties.getFilterComponents(
      setFilter,
      setAllFilters,
      state.filters
    )}
  */
  return (
    <>
      {slotTableProperties.getGlobalFilterComponent(setGlobalFilter)}
      <TableContainer {...getTableProps({ style: { overflow: 'auto' } })}>
        {headerGroups.map((headerGroup) => {
          return (
            <HeaderGroup {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Header {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Header>
                );
              })}
            </HeaderGroup>
          );
        })}
        <TableBody {...getTableBodyProps()}>
          <FixedSizeList height={400} itemCount={rows.length} itemSize={45}>
            {RenderRow}
          </FixedSizeList>
        </TableBody>
      </TableContainer>
    </>
  );
};
