import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon } from 'ui/core';

export const SimpleGearCardContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({ alignItems: 'center' })}
`;

export const StyledIcon = styled(Icon)`
  ${css({
    marginRight: 'tiny',
  })}
`;
