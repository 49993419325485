import styled from 'styled-components';
import { compose, space, SpaceProps } from 'styled-system';
import css from '@styled-system/css';

type HorizontalRuleProps = SpaceProps;

export const HorizontalRule = styled.hr<HorizontalRuleProps>`
  ${compose(space)}

  ${css({
    width: '75%',
    borderColor: 'border',
  })}
`;
