import React, { useState, useEffect } from 'react';
import { useDataContext } from 'hooks';

import {
  SkillSelectContainer,
  PickSkillButton,
  SelectedSkillCancelButton,
  ButtonTextSpan,
  SkillSelectWrapper,
  SelectedSkillContainer,
  WeaponModalHack,
} from './styled';
import { Select, Icon, Modal } from 'ui/core';
import { DataTable } from 'components';

interface SkillSelectProps {
  selectedSkill: any;
  setSelectedSkill: any;
  selectedSkillLevel: any;
  setSelectedSkillLevel: any;
  selectedSkills?: any;
}

export const SkillSelect: React.FC<SkillSelectProps> = ({
  selectedSkill,
  setSelectedSkill,
  selectedSkillLevel,
  setSelectedSkillLevel,
  selectedSkills = [],
}) => {
  const { getSkillData } = useDataContext();
  const skillData = getSkillData();

  const [showModal, setShowModal] = useState(false);

  const skillDataWithoutDupes = skillData.filter(
    (skill) => !selectedSkills.includes(skill.id)
  );

  useEffect(() => {
    if (selectedSkill !== null) {
      setSelectedSkillLevel(
        selectedSkill.skillLevels[selectedSkill.appliedSkillLevel]
      );
    }
  }, [selectedSkill]);

  const onClickSkill = (value: any) => {
    const newSkillLevels = value.skillLevels.map(
      (skillLevel: any, index: number) => {
        return {
          index,
          ...skillLevel,
          name: index + 1,
        };
      }
    );
    setSelectedSkill({
      ...value,
      appliedSkillLevel: 0,
      skillLevels: newSkillLevels,
    });
    setShowModal(false);
  };

  const onCancelSkill = () => {
    setSelectedSkill(null);
  };

  return (
    <>
      <SkillSelectContainer>
        {selectedSkill ? (
          <SelectedSkillContainer>
            <Select
              options={selectedSkill.skillLevels}
              selectedOption={selectedSkillLevel}
              setSelectedOption={setSelectedSkillLevel}
              isSmall={true}
            />
            <SelectedSkillCancelButton onClick={onCancelSkill}>
              <ButtonTextSpan>{selectedSkill.name}</ButtonTextSpan>
              <Icon name={'X'} height={15} width={15}></Icon>
            </SelectedSkillCancelButton>
          </SelectedSkillContainer>
        ) : (
          <SkillSelectWrapper>
            <PickSkillButton onClick={() => setShowModal(true)}>
              Pick a Skill
            </PickSkillButton>
          </SkillSelectWrapper>
        )}
      </SkillSelectContainer>
      {showModal && (
        <WeaponModalHack
          isOpen={true}
          setIsOpen={setShowModal}
          modalTitle="Skills"
          modalDescription="Select a skill for your talisman slot."
        >
          <DataTable
            tableData={skillDataWithoutDupes}
            slot={'Skills'}
            onClickRow={onClickSkill}
          ></DataTable>
        </WeaponModalHack>
      )}
    </>
  );
};
