import React from 'react';

export default {
  viewBox: '0 0 477 534',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M18.707,391.033c-14.284,12.125 35.394,57.057 47.28,47.204c43.133,-35.752 85.378,-57.087 153.594,-76.08c5.376,-1.496 15.576,-42.032 -2.037,-59.41c-14.823,-14.625 -106.626,10.011 -198.837,88.286Zm95.554,16.74c6.158,15.475 22.533,43.964 22.533,43.964c-4.063,6.045 -9.726,25.359 -5.363,36.844c3.249,8.551 17.614,20.488 25.53,23.515c8.323,3.183 22.786,6.597 38.69,-0.938c6.976,-3.304 12.487,-7.744 17.943,-15.094c12.535,-16.883 14.496,-42.626 4.184,-51.713c-1.683,-1.482 -16.476,-11.321 -26.279,-10.833c-3.372,-6.958 -19.362,-34.564 -30.444,-48.894l-46.794,23.149Zm-22.632,-68.594c-30.646,-56.533 -71.596,-125.646 -67.252,-181.408c14.195,-97.939 84.886,-125.304 167.234,-141.621c2.088,6.663 -0.111,17.715 -4.684,25.459c-56.991,26.306 -79.939,74.047 -76.04,106.975c4.525,66.65 49.948,140.73 53.714,157.326c-26.746,9.054 -51.123,20.099 -72.972,33.269Zm364.325,42.634c19.438,12.603 -25.943,67.929 -42.408,57.652c-47.526,-29.663 -89.085,-54.823 -157.3,-73.816c-5.377,-1.496 -11.87,-44.296 5.743,-61.675c14.823,-14.625 92.476,12.037 193.965,77.839Zm-90.526,28.783c-6.159,15.475 -22.688,42.369 -22.688,42.369c4.062,6.044 4.75,26.296 0.387,37.78c-3.248,8.551 -12.638,19.552 -20.555,22.579c-8.322,3.183 -22.786,6.597 -38.69,-0.938c-6.976,-3.304 -12.486,-7.744 -17.943,-15.094c-12.535,-16.884 -9.471,-40.614 0.841,-49.7c1.683,-1.483 11.452,-13.334 21.254,-12.846c3.373,-6.958 18.384,-33.476 29.466,-47.806l47.928,23.656Zm24.533,-68.069c30.646,-56.532 69.539,-127.766 65.195,-183.528c-14.195,-97.939 -84.886,-125.304 -167.234,-141.621c-2.087,6.663 0.111,17.715 4.684,25.459c56.992,26.306 79.94,74.047 76.04,106.974c-4.525,66.651 -49.948,140.731 -53.714,157.327c26.747,9.054 53.18,22.22 75.029,35.389Z"
        />
      </g>
      <g id="mediumColor">
        <path
          id="medium"
          d="M18.707,391.033c-14.284,12.125 35.394,57.057 47.28,47.204c43.133,-35.752 85.378,-57.087 153.594,-76.08c5.376,-1.496 15.576,-42.032 -2.037,-59.41c-14.823,-14.625 -106.626,10.011 -198.837,88.286Zm95.554,16.74c6.158,15.475 22.533,43.964 22.533,43.964c-4.063,6.045 -9.726,25.359 -5.363,36.844c3.249,8.551 17.614,20.488 25.53,23.515c8.323,3.183 22.786,6.597 38.69,-0.938c6.976,-3.304 12.487,-7.744 17.943,-15.094c12.535,-16.883 14.496,-42.626 4.184,-51.713c-1.683,-1.482 -16.476,-11.321 -26.279,-10.833c-3.372,-6.958 -19.362,-34.564 -30.444,-48.894l-46.794,23.149Zm341.693,-25.96c19.438,12.603 -25.943,67.929 -42.408,57.652c-47.526,-29.663 -89.085,-54.823 -157.3,-73.816c-5.377,-1.496 -11.87,-44.296 5.743,-61.675c14.823,-14.625 92.476,12.037 193.965,77.839Zm-90.526,28.783c-6.159,15.475 -22.688,42.369 -22.688,42.369c4.062,6.044 4.75,26.296 0.387,37.78c-3.248,8.551 -12.638,19.552 -20.555,22.579c-8.322,3.183 -22.786,6.597 -38.69,-0.938c-6.976,-3.304 -12.486,-7.744 -17.943,-15.094c-12.535,-16.884 -9.471,-40.614 0.841,-49.7c1.683,-1.483 11.452,-13.334 21.254,-12.846c3.373,-6.958 18.384,-33.476 29.466,-47.806l47.928,23.656Z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          d="M18.707,391.033c-14.284,12.125 35.394,57.057 47.28,47.204c43.133,-35.752 85.378,-57.087 153.594,-76.08c5.376,-1.496 15.576,-42.032 -2.037,-59.41c-14.823,-14.625 -106.626,10.011 -198.837,88.286Zm95.554,16.74c6.158,15.475 22.533,43.964 22.533,43.964c-4.063,6.045 -9.726,25.359 -5.363,36.844c3.249,8.551 17.614,20.488 25.53,23.515c8.323,3.183 22.786,6.597 38.69,-0.938c6.976,-3.304 12.487,-7.744 17.943,-15.094c12.535,-16.883 14.496,-42.626 4.184,-51.713c-1.683,-1.482 -16.476,-11.321 -26.279,-10.833c-3.372,-6.958 -19.362,-34.564 -30.444,-48.894l-46.794,23.149Zm-22.632,-68.594c-30.646,-56.533 -71.596,-125.646 -67.252,-181.408c14.195,-97.939 84.886,-125.304 167.234,-141.621c2.088,6.663 -0.111,17.715 -4.684,25.459c-56.991,26.306 -79.939,74.047 -76.04,106.975c4.525,66.65 49.948,140.73 53.714,157.326c-26.746,9.054 -51.123,20.099 -72.972,33.269Zm364.325,42.634c19.438,12.603 -25.943,67.929 -42.408,57.652c-47.526,-29.663 -89.085,-54.823 -157.3,-73.816c-5.377,-1.496 -11.87,-44.296 5.743,-61.675c14.823,-14.625 92.476,12.037 193.965,77.839Zm-90.526,28.783c-6.159,15.475 -22.688,42.369 -22.688,42.369c4.062,6.044 4.75,26.296 0.387,37.78c-3.248,8.551 -12.638,19.552 -20.555,22.579c-8.322,3.183 -22.786,6.597 -38.69,-0.938c-6.976,-3.304 -12.486,-7.744 -17.943,-15.094c-12.535,-16.884 -9.471,-40.614 0.841,-49.7c1.683,-1.483 11.452,-13.334 21.254,-12.846c3.373,-6.958 18.384,-33.476 29.466,-47.806l47.928,23.656Zm24.533,-68.069c30.646,-56.532 69.539,-127.766 65.195,-183.528c-14.195,-97.939 -84.886,-125.304 -167.234,-141.621c-2.087,6.663 0.111,17.715 4.684,25.459c56.992,26.306 79.94,74.047 76.04,106.974c-4.525,66.651 -49.948,140.731 -53.714,157.327c26.747,9.054 53.18,22.22 75.029,35.389Z"
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
        />
      </g>
    </>
  ),
};
