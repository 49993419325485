import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const DefenseStatsCellContainer = styled(Container).attrs({
  variant: 'flexRow',
})``;

export const DefenseStatContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;
