import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text, Icon } from 'ui/core';
import { SharpnessDisplay } from 'components';

export const TotalStatsCardContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    border: '1px solid',
    borderColor: 'cardBorder',
    borderRadius: 'medium',
    height: '100%',
    padding: 'small',
    backgroundColor: 'cardBackground',
    display: 'flex',
    minHeight: ['100%', null, 'initial', null, null],
    overflow: 'auto',
  })}
`;

export const CardTitleText = styled(Text)`
  ${css({
    fontWeight: 'bold',
    fontSize: 'xSmall',
    color: 'secondaryText',
    letterSpacing: '1px',
  })}
`;

export const DetailsContainer = styled(Container).attrs({
  variant: 'flexCol',
})``;

export const StatContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'space-between',
    marginTop: 'tiny',
  })}
`;

export const IconAndStatNameContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const StatNameSpan = styled.span`
  ${css({ marginLeft: 'tiny', fontSize: 'small' })}
`;

export const DetailText = styled(Text)`
  ${css({})}
`;

export const ScrollHelperContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'center',
  })}
`;

export const StyledSharpness = styled<any>(SharpnessDisplay)`
  ${css({
    marginTop: 'xSmall',
    borderRadius: 'medium',
  })}
`;

export const SpecialDamageContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const SpecialDamageIcon = styled(Icon)`
  ${css({
    marginRight: '2px',
  })}
`;

export const LevelCapacityContainer = styled(Container)`
  ${css({
    border: 'solid 1px',
    borderColor: 'border',
    fontSize: 'xSmall',
    borderRadius: 'large',
    paddingX: '2px',
    paddingY: '1px',
    marginRight: 'tiny',
  })}
`;

export const NonLeveledAmmoContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateRows: 'repeat(1,1fr)',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '100%',
  })}
`;

export const AmmoContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'left',
  })}
`;
