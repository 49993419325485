import { GunPropertiesPill, GunPropertiesValue } from './styled';

export const gunPropertiesAccessor = (originalRow: any) => {
  return originalRow.properties.map((property: any) => ({
    name: property.property,
    value: property.value,
  }));
};
// onMouseEnter={() => console.log(skill.desc)}
export const GunPropertiesCell = ({ value }: { value: any }) => {
  return (
    <>
      {value.map((skill: any) => {
        return (
          <GunPropertiesPill>
            {skill.name} -{' '}
            <GunPropertiesValue>{skill.value}</GunPropertiesValue>
          </GunPropertiesPill>
        );
      })}
    </>
  );
};
