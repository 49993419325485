export interface QurioEffect {
  bonus: any;
  calculationBonus: number;
  cost: number;
  actualCost: number;
}
interface QurioAugmentSchema {
  displayName: string;
  color: string;
  effects: QurioEffect[];
}

interface QurioWeaponSchema {
  attack: QurioAugmentSchema;
  affinity: QurioAugmentSchema;
  element: QurioAugmentSchema;
  sharpness: QurioAugmentSchema;
  status: QurioAugmentSchema;
  rampageDecoration: QurioAugmentSchema;
}

export const qurioWeaponSchema = {
  attack: {
    displayName: 'Attack Boost',
    color: '#c9484e',
    effects: [
      {
        bonus: '+5',
        calculationBonus: 5,
        cost: 2,
      },
      {
        bonus: '+10',
        calculationBonus: 10,
        cost: 4,
      },
    ],
  },
  affinity: {
    displayName: 'Affinity Boost',
    color: '#dae076',
    effects: [
      {
        bonus: '+5%',
        calculationBonus: 5,
        cost: 3,
      },
    ],
  },
  element: {
    displayName: 'Elemental Boost',
    color: '#d87839',
    effects: [
      {
        bonus: '+3',
        calculationBonus: 3,
        cost: 1,
      },
      {
        bonus: '+6',
        calculationBonus: 6,
        cost: 2,
      },
      {
        bonus: '+9',
        calculationBonus: 9,
        cost: 3,
      },
    ],
  },
  sharpness: {
    displayName: 'Sharpness Boost',
    color: '#6dddd1',
    effects: [
      {
        bonus: '+10',
        calculationBonus: 10,
        cost: 3,
      },
    ],
  },
  status: {
    displayName: 'Status Boost',
    color: '#4dcf36',
    effects: [
      {
        bonus: '+4',
        calculationBonus: 4,
        cost: 2,
      },
    ],
  },
  rampageDecoration: {
    displayName: 'Rampage Slot Upgrade',
    color: '#5278df',
    effects: [
      {
        bonus: 'Slot +1',
        calculationBonus: 1,
        cost: 4,
      },
    ],
  },
} as QurioWeaponSchema;
