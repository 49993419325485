import styled from 'styled-components';
import {
  variant,
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';
import css from '@styled-system/css';
import { motion, HTMLMotionProps } from 'framer-motion';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'circle'
  | 'warning';

const variants = {
  primary: {
    color: 'text',
    backgroundColor: 'primary',
    border: '1px solid',
    borderColor: 'border',
    ':hover:enabled': {
      color: 'white',
      backgroundColor: 'primaryDark',
      borderColor: 'transparent',
    },
    ':active:enabled': {
      backgroundColor: 'themeBlack',
      color: 'activeColor',
      borderColor: 'primary',
    },
  },
  secondary: {
    color: 'text',
    backgroundColor: 'secondary',
    border: '1px solid',
    borderColor: 'border',
    ':hover:enabled': {
      backgroundColor: 'primary',
      borderColor: 'transparent',
    },
    ':active:enabled': {
      color: 'activeColor',
      backgroundColor: 'themeBlack',
      borderColor: 'primary',
    },
    ':disabled': {
      backgroundColor: 'background',
    },
  },
  tertiary: {
    color: 'text',
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'transparent',
    ':hover:enabled': {
      color: 'tertiary',
    },
    ':active:enabled': {
      borderColor: 'tertiary',
    },
  },
  warning: {
    backgroundColor: 'warning',
    border: '1px solid',
    borderColor: 'transparent',
    ':hover:enabled': {
      color: 'white',
      backgroundColor: 'warningDark',
    },
    ':active:enabled': {
      color: 'white',
      backgroundColor: 'themeBlack',
    },
  },
};

type ButtonProps = {
  variant?: ButtonVariant;
} & ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps &
  HTMLMotionProps<'button'>;

export const Button = styled(motion<ButtonProps>('button'))`
  transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${css({
    paddingY: 'tiny',
    borderRadius: 'medium',
    fontSize: 'small',
    textTransform: 'uppercase',
    lineHeight: 1,
    letterSpacing: '1px',
    fontWeight: 'bold',
    ':disabled': {
      opacity: '65%',
      pointerEvents: 'none',
    },
    width: '100%',
    margin: 'auto',
    cursor: 'pointer',
  })}

  ${variant({
    variants: {
      ...variants,
      primaryBorderless: {
        borderRadius: 'small',
        border: 'faint',
      },
      roundedBorderless: {
        borderRadius: '40px',
        border: 'faint',
      },
      circle: {
        border: 'none',
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        padding: 0,
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  })}
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;
