import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const IconContainer = styled(Container)`
  ${css({
    marginLeft: 'tiny',
    '& path': {
      stroke: 'text',
    },
    cursor: 'pointer',
  })}
`;

export const LeftAlignedContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginLeft: 'auto',
  })}
`;

export const GearControlsAndTitleContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  })}
`;

export const GearTitle = styled(Text)`
  ${css({
    fontSize: ['xSmall', null, 'medium', null, null],
    marginLeft: 'medium',
    fontWeight: 'bold',
  })}
`;

export const GearRarity = styled(Text)`
  ${css({
    fontSize: ['tiny', null, 'small', null, null],
    color: 'secondaryText',
    marginLeft: 'tiny',
  })}
`;

export const TitleAlignmentContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'baseline',
  })}
`;
