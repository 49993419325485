import React, { useState } from 'react';
import type { AppliedSkill } from 'models';

import {
  SkillDisplayContainer,
  NameAndLevelContainer,
  SkillName,
  SkillLevel,
  IndicatorContainer,
  Indicator,
  SkillDescriptionContainer,
  SkillDescription,
  SkillLevelIconContainer,
  ExpandedIndicator,
  ExpandedIndicatorContainer,
} from './styled';
import { Container, Text, Tooltip } from 'ui/core';
import styled from 'styled-components';
import css from '@styled-system/css';

import { AnimatePresence, useAnimation } from 'framer-motion';
interface SkillProps {
  skill: AppliedSkill;
  interactive: boolean;
}

// TODO: move this out for resuse as an app-wide component?
export const SkillDisplay: React.FC<SkillProps> = ({ skill, interactive }) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const controls = useAnimation();

  const { description, skillLevels, appliedSkillLevel, name, maxSkillLevel } =
    skill;

  const generateIndicatorArray = (
    appliedSkillLevel: number,
    maxSkillLevel: number
  ) => {
    return Array.from(
      Array(Math.max(appliedSkillLevel, maxSkillLevel) + 1).keys()
    );
  };

  const indicatorArray = generateIndicatorArray(
    appliedSkillLevel,
    maxSkillLevel
  );
  const currentSkillLevel = Math.min(appliedSkillLevel, maxSkillLevel) + 1;
  const handleToggle = () => {
    const direction = !toggle ? { rotate: -180 } : { rotate: 0 };
    controls.start(direction);
    setToggle(!toggle);
  };

  const skillTickVariants = {
    offscreen: {
      x: -100,
      opacity: 0,
    },
    inactive: {
      x: 0,
      opacity: 1,
    },
    activated: {
      x: 0,
      opacity: 1,
    },
  };
  return interactive ? (
    <SkillDisplayContainer
      onClick={handleToggle}
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      key={name}
    >
      <NameAndLevelContainer>
        <SkillName>{name}</SkillName>
        <SkillLevel max={currentSkillLevel > maxSkillLevel}>
          Level {currentSkillLevel}
        </SkillLevel>
      </NameAndLevelContainer>
      <IndicatorContainer layout>
        {indicatorArray.map((_, index) => {
          const iconName = index === 0 ? 'Base' : 'Tick';
          return (
            <Tooltip content={skill.skillLevels[index]?.effect || 'N/A'}>
              <SkillLevelIconContainer key={index}>
                <Indicator
                  name={iconName}
                  activated={index <= appliedSkillLevel}
                  exceeded={index > maxSkillLevel}
                />
              </SkillLevelIconContainer>
            </Tooltip>
          );
        })}
        <ExpandedIndicatorContainer animate={controls}>
          <ExpandedIndicator name={'Up'}></ExpandedIndicator>
        </ExpandedIndicatorContainer>
      </IndicatorContainer>
      <AnimatePresence initial={false}>
        {toggle && (
          <SkillDescriptionContainer
            animate={{ opacity: 1, height: 'auto' }}
            initial={{ opacity: 0, height: 0 }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.2, ease: 'linear' }}
          >
            <SkillDescription>{description}</SkillDescription>
            <SkillDescription marginTop="medium" fontWeight="bold">
              Active Effect:
            </SkillDescription>
            <SkillDescription>
              {appliedSkillLevel >= maxSkillLevel
                ? skill.skillLevels[maxSkillLevel].effect
                : skill.skillLevels[appliedSkillLevel].effect}
            </SkillDescription>
          </SkillDescriptionContainer>
        )}
      </AnimatePresence>
    </SkillDisplayContainer>
  ) : (
    <Container variant="flexCol" paddingBottom="tiny">
      <Text>{skill.name}</Text>
      <Container variant="flexRow" paddingLeft="small">
        {indicatorArray.map((_, index) => {
          const iconName = index === 0 ? 'Base' : 'Tick';
          return (
            <SkillLevelIconContainer key={index}>
              <Indicator
                name={iconName}
                activated={index <= appliedSkillLevel}
                exceeded={index > maxSkillLevel}
              />
            </SkillLevelIconContainer>
          );
        })}
      </Container>
    </Container>
  );
};
