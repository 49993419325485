import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, SpacedFlexRowContainer, Text, Icon } from 'ui/core';

export const GenericStatDetailsContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    backgroundColor: 'detailsBackground',
    border: '1px solid',
    borderColor: 'detailsBorder',
    borderRadius: 'medium',
    justifyContent: 'space-evenly',
    paddingY: 'tiny',
    alignItems: 'center',
  })}
`;

export const StatContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({ justifyContent: 'center', alignItems: 'center' })}
`;

export const SpecialDamageIcon = styled(Icon)`
  ${css({
    marginRight: '2px',
  })}
`;

export const SpecialDamageContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    alignItems: 'center',
  })}
`;

export const SpecialDamageValuesContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    alignItems: 'center',
  })}
`;
