import React from 'react';
import { Tab } from '@headlessui/react';

import css from '@styled-system/css';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';

type TabProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

interface PrimitiveTabProps {
  tabs: any[];
  content: any[];
  className?: any;
}
const PrimitiveTab: React.FC<PrimitiveTabProps> = ({
  tabs,
  content,
  className,
}) => {
  const tabComponents = tabs.map((tab: any) => {
    return <Tab>{tab}</Tab>;
  });

  const tabPanels = content.map((panel: any) => {
    return <Tab.Panel>{panel}</Tab.Panel>;
  });

  return (
    <div className={className}>
      <Tab.Group>
        <Tab.List>{tabComponents}</Tab.List>
        <Tab.Panels>{tabPanels}</Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export const Tabs = styled(PrimitiveTab)<TabProps & PrimitiveTabProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;
