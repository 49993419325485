import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M19.9213 13.8805C19.6785 12.1625 18.8837 10.57 17.6569 9.34312L20 4.00001C12.3065 4.39984 9.10644 5.86776 5.34388 10.562C4.38134 12.0056 3.91422 13.723 4.01297 15.4553C4.11172 17.1875 4.77097 18.8408 5.89133 20.1656C7.01169 21.4905 8.53242 22.4153 10.2242 22.8004C10.8103 22.9338 11.406 23 12 23C13.1207 23 14.2357 22.7645 15.2706 22.3009C16.8541 21.5915 18.164 20.3866 19.0029 18.8678C19.8417 17.3489 20.1641 15.5986 19.9213 13.8805Z"
        fill="#EE6363"
      />
      <path
        d="M12 23C13.1207 23 14.2357 22.7645 15.2706 22.3009C16.8541 21.5915 18.164 20.3866 19.0029 18.8678C19.8417 17.3489 20.1641 15.5986 19.9213 13.8805C19.6785 12.1625 18.8838 10.57 17.6569 9.34312L20 4.00001C12.3065 4.39984 9.10644 5.86776 5.34388 10.562C4.38134 12.0056 3.91422 13.723 4.01297 15.4553C4.11172 17.1875 4.77097 18.8408 5.89133 20.1656C7.01169 21.4905 8.53242 22.4153 10.2242 22.8004C10.8103 22.9338 11.406 23 12 23ZM12 23C12 23 7.5 20 7.5 17C7.5 14 10.5 12 12.5 12.5C14.5 13 17 16 15 18C13 20 11 17 11 17"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
