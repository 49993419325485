import { Tooltip } from 'ui/core';
import { ActiveSkillPill, NoSlotText } from './styled';
import { getSkillRomanNumeral } from 'utils/formatting';

export const activeSkillsAccessor = (originalRow: any) => {
  return originalRow.appliedSkills.map((appliedSkill: any) => {
    return {
      name: appliedSkill.name,
      description: appliedSkill.description,
      appliedSkillLevel: appliedSkill.appliedSkillLevel,
      maxSkillLevel: appliedSkill.maxSkillLevel,
    };
  });
};

// TODO: tooltip on skill hover
export const ActiveSkillsCell = ({ value }: { value: any }) => {
  return (
    <>
      {value.length !== 0 ? (
        value.map((skill: any) => {
          const { appliedSkillLevel, maxSkillLevel } = skill;
          const currentSkillLevel =
            Math.min(appliedSkillLevel, maxSkillLevel) + 1;
          return (
            <Tooltip content={skill.description}>
              <ActiveSkillPill>
                {skill.name} {getSkillRomanNumeral(currentSkillLevel)}
              </ActiveSkillPill>
            </Tooltip>
          );
        })
      ) : (
        <NoSlotText>-</NoSlotText>
      )}
    </>
  );
};
