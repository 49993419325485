import { DecorationSlot } from 'models';
import create from 'zustand';

interface ExportStore {
  loadoutExportString: string | null;
}

// export const useExportStore = create((set: any) => ({
//   loadoutString: null,
// } as ExportStore, assignExportString: (loadoutExportString: string) => set((state: LoadoutStore) => {
//   return {
//     loadoutExportString
//   }
// })));

export const useExportStore = create((set: any) => ({
  exportEncryption: '',
  setExportEncryption: (exportEncryption: string) =>
    set((state: ExportStore) => {
      return {
        exportEncryption,
      };
    }),
}));
