export const switchSkillData = [
  {
    weapon: 'GreatSword',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Tackle',
          description:
            'A shoulder thrust that withstands all attacks. Keeps you from faltering or falling when attacked, making it useful for standing your ground while charging.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Guard Tackle',
          description:
            'A forward-dashing attack using the flat side of the blade. More cumbersome that your standard tackle, but strong and allows you to guard.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Hunting Edge',
          description:
            'Flies up using a Wirebug for a hefty Roundslash. If the attack connects, the hunter bounces up and transitions directly to the next Charged Slash attack.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Adamant Charged Slash',
          description:
            'Uses a Wirebug to dash forward, and deliver a Strong Charged Slash. Has knockback protection as you dash forth.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Strongarm Stance',
          description:
            'A technique that uses Ironsilk to reinforce both your arms and weapon temporarily to fend off attacks. If used while charging, you can quickly parry an attack and then unleash a fierce onslaught afterwards.',
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'True Charge Slash',
          description:
            "A devastating Charged Slash attack delivering extensive damage, striking twice by making a large swing with the weapon. When striking the monster's weakspot, the second strike deals additional damage.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Rage Slash',
          description:
            'Strike from a unique stance. Not as powerful that True Charge Slash but when met with an attack, Rage Slash will increase its damage.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Charged Slash Combo',
          description:
            'The bread-and-butter great sword combo. Build off of the Charged Slash, many a hunter knows this combo by heart.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Surge Slash Combo',
          description:
            'An offensive style that combines speed with the weight of the weapojn to unleash a stream of powerful slashes. Proper footwork is required, but allows for a large number of combos.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Power Sheathe',
          description:
            'A Wirebug technique to prepare for the next attack, auto-sheathing as you draw in and boosting attack power briefly. You can either close the gap ready for the next attack, or pull back to evade, making this an all-round technique.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Backslide',
          description:
            'A quick evasive move using a Wirebug. Watch for an opening while charging and upping attack level, and maintain charge during Surge Slash Combo-based moves.',
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'LongSword',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Soaring Kick',
          description:
            'A quick Wirebug-induced jump kick. A missed kick ends the attack with a single slash, while a successful kick can be followed with either a Plunging Thrust or a Spirit Helm Breaker.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Silkbind Sakura Slash',
          description:
            'A whirlwind-like attack, dashing past the target with a Wirebug. Increases the Spirit Gauge by 1.',
          wirebugRecovery: 'Slow',
        },
        {
          id: 2,
          name: 'Tempered Spirit Blade',
          description:
            'A decisive Silkbind maneuver that parries any incoming monster attack with a Spirit Blade. Timing is key if you want to successfully parry an attack, but doing so will fill your Spirit Gauge and raise it one level.',
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Step Slash',
          description:
            'A top-down slash while moving forward. The sword takes a vertical path from top to bottom that allows you to hit any target up high.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Drawn Double Slash',
          description:
            'A drawn attack that unleashes two attacks in a row. You can absorb attacks while moving forward and this move can be comboed with R to go straight to Spirit Blade II!',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Spirit Roundslash Combo',
          description:
            'Changes into a Spirit Blade on its third strike nd onwards. Follows from a Spirit Blade III into a Spirit Roundslash. A basic Spirit Blade combo that can easily hit the target while damaging any enemies close by.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Spirit Reckoning Combo',
          description:
            'Changes into a Spirit Blade at the third strike. Drives forward using Dividing Slash then finishes with the Spirit Reckining combo. Difficult to pull off, but has high damage.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Special Sheathe Combo',
          description:
            'A special sheathe that can be performed after attacking. Both of its follow-up attacks fill the Spirit Gauge. An Iai Slash automatically increases Spirit Gauge. Successfully countering a monster attack with an Iai Spirit Slash unleashes a powerful flash and raises the Spirit Gauge by one level.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Sacred Sheathe Combo',
          description:
            'A slow methodical movement that can be executed following an attack. Upon sheathing your weapon, you can harness the increased spirit to unleash a follow-up slash. The power of the slash increases according to the level of the Spirit Gauge. If you are attacked before you have finished with the sheathe, it will consume one level of Spirit Gauge and the move will be cancelled.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Serene Pose',
          description:
            "A move that casts out Ironsilk, and auto- matically counters any incoming attack. Like a reflection of the moon, it's visible, but untouchable. If a hit is countered, one level of Spirit Gauge will be consumed.",
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Harvest Moon',
          description:
            "A move that casts out an Ironsilk ring that narrows your range of attack, but boosts spirit so you can go on the offensive. Within the ring, your Spirit Gauge won't deplete, and if you perform any counter attacks, it will add additional hits.",
          wirebugRecovery: 'Medium',
        },
      ],
    ],
  },
  {
    weapon: 'SwordAndShield',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Advancing Slash',
          description:
            'A drawn attack where you perform a downward slash while stepping forward. Negates stagger and knockbacks.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Sliding Slash',
          description:
            'A sliding attack that slashes the target twice. High reach but no ability to turn. Allows for follow up jumping attack.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Hard Basher Combo',
          description:
            'A derivative of the Shield Slam that converts Shield Bash to a Hard Bash. Basically an attack using nothing but a shield. A direct hit to the head may stun the target.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Drill Slash Combo',
          description:
            'A derivative of the Shield Slam that converts Thrust to a Drill Slash. Basically a multi-hit attack with great potential when combined with an elemental weapon.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Windmill',
          description:
            "A multi-step attack using a Wirebug's silk to spin the weapon around. Any incoming attack is negated while the weapon is spinning.",
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Metsu Shoryugeki',
          description:
            'A jumping Wirebug attack that uses the shield to uppercut the target. Can perform a Guard at the start and can finish off with a Falling Bash.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Destroyer Oil',
          description:
            'Uses a Wirebug to cover your blade with a special ointment whose effects trigger if ignited by friction with your shield. While active, monsters flinch more easily, creating more openings for attack.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Sword/Shield Combo',
          description:
            'A two-hit attack that uses both the sword and shield. This traditional combo opens with a horizontal shield swipe and then follows up with a stepping downward slash.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Twin Blade Combo',
          description:
            'A two-part attack that opens with a circular slash and follows up with a blade thrust. An easy attack to get multiple hits in with, so ideal for weapons with high elemental and status properties.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Falling Shadow',
          description:
            'A technique that uses a Wirebug to leap forward and attack. If the attack lands, you will perform a Scaling Slash which can be chained into various finishing attacks.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Shield Bash',
          description:
            'A combined offensive and defensive move using a Wirebug to dash forward with your shield raised. If the attack connects with a monster, you can instantly follow up with a variety of attacks. If timed right, you can even parry an incoming attack and then continue your assault.',
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'DualBlades',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Demon Flurry Rush',
          description:
            'A spinning multi-hit attack performed on the ground. Only available when Demon or Archdemon Mode is active.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Demon Flight',
          description:
            'An attack performed by slamming down both blades. A successful hit vaults you into the air, slicing your target as you spin upwards. Effective against monsters with weakpoints high above.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Demon Mode',
          description:
            'While active, your stamina gradually decreases while your attack and evasion change, resisting any knockback. Movement speed increases dramatically, allowing you to roam the field like a force of nature.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Feral Demon Mode',
          description:
            'A more offernsive verson of Demon mode. You switch to an attack-focused stance, dealing slashing attacks when dodging.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Piercing Bind',
          description:
            'Thrusts an explosive kunai through a monster. When that body part is attacked again, it will inflict additional blows. After some time, the kunai will explode and deal additional damage based on the number of attacks dealt on that body part.',
          wirebugRecovery: 'Slow',
        },
        {
          id: 1,
          name: 'Tower Vault',
          description:
            'Launches a Wirebug upwards and pulls you into the air. Does not deal any damage but allows you to execute aerial combos with the Dual Blades.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 2,
          name: 'Spiral Slash',
          description:
            "A Silkbind attack that launches you spiraling forwards. Once your attack connects, your body acts as a drill as your blades bore into your target's flesh. Note: You can also perform a Spiral Slash midair.",
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Roundslash Combo',
          description:
            'A slashing attack that allows you to reposition. Use this attack to continue a combo or change direction giving you the ability to keep up the pressure on a targeted spot.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Side Slash Combo',
          description:
            "Float like a butterfly, sting like a bee. This technique allows you to attack while evading, and if you work it into your combos, you can unleash a relentless attack that's difficult to defend against.",
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Shrouded Vault',
          description:
            'A technique that uses a Wirebug to close in rapidly on the enemy, while instantly countering any attacks. Perfect for a good start in battle, as you can jump straight to the heart of the action without fear of taking a hit.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Ironshine Silk',
          description:
            'An ingenious Silkbind technique that uses turbulence from evading to sharpen your blades. Even with Shrouded Vault, and other evades, sharpness recovers if you time it right.',
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'Lance',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Dash Attack',
          description:
            'A charging attack using the Lance. Continue to speed up after a certain amount of time.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Shield Charge',
          description:
            'A charging attack with the shield up. Can only dash for a limited amount of time while fending off attacks with the shield. Crashing into a monster deals shield-based blunt damage.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Guard',
          description:
            'Your regular block using a shield. Can be used to absorb incoming attacks.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Insta-Block',
          description:
            'Swings your shield in an arch to parry attacks. Time your guard just before a monster hits you to follow-up with a Cross Slash. Great for countering right after blocking.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Anchor Rage',
          description:
            'A defensive skill requiring a shield and some patience. Efficiently braces and absorbs power of the incoming attack - a high-risk move, but the more power absorbed the greater the damage.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Spiral Thrust',
          description:
            'Two-step maneuver to counterattack. Uses the shield to parry then uses Wirebugs to pull you forward. Use this to confuse your targets and let out a flurry of strikes. Reposition using the Left Analog after the first dash or halt altogether.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Skyward Thrust',
          description:
            'A technique where a Wirebug launches you high into the air. Thrust your lance towards the heavens and then pierce the earth. Note: The falling portion of the attack inflicts multiple hits.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Guard Dash',
          description:
            'A technique that allows mobility while guarding with your shield. If performed after an attack you can move in any direction, forward, backward, left, and right.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Shield Tackle',
          description:
            "A technique where you charge forward with your shield raised. As your shield is raised, you're able to guard against incoming attacks. You can also follow up with a variety of thrusting attacks. Watch for an opening and then unleash an unrelenting series of attacks.",
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Twin Vine',
          description:
            "A technique that draws a monster's attention by thrusting a kunai tethered with Ironsilk into it. While the kunai is lodged, the hunter can leap in close to the target with a Closed Jump. In addition, stamina consumption while guarding is reduced.",
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Sheathing Retreat',
          description:
            "A technique that automatically sheathes your weapon while using a Wirebug to pull you backwards out of harm's way. No matter how effective your shield is, if you sense life-threatening danger, retreat is the wisest choice.",
          wirebugRecovery: 'Slow',
        },
      ],
    ],
  },
  {
    weapon: 'Gunlance',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Hail Cutter',
          description:
            "Uses a Wirebug to launch you upwards performing a Rising Slash going up and an Overhead Smash going down. Can reload in midair that reduces Wyvern's Fire cooldown.",
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Ground Splitter',
          description:
            'Scrapes the ground while slashing upwards. Increases shell, Wyrmstake Shot, and Wyvern Fire damage temporarily.',
          wirebugRecovery: 'Slow',
        },
        {
          id: 2,
          name: 'Bullet Barrage',
          description:
            "Uses a Wirebug to Blast Dash toward your target, unleashing everything with abandon. Be careful: Shells, Wyrmstake Cannon, and Wyvern's Fire will automatically be reloaded first before performing the attack.",
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Charged Shelling',
          description:
            'Shelling attack from the Gunlance. Unleashes a fortified shot but needs time to charge up before firing.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Blast Dash',
          description:
            'Uses recoil to travel far. Aims the Gunlance backwards then launches forward to close in the target. It is possible to use Blast Dash again while hurtling!',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Quick Reload',
          description:
            'An action for shell reload. Allows for quicker shell reloading but does not reload the Wyrmstake cannon.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Spiral Thrust',
          description:
            'Has a Guard at the start of the reload. Reloads both the shells and the Wyrmstake Cannon but only reloads less shells than normal Reload.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Wyrmstake Cannon',
          description:
            "An attack that impales a monster with a stake from your gunlance. The effects of each stake differs depending on the gunlance's shelling type. Normal and long shelling types detonate after multiple hits, while the wide shelling type only detonates a single blast, but can stun monsters.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Erupting Cannon',
          description:
            'This technique involves firing an exploding stake into your target. When using the Eruption Cannon, the tip of the gunlance heats up and slashing attacks are enhanced for a certain period of time.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Guard Edge',
          description:
            'A strong blocking stance utilizing Wirebug silk. You also enjoy bonus sharpness recovery if you get the timing right. After a successful guard, you can combo into a variety of different attacks.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Reverse Blast',
          description:
            "A rapid, backward evasive maneuver using a Wirebug to stop. If no shells are loaded, you'll do a quick reload, so act with caution.",
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'Hammer',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Silkbind Spinning Bludgeon',
          description:
            'A spinning attack using a Wirebug. Moves along an arc pattern like a midair spinning attack on slopes, but is just as effective on flat terrain.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Dash Breaker',
          description:
            'Forward rush using Wirebugs. Enemy attack damage is negated while launching forward!',
          wirebugRecovery: 'Fast',
        },
        {
          id: 2,
          name: 'Keeping Sway',
          description:
            "A maneuver that uses a Wirebug to quickly evade an attack at high speed. By using a Wirebug, you're able to maintain your charge during the evade.",
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Charge Switch: Strength',
          description:
            'Lengthens required charge time, but allows for more powerful actions.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Charge Switch: Courage',
          description:
            'Decreases charging levels, but allows you to follow from one charge into another more quickly.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Side Smash',
          description:
            'A side swipe with the Hammer. Can be used as the starting point of multiple Hammer combos.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Water Strike',
          description:
            'Attack that allows you to absorb an enemy attack. Perform before getting hit to negate the damage. Can be followed-up by an upswing hammer attack.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Spinning Bludgeon',
          description:
            'Charge as you advance to unleash a devastating spinning attack. The attack hits with each rotation allowing you to land multiple successive blows. In addition, the number of rotations determines which attack can be performed next.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Spinning Bludgeon: Charge',
          description:
            'A technique that stores released power and funnels it into the next Charge Switch attack. Power is built up when performing a Spinning Bludgeon. The charge level is then maintained when executing a Charge Switch. Make good use of this to unleash a series of level 3 charged attacks.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Impact Crater',
          description:
            "A Silkbind attack that makes you leap in the air and come down with multiple earth-shattering strikes. The power of the attack and number of hits you'll land are determined by your charge level at the start of the attack.",
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Impact Burst',
          description:
            'A Silkbind attack that wraps your weapon in Ironsilk. For a short period of time, any charged attack performed causes the silk to vibrate, creating a shockwave that makes it easier to flinch monsters.',
          wirebugRecovery: 'Medium',
        },
      ],
    ],
  },
  {
    weapon: 'HuntingHorn',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Melody Mode: Performance',
          description:
            'An action that affects invigorating melodies. Two attacks of the same note in a row generates a melody effect. Press zR to boost your own abilities. A mode focused on combat.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Melody Mode: Echo',
          description:
            'An action that affects invigorating melodies. Generates a melody effect from the Staff using zR, while boosting your own abilities. A mode focused on applying various melody effects.',
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Earthshaker',
          description:
            'Drives an Ironsilk-bound blade into the target like a stake. When it hits, the Horn is connected with the blade via a Wirebug thread and the vibration by the weapon explodes inside the target.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Bead of Resonance',
          description:
            'Places a Wirebug cocoon on the ground. If the same hunter generates melody effects, the effect also appears around the cocoon, as does as a Sonic Wave that damages monsters near it. Only one cocoon can be placed at a time.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Sonic Bloom',
          description:
            "Places a Wirebug cocoon on the ground that's tethered to you with Ironsilk. When attacking, vibrations are sent to the silk and stored in the cocoon, which gradually swells. After a certain number of Perform- ances, the energy stored detonates, dealing stun and exhaust damage.",
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Overhead Smash',
          description:
            "A mighty attack that swings the Hunting Horn down from above. Effective in reducing the target's stamina.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Melodic Slap',
          description:
            'Swings the Hunting Horn around once, generating a forwards Sonic Smash. Low damage output but effective at stunning.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Crush Attack Combo',
          description:
            'A combo that uses Swing and Crush attacks. Useful from a fixed position and from close range. The combo loop varies depending on button input.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Swing Combo',
          description:
            'A two-hit attack that can be delivered from either the left or right. This allows you to quickly shift directions, making it useful for repositioning.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Slide Beat',
          description:
            'A technique that uses a Wirebug to push ahead with repeated attacks, punctuated by a performance that buffs strength. It serves the dual purpose of closing in on your enemy and boosting your power.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Silkbind Shockwave',
          description:
            'A Silkbind attack that wraps your weapon in Ironsilk. For a short period of time, any attack performed causes the silk to vibrate, creating a time-delayed shockwave that triggers additional hits. These additional hits do major stun, exhaust, and part damage.',
          wirebugRecovery: 'Medium',
        },
      ],
    ],
  },
  {
    weapon: 'SwitchAxe',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Invincible Gambit',
          description:
            'A Wirebug technique to surge forward and dish out three strikes. You are impervious to knockdowns during the process making this useful in a pinch.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Soaring Wyvern Blade',
          description:
            'Launches you in the air while ending with a Forward Slash in midair. If Forward Slash connects, an explosion occurs.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Wire Step',
          description:
            'A Wirebug-based evasion technique that allows you to morph into Axe Mode while flanking the enemy. Highly effective for maneuvering while in Axe Mode.',
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Forward Slash',
          description:
            "Thrust the axe's blade forward while stepping. Doesn't do a lot of damage, but is very versatile.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Forward Overhead Slash',
          description:
            'A lunging attack performed by winding up the axe and smashing it down. Difficult to pull off but delivers good damage. A subsequent Sword Mode attack will turn into a Double Slash.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Finishing Discharge',
          description:
            'Performs a thrust attack while fortifying elemental energy, ending with a massive explosion. If the Activation Gauge is full and the target is a large monster, the action becomes a Zero Sum Elemental Discharge.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Compressed Finishing Discharge',
          description:
            'Puts you in a knockdown-resistant state and delivers a swift strike accompanied by an elemental explosion. The Activation Gauge has no effect on this action.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Axe: Wide Sweep',
          description:
            'A technique that closes the distance to your target by using the centrifugal force of swinging with an Axe: Wild Swing. Useful for when the target is out of swinging range, or when you want to get in close for Sword Mode attacks.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: '2-Staged Morph Slash Combo',
          description:
            'A two-staged Morph Slash attack that follows an Axe: Wild Swing. 1st Stage: Morphs into Sword Mode. 2nd Stage: Morphs into Axe Mode. During this combo, the power of Sword Mode attacks increase and Axe Mode attacks fill the Switch Gauge faster.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Switch Charger',
          description:
            "A technique that uses a Wirebug to restore your Switch Gauge as you move. Once active, your Switch Gauge won't deplete for a while, so use this technique to gain the advantage at the start of battle.",
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Elemental Burst Counter',
          description:
            "Compresses Switch Gauge energy, that can be released as an Elemental Burst. If the burst is released right as a monster attacks, you'll perform a Power Finisher and your switch axe will go into an Amped State.",
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'ChargeBlade',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Condensed Element Slash',
          description:
            'While charging, elemental energy gathers on the sword and strengthens it. Damage dealt by the sword is increased. Reinforcing the sword requires the shield to be elementally boosted.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Condensed Spinning Slash',
          description:
            'Activates the Element Regeneration system while charging. The shield rotates and acts as a spinning saw blade. Switches the weapon to Axe mode and turns energy into phials.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Morph Slash',
          description:
            'Motion that switches Sword to Axe, and Axe to Sword. At the start and the end of the switching, the shield can block any incoming attacks.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Counter Morph Slash',
          description:
            "A more defense-focused switching attack with a longer blocking effect. If successful, the follow-up Element Discharge Slash's damage increases.",
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Counter Peak Performance',
          description:
            'A blocking attack by the Charge Blade. If successful, phials are filled and you can lead into various attack-focused actions like Super Amped Elemental Discharge (SAED).',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Axe Hopper',
          description:
            'Slam the Axe overhead, using recoil and Ironsilk to propel you to the air. While airborne, you can execute a Super Amped Elemental Discharge (SAED).',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Ready Stance',
          description:
            "A Silkbind maneuver that binds sword and axe together with Ironsilk. After guarding, a heavy knockback leaves you in Sword Mode, and lowers your guard reaction so you're able to chain sword/axe attacks.",
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Phial Follow-up',
          description:
            'A mechanism in which a small explosion is triggered by Shield Thrust during Elemental Boost or by sword attacks while in Sword Boost Mode. Functions as support when attacking in Sword Mode. Shield Thrust during Elemental Boost or sword attacks in Sword Boost Mode.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Phial Power-up: Firing Pin',
          description:
            'A mechanism that lowers the pressure of elemental energy generated through Shield Thrust during Elemental Boost, or by sword attacks while in Sword Boost Mode. The elemental energy generated builds up on your target, but disappears over time. It can be detonated by any attack in Axe Mode, and extending the time limit of the Elemental Boost. Shield Thrust during Elemental Boost or sword attacks in Sword Boost Mode. Continuing to attack will amplify the energy built up on your target.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Morphing Advance',
          description:
            "A Silkbind technique for quickly closing the distance to your target while morphing into Axe Mode. While advancing, you're immune to knock- backs and attacks that send you flying. You can also follow up by morphing back into Sword Mode.",
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Air Dash',
          description:
            "A Wirebug-based technique where you rise up while whirling an axe, then propel yourself away with a Phial Explosion. Damage often leaves monsters mountable, and you can control your fall direction to some degree. If no Phial Explosion is detonated, you'll fall straight down.",
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'InsectGlaive',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Tornado Slash',
          description:
            'Performed after a bludgeoning attack, using the entire body to swing the weapon. With Red Extract loaded, perform a Leaping Slash and press A.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Tetraseal Slash',
          description:
            "Delivers four strikes on the spot. One of the attacks is hit with the handle's seal, which can lure in Powder Kinsects. With Red Extract loaded, perform a Leaping Slash and press A.",
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Leaping Slash',
          description:
            'Downward slash technique performed while lunging forward. Can negate knockbacks without Orange Extracts. Can reach higher areas with its attack.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Advancing Roundslash',
          description:
            'Attack performed by spinning foward like a top. When attacked during the spin, you will use that force to jump up. Only happens while you still have momentum.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Recall Kinsect',
          description:
            'An emergency evasion performed with a Wirebug that recalls your Kinsect. Upon activation, it will perform a spinning attack that can gather a healing extract. Once it returns, its stamina will fully recover.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Diving Wyvern',
          description:
            'A devastating dive attack that uses a Wirebug to pull you downward with great force. The attack has a small damage radius, but that means the damage is highly focused.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Awakened Kinsect Attack',
          description:
            "A technique where you hurl a Kinsect to inflict massive damage. All extracts are consumed and the more extract you have, the more potent this attack will be. After launching the Kinsect, you'll close in using a Wirebug; simultaneously absorbing extract, marking the target, and then chaining into a Vaulting Dance.",
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Jumping Advancing Slash',
          description:
            "A technique that consumes stamina to perform a midair slash. If you have red extract absorbed, you'll perform a multi-hit Strong Jumping Advancing Slash instead. Landing either Slash will follow up with a Vaulting Dance.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Kinsect Slash',
          description:
            "A mid-air technique where you thrust forward with your glaive and Kinsect. If the slash connects, you'll perform a Vaulting Dance, absorbing extract. However, once you've absorbed red extract with Kinsect Slash, you won't be able to absorb any other extract with it, but you'll be able to perform an Enhanced Insect Spiker as a trade-off.",
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Silkbind Vault',
          description:
            'A Silkbind attack in which you perform a forward-leaping attack. Also useable midair, you can close in on a target quickly by following up with either a Midair Evade or Jumping Advancing Slash.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Kinsect Glide',
          description:
            "Kinsect Glide	A technique that sends your Kinsect out and uses a Wirebug to quickly jump to it. The Kinsect normally aims for a marker, but in the absence of a marker, it will fly forward or follow the reticle. When your Kinsect comes in contact with a monster, it'll absorb extract.",
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'LightBowgun',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Fanning Vault',
          description:
            'A jumping action using Wirebugs. Shots can be fired while in midair. You can also fire Wyvernblasts while airborne.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Fanning Maneuver',
          description:
            'Uses wirebug as a level to quickly flank left or right. Increases attack power temporarily.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Wyvern Counter',
          description:
            "An urgent retreat that fires a shot with massive recoil, using a Wirebug to stop. While not a powerful attack, its true value comes when it's used to evade incoming attacks. If timed right, you'll retreat with an explosive exit that can even neutralize breath attacks.",
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Forward Dodge',
          description:
            'Evasive maneuver while your Light Bowgun is drawn. Essential to create distance between you and the target.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Quickstep Evade',
          description:
            'Quick evasive maneuver while your Light Bowgun is drawn. Distance covered is much smaller but requires less time to recover. Ammo fired immediately after this deals increased damage.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Reload',
          description:
            'Executes a reload. Reload speed depends on the ammo type.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Diving Wyvern',
          description:
            'Reloads take longer than usual but boosts elemental damage of elemental shots.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Marksman',
          description:
            'A style of shooting that draws out the true potential of ammo.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Critical Firepower',
          description:
            'A special shooting style that further raises damage output but narrows critical distance and increases recoil. Because the distance at which the power of ammo is most effective has been reduced, you will need to be aware and adjust your firing position. Note: Does not apply to certain ammo types.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Silkbind Glide',
          description:
            'A technique that uses a Wirebug to pull you forward. If can be followed up with a powerful close-range attack that blasts Ironsilk into the target dealing severing damage. After the attack, whatever ammo was previously loaded will be automatically reloaded.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Mech Silkbind Shot',
          description:
            'Purified ammo is loaded with Ironsilk, allowing you to unleash an onslaught of shots. Each shot laced with Ironsilk lodges into the monster, and if any follow-up attacks hit the lodged ammo, the silk reacts, increasing the chance of leaving the part broken or the monster mountable.',
          wirebugRecovery: 'Medium',
        },
      ],
    ],
  },
  {
    weapon: 'HeavyBowgun',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Mech-Wyvernsnipe',
          description:
            'Fires a specialized shot, different depending on the weapon. Has its own gauge, and replenishes over time.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 1,
          name: 'Healing Mech-Wyvernsnipe',
          description:
            'Fires a specialized shot, different depending on the weapon. Has its own gauge, and replenishes over time. Regular Wyvernheart and Wyvernsnipe deals less damage but can heal the hunter.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Melee Attack',
          description:
            'Bludgeon attack delivered by directly smashing with the weapon. Delivers impressive damage, and may stun your taret. If a shield is equipped, incoming attacks can be blocked while executing this.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Tackle',
          description:
            'A shoulder check by the Hunter. Prevents flinching and knockback, but still allow significant damage. Only use this for weak attacks that may knock you back!',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Counter Shot',
          description:
            'Parrying skill using Wirebugs. Absorbs an attack from a monster without taking any damage then follow up with a powerful counterattack.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Counter Charger',
          description:
            'A parry skill using Wirebugs. Absorbs an attack from a monster without taking any damage. Will shorten the amount of time for a Charged Shot.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: 'Rising Moon',
          description:
            "A Silkbind technique in which a Wirebug generates a ring of Ironsilk. Special powder accumulates within the ring, and when ammo passes through it, the velocity of the ammo increases, and in turn, extends the ammo's range. This can make certain ammo types viable from farther away.",
          wirebugRecovery: 'Fast',
        },
      ],
      [
        {
          id: 0,
          name: 'Charged Shot',
          description:
            'A style of shooting that accumulates power by charging a shot. The amount of power a shot is increased by is determined by how long the shot was charged.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Crouching Shot',
          description:
            "A style of shooting that utilizes a special stance to reduce recoil. In exchange for not being able to move, you're able to fire in rapid succession. The longer you fire, the shorter the firing interval becomes, but at the risk of overheating your bowgun. If your bowgun overheats, it can't be fired until it has cooled down. Note: Certain ammo types cannot be used.",
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Free Silkbind Glide',
          description:
            'Advance like a raging wildfire. This technique uses a Wirebug to pull you while you glide forward. It can be used to evade attack and adjust position.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Setting Sun',
          description:
            'A Silkbind technique in which a Wirebug generates a ring of Ironsilk. Special powder accumulates within the ring, and when ammo passes through it, the velocity of the ammo is decreased. Firing piercing ammo/multi-hit ammo through the ring will boost the total number of hits upon impact.',
          wirebugRecovery: 'Fast',
        },
      ],
    ],
  },
  {
    weapon: 'Bow',
    switchSkills: [
      [
        {
          id: 0,
          name: 'Charging Sidestep',
          description:
            'Evasive skill that covers a lot of ground. Raises your charge level while dodging, and controls the direction of dodge using the left analog stick.',
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Dodgebolt',
          description:
            'Spinning evade that doubles as an attack. Making it a near-miss can fill up the charge level considerably. Allows you to move forward, backwards, left, or right.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Focus Shot',
          description:
            'Wirebug evasive action. After landing, crouching will cause stamina to recover at a rapid pace.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Aerial Aim',
          description:
            'An action for firing arrows by jumping straight up with a Wirebug. Particularly damaging shots can be fired from midair. A close-range attack using an arrow can also be aimed directly underneath you.',
          wirebugRecovery: 'Medium',
        },
        {
          id: 2,
          name: "Butcher's Bind",
          description:
            'Fires an arrow loaded with Ironsilk. If the follow-up arrow lands in the same spot as the first, Ironsilk winds around both arrows, inflicting severing damage. If the second arrow is off target, the lodged arrow disappears.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Power Shot',
          description:
            'A regular shot followed by a particularly powerful shot. Fires shots with a higher level than usual (but never over the charge level limit).',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Absolute Power Shot',
          description:
            'Fires a powerful shot after charging. Shots are fired with a higher level than usual (but never over the charge level limit). Power Shots will cost more stamina but can stun the target.',
          wirebugRecovery: 'Medium',
        },
      ],
      [
        {
          id: 0,
          name: 'Melee Attack',
          description:
            "A basic melee attack. If you perform a Charging Sidestep after a Melee Attack, you can follow-up with a Lunging Melee Attack. Both melee attacks will have the same effect as your currently applied coating but won't consume it.",
          wirebugRecovery: 'N/A',
        },
        {
          id: 1,
          name: 'Stake Thrust',
          description:
            'An attack that thrusts an explosive stake into your target. The stake reacts to follow-up attacks, dealing extra damage to the target. The amount of extra damage dealt is determined by type of arrow loosed.',
          wirebugRecovery: 'N/A',
        },
      ],
      [
        {
          id: 0,
          name: 'Herculean Draw',
          description:
            'A maneuver using a Wirebug to boost bow-drawing strength. Shots can be fired while evading.',
          wirebugRecovery: 'Fast',
        },
        {
          id: 1,
          name: 'Bolt Boost',
          description:
            'A maneuver that twines Ironsilk around your arrow. This move deals damage more effectively, and thus requires range adjustment. Activates Super Critical Range, boosting damage within that range for a set time.',
          wirebugRecovery: 'Medium',
        },
      ],
    ],
  },
];
