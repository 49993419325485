import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M18 14C18 17.866 15.3137 21 12 21C8.68629 21 6 17.866 6 14C6 10.134 12 7 12 7C12 7 18 10.134 18 14Z"
        fill="white"
      />
      <path
        d="M18 14C18 17.866 15.3137 21 12 21M18 14C18 10.134 12 7 12 7C12 7 6 10.134 6 14M18 14H12M12 21C8.68629 21 6 17.866 6 14M12 21V14M6 14H12"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M14.4 3V7.90323L17.4 10.9677V6.67742C17.4 6.67742 16.7303 5.51048 16.2 4.83871C15.5759 4.04824 14.4 3 14.4 3Z"
        fill="white"
      />
      <path
        d="M9.6 3.00001V7.90323L6.6 10.9677V6.67743C6.6 6.67743 7.26965 5.51049 7.8 4.83872C8.42406 4.04825 9.6 3.00001 9.6 3.00001Z"
        fill="white"
      />
      <path
        d="M6 12.8065L4.8 11.5806V9.74194L3.6 8.51613L3 12.1935L5.4 15.2581M6.6 17.7097L5.4 18.9355V20.7742L4.2 22L3.6 18.3226L6 15.2581M18 12.8065L19.2 11.5806V9.74194L20.4 8.51613L21 12.1935L18.6 15.2581M17.4 17.7097L18.6 18.9355V20.7742L19.8 22L20.4 18.3226L18 15.2581M14.4 7.90323V3C14.4 3 15.5759 4.04824 16.2 4.83871C16.7303 5.51048 17.4 6.67742 17.4 6.67742V10.9677L14.4 7.90323ZM9.6 7.90323V3.00001C9.6 3.00001 8.42406 4.04825 7.8 4.83872C7.26965 5.51049 6.6 6.67743 6.6 6.67743V10.9677L9.6 7.90323Z"
        stroke="black"
        strokeMiterlimit="11.4737"
        strokeLinecap="round"
      />
    </>
  ),
};
