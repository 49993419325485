import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <circle
        cx="8"
        cy="9"
        r="4"
        fill="#B37BCE"
        stroke="black"
        stroke-width="2"
      />
      <circle
        cx="16"
        cy="17"
        r="3"
        fill="#B37BCE"
        stroke="black"
        stroke-width="2"
      />
    </>
  ),
};
