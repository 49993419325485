import { DefenseStatsCellContainer, DefenseStatContainer } from './styled';
import { Icon } from 'ui/core';

export const defenseStatsAccessor = (originalRow: any) => {
  return Object.values(originalRow.defenseStats);
};

export const DefenseStatsCell = ({ value }: { value: any }) => {
  const defenseKeys = [
    'Defense',
    'Fire',
    'Water',
    'Thunder',
    'Ice',
    'Dragon',
  ] as const;

  return (
    <DefenseStatsCellContainer variant="flexRow">
      {value.map((defenseStat: number, index: number) => {
        return (
          <DefenseStatContainer
            marginRight={index !== 5 ? 'tiny' : null}
            fontSize="small"
          >
            <Icon name={defenseKeys[index]} height={16} width={16}></Icon>
            {defenseStat}
          </DefenseStatContainer>
        );
      })}
    </DefenseStatsCellContainer>
  );
};
