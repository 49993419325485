import styled from 'styled-components';
import css from '@styled-system/css';
import { Button, Container, Text, Icon, Span } from 'ui/core';

export const LoadoutWrapper = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateColumns: '1fr minmax(500px, 1.5fr) 1fr',
  })}
`;

export const StackedCardContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
  })}
`;

// convert to banner
export const ErrorContainer = styled(Container)`
  ${css({
    textAlign: 'center',
  })}
`;

export const SpinnerContainer = styled(Container)`
  ${css({
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;

export const AppGridContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: [
      null,
      null,
      '1.5fr 1.5fr',
      null,
      '1fr 1.5fr 1.5fr 1fr',
    ],
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gap: '1rem 1rem',
    gridTemplateAreas: [
      null,
      null,
      "'weapon weapon' 'head chest' 'gloves waist' 'feet accessories'",
      null,
      "'tooling weapon weapon skills' 'summary head chest skills' 'summary gloves waist skills' 'summary feet accessories skills'",
    ],
    height: '100%',
  })}
`;

export const LoadoutToolingContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridAutoRows: '1fr',
    gridTemplateColumns: [null, '1.5fr 1.5fr', null, null],
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gap: '1rem 1rem',
    gridTemplateAreas: [
      null,
      "'tooling skills' 'summary skills' 'summary skills' 'summary skills'",
    ],
    height: '100%',
  })}
`;

export const LoadoutViewControls = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    marginBottom: 'tiny',
  })}
`;

export const LoadoutViewSpan = styled(Span)`
  ${css({
    marginX: 'xSmall',
    cursor: 'pointer',
  })}
`;

export const LoadoutViewIcon = styled(Icon)``;

export const ControlButtonContainer = styled(Container)`
  ${css({
    position: 'absolute',
    transform: 'translate(0, 45vh)',
    '& path': {
      stroke: 'text',
    },
  })}
`;

// export const ColumnAppGridContainer = styled(Container).attrs({
//   variant: 'flexCol',
// })`
//   ${css({ height: '100%' })}
// `;
export const ColumnAppGridContainer = styled(Container).attrs({})`
  ${css({
    display: 'grid',
    gridTemplateRows: 'min-content min-content min-content min-content auto',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: "'tooling' 'slotSelect' 'slot' 'controls' 'info'",
    gap: 'small',
    height: '100%',
  })}
`;
// export const ColumnAppGridContainer = styled(Container).attrs({
//   variant: 'flexCol',
// })`
//   ${css({})}
// `;
export const MobileSlotSelectionContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'center',
    marginY: 'tiny',
    gridArea: 'slotSelect',
  })}
`;

export const SlotSelectionContainer = styled(Container)`
  ${css({
    marginLeft: 'tiny',
    padding: 'tiny',
    borderRadius: 'small',
  })}
`;

export const SlotSelectionIcon = styled(Icon)``;
