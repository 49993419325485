import React, { createContext } from 'react';
import { DataObject } from 'models/data';
import { loadData } from 'utils/loadData';

const data: DataObject = loadData();

export const DataContext = createContext(data);

export const DataContextProvider: React.FC = ({ children }) => {
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};
