import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M2 19H4.5L5.5 18V16.75H2V19Z" fill="white" />
      <path d="M5.5 13.5H3L2 14.5V16.75H5.5V13.5Z" fill="white" />
      <path d="M5.5 11H4L3 11.5V13.5H5.5V13V11Z" fill="white" />
      <path d="M7 11H5.5V13H7.5V11H7Z" fill="white" />
      <path d="M10 11H7.5V13V13.5H10V12.5V11Z" fill="white" />
      <path d="M12 11H10V12.5H13.5V11H12Z" fill="white" />
      <path d="M16.5 11H13.5V12.5V13.5H14H16.5V13V11Z" fill="white" />
      <path d="M17 11H16.5V13H18.5V11H17Z" fill="white" />
      <path d="M20 11H18.5V13V13.5H21V11.5L20 11Z" fill="white" />
      <path d="M21 13.5H18.5V16.75H22V15L21 13.5Z" fill="white" />
      <path d="M19.5 19H22V16.75H18.5V18L19.5 19Z" fill="white" />
      <path d="M20 3H4V4.5V6.5H7H12H17H20V4.5V3Z" fill="white" />
      <path d="M7 6.5V11H7.5H10H12V6.5H7Z" fill="white" />
      <path d="M4 6.5V11H5.5H7V6.5H4Z" fill="white" />
      <path d="M17 11V6.5H12V11H13.5H16.5H17Z" fill="white" />
      <path d="M20 11V6.5H17V11H18.5H20Z" fill="white" />
      <path
        d="M16.5 18.5L18.5 21H22V19H19.5L18.5 18V16.75V13.5V13H16.5V13.5V18.5Z"
        fill="white"
      />
      <path
        d="M2 19V21H5.5L7.5 18.5V13.5V13H5.5V13.5V16.75V18L4.5 19H2Z"
        fill="white"
      />
      <path d="M13.5 13.5H10V21H14V13.5H13.5Z" fill="white" />
      <path
        d="M4 3H20M4 3V6.5M4 3V4.5M20 3V6.5M20 3V4.5M20 11V6.5M20 11H18.5M20 11L21 11.5V13.5M4 11V6.5M4 11H5.5M4 11L3 11.5V13.5M4 6.5H7M4 6.5V4.5M20 6.5H17M20 6.5V4.5M17 6.5V11M17 6.5H12M17 11H16.5M17 11H18.5M7 6.5V11M7 6.5H12M7 11H5.5M7 11H7.5M12 6.5V11M12 11H10M12 11H13.5M2 19V21H5.5L7.5 18.5V13.5M2 19H4.5L5.5 18V16.75M2 19V16.75M5.5 11V13M7.5 11H10M7.5 11V13M16.5 11H13.5M16.5 11V13M22 19V21H18.5L16.5 18.5V13.5M22 19H19.5L18.5 18V16.75M22 19V16.75M18.5 11V13M7.5 13.5H10M7.5 13.5V13M16.5 13.5H14M16.5 13.5V13M10 11V12.5M10 13.5H13.5M10 13.5V12.5M10 13.5V21H14V13.5M13.5 11V12.5M13.5 13.5V12.5M13.5 13.5H14M10 12.5H13.5M3 13.5H5.5M3 13.5L2 14.5V16.75M5.5 13.5V13M5.5 13.5V16.75M18.5 13.5H21M18.5 13.5V13M18.5 13.5V16.75M21 13.5L22 15V16.75M5.5 13H7.5M16.5 13H18.5M2 16.75H5.5M18.5 16.75H22M5 8V9.5M9.5 8V9.5M14.5 8V9.5M19 8V9.5M12.5 4.5H13.5M8 4.5H9M4 4.5H5M4 4.5H4.5M10.5 4.5H11.5M15 4.5H16M19 4.5H20M17 4.5H18M6 4.5H7"
        stroke="black"
      />
      <path d="M2 19H4.5L5.5 18V16.75H2V19Z" fill="#C4C4C4" />
      <path d="M5.5 13.5H3L2 14.5V16.75H5.5V13.5Z" fill="#C4C4C4" />
      <path d="M5.5 11H4L3 11.5V13.5H5.5V13V11Z" fill="#C4C4C4" />
      <path d="M7 11H5.5V13H7.5V11H7Z" fill="#C4C4C4" />
      <path d="M10 11H7.5V13V13.5H10V12.5V11Z" fill="#C4C4C4" />
      <path d="M12 11H10V12.5H13.5V11H12Z" fill="#C4C4C4" />
      <path d="M16.5 11H13.5V12.5V13.5H14H16.5V13V11Z" fill="#C4C4C4" />
      <path d="M17 11H16.5V13H18.5V11H17Z" fill="#C4C4C4" />
      <path d="M20 11H18.5V13V13.5H21V11.5L20 11Z" fill="#C4C4C4" />
      <path d="M21 13.5H18.5V16.75H22V15L21 13.5Z" fill="#C4C4C4" />
      <path d="M19.5 19H22V16.75H18.5V18L19.5 19Z" fill="#C4C4C4" />
      <path
        d="M20 11V6.5M20 11H18.5M20 11L21 11.5V13.5M4 11V6.5M4 11H5.5M4 11L3 11.5V13.5M4 6.5V3H20V6.5M4 6.5H7M20 6.5H17M17 6.5V11M17 6.5H12M17 11H16.5M17 11H18.5M7 6.5V11M7 6.5H12M7 11H5.5M7 11H7.5M12 6.5V11M12 11H10M12 11H13.5M2 19V21H5.5L7.5 18.5V13.5M2 19H4.5L5.5 18V16.75M2 19V16.75M5.5 11V13M7.5 11H10M7.5 11V13M16.5 11H13.5M16.5 11V13M22 19V21H18.5L16.5 18.5V13.5M22 19H19.5L18.5 18V16.75M22 19V16.75M18.5 11V13M7.5 13.5H10M7.5 13.5V13M16.5 13.5H14M16.5 13.5V13M10 11V12.5M10 13.5H13.5M10 13.5V12.5M10 13.5V21H14V13.5M13.5 11V12.5M13.5 13.5V12.5M13.5 13.5H14M10 12.5H13.5M3 13.5H5.5M3 13.5L2 14.5V16.75M5.5 13.5V13M5.5 13.5V16.75M18.5 13.5H21M18.5 13.5V13M18.5 13.5V16.75M21 13.5L22 15V16.75M5.5 13H7.5M16.5 13H18.5M2 16.75H5.5M18.5 16.75H22M5 8V9.5M9.5 8V9.5M14.5 8V9.5M19 8V9.5M12.5 4.5H13.5M8 4.5H9M10.5 4.5H11.5M15 4.5H16M19 4.5H20M17 4.5H18M5 4.5H4H4.5M6 4.5H7"
        stroke="black"
      />
      <path d="M12.5 13.5H11.5V15.5H12.5V13.5Z" fill="#C4C4C4" />
      <path d="M12.5 15.5H11.5V18H12.5V15.5Z" fill="#C4C4C4" />
      <path d="M11.5 19.5H12.5V18H11.5V19.5Z" fill="#C4C4C4" />
      <path
        d="M11.5 15.5V13.5H12.5V15.5M11.5 15.5H12.5M11.5 15.5V18M12.5 15.5V18M11.5 18V19.5H12.5V18M11.5 18H12.5"
        stroke="black"
        strokeWidth="0.5"
      />
    </>
  ),
};
