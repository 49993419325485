import React, { useState } from 'react';
import { useDataContext, useLoadoutStore } from 'hooks';

import {
  DecorationsContainer,
  SharpnessDisplay,
  DataTable,
  GearControlsAndTitle,
  GenericStatDetails,
  KinsectPicker,
} from 'components';
import {
  WeaponDetailsContainer,
  WeaponDetailsBody,
  StatsAndPropertiesContainer,
  WeaponPropertiesContainer,
  SongsContainer,
  CoatingIcon,
  CoatingContainer,
  ShotsContainer,
  ShotSpan,
  SongSpan,
  RampageAndDecorationsContainer,
  WeaponDecorationContainer,
  WeaponViewControls,
  WeaponViewButtonContainer,
  SubSectionText,
  StyledKinsectPicker,
} from './styled';
import { Container, Icon, Modal, Select, Text } from 'ui/core';

import { WeaponType } from 'models/enums/WeaponType';
import { Rarity } from 'models/enums/Rarity';
import {
  Weapon,
  isMeleeWeapon,
  isHuntingHorn,
  isRangedWeapon,
  isBowgun,
  isBow,
  Bow,
  isInsectGlaive,
} from 'models/weapons';
import shallow from 'zustand/shallow';
import { cloneDeep } from 'lodash';

interface WeaponDetailsProps {
  weapon: Weapon;
  onClickChangeWeapon: any;
  onClickCancelWeaponType: any;
  isMobile?: boolean;
}

export const WeaponDetails: React.FC<WeaponDetailsProps> = ({
  weapon,
  onClickChangeWeapon,
  onClickCancelWeaponType,
  isMobile = false,
}) => {
  const { getWeaponData } = useDataContext();

  const { weaponType } = weapon;
  const { appliedRampages } = weapon;

  const [openModal, setOpenModal] = useState(false);
  const [currentView, setCurrentView] = useState<'stats' | 'decorations'>(
    'stats'
  );
  const { assignSlot, loadout } = useLoadoutStore(
    (state) => ({ assignSlot: state.assignSlot, loadout: state.loadout }),
    shallow
  );
  const originalWeapon = (
    getWeaponData(weapon.weaponType as WeaponType) as any[]
  ).find((aWeapon: any) => aWeapon.name === weapon.name);

  const qurioWeaponConfig = loadout.QurioWeaponConfig;

  const createMeleeWeaponProperties = () => {
    return isMeleeWeapon(weapon) ? (
      <WeaponPropertiesContainer
        variant={isInsectGlaive(weapon) && !isMobile ? 'flexRow' : 'flexCol'}
        justifyContent="space-evenly"
        height="100%"
      >
        <SharpnessDisplay sharpness={weapon.baseSharpness} long={isMobile} />
        {isHuntingHorn(weapon) && (
          <SongsContainer>
            {weapon.songs.map((song) => (
              <SongSpan>{song.name}</SongSpan>
            ))}
          </SongsContainer>
        )}
        {isInsectGlaive(weapon) && <StyledKinsectPicker />}
      </WeaponPropertiesContainer>
    ) : null;
  };
  const createBowProperties = () => {
    const { coatings, shots } = weapon as Bow;
    const [arc, ...shotsWithoutArc] = shots;
    return (
      <>
        <ShotsContainer>
          {shotsWithoutArc.map((shot) => {
            return (
              <ShotSpan opacity={shot.isEnabled ? null : '40%'}>
                {shot.type} {shot.level}
              </ShotSpan>
            );
          })}
        </ShotsContainer>
        <CoatingContainer>
          {coatings.map((coating) => {
            return (
              <CoatingIcon
                coating={coating.type as any}
                isEnabled={coating.isEnabled}
              />
            );
          })}
        </CoatingContainer>
      </>
    );
  };

  // todo
  const createGunProperties = () => {
    const properties = isBowgun(weapon) ? weapon.properties : [];

    return (
      <Container variant="flexRow" width="100%" justifyContent="space-around">
        {properties.map((property: any) => {
          return (
            <Container variant="flexCol" alignItems="center">
              <Text fontWeight="bold">{property.property}</Text>{' '}
              <Text>{property.value}</Text>
            </Container>
          );
        })}
      </Container>
    );
  };

  const createRangedWeaponProperties = () => {
    return isRangedWeapon(weapon) ? (
      <WeaponPropertiesContainer
        variant="flexCol"
        justifyContent="space-evenly"
        height="100%"
        marginTop="xSmall"
      >
        {isBow(weapon) && createBowProperties()}
        {isBowgun(weapon) && createGunProperties()}
      </WeaponPropertiesContainer>
    ) : null;
  };

  const handleSelectRampage = (value: any) => {
    const newWeapon = { ...weapon };
    newWeapon.appliedRampages = newWeapon.appliedRampages.map((rampage) => {
      rampage.isApplied = false;
      return rampage;
    });

    const mutatedRampageIndex = newWeapon.appliedRampages.findIndex(
      (rampage: any) => rampage.id === value.id
    );
    newWeapon.appliedRampages[mutatedRampageIndex].isApplied = true;
    assignSlot('Weapon', newWeapon);
  };

  // const handleSelectRampageForRampage = (value: any) => {
  //   const newWeapon = { ...weapon };
  //   newWeapon.appliedRampages = newWeapon.appliedRampages.map((rampage) => {
  //     rampage.isApplied = false;
  //     return rampage;
  //   });

  //   const mutatedRampageIndex = newWeapon.appliedRampages.findIndex(
  //     (rampage: any) => rampage.id === value.id
  //   );
  //   newWeapon.appliedRampages[mutatedRampageIndex].isApplied = true;
  //   assignSlot('Weapon', newWeapon);
  // };

  const createRampageSkillPicker = () => {
    const appliedRampage = appliedRampages.find((rampage) => rampage.isApplied);
    const selectedRampage = appliedRampage
      ? appliedRampage
      : {
          id: 420,
          name: 'Ramp-Up',
        };

    return (
      <>
        <Select
          options={appliedRampages}
          selectedOption={selectedRampage}
          setSelectedOption={handleSelectRampage}
          marginX="xSmall"
          marginY="tiny"
          fontSize={['tiny', null, 'medium', null, null]}
        />
      </>
    );
  };

  const createRampageDecorationPicker = () => {
    // return <Container textAlign="center">coming soon!</Container>;
    return (
      <DecorationsContainer slot={'Weapon'} isRampage></DecorationsContainer>
    );
  };

  // for rampage weapons where u can pick 3
  // const createRampageWeaponRampageSkillPicker = () => {
  //   const defaultSelection = {
  //     id: 420,
  //     name: 'Ramp-Up',
  //   };

  //   const appliedRampageArray = appliedRampages.filter(
  //     (rampage) => rampage.isApplied
  //   );
  //   const selectedRampages = [] as any;

  //   for (let i = 0; i < 3; i++) {
  //     if (i > appliedRampageArray.length - 1) {
  //       selectedRampages.push(defaultSelection);
  //     } else {
  //       selectedRampages.push(appliedRampageArray[i]);
  //     }
  //   }

  //   return (
  //     <>
  //       {selectedRampages.map((selectedRampage: any) => {
  //         return (
  //           <Select
  //             options={appliedRampages}
  //             selectedOption={selectedRampage}
  //             setSelectedOption={handleSelectRampageForRampage}
  //           />
  //         );
  //       })}
  //     </>
  //   );
  // };

  const isRampageDecoration =
    weapon &&
    weapon.rampageDecorationSlots!.some((slot) => slot.slotValue !== 0);

  // use originalWeapon for qurio crafting, to make resetting easier
  // apply the weapon details at the top level component, and propogate down
  return (
    <WeaponDetailsContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
    >
      <GearControlsAndTitle
        backButtonHandler={onClickChangeWeapon}
        cancelButtonHandler={onClickCancelWeaponType}
        rarity={Rarity.DEFAULT}
        gear={originalWeapon}
        gearType={weaponType as WeaponType}
      />
      <WeaponDetailsBody>
        {isMobile ? (
          <>
            {currentView === 'stats' ? (
              <StatsAndPropertiesContainer
                initial={{ x: -30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <GenericStatDetails gear={weapon} />
                {createMeleeWeaponProperties()}
                {createRangedWeaponProperties()}
              </StatsAndPropertiesContainer>
            ) : (
              <RampageAndDecorationsContainer
                initial={{ x: 30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
              >
                <WeaponDecorationContainer>
                  <SubSectionText>
                    {weapon.isSunbreak && weapon.name.indexOf('Defender') === -1
                      ? 'Rampage Decoration'
                      : 'Rampage Skills'}
                  </SubSectionText>
                  {weapon.isSunbreak && weapon.name.indexOf('Defender') === -1
                    ? createRampageDecorationPicker()
                    : createRampageSkillPicker()}
                </WeaponDecorationContainer>
                <WeaponDecorationContainer>
                  <DecorationsContainer slot={'Weapon'}></DecorationsContainer>
                </WeaponDecorationContainer>
              </RampageAndDecorationsContainer>
            )}
            <WeaponViewControls>
              <WeaponViewButtonContainer
                disabled={currentView === 'stats'}
                onClick={() => setCurrentView('stats')}
              >
                <Icon name="Back"></Icon>
              </WeaponViewButtonContainer>
              <WeaponViewButtonContainer
                disabled={currentView === 'decorations'}
                marginLeft="medium"
                onClick={() => setCurrentView('decorations')}
              >
                <Icon name="Forward"></Icon>
              </WeaponViewButtonContainer>
            </WeaponViewControls>
          </>
        ) : (
          <>
            <StatsAndPropertiesContainer>
              <GenericStatDetails gear={weapon} />
              {createMeleeWeaponProperties()}
              {createRangedWeaponProperties()}
            </StatsAndPropertiesContainer>
            <RampageAndDecorationsContainer>
              <WeaponDecorationContainer>
                <SubSectionText>
                  {isRampageDecoration
                    ? 'Rampage Decoration'
                    : 'Rampage Skills'}
                </SubSectionText>
                {isRampageDecoration
                  ? createRampageDecorationPicker()
                  : createRampageSkillPicker()}
              </WeaponDecorationContainer>
              <WeaponDecorationContainer>
                <SubSectionText>Weapon Decorations</SubSectionText>
                <DecorationsContainer slot={'Weapon'}></DecorationsContainer>
              </WeaponDecorationContainer>
            </RampageAndDecorationsContainer>
          </>
        )}
      </WeaponDetailsBody>
    </WeaponDetailsContainer>
  );
};
