import React, { useState, useEffect } from 'react';
import { useDataContext } from 'hooks';
import { useAnimation } from 'framer-motion';
import { DataTable } from 'components';
import {
  StyledArmorSelectContainer,
  DataTableContainer,
  ArmorModalHack,
} from './styled';
import { Icon, Modal } from 'ui/core';

import { ArmorType } from 'models/enums';
import { Armor } from 'models';

interface ArmorSelectProps {
  armorType: ArmorType;
  onClickArmor: (armor: Armor) => void;
}

export const ArmorSelect: React.FC<ArmorSelectProps> = ({
  armorType,
  onClickArmor,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const { getArmorData } = useDataContext();
  const dropdownData = getArmorData(armorType);
  const animationControls = useAnimation();

  useEffect(() => {
    animationControls.start({ opacity: 1, y: 0 });
  });

  // move up, down, or to base
  const triggerHoverAnimation = (direction: 0 | 1 | -1) => {
    animationControls.start({ y: 10 * direction });
  };

  return (
    <>
      <StyledArmorSelectContainer
        onClick={() => setOpenModal(true)}
        onMouseEnter={() => triggerHoverAnimation(-1)}
        onMouseLeave={() => triggerHoverAnimation(0)}
        initial={{ opacity: 0, y: -20 }}
        animate={animationControls}
      >
        <Icon name={armorType} height={90} width={90} />
      </StyledArmorSelectContainer>
      {openModal && (
        <ArmorModalHack
          isOpen={true}
          setIsOpen={setOpenModal}
          modalTitle={armorType}
          modalDescription="Select an armor piece."
        >
          <DataTable
            tableData={dropdownData}
            slot={armorType}
            onClickRow={onClickArmor}
          ></DataTable>
        </ArmorModalHack>
      )}
    </>
  );
};
