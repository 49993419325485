import React from 'react';

export default {
  viewBox: '0 0 499 539',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M148 100h196v126h-15l-78 70-88-70h-15V100zm69 169l1 83h-24v84h23l1 71h62v-71h19V309h-19v-40l-29 27-34-27zm-42-33v94h-51l-76-75c2-117-1-205-16-223h162v68h-46v126h15l12 10zm175-136v126-126zm-27 136v94h52l75-75c-2-117 1-205 17-223H304v68h46v126h-15l-12 10z"
        />
      </g>
      <g id="lightColor">
        <path id="light" d="M196 141l110 1v51l-57 33-53-33v-52z" />
      </g>
      <g id="mediumColor">
        <path
          id="medium"
          d="M344 100v126-62M87 32h107v68h-46v126h15l12 10-12-10h-15V100h46V32H87zm336 0H304v68h40v126h-21v76m0 0c46-4 99-55 100-75 1-23-43-30-42-40 1-14 46-26 43-45-3-23-42-36-44-44-1-6 51-21 43-66m-229 68h-46v126h15l12 10v60c-33-1-91-45-92-65 0-9 7-18 15-24 13-9 33-14 29-24-3-4-36-16-44-43-5-18 43-30 44-40 2-7-33-21-41-40-4-8-5-17 1-28h107v68z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          d="M203 40V23H12l13 14c4 5 6 15 8 30 6 39 7 107 6 188v4l81 80h64v-85l24 19 1 70h-24v102h23l1 71h80v-71h19V300h-19l1-27 24-22v88h64l81-80v-4c-1-81 0-149 6-188 2-15 4-25 8-30l13-14H295v19l-44 38-48-40zm23 247l25 21 21-19-1 29h19v109h-19v71h-44l-1-71h-23v-66h24l-1-74zm106-52h21V91h-40V41h139l-5 23c-6 40-7 107-6 187l-70 70h-39v-86zM185 91h-46v144h21l7 5-1 81h-39l-70-70c1-80 0-147-6-187l-5-23h139v50zm140 126h10V109H157v108h9l18 14 24 19 15 12 28 22 74-67zm-12-84l-126-2v66l64 45 62-45v-64zm-108 17l90 1v37l-44 32-46-32v-38zm-2-59h33l-33-28v28zm63 0h29V65l-29 26z"
        />
        <path d="M221 98l9 3h5c4-1 6-3 7-5 1-4 1-7-1-10-1-3-5-5-6-6l-13-10-1-1c-1-3-4-5-8-6l-1-1c-4-4-7-4-11-2-1 1-3 2-4 5l-1 10v13l4 8c2 2 4 3 8 3l13-1zm63-26l-6 5-10 8c-5 6-1 11 1 12 0 1 4 5 11 2l2-2c1 2 4 2 6 2 6 0 9-2 11-4 2-1 3-4 3-6l1-9 1-8c0-3-1-5-3-7s-6-4-11-1l-3 3-3 5zm-12 11zm1-1h-1v1l1-1zm-57-1h-1 1v-1 1zm0-1zm-11-3c-2 0-4-1-5-3 1 2 3 3 5 3zM225 345v132h22v-8h33v-22h-33V345h-22z" />
        <path d="M194 402h42v-22l-42-1v23zM245 317v28h23v-28h-23z" />
        <path d="M267 336v28h23v-28h-23zM267 378v28h23v-28h-23z" />
        <path d="M247 401v28h23v-28h-23z" />
      </g>
    </>
  ),
};
