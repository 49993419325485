export const kinsectData = [
  {
    id: 0,
    name: 'Culldrone',
    rarity: 1,
    attackType: 'Severing',
    kinsectTypes: ['Normal'],
    power: 72,
    speed: 6,
    heal: 8,
    kinsectBonus: 'Bonus Heal',
  },
  {
    id: 1,
    name: 'Mauldrone',
    rarity: 1,
    attackType: 'Blunt',
    kinsectTypes: ['Normal'],
    power: 72,
    speed: 6,
    heal: 8,
    kinsectBonus: 'Bonus Heal',
  },
  {
    id: 2,
    name: 'Alucanid',
    rarity: 2,
    attackType: 'Severing',
    kinsectTypes: ['Assist'],
    power: 107,
    speed: 6,
    heal: 10,
    kinsectBonus: 'Dual Color (Defense)',
  },
  {
    id: 3,
    name: 'Foebeetle',
    rarity: 2,
    attackType: 'Blunt',
    kinsectTypes: ['Assist'],
    power: 107,
    speed: 6,
    heal: 10,
    kinsectBonus: 'Dual Color (Defense)',
  },
  {
    id: 4,
    name: 'Rigiprayne',
    rarity: 2,
    attackType: 'Severing',
    kinsectTypes: ['Powder', 'Paralysis'],
    power: 72,
    speed: 6,
    heal: 17,
    kinsectBonus: 'Stamina Use Slowed',
  },
  {
    id: 5,
    name: 'Ladytarge',
    rarity: 2,
    attackType: 'Blunt',
    kinsectTypes: ['Powder', 'Poison'],
    power: 72,
    speed: 6,
    heal: 17,
    kinsectBonus: 'Stamina Use Slowed',
  },
  {
    id: 6,
    name: 'Windchopper',
    rarity: 2,
    attackType: 'Severing',
    kinsectTypes: ['Speed'],
    power: 72,
    speed: 8,
    heal: 10,
    kinsectBonus: 'Triple Up Time',
  },
  {
    id: 7,
    name: 'Gullshad',
    rarity: 2,
    attackType: 'Blunt',
    kinsectTypes: ['Speed'],
    power: 72,
    speed: 8,
    heal: 10,
    kinsectBonus: 'Triple Up Time',
  },
  {
    id: 8,
    name: 'Monarch Alucanid',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Assist'],
    power: 125,
    speed: 6,
    heal: 8,
    kinsectBonus: 'Dual Color (Attack)',
  },
  {
    id: 9,
    name: 'Carnage Beetle',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Assist'],
    power: 125,
    speed: 6,
    heal: 8,
    kinsectBonus: 'Dual Color (Attack)',
  },
  {
    id: 10,
    name: 'Empresswing',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Assist'],
    power: 107,
    speed: 6,
    heal: 10,
    kinsectBonus: 'Dual Color (Speed)',
  },
  {
    id: 11,
    name: 'Bonnetfille',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Assist'],
    power: 107,
    speed: 6,
    heal: 10,
    kinsectBonus: 'Dual Color (Speed)',
  },
  {
    id: 12,
    name: 'Cancadaman',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Powder', 'Poison / Paralysis'],
    power: 90,
    speed: 7,
    heal: 12,
    kinsectBonus: 'Auto-attack Frequency Up',
  },
  {
    id: 13,
    name: 'Ladypavise',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Powder', 'Heal / Poison'],
    power: 90,
    speed: 7,
    heal: 12,
    kinsectBonus: 'Auto-attack Frequency Up',
  },
  {
    id: 14,
    name: 'Fiddlebrix',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Powder', 'Blast'],
    power: 72,
    speed: 6,
    heal: 20,
    kinsectBonus: 'Idle Stamina Recovery Up',
  },
  {
    id: 15,
    name: 'Arkmaiden',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Powder', 'Heal'],
    power: 72,
    speed: 6,
    heal: 20,
    kinsectBonus: 'Idle Stamina Recovery Up',
  },
  {
    id: 16,
    name: 'Grancathar',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Speed'],
    power: 90,
    speed: 9,
    heal: 8,
    kinsectBonus: 'Charged Chain Attack',
  },
  {
    id: 17,
    name: 'Bullshroud',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Speed'],
    power: 90,
    speed: 9,
    heal: 8,
    kinsectBonus: 'Charged Chain Attack',
  },
  {
    id: 18,
    name: 'Pseudocanth',
    rarity: 4,
    attackType: 'Severing',
    kinsectTypes: ['Speed'],
    power: 90,
    speed: 9,
    heal: 8,
    kinsectBonus: 'Fast Charge',
  },
  {
    id: 19,
    name: 'Whispervesp',
    rarity: 4,
    attackType: 'Blunt',
    kinsectTypes: ['Speed'],
    power: 90,
    speed: 9,
    heal: 8,
    kinsectBonus: 'Fast Charge',
  },
  // sunbreak
  {
    id: 20,
    name: 'Exalted Alucanid',
    rarity: 7,
    attackType: 'Severing',
    kinsectTypes: ['Assist'],
    power: 125,
    speed: 7,
    heal: 10,
    kinsectBonus: 'Boosted Powder Extract',
  },
  {
    id: 21,
    name: 'Gleambeetle',
    rarity: 7,
    attackType: 'Blunt',
    kinsectTypes: ['Assist'],
    power: 125,
    speed: 7,
    heal: 10,
    kinsectBonus: 'Boosted Powder Extract',
  },
  {
    id: 22,
    name: 'Bilbobrix',
    rarity: 7,
    attackType: 'Severing',
    kinsectTypes: ['Powder', 'Blast / Heal'],
    power: 90,
    speed: 8,
    heal: 17,
    kinsectBonus: 'Powder Vortex',
  },
  {
    id: 23,
    name: 'Ladytower',
    rarity: 7,
    attackType: 'Blunt',
    kinsectTypes: ['Powder', 'Heal / Paralysis'],
    power: 90,
    speed: 8,
    heal: 17,
    kinsectBonus: 'Powder Vortex',
  },
  {
    id: 24,
    name: 'Foliacath',
    rarity: 7,
    attackType: 'Severing',
    kinsectTypes: ['Speed'],
    power: 107,
    speed: 12,
    heal: 17,
    kinsectBonus: 'Kinsect Charge',
  },
  {
    id: 25,
    name: 'Fleetflammer',
    rarity: 7,
    attackType: 'Blunt',
    kinsectTypes: ['Speed'],
    power: 107,
    speed: 12,
    heal: 17,
    kinsectBonus: 'Kinsect Charge',
  },
];
