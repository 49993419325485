import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Button, Text } from 'ui/core';

export const GearGeneratorContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  })}
`;

export const GenerateButton = styled(Button)``;

export const GenerateContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  })}
`;

export const HelperText = styled(Text)`
  ${css({
    fontWeight: 'bold',
  })}
`;

export const GeneratedResultContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  })}
`;

// export const GeneratedSetContainer = styled(Container)`
//   ${css({
//     display: 'flex',
//     flexDirection: 'column',
//     border: 'black',
//     margin: 'tiny',
//     padding: 'tiny',
//     alignItems: 'flex-end',
//     ':hover': {
//       boxShadow: 'black',
//     },
//     transition: 'all 0.2s ease-in',
//   })}
// `;

export const GeneratedContainer = styled(Container)`
  ${css({
    marginTop: 'small',
  })}
`;

export const InputContainer = styled(Container)`
  ${css({})}
`;

// start of new styles.
// [armor_pool    | required_skills]
// [armor_pool    | required_skills]
// [preferences   | required_skills]
// [preferences   | required_skills]
// [armor_lockins | armor_lockins]
// [armor_lockins | armor_lockins]
// [.             | generate   ]
export const GeneratorInputGrid = styled(Container)`
  ${css({
    display: ['flex', null, null, 'grid', null],
    flexDirection: ['column', null, null, null, null],
    gridTemplateColumns: 'repeat(2,1fr)',
    gridTemplateRows: 'repeat(8, 1fr)',
    gap: '1rem',
    gridTemplateAreas:
      '"armor_pool required_skills" "armor_pool required_skills" "preferences required_skills" "preferences required_skills" "armor_lockins armor_lockins" "armor_lockins armor_lockins" "armor_lockins armor_lockins" ". generate"',
  })}
`;

export const GeneratorResultsGrid = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    gap: 'medium',
  })}
`;

export const SectionText = styled(Text)`
  ${css({
    textAlign: 'center',
    color: 'secondaryText',
    fontWeight: 'bold',
    fontSize: 'small',
    textTransform: 'uppercase',
  })}
`;

export const GriddedSectionWrapper = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
  })}
`;

export const SectionContentContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    padding: 'small',
    borderRadius: 'medium',
    backgroundColor: 'detailsBackground',
    border: '1px solid',
    borderColor: 'border',
    flex: 1,
  })}
`;

export const GeneratedSetContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    backgroundColor: 'detailsBackground',
    padding: 'xSmall',
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'detailsBorder',
  })}
`;

// remember for later; parent is grid. each grid item should be a flex item. then the content should flex 1.
