import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M6 20V4H13L21 12L13 20H6Z" stroke="black" strokeWidth="1.5" />
    </>
  ),
};
