import React from 'react';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';

import { SkillSelect } from './components';

import _ from 'lodash';

interface SkillPickerProps {}

export const SkillPicker: React.FC<SkillPickerProps> = () => {
  const { loadout, assignSkills } = useLoadoutStore(
    (state) => ({ loadout: state.loadout, assignSkills: state.assignSkills }),
    shallow
  );
  const { Talisman } = loadout;
  const { appliedSkills } = Talisman;

  const setCorrectSkill = (index: number, appliedSkill: any) => {
    const newAppliedSkills = [...appliedSkills];

    newAppliedSkills[index] = appliedSkill;

    assignSkills(newAppliedSkills);
  };

  const setCorrectSkillLevel = (index: number, appliedSkillLevel: any) => {
    const newAppliedSkills = [...appliedSkills];
    newAppliedSkills[index].appliedSkillLevel = appliedSkillLevel.index;

    assignSkills(newAppliedSkills);
  };

  return (
    <>
      {appliedSkills.map((appliedSkill: any, index: number) => {
        const setCorrectSkillPartial = _.partial(setCorrectSkill, index);
        const setCorrectSkillLevelPartial = _.partial(
          setCorrectSkillLevel,
          index
        );
        const appliedSkillLevel = appliedSkill
          ? appliedSkill.skillLevels[appliedSkill.appliedSkillLevel]
          : null;
        return (
          <SkillSelect
            selectedSkill={appliedSkill}
            setSelectedSkill={setCorrectSkillPartial}
            selectedSkillLevel={appliedSkillLevel}
            setSelectedSkillLevel={setCorrectSkillLevelPartial}
          ></SkillSelect>
        );
      })}
    </>
  );
};
