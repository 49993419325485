import styled from 'styled-components';
import {
  compose,
  space,
  color,
  typography,
  layout,
  flexbox,
  background,
  border,
  position,
  shadow,
  grid,
  SpaceProps,
  ColorProps,
  TypographyProps,
  LayoutProps,
  FlexboxProps,
  BackgroundProps,
  BorderProps,
  PositionProps,
  ShadowProps,
  GridProps,
} from 'styled-system';

type SpanProps = ColorProps &
  SpaceProps &
  LayoutProps &
  TypographyProps &
  LayoutProps &
  FlexboxProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  GridProps;

export const Span = styled.span<SpanProps>`
  ${compose(
    color,
    layout,
    typography,
    space,
    flexbox,
    background,
    border,
    position,
    shadow,
    grid
  )}
`;
