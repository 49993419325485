import {
  DecorationSlotsCell,
  decorationSlotsAccessor,
  WeaponStatsCell,
  weaponStatsAccessor,
  BaseSharpnessCell,
  baseSharpnessAccessor,
  PhialCell,
  phialAccessor,
  SongsCell,
  songsAccessor,
  ShellingCell,
  shellingAccessor,
  CoatingsCell,
  coatingsAccessor,
  ShotsCell,
  shotsAccessor,
  ActiveSkillsCell,
  ArcShotCell,
  GunPropertiesCell,
  gunPropertiesAccessor,
} from './components';
import { NameFilter } from './components/filters';

import { matchSorter } from 'match-sorter';

const baseWeaponColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'NAME',
    width: 110,
  },
  {
    id: 'decorationSlots',
    accessor: decorationSlotsAccessor,
    Header: 'SLOTS',
    Cell: DecorationSlotsCell,
    width: 40,
  },
  {
    id: 'weaponStats',
    accessor: weaponStatsAccessor,
    Header: 'OFFENSES',
    Cell: WeaponStatsCell,
    width: 70,
  },
];

const baseMobileColumns = [
  {
    id: 'name',
    accessor: 'name',
    Header: 'NAME',
    width: 110,
  },
  {
    id: 'weaponStats',
    accessor: weaponStatsAccessor,
    Header: 'OFFENSES',
    Cell: WeaponStatsCell,
    width: 70,
  },
];

export const defaultColumn = {
  Filter: () => <></>,
};

export const initialFilterState = [
  {
    id: 'decorationSlots',
    value: [],
  },
];

// should probably create smaller functions per weapon
// no real reason but in the real world, makes for easier testing
const getWeaponSpecificConfigs = (weaponType: any) => {
  const meleeWeapons = [
    'ChargeBlade',
    'DualBlades',
    'GreatSword',
    'Gunlance',
    'Hammer',
    'HuntingHorn',
    'InsectGlaive',
    'Lance',
    'LongSword',
    'SwitchAxe',
    'SwordAndShield',
  ] as const;

  const initialGlobalFilterState = [
    {
      id: 'name',
      value: '',
    },
  ];

  const additionalColumns = [] as any;

  const globalFilterResolverKeys = [(row: any) => row.original.name];

  if (meleeWeapons.includes(weaponType)) {
    additionalColumns.push({
      id: 'baseSharpness',
      accessor: baseSharpnessAccessor,
      Header: 'SHARPNESS',
      Cell: BaseSharpnessCell,
      width: 75,
    });

    if (weaponType === 'ChargeBlade' || weaponType === 'SwitchAxe') {
      additionalColumns.push({
        id: 'phial',
        accessor: phialAccessor,
        Header: 'PHIAL',
        Cell: PhialCell,
        width: 70,
      });
      initialGlobalFilterState.push({
        id: 'phial',
        value: '',
      });
      globalFilterResolverKeys.push((row: any) => row.original.phial.type);
    } else if (weaponType === 'HuntingHorn') {
      additionalColumns.push({
        id: 'songs',
        accessor: songsAccessor,
        Header: 'SONGS',
        Cell: SongsCell,
      });
      initialGlobalFilterState.push({
        id: 'songs',
        value: '',
      });
      globalFilterResolverKeys.push((row: any) =>
        row.original.songs.map((song: any) => song.name)
      );
    } else if (weaponType === 'Gunlance') {
      additionalColumns.push({
        id: 'shelling',
        accessor: shellingAccessor,
        Header: 'SHELLING',
        Cell: ShellingCell,
        width: 70,
      });
      initialGlobalFilterState.push({
        id: 'shelling',
        value: '',
      });
      globalFilterResolverKeys.push((row: any) => row.original.shelling.type);
    } else if (weaponType === 'InsectGlaive') {
      // console.log('triggered');
      additionalColumns.push({
        id: 'kinsectLevel',
        accessor: (originalRow: any) => {
          return originalRow.kinsectLevel;
        },
        Header: 'KINSECT',
        width: 35,
      });
    }
  } else {
    if (weaponType === 'Bow') {
      additionalColumns.push(
        {
          id: 'coatings',
          accessor: coatingsAccessor,
          Header: 'COATINGS',
          Cell: CoatingsCell,
          width: 100,
        },
        {
          id: 'shots',
          accessor: shotsAccessor,
          Header: 'SHOTS',
          Cell: ShotsCell,
          width: 120,
        },
        {
          id: 'arc',
          accessor: 'shots[0].type',
          Header: 'ARC',
          width: 60,
          Cell: ArcShotCell,
        }
      );
      initialGlobalFilterState.push({
        id: 'shots',
        value: '',
      });
      initialGlobalFilterState.push({
        id: 'arc',
        value: '',
      });
      globalFilterResolverKeys.push((row: any) =>
        row.original.shots.map((shot: any) => shot.type)
      );
      globalFilterResolverKeys.push((row: any) =>
        row.original.coatings.map((coating: any) => coating.type)
      );
    } else if (weaponType.indexOf('gun') !== -1) {
      additionalColumns.push({
        id: 'properties',
        accessor: gunPropertiesAccessor,
        Header: 'PROPERTIES',
        width: 110,
        Cell: GunPropertiesCell,
      });
      initialGlobalFilterState.push({
        id: 'properties',
        value: '',
      });
      globalFilterResolverKeys.push((row: any) =>
        row.original.properties.map((property: any) => property.value)
      );
    }
  }

  const getFilterComponents = (
    setFilter: any,
    setAllFilters: any,
    filterValues: any
  ) => {
    return <></>;
  };

  const globalFilterResolver = (
    rows: any,
    columnIds: any,
    globalFilterValue: any
  ) => {
    const nameMatches = matchSorter(rows, globalFilterValue, {
      keys: globalFilterResolverKeys,
    });
    return globalFilterValue === '' ? rows : nameMatches;
  };

  return {
    additionalColumns,
    initialGlobalFilterState,
    globalFilterResolver,
  };
};

const getGlobalFilterComponent = (setFilter: any) => {
  return <NameFilter setFilter={setFilter}></NameFilter>;
};

export const getWeaponProperties = (weaponType: any) => ({
  getColumns: () => [
    ...baseWeaponColumns,
    ...getWeaponSpecificConfigs(weaponType).additionalColumns,
  ],
  getMobileColumns: () => [...baseMobileColumns],
  getOptions: () => ({
    defaultColumn,
    initialFilterState,
    ...getWeaponSpecificConfigs(weaponType).initialGlobalFilterState,
    globalFilterResolver:
      getWeaponSpecificConfigs(weaponType).globalFilterResolver,
  }),
  getFilterComponents: (
    setFilter: any,
    setAllFilters: any,
    filterValues: any
  ) => <></>,
  getGlobalFilterComponent: (setFilter: any) =>
    getGlobalFilterComponent(setFilter),
});
