import React from 'react';

export default {
  viewBox: '0 0 521 521',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M16 434l77-2c56-12 140-92 235-223l68 55c-71 87-181 201-222 223-34 18-81 21-109 14-46-17-49-67-49-67zm280-251l134 91 31-45-128-93-37 47zm57-32l83-106 69 48-78 110M240 96l1 45c4-5 45-7 125-6l36-46c-62 0-117 2-162 7zm1 45c-4 10-12 18-21 24l-154 5c-7-5-10-12-10-22 10-11 21-22 36-29-9-4-18-10-31-25-3-10-3-20-1-31 13-2 29-2 49 1-7-5-9-17-4-46 13-3 25-2 34 5 37 32 54 51 53 58h33c4 1 9 6 15 15l1 45z"
        />
      </g>
      <g id="lightColor">
        <path
          id="light"
          d="M16 434l77-2c56-12 140-92 235-223l30 24c-2 2-176 235-269 235-51 7-73-34-73-34"
        />
      </g>
      <g id="mediumColor">
        <path
          id="medium"
          d="M296 183l134 91 31-45-128-93-37 47zm57-32l83-106 69 48-78 110M240 96l1 45c4-5 45-7 125-6l36-46c-62 0-117 2-162 7zm1 45c-4 10-12 18-21 24-53 5-131 17-155 5-7-3-10-12-10-21 0-10 22-23 37-30-9-4-18-10-31-25-3-10-3-20-1-31 13-2 29-2 49 1-7-5-9-17-4-46 13-3 25-2 34 5 37 32 54 51 53 58h33c4 1 9 6 15 15l1 45zm138-23l70 54m-40-92l70 50m-9-61l-26 36M186 69c-6 38-7 70-5 97m-1-40c-29 1-58 0-88-7m17-55c70 26 72 36 72 40"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          d="M16 434l77-2c56-12 140-92 235-223l68 55c-71 87-181 201-222 223-34 18-81 21-109 14-46-17-49-67-49-67zm280-251l134 91 31-45-128-93-37 47zm57-32l83-106 69 48-78 110M240 96l1 45c4-5 45-7 125-6l36-46c-62 0-117 2-162 7zm1 45c-4 10-12 18-21 24-53 5-131 17-155 5-7-3-10-12-10-21 0-10 22-23 37-30-9-4-18-10-31-25-3-10-3-20-1-31 13-2 29-2 49 1-7-5-9-17-4-46 13-3 25-2 34 5 37 32 54 51 53 58h33c4 1 9 6 15 15l1 45zm138-23l70 54m-40-92l70 50m-9-61l-26 36M186 69c-6 38-7 70-5 97m-1-40c-29 1-58 0-88-7m17-55c70 26 72 36 72 40"
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
        />
      </g>
    </>
  ),
};
