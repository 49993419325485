import create from 'zustand';
import { cloneDeep } from 'lodash';

import {
  GeneratorState,
  ArmorRarityConfig,
  ArmorConfig,
  ArmorRank,
} from 'models/generator';

// for use with gear generator.
// state needs to hold:
// 1. applied skills
// 2. armor config

const armorRaritySelection = {
  low: [
    { rarity: 'rarity1', selected: true, name: 'Rarity 1' },
    { rarity: 'rarity2', selected: true, name: 'Rarity 2' },
    { rarity: 'rarity3', selected: true, name: 'Rarity 3' },
  ] as ArmorRarityConfig[],
  high: [
    { rarity: 'rarity4', selected: true, name: 'Rarity 4' },
    { rarity: 'rarity5', selected: true, name: 'Rarity 5' },
    { rarity: 'rarity6', selected: true, name: 'Rarity 6' },
    { rarity: 'rarity7', selected: true, name: 'Rarity 7' },
  ] as ArmorRarityConfig[],
  master: [
    { rarity: 'rarity8', selected: true, name: 'Rarity 8' },
    { rarity: 'rarity9', selected: true, name: 'Rarity 9' },
    { rarity: 'rarity10', selected: true, name: 'Rarity 10' },
  ] as ArmorRarityConfig[],
} as const;

const initialGeneratorState: GeneratorState = {
  requiredSkills: [null],
  armorGearConfig: {
    armorRank: 'master',
    armorRarityConfigs: armorRaritySelection['master'],
  },
};

interface State {
  generatorState: GeneratorState;
}

export const useGeneratorStore = create((set: any) => {
  return {
    generatorState: initialGeneratorState,
    setArmorGearRank: (rank: ArmorRank) => {
      set((state: State) => {
        const { generatorState } = state;
        const clonedGeneratorState = cloneDeep(generatorState);
        const newArmorRarityConfigs = armorRaritySelection[rank];

        return {
          ...state,
          generatorState: {
            ...clonedGeneratorState,
            armorGearConfig: {
              armorRank: rank,
              armorRarityConfigs: newArmorRarityConfigs,
            },
          },
        };
      });
    },
    setArmorGearRarityConfig: (index: number) => {
      set((state: State) => {
        const { generatorState } = state;
        const clonedGeneratorState = cloneDeep(generatorState);
        const { armorGearConfig } = clonedGeneratorState;
        const { armorRarityConfigs } = armorGearConfig;

        const currentValue = armorRarityConfigs[index].selected;
        armorRarityConfigs[index].selected = !currentValue;

        return {
          ...state,
          generatorState: {
            ...clonedGeneratorState,
            armorGearConfig: {
              ...armorGearConfig,
              armorRarityConfigs,
            },
          },
        };
      });
    },
    setRequiredSkills: (requiredSkills: any[]) => {
      set((state: State) => {
        const { generatorState } = state;
        const clonedGeneratorState = cloneDeep(generatorState);

        return {
          ...state,
          generatorState: {
            ...clonedGeneratorState,
            requiredSkills,
          },
        };
      });
    },
  };
});
