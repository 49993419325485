import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon, Button, Modal } from 'ui/core';

export const SelectionContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  })}
`;

export const DataTableContainer = styled(Container)`
  ${css({
    margin: 'medium',
  })}
`;

export const CancelIcon = styled(Icon)``;

export const CancelTypeButton = styled(Button).attrs({
  variant: 'circle',
})`
  ${css({
    position: 'absolute',
    top: 'small',
    right: 'small',
    cursor: 'pointer',
  })}
`;

export const WeaponTypeIconContainer = styled(Container)``;

export const WeaponModalHack = styled(Modal)`
  ${css({
    width: '100%',
  })}
`;
