import React from 'react';

export default {
  viewBox: '0 0 534 514',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M89.365,401.044l70.88,-0l0,96.7l-70.88,-0l-0,-96.7Zm-73.215,-59.596l-0,59.596l197.813,-0l-0,-59.596l-197.813,-0Zm73.215,-0l70.88,-0l0,-325.298l-26.649,-0l-44.231,42.488l-0,282.81Zm70.88,-0l0,59.596m235.002,15.667c-36.444,-0 -64.259,-14.628 -83.912,-33.466c-20.224,-19.385 -31.806,-44.302 -35.258,-73.932c-0.634,-5.444 -0.994,-11.046 -1.083,-16.803c0.144,-37.42 14.681,-66.071 36.341,-88.147c20.228,-20.616 46.253,-35.911 83.912,-35.911c38.739,0 65.549,13.714 85.812,35.911c20.528,22.488 34.268,53.684 36.072,88.147c-0,35.798 -13.88,68.066 -36.072,90.735c-20.32,20.757 -47.174,33.466 -85.812,33.466Zm-110.779,-114.031l12.984,-0l-0,-13.947l-12.984,0l0,13.947Zm103.889,-120.971l-0,17.932l15.092,0l-0,-17.932l-15.092,0Zm-97.404,110.801l-0.326,6.306m202.479,-10.083l16.064,0l0,13.947l-16.064,-0l-0,-13.947Zm8.811,10.083l-1.273,-6.306m-112.287,119.18l-0,-24.598l15.092,0l-0,24.598l-15.092,-0Z"
        />
      </g>
      <g id="mediumColor">
        <path id="medium" d="M89 401h71v97H89v-97zm71 0H89h71zm-71 52h71" />
      </g>
      <g id="darkColor">
        <path
          id="dark"
          stroke="#000"
          strokeWidth="2.2"
          d="M341 241h108v110H341z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
          d="M89 401h71v97H89v-97zm-73-60v60h198v-60H16zm73 0h71V16h-26L89 59v282zm71 0v60m235 16a119 119 0 01-120-124c0-38 15-67 36-89 21-20 47-36 84-36 39 0 66 14 86 36 21 23 34 54 36 89 0 35-14 68-36 90-20 21-47 34-86 34zm-55-165l11-11h87l11 11v88l-11 10h-87l-11-10v-88zm-56 51h13v-14h-13v14zm104-121v18h15v-18h-15zm-97 111v6m202-10h16v14h-16v-14zm9 10l-1-6M388 412v-25h15v25h-15zM89 453h71"
        />
      </g>
    </>
  ),
};
