import { ShotNamePill, ShotsContainer, ArcShotText } from './styled';

export const shotsAccessor = (originalRow: any) => {
  const [arc, ...rest] = originalRow.shots;
  return rest.map((shot: any) => ({
    name: `${shot.type} ${shot.level}`,
    isEnabled: shot.isEnabled,
    desc: '',
  }));
};
// onMouseEnter={() => console.log(shot.desc)}
export const ShotsCell = ({ value }: { value: any }) => {
  return (
    <ShotsContainer>
      {value.map((shot: any) => {
        const { isEnabled } = shot;
        return <ShotNamePill isEnabled={isEnabled}>{shot.name}</ShotNamePill>;
      })}
    </ShotsContainer>
  );
};

export const ArcShotCell = ({ value }: { value: any }) => {
  return <ArcShotText>{value}</ArcShotText>;
};
