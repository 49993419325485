import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const SummarySkillsCardContainer = styled(Container)`
  ${css({
    // display: [null, 'none', null, null, 'flex'],
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: 'cardBorder',
    borderRadius: 'medium',
    backgroundColor: 'cardBackground',
    minHeight: ['100%', null, 'initial', null, null],
    padding: 'small',
  })}
`;

export const CardTitleContainer = styled(Container)`
  ${css({
    width: '100%',
  })}
`;

export const CardTitleText = styled(Text)`
  ${css({
    fontWeight: 'bold',
    fontSize: 'xSmall',
    color: 'secondaryText',
    letterSpacing: '1px',
  })}
`;
