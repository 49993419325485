import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M8.5 13L18 3V8.5L11 15.5V18H9L5.5 21.5L2.5 18.5L6 15V13H8.5Z"
        fill="white"
      />
      <path
        d="M8.5 13L18 3V8.5L11 15.5M8.5 13L11 15.5M8.5 13H6V15M11 15.5V18H9M6 15L9 18M6 15L2.5 18.5L5.5 21.5L9 18"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M9.5 11L7 5L13 7.5L9.5 11Z" fill="#C4C4C4" />
      <path
        d="M18 6L22 4.5L17.5 12.5L21 19L14 15.5L11 18V15.5L18 8.5V6Z"
        fill="#C4C4C4"
      />
      <path d="M9.5 11L7 5L13 7.5L9.5 11Z" stroke="black" />
      <path
        d="M18 6L22 4.5L17.5 12.5L21 19L14 15.5L11 18V15.5L18 8.5V6Z"
        stroke="black"
      />
    </>
  ),
};
