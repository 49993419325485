import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text, Button } from 'ui/core';

export const ArmorDetailsContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    width: '100%',
    height: '100%',
    padding: 'xSmall',
  })}
`;

export const ArmorBodyContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    width: '100%',
    height: '100%',
    paddingBottom: 'xSmall',
    paddingTop: 'medium',
    flexDirection: ['column', null, 'row', null, null],
  })}
`;

export const StatsAndSkillsContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    height: '100%',
    width: ['100%', null, '50%', null, null],
    textAlign: 'center',
    marginRight: 'tiny',
  })}
`;

// possible design choice
// inset 6px 0px 5px -7px #e7e8e9
export const ArmorDecorationContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    height: '100%',
    width: ['100%', null, '50%', null, null],
    border: '1px solid',
    borderColor: 'border',
    justifyContent: 'center',
    borderRadius: 'medium',
    backgroundColor: 'detailsBackground',
  })}
`;

export const SimpleSkillsContainer = styled(Container)`
  ${css({
    display: ['grid', null, 'flex', null, null],
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    flexDirection: 'column',
    textAlign: [null, null, 'left', null, null],
    paddingX: 'tiny',
    marginTop: ['medium', null, 'xSmall', null, null],
    height: [null, null, '100%', null, null],
    border: [null, null, '1px solid', null, null],
    borderColor: [null, null, 'detailsBorder', null, null],
    borderRadius: 'medium',
    justifyContent: 'center',
    backgroundColor: 'detailsBackground',
  })}
`;

export const SkillDetail = styled(Text)`
  ${css({
    fontSize: 'xSmall',
  })}
`;

export const ArmorViewControls = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    margin: 'auto',
    marginTop: 'medium',
  })}
`;

export const ArmorViewButtonContainer = styled(Button).attrs({
  variant: 'circle',
})`
  ${css({})}
`;
