import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';
import { CheckBox } from 'components';

export const SetPickerContainer = styled(Container)`
  ${css({
    padding: 'medium',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  })}
`;

export const RarityContainer = styled(Container)`
  ${css({ marginY: 'small' })}
`;

export const RarityText = styled(Text)`
  ${css({
    fontSize: 'small',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    color: 'secondaryText',
  })}
`;

export const SetCardContainer = styled(Container)`
  ${css({
    display: ['flex', null, 'grid', null, null],
    flexDirection: 'column',

    gridTemplateColumns: 'repeat(4,1fr)',
    gridTemplateRows: 'repeat(auto-fill, 1fr)',
    gap: 'small',
    textAlign: 'center',
    margin: 'tiny',
  })}
`;

export const SearchInput = styled.input`
  ${css({
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'border',
    background: 'white',
    padding: 'small',
    fontSize: 'medium',
    width: ['100%', null, '50%', null, null],
    backgroundColor: 'detailsBackground',
    '::placeholder': {
      color: 'text',
      opacity: 0.5,
    },
    color: 'text',
  })}
`;

export const ResultsContainer = styled(Container)`
  ${css({
    overflow: 'auto',
    marginTop: 'xSmall',
  })}
`;

export const ResultForm = styled.form`
  ${css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    marginX: 'auto',
  })}
`;

export const SetContainer = styled(Container)`
  ${css({
    padding: 'small',
    border: '1px solid',
    borderRadius: 'medium',
    borderColor: 'border',
    height: '100%',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'primary',
    },
  })}
`;

export const HRCheckbox = styled(CheckBox)`
  ${css({
    paddingX: 'small',
    marginRight: 'small',
    backgroundColor: 'detailsBackground',
  })}
`;
