import React, { useState } from 'react';
import { useDataContext, useLoadoutStore } from 'hooks';

import {
  SetPickerContainer,
  RarityContainer,
  RarityText,
  SetCardContainer,
  SearchInput,
  ResultsContainer,
  ResultForm,
  SetContainer,
  HRCheckbox,
} from './styled';
import { CheckBox } from 'components';

import { lowRankSets, highRankSets, masterRankSets } from './constants';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';

interface SetPickerProps {
  toggleModal: (toggle: boolean) => void;
}

export const SetPicker: React.FC<SetPickerProps> = ({ toggleModal }) => {
  const { getArmorByRarity } = useDataContext();
  const { assignLoadout } = useLoadoutStore();
  const [formData, setFormData] = useState<string>('');
  const [hrOnly, setHrOnly] = useState(false);
  const [masterOnly, setMasterOnly] = useState(false);

  const originalData = hrOnly
    ? highRankSets
    : masterOnly
    ? masterRankSets
    : [...lowRankSets, ...highRankSets, ...masterRankSets];

  const handleOnClickSet = (rarity: number, armorIds: number[]) => {
    const armorByRarity = getArmorByRarity(`rarity${rarity}` as any);
    const newLoadout = {} as any;

    armorByRarity
      .filter((armor) => armorIds.includes(armor.id))
      .forEach((armor) => {
        newLoadout[armor.armorType] = armor;
      });
    assignLoadout(newLoadout, true);
    toggleModal(false);
  };

  const createSetTiles = () => {
    // create a copy so data is not lost
    const mutableData = _.cloneDeep(originalData);
    const filteredData =
      formData !== ''
        ? mutableData.filter((setArmorData) => {
            const sets = setArmorData.sets;
            const results = matchSorter(sets, formData, {
              keys: ['*.name', '*.monster'],
            });
            setArmorData['sets'] = results;
            return results.length > 0;
          })
        : mutableData;

    return filteredData.map((chunk: any) => {
      const { rarity, sets } = chunk;

      return (
        <RarityContainer>
          <RarityText>Rarity {rarity}</RarityText>
          <SetCardContainer>
            {sets.map((set: any) => {
              const { name, armorIds } = set;
              return (
                <SetContainer
                  onClick={() =>
                    handleOnClickSet(rarity, armorIds as any as number[])
                  }
                >
                  {name}
                </SetContainer>
              );
            })}
          </SetCardContainer>
        </RarityContainer>
      );
    });
  };

  return (
    <SetPickerContainer>
      <ResultForm>
        <HRCheckbox
          isChecked={hrOnly}
          setIsChecked={(isChecked: boolean) => setHrOnly(isChecked)}
          label="HR"
        ></HRCheckbox>
        <HRCheckbox
          isChecked={masterOnly}
          setIsChecked={(isChecked: boolean) => setMasterOnly(isChecked)}
          label="MR"
        ></HRCheckbox>
        <SearchInput
          value={formData}
          onChange={(e) => {
            e.preventDefault();
            setFormData(e.target.value);
          }}
          placeholder="Search set or monster"
        ></SearchInput>
      </ResultForm>
      <ResultsContainer>{createSetTiles()}</ResultsContainer>
    </SetPickerContainer>
  );
};
