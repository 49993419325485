import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M20.0001 4H4.00011C4.00011 4 3.00011 12 4.00011 15C5.00011 18 9.50011 22 12.0001 22C14.5001 22 19 18 20.0001 15C21.0002 12 20.0001 4 20.0001 4Z"
        fill="#f3e9b7"
        stroke="black"
        strokeWidth="2"
      />
      <path d="M12 8V16M8 12H16" stroke="black" strokeWidth="2" />
    </>
  ),
};
