import React, { useEffect, useState } from 'react';
import {
  useDataContext,
  useLoadoutStore,
  useIsMobile,
  useGeneratorStore,
} from 'hooks';
import styled from 'styled-components';
import css from '@styled-system/css';

import {
  GenerateButton,
  GenerateContainer,
  HelperText,
  GeneratedResultContainer,
  GeneratedSetContainer,
  GeneratedContainer,
  InputContainer,
  GeneratorInputGrid,
  SectionText,
  GriddedSectionWrapper,
  SectionContentContainer,
  GeneratorResultsGrid,
} from './styled';
import {
  SimpleGearCard,
  SimpleSummary,
  ArmorPoolSelect,
  SkillRequirementSelect,
} from './components';
import { HorizontalRule, Text, Container, Button, Span, Icon } from 'ui/core';

import { sumArmorDefense } from 'utils/stats';
import { calculateWeights, generateLoadouts } from './utils';
import { ArmorTypeValues } from 'models/enums/ArmorType';
import { Loadout } from 'models/state';
import { AppliedSkill, Armor, Skill } from 'models';
import { Weapon } from 'models/weapons';
import { partial, flatten, cloneDeep } from 'lodash';
import {
  ArmorRarity,
  ArmorRank,
  ArmorRarityConfig,
  ArmorConfig,
  ArmorByRarity,
  ArmorType,
} from './types';

const StyledIcon = styled(Icon)`
  ${css({
    '& path': {
      stroke: 'text',
    },
  })}
`;

interface GearGeneratorProps {
  toggleModal?: (val: boolean) => void;
}
export const GearGenerator: React.FC<GearGeneratorProps> = ({
  toggleModal,
}) => {
  const isMobile = useIsMobile();

  // TODO: incorporate localStorage.
  // const [requiredEmptyDecorations, setRequiredEmptyDecorations] = useState<
  //   any[]
  // >([0, 0, 0, 0]);
  const [generatedResults, setGeneratedResults] = useState<any[]>([]);
  const [performance, setPerformance] = useState<any>();
  const [noneFound, setNoneFound] = useState<any>(null);

  const assignLoadout = useLoadoutStore((state) => state.assignLoadout);

  const {
    generatorState,
    setArmorGearRank,
    setArmorGearRarityConfig,
    setRequiredSkills,
  } = useGeneratorStore(
    ({
      generatorState,
      setArmorGearRank,
      setArmorGearRarityConfig,
      setRequiredSkills,
    }) => ({
      generatorState,
      setArmorGearRank,
      setArmorGearRarityConfig,
      setRequiredSkills,
    })
  );

  const { armorGearConfig, requiredSkills } = generatorState;

  const { getAllDecorationData, getArmorsFromConfig } = useDataContext();

  useEffect(() => {
    setGeneratedResults([]);
  }, [requiredSkills]);

  const getArmors = (): ArmorByRarity => {
    const { armorRarityConfigs } = armorGearConfig;
    const armorData = getArmorsFromConfig(armorRarityConfigs);

    const armorByTypeAcc = {
      Head: [] as Armor[],
      Chest: [] as Armor[],
      Gloves: [] as Armor[],
      Waist: [] as Armor[],
      Feet: [] as Armor[],
    };

    // TODO: consider "locked in gear" when splitting the buckets? or just grab all of them regardless of that config.
    const armorByType = flatten(armorData).reduce((acc, currentArmor) => {
      const { armorType } = currentArmor;

      acc[armorType as ArmorType].push(currentArmor);

      return acc;
    }, armorByTypeAcc);
    return armorByType;
  };

  const [armorPool, setArmorPool] = useState<ArmorByRarity>(getArmors);

  useEffect(() => {
    setArmorPool(getArmors());
  }, [armorGearConfig]);

  const handleChangeArmorRank = (rank: ArmorRank) => {
    setArmorGearRank(rank);
  };

  const handleToggleArmorRarity = (index: number) => {
    setArmorGearRarityConfig(index);
  };

  const generateSetsV2 = () => {
    setNoneFound(null);
    const start = window.performance.now();
    const decorations = getAllDecorationData();
    // match skills with decorations
    // should this be done at the data import level? as to not calculate each time?
    const skillsAndDecorations = requiredSkills
      .filter((requiredSkill) => requiredSkill !== null)
      .map((requiredSkill) => {
        const clonedSkill = cloneDeep(requiredSkill);
        if (clonedSkill.hasDeco) {
          const decoration = decorations.find(
            (decoration) => decoration.id === requiredSkill.decoId
          );
          // return { ...requiredSkill, decoration: { ...found } };
          clonedSkill.decoration = decoration;
        }
        const { id, appliedSkillLevel, maxSkillLevel, hasDeco, name } =
          clonedSkill;

        return {
          name,
          skillId: id,
          minimumRequirement: appliedSkillLevel + 1,
          maximumRequirement: maxSkillLevel + 1,
          ...(hasDeco ? { decoration: { ...clonedSkill.decoration } } : {}),
        };
      });

    const armorTypeToArmorsMap = {} as any;

    // weights should be calculated at runtime
    Object.entries(armorPool).forEach((entry) => {
      const [armorType, armors] = entry;
      armorTypeToArmorsMap[armorType] = calculateWeights(
        armors,
        // would we drop in the preferences for slots here? or in the generate
        skillsAndDecorations
      );
    });

    // should return sets
    const prospectiveSets = generateLoadouts(
      armorTypeToArmorsMap,
      skillsAndDecorations
    );

    const end = window.performance.now();

    if (prospectiveSets) {
      const sortedByArmor = prospectiveSets.sort((setA: any, setB: any) => {
        const setADefense = sumArmorDefense(setA);
        const setBDefense = sumArmorDefense(setB);
        return setBDefense - setADefense;
      });
      setGeneratedResults(
        generateSetComponents(sortedByArmor, skillsAndDecorations)
      );
      setPerformance(end - start);
    } else {
      setNoneFound('No loadouts were found. Modify input.');
    }
  };

  const getSummaryStats = (set: any) => {
    return <SimpleSummary set={set}></SimpleSummary>;
  };

  const clearData = () => {
    setGeneratedResults([]);
  };

  const dontForgetAboutEmptyDecoSlots = () => {
    return (
      <Container margin="auto">
        <Container display="flex" flexDirection="column" alignItems="center">
          <Icon height={20} width={20} name="OneSlot" />
          {'0'}
        </Container>
      </Container>
    );
  };

  // TODO: separate into component
  // put sets into state?
  const generateSetComponents = (sets: any[], skillsAndDecorations: any) => {
    const armorTypes = ['Head', 'Chest', 'Gloves', 'Waist', 'Feet'] as const;
    const statuses = ['❌', '⚠️', '✅', '📈', '⬆️'];
    return sets.map((set, index) => {
      const { metadata, ...actualSet } = set;
      const { requirementStatuses, condensedSkills, skillIdToStatsMap } =
        metadata;
      const missingSkills = skillsAndDecorations.filter((skillAndDeco: any) => {
        return (
          condensedSkills.findIndex(
            (condensedSkill: any) => condensedSkill.id === skillAndDeco.skillId
          ) === -1
        );
      });
      return (
        <GeneratedSetContainer key={index}>
          <Container
            display="flex"
            flexDirection={['column', null, null, 'row', null]}
            marginBottom="small"
            alignItems="center"
          >
            {getSummaryStats(actualSet)}
            <Container marginLeft={[null, null, null, 'auto', null]}>
              <Button
                onClick={() => handleClickSetV2(actualSet)}
                variant="primary"
              >
                select
              </Button>
            </Container>
          </Container>
          <Container
            display="flex"
            flexDirection={['column', null, null, 'row', null]}
            style={{ gap: '1rem' }}
            justifyContent="center"
            alignItems="center"
          >
            <Container margin="tiny" variant="flexCol" style={{ gap: '.4rem' }}>
              {armorTypes.map((armorType) => {
                return (
                  <SimpleGearCard
                    slot={armorType}
                    gear={actualSet[armorType]}
                  ></SimpleGearCard>
                );
              })}
            </Container>
            <Container display="flex" flexDirection="column" flexWrap="wrap">
              {condensedSkills.map((condensedSkill: any, index: any) => {
                const { id, name, appliedSkillLevel, maxSkillLevel } =
                  condensedSkill;
                const skillRequirementIndex = skillsAndDecorations.findIndex(
                  (skill: any) => skill.skillId === id
                );

                return (
                  <Container
                    key={index}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    style={{ gap: '.5rem' }}
                  >
                    {skillRequirementIndex === -1
                      ? statuses[4]
                      : statuses[
                          requirementStatuses[skillRequirementIndex]
                        ]}{' '}
                    {name}{' '}
                    <Span fontWeight="bold">
                      {appliedSkillLevel + 1}/{maxSkillLevel + 1}
                    </Span>
                  </Container>
                );
              })}
            </Container>
            {missingSkills.length >= 1 && (
              <Container display="flex" flexDirection="column">
                {missingSkills.map((missingSkill: any, index: any) => {
                  const { name } = missingSkill;
                  return (
                    <Container key={index}>
                      {statuses[0]} {name}
                    </Container>
                  );
                })}
              </Container>
            )}
          </Container>
        </GeneratedSetContainer>
      );
    });
  };

  const handleClickSet = (e: any, set: any) => {
    e.preventDefault();
    clearData();
    assignLoadout(set, true);
    if (toggleModal) {
      toggleModal(false);
    }
  };

  const handleClickSetV2 = (set: any) => {
    clearData();
    assignLoadout(set, true);
    if (toggleModal) {
      toggleModal(false);
    }
  };

  // TODO: handle the case where there are no results. still need to go to the next screen?
  // or show it on the same page as warning text?
  // after it ran, see if the button was clicked
  return generatedResults.length >= 1 ? (
    <GeneratorResultsGrid>
      <Container
        display="flex"
        flexDirection={['column', null, null, 'row', null]}
        alignContent="center"
      >
        <Container width={['100%', null, null, '25%', null]}>
          <Button variant="primary" onClick={() => setGeneratedResults([])}>
            modify inputs
          </Button>
        </Container>
        <Container
          variant="flexRow"
          style={{ gap: '1rem' }}
          marginLeft={[null, null, null, null, 'auto']}
          justifyContent={['center', null, null, null, null]}
          marginTop={['tiny', null, null, null, null]}
        >
          <Span>✅ met</Span>
          {/* {<Span>⚠️ partial</Span>} */}
          {/* {<Span>❌ missing</Span>} */}
          <Span>⬆️ bonus</Span>
        </Container>
      </Container>
      <Container variant="flexRow">
        Generated {generatedResults.length}{' '}
        {`set${generatedResults.length > 1 ? 's' : ''}`} in{' '}
        {performance.toFixed(2)} ms.
      </Container>
      <Container variant="flexCol" style={{ gap: '1rem' }}>
        {generatedResults}
      </Container>
    </GeneratorResultsGrid>
  ) : (
    <GeneratorInputGrid>
      <GriddedSectionWrapper gridArea="armor_pool">
        <SectionText>Armors</SectionText>
        <SectionContentContainer>
          <ArmorPoolSelect
            armorConfig={armorGearConfig}
            handleChangeArmorRank={handleChangeArmorRank}
            handleToggleArmorRarity={handleToggleArmorRarity}
          />
        </SectionContentContainer>
      </GriddedSectionWrapper>
      <GriddedSectionWrapper gridArea="preferences">
        <SectionText>Preferences</SectionText>
        <SectionContentContainer textAlign="center">
          WIP
        </SectionContentContainer>
      </GriddedSectionWrapper>
      <GriddedSectionWrapper gridArea="required_skills">
        <SectionText>Required Skills</SectionText>
        <SectionContentContainer>
          <SkillRequirementSelect
            requiredSkills={requiredSkills}
            setRequiredSkills={setRequiredSkills}
          />
        </SectionContentContainer>
      </GriddedSectionWrapper>
      <GriddedSectionWrapper gridArea="armor_lockins">
        <SectionText>Armor Lock-in</SectionText>
        <SectionContentContainer textAlign="center">
          WIP
        </SectionContentContainer>
      </GriddedSectionWrapper>
      <GriddedSectionWrapper gridArea="generate" textAlign="center">
        {noneFound && (
          <Span color="warning" fontWeight="bold">
            {noneFound}
          </Span>
        )}
        <Button
          disabled={
            !requiredSkills.some((requiredSkill) => requiredSkill !== null)
          }
          variant="primary"
          onClick={() => generateSetsV2()}
        >
          generate
        </Button>
      </GriddedSectionWrapper>
    </GeneratorInputGrid>
  );
  // return (
  //   <>
  //     <GenerateContainer>
  //       {generassstedResults.length >= 1 ? (
  //         <GeneratedContainer>
  //           <HelperText>Select to Populate</HelperText>
  //           <GeneratedResultContainer>
  //             {generatedResults}
  //           </GeneratedResultContainer>
  //         </GeneratedContainer>
  //       ) : (
  //         <>
  // <Button
  //   disabled={
  //     !requiredSkills.some((requiredSkill) => requiredSkill !== null)
  //   }
  //   variant="primary"
  //   onClick={() => generateSetsV2()}
  // >
  //   generate
  // </Button>
  //         </>
  //       )}
  //     </GenerateContainer>
  //   </>
  // );
};

/*<Container
            padding="small"
            borderRadius="medium"
            backgroundColor="detailsBackground"
            variant="flexCol"
            justifyContent="center"
            height="100%"
            marginBottom="small"
            border="1px solid"
            borderColor="border"
          >
            <Container
              borderBottom="1px solid"
              borderColor="selectBorder"
              paddingX="large"
              maxHeight="150px"
              height="150px"
              overflow={'scroll'}
            >
              {requiredSkills.map((requiredSkill, index) => {
                const setCorrectSkillPartial = partial(setCorrectSkill, index);
                const setCorrectSkillLevelPartial = partial(
                  setCorrectSkillLevel,
                  index
                );
                // @ts-ignore
                const requiredSkillLevel = requiredSkill
                  ? // @ts-ignore
                    requiredSkill.skillLevels[requiredSkill.appliedSkillLevel]
                  : null;
                return (
                  <Container variant="flexRow">
                    {index !== 0 && (
                      <Span
                        display="flex"
                        alignItems="center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => removeSkill(index)}
                      >
                        <StyledIcon name="X" />
                      </Span>
                    )}
                    <SkillSelect
                      selectedSkill={requiredSkill}
                      setSelectedSkill={setCorrectSkillPartial}
                      selectedSkillLevel={requiredSkillLevel}
                      setSelectedSkillLevel={setCorrectSkillLevelPartial}
                      selectedSkills={requiredSkillIds}
                    ></SkillSelect>
                  </Container>
                );
              })}
            </Container>
            <Container variant="flexRow" style={{ gap: '1rem' }}>
              <Button marginTop="small" variant="primary" onClick={addSkill}>
                add
              </Button>
              <Button
                marginTop="small"
                variant="secondary"
                onClick={() => setRequiredSkills([null])}
              >
                clear
              </Button>
            </Container>
          </Container>*/
/**
 * 
 * <Text marginTop="medium">
          Step 3: (Optional) Set desired empty slots
        </Text>
        <Container>
          <form>
            <label>one</label>
            <input
              value={requiredEmptyDecorations[0]}
              onChange={(e) =>
                setRequiredEmptyDecorations((prev) => {
                  const newish = [...prev];
                  const val = parseInt(e.target.value);
                  newish[0] = val < 0 ? 0 : val;

                  return newish;
                })
              }
              name="oneSlot"
              type="number"
            ></input>
            <label>two</label>
            <input
              value={requiredEmptyDecorations[1]}
              onChange={(e) =>
                setRequiredEmptyDecorations((prev) => {
                  const newish = [...prev];
                  const val = parseInt(e.target.value);
                  newish[1] = val < 0 ? 0 : val;
                  return newish;
                })
              }
              name="twoSlot"
              type="number"
            ></input>
            <label>two</label>
            <input
              value={requiredEmptyDecorations[2]}
              onChange={(e) =>
                setRequiredEmptyDecorations((prev) => {
                  const newish = [...prev];
                  const val = parseInt(e.target.value);
                  newish[2] = val < 0 ? 0 : val;
                  return newish;
                })
              }
              name="threeSlot"
              type="number"
            ></input>
            <label>two</label>
            <input
              value={requiredEmptyDecorations[3]}
              onChange={(e) =>
                setRequiredEmptyDecorations((prev) => {
                  const newish = [...prev];
                  const val = parseInt(e.target.value);
                  newish[3] = val < 0 ? 0 : val;
                  return newish;
                })
              }
              name="fourSlot"
              type="number"
            ></input>
          </form>
        </Container>
 */

/**
 * 
 * return (
    <>
      <GenerateContainer>
        <InputContainer>
          <CheckBox
            isChecked={hrOnly}
            setIsChecked={setHrOnly}
            label="HR Only"
          ></CheckBox>
          <DataSearch
            appliedFilters={skillFilters}
            setAppliedFilters={setSkillFilters}
            dataset="skills"
            resultAmount={5}
            width={75}
          />
        </InputContainer>
        {generatedResults.length > 1 && skillFilters.length > 0 && (
          <GeneratedContainer>
            <HelperText>Select to Populate</HelperText>
            <GeneratedResultContainer>
              {generatedResults}
            </GeneratedResultContainer>
          </GeneratedContainer>
        )}
        <GenerateButton
          disabled={skillFilters.length === 0}
          variant="primary"
          onClick={handleGenerateSets}
          maxWidth="initial"
          margin="auto"
          marginBottom="0"
        >
          generate!
        </GenerateButton>
      </GenerateContainer>
    </>
  );
 */

// ArmorTypeValues.forEach((armorType) => {
//   armorTypeToArmorsMap[armorType] = calculateWeights(
//     getArmorDataV2(armorType, selectedRank),
//     { requiredSkills: skillsAndDecorations }
//   );
// });

// <Container
//             variant={isMobile ? 'flexCol' : 'flexRow'}
//             style={{ gap: '1rem' }}
//             height="100%"
//           >
//             <Container
//               variant="flexCol"
//               height="100%"
//               width={isMobile ? '100%' : '45%'}
//               style={{ gap: '1rem' }}
//             >
//               <Container>
//                 <Text
//                   textAlign="center"
//                   color="secondaryText"
//                   fontWeight="bold"
//                   fontSize="small"
//                 >
//                   ARMORS
//                 </Text>

//                 {/* armor pool selector */}
//                 <Container
//                   padding="small"
//                   borderRadius="medium"
//                   variant="flexCol"
//                   justifyContent="center"
//                   backgroundColor="detailsBackground"
//                   height="100%"
//                   border="1px solid"
//                   borderColor="border"
//                 >
//                   {createArmorRaritySelection()}
//                 </Container>
//               </Container>
//               {/* <Container height="100%">
//             <Text
//               textAlign="center"
//               color="secondaryText"
//               fontWeight="bold"
//               fontSize="small"
//             >
//               MISC
//             </Text>
//             <Container
//               padding="small"
//               borderRadius="medium"
//               variant="flexCol"
//               justifyContent="center"
//               backgroundColor="detailsBackground"
//               height="100%"
//               border="1px solid"
//               borderColor="border"
//             ></Container>
//           </Container> */}
//             </Container>
//             <Container height="100%" width={isMobile ? '100%' : '55%'}>
//               <Text
//                 textAlign="center"
//                 color="secondaryText"
//                 fontWeight="bold"
//                 fontSize="small"
//               >
//                 SKILL REQUIREMENTS
//               </Text>
//               {/* skill selections */}
// <Container
//   padding="small"
//   borderRadius="medium"
//   backgroundColor="detailsBackground"
//   variant="flexCol"
//   justifyContent="center"
//   height="100%"
//   marginBottom="small"
//   border="1px solid"
//   borderColor="border"
// >
//   <Container
//     borderBottom="1px solid"
//     borderColor="selectBorder"
//     paddingX="large"
//     maxHeight="150px"
//     height="150px"
//     overflow={'scroll'}
//   >
//     {requiredSkills.map((requiredSkill, index) => {
//       const setCorrectSkillPartial = partial(
//         setCorrectSkill,
//         index
//       );
//       const setCorrectSkillLevelPartial = partial(
//         setCorrectSkillLevel,
//         index
//       );
//       // @ts-ignore
//       const requiredSkillLevel = requiredSkill
//         ? // @ts-ignore
//           requiredSkill.skillLevels[
//             requiredSkill.appliedSkillLevel
//           ]
//         : null;
//       return (
//         <Container variant="flexRow">
//           {index !== 0 && (
//             <Span
//               display="flex"
//               alignItems="center"
//               style={{ cursor: 'pointer' }}
//               onClick={() => removeSkill(index)}
//             >
//               <StyledIcon name="X" />
//             </Span>
//           )}
//           <SkillSelect
//             selectedSkill={requiredSkill}
//             setSelectedSkill={setCorrectSkillPartial}
//             selectedSkillLevel={requiredSkillLevel}
//             setSelectedSkillLevel={setCorrectSkillLevelPartial}
//             selectedSkills={requiredSkillIds}
//           ></SkillSelect>
//         </Container>
//       );
//     })}
//   </Container>
//   <Container variant="flexRow" style={{ gap: '1rem' }}>
//     <Button
//       marginTop="small"
//       variant="primary"
//       onClick={addSkill}
//     >
//       add
//     </Button>
//     <Button
//       marginTop="small"
//       variant="secondary"
//       onClick={() => setRequiredSkills([null])}
//     >
//       clear
//     </Button>
//   </Container>
// </Container>
//             </Container>
//           </Container>

// const getTopNSets = (n: number) => {
//   const allSets = generateSets();

//   const criteriaSets = allSets.filter((set: any) => {
//     const appliedSkills = getAppliedSkillsFromLoadout(set);

//     const appliedSkillIds = appliedSkills.map((skill: AppliedSkill) => {
//       return skill.id;
//     });

//     const skillFilterIds = skillFilters.map((skill: Skill) => {
//       return skill.id;
//     });

//     for (const skillId of skillFilterIds) {
//       if (!appliedSkillIds.includes(skillId)) {
//         return false;
//       }
//     }
//     return true;
//   });

//   const sortedSets = criteriaSets.sort((a: any, b: any) => {
//     const defA = sumArmorDefense(a);
//     const defB = sumArmorDefense(b);

//     const diff = defB - defA;

//     return diff;
//   });
//   return sortedSets.slice(0, n);
// };
