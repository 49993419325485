import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const CheckBoxContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'border',
    backgroundColor: 'white',
    borderRadius: 'medium',
    padding: 'tiny',
    width: 'fit-content',
    fontSize: 'small',
  })}
`;
