import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const SimpleSummaryContainer = styled(Container)`
  ${css({
    margin: 'tiny',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 'xSmall',
    alignItems: 'center',
  })}
`;

export const ResistsContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
  })}
`;

export const StatContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })}
`;
