import React from 'react';

export default {
  viewBox: '0 0 549 565',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M27 435v-35c18-13 31-25 42-45 47-103 46-223 57-328h42c5 103 0 206 54 328 9 18 23 32 40 45v35H27zm88 0v103h61V435h-61zm-46-80h153m31-194v73l27 22v144l71 72h73l69-72V256l29-22v-73l-88-49h-93l-88 49zm64-36v310l34 37h24V112h-34l-24 13z"
        />
      </g>
      <g id="lightColor">
        <path id="light" d="M317 125v310l34 37h24V112h-34l-24 13z" />
      </g>
      <g id="outline">
        <path
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
          d="M27 435v-35c18-13 31-25 42-45 47-103 46-223 57-328h42c5 103 0 206 54 328 9 18 23 32 40 45v35H27zm88 0v103h61V435h-61zm-46-80h153m31-194v73l27 22v144l71 72h73l69-72V256l29-22v-73l-88-49h-93l-88 49zm64-36v310l34 37h24V112h-34l-24 13z"
        />
        <path d="M423 236l51-51v-15h-14l-51 51v15h14z" />
        <path d="M404 240h21l53-54v-21h-20l-54 55v20zm17-8h-8v-9l48-49h9v9l-49 49z" />
        <path d="M460 236l-51-51v-15h14l51 51v15h-14z" />
        <path d="M404 186l54 54h20v-20l-53-55h-21v21zm57 46l-48-49v-9h8l49 49v9h-9z" />
      </g>
    </>
  ),
};
