import React, { useState, useEffect } from 'react';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';
import { useQuery } from 'react-query';
import axios from 'axios';

import {
  GearsetCard,
  SummarySkillsCard,
  TotalStatsCard,
  Spinner,
  Tooling,
  Status,
} from 'components';
import {
  ErrorContainer,
  SpinnerContainer,
  AppGridContainer,
  LoadoutToolingContainer,
  LoadoutViewIcon,
  ControlButtonContainer,
  LoadoutViewControls,
  LoadoutViewSpan,
  ColumnAppGridContainer,
  MobileSlotSelectionContainer,
  SlotSelectionContainer,
  SlotSelectionIcon,
} from './styled';

interface LoadoutContainerProps {
  modeToggle: any;
}

// TODO: loading message is randomly picked from a set of values, quirky & fun
// TODO: error banner
export const LoadoutContainer: React.FC<LoadoutContainerProps> = ({
  modeToggle,
}) => {
  const { currMode, changeMode } = modeToggle;
  const windowSize = window.innerWidth;
  const [splitView, setSplitView] = useState(
    windowSize <= 1280 && windowSize >= 600
  );
  const [columnView, setColumnView] = useState(windowSize < 600);
  const defaultCurrent = splitView
    ? 'loadout'
    : columnView
    ? 'summary'
    : 'loadout';
  const [currentView, setCurrentView] = useState<
    'loadout' | 'tooling' | 'skills' | 'summary'
  >(defaultCurrent);

  const { assignLoadout, loadout } = useLoadoutStore(
    (state) => ({
      assignLoadout: state.assignLoadout,
      loadout: state.loadout,
    }),
    shallow
  );

  // const currentWeapon = loadout.Weapon
  //   ? loadout.Weapon.weaponType
  //   : 'GreatSword';

  const [currentSlot, setCurrentSlot] = useState<any>('LongSword');
  // brittle with theme

  const [loadoutEncryption, setLoadoutEncryption] = useState<any>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('loadout')) {
      setLoadoutEncryption(params.get('loadout'));
    }
  }, [loadoutEncryption]);

  const { isLoading, isSuccess, data, isError } = useQuery(
    'getLoadout',
    () => {
      // return axios.get(`/api/loadout/${loadoutEncryption}`);
      return axios.post(`/.netlify/functions/import`, loadoutEncryption);
    },
    {
      enabled: loadoutEncryption !== null,
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      if (data) {
        assignLoadout(data.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    window.addEventListener('resize', (event) => {
      const currWindow = window.innerWidth;
      setSplitView(currWindow <= 1280 && currWindow >= 600);
      setColumnView(currWindow < 600);
    });
  }, [windowSize]);

  const createMobileSelectionControls = () => {
    const slots = [
      // 'Overview',
      // currentWeapon as any,
      'LongSword',
      'Head',
      'Chest',
      'Gloves',
      'Waist',
      'Feet',
      'Talisman',
    ] as const;

    return (
      <MobileSlotSelectionContainer>
        {slots.map((slot) => (
          <SlotSelectionContainer
            key={slot}
            border={
              currentSlot === slot ? '1px solid' : '1px solid transparent'
            }
            borderColor={currentSlot === slot ? 'border' : 'transparent'}
            onClick={() => setCurrentSlot(slot)}
          >
            <SlotSelectionIcon name={slot} />
          </SlotSelectionContainer>
        ))}
      </MobileSlotSelectionContainer>
    );
  };

  const flavorTextArray = [
    "Don't forget to drink your milk!",
    'When in doubt, equip Fortify!',
    'Loading your ludicrously leet loadout!',
    'The palicos are fetching your armor pieces...',
    "Aren't you glad there's no underwater combat in Rise?",
    'Gore Magala has arrived!',
    "Always eat for Dango Bulker. (Don't).",
    'Hold on to your Canyne! Loadout incoming!',
  ];
  const flavorText =
    flavorTextArray[Math.floor(Math.random() * flavorTextArray.length)];
  // tablet screen size ? render tablet with current view and controls, else, phone screen size ? column view, else, full page view
  return isLoading ? (
    <SpinnerContainer>
      <Spinner spinnerText={flavorText}></Spinner>
    </SpinnerContainer>
  ) : (
    <>
      {isError && (
        <ErrorContainer
          marginBottom="medium"
          fontSize="xSmall"
          color="red"
          fontWeight="bold"
        >
          There was an error importing. Try again.
        </ErrorContainer>
      )}
      {splitView ? (
        <>
          <LoadoutViewControls>
            <LoadoutViewSpan
              opacity={currentView === 'tooling' ? '100%' : '40%'}
              fontWeight={currentView === 'tooling' ? 'bold' : null}
              onClick={() => setCurrentView('tooling')}
            >
              Tooling
            </LoadoutViewSpan>
            <LoadoutViewSpan
              opacity={currentView === 'loadout' ? '100%' : '40%'}
              fontWeight={currentView === 'loadout' ? 'bold' : null}
              onClick={() => setCurrentView('loadout')}
            >
              Loadout
            </LoadoutViewSpan>
          </LoadoutViewControls>
          {currentView === 'tooling' ? (
            <LoadoutToolingContainer initial={{ x: -50 }} animate={{ x: 0 }}>
              <ControlButtonContainer
                onClick={() => setCurrentView('loadout')}
                right={0}
                initial={{ y: '45vh' }}
                animate={{
                  x: [0, 5, 0],
                  transition: { repeat: Infinity, repeatDelay: 1 },
                }}
              >
                <LoadoutViewIcon name="Forward" />
              </ControlButtonContainer>
              <Tooling
                colorMode={currMode}
                handleChangeColorMode={changeMode}
              />
              <SummarySkillsCard></SummarySkillsCard>
              <TotalStatsCard></TotalStatsCard>
            </LoadoutToolingContainer>
          ) : (
            <AppGridContainer initial={{ x: 50 }} animate={{ x: 0 }}>
              <ControlButtonContainer
                onClick={() => setCurrentView('tooling')}
                left={0}
                initial={{ y: '45vh' }}
                animate={{
                  x: [0, -5, 0],
                  transition: { repeat: Infinity, repeatDelay: 1 },
                }}
              >
                <LoadoutViewIcon name="Back" />
              </ControlButtonContainer>
              <GearsetCard></GearsetCard>
            </AppGridContainer>
          )}
        </>
      ) : columnView ? (
        <>
          <ColumnAppGridContainer>
            <Tooling colorMode={currMode} handleChangeColorMode={changeMode} />
            {createMobileSelectionControls()}
            <GearsetCard mobileSelection={currentSlot} />
            <LoadoutViewControls gridArea="controls">
              <LoadoutViewSpan
                opacity={currentView === 'summary' ? '100%' : '40%'}
                fontWeight={currentView === 'summary' ? 'bold' : null}
                onClick={() => setCurrentView('summary')}
              >
                Summary
              </LoadoutViewSpan>
              <LoadoutViewSpan
                opacity={currentView === 'skills' ? '100%' : '40%'}
                fontWeight={currentView === 'skills' ? 'bold' : null}
                onClick={() => setCurrentView('skills')}
              >
                Skills
              </LoadoutViewSpan>
            </LoadoutViewControls>
            {currentView === 'summary' ? (
              <TotalStatsCard></TotalStatsCard>
            ) : (
              <SummarySkillsCard></SummarySkillsCard>
            )}
          </ColumnAppGridContainer>
        </>
      ) : (
        <AppGridContainer>
          <Tooling colorMode={currMode} handleChangeColorMode={changeMode} />
          <SummarySkillsCard></SummarySkillsCard>
          <GearsetCard></GearsetCard>
          <TotalStatsCard></TotalStatsCard>
        </AppGridContainer>
      )}
    </>
  );
  // uncomment to work on screenshot image
  // return (
  //   <div>
  //     <Tooling colorMode={currMode} handleChangeColorMode={changeMode} />
  //     <Status></Status>
  //   </div>
  // );
};
