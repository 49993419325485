import { isArmor, Armor, DefenseStats } from 'models/Armor';
import { Weapon } from 'models/weapons';
import { Loadout } from 'models/state';
import {
  getAppliedSkillsFromLoadout,
  condenseAppliedSkills,
} from 'utils/skills';
import { cloneDeep } from 'lodash';

type GeneratedLoadout = Omit<Loadout, 'Weapon' | 'Talisman'>;

// TODO: skillsAndDecorations => object of options
export const calculateWeights = (gears: any[], skillsAndDecorations: any[]) => {
  // const {skillsAndDecorations} = requirements;
  const requiredSkillIds = new Set(
    skillsAndDecorations.map((skill: any) => skill.skillId)
  );

  const requiredSlotValues = new Set(
    skillsAndDecorations.map((skill: any) =>
      skill.decoration ? skill.decoration.slotValue : -1
    )
  );

  const weightedGear = gears.map((gear) => {
    const gearCopy = { ...gear };

    const { appliedSkills, decorationSlots } = gearCopy;

    const decoWeight = decorationSlots.filter((slot: any) =>
      requiredSlotValues.has(slot.slotValue)
    ).length;

    const skillWeight = appliedSkills.reduce((acc: any, appliedSkill: any) => {
      if (requiredSkillIds.has(appliedSkill.id)) {
        return acc + appliedSkill.appliedSkillLevel + 1;
      }
      return acc;
    }, 0);

    return {
      ...gearCopy,
      totalWeight: skillWeight + decoWeight,
      decoWeight,
      skillWeight,
    };
  });

  // TODO: ternary statement if we're considering decorations
  const weightedGearSort = (gearA: any, gearB: any) => {
    return gearB.skillWeight - gearA.skillWeight;
    // return fillWithDecorations ?  gearB.totalWeight - gearA.totalWeight : gearB.skillWeight - gearA.skillWeight;
  };

  return (
    weightedGear
      // TODO: ternary statement if we're considering decorations
      .filter((gear) => {
        return gear.skillWeight !== 0;
        // return fillWithDecorations ? gear.totalWeight !== 0 : gear.skillWeight !== 0;
      })
      .sort(weightedGearSort)
  );
};

// TODO: requirements array => requirements object
export const generateLoadouts = (
  armorTypeToArmorsMap: any[],
  requirements: any[]
) => {
  const armorTypeToArmorsMapClone = cloneDeep(armorTypeToArmorsMap);
  const prospectiveLoadouts = [] as any[];

  // sort entries by the first item weights, descending
  const sortedByHighestCriteria = Object.entries(
    armorTypeToArmorsMapClone
  ).sort((kvA: any[], kvB: any[]) => {
    const [, armorsA] = kvA;
    const [, armorsB] = kvB;

    // TODO: what
    if (armorsA.length === 0 && armorsB.length === 0) {
      return 0;
    } else if (armorsA.length === 0) {
      return 1;
    } else if (armorsB.length === 0) {
      return -1;
    } else {
      // TODO: ternary statement if we're considering decorations
      return armorsB[0].skillWeight - armorsA[0].skillWeight;
      // return considerDecorations ? armorsB[0].totalWeight - armorA[0].totalWeight : armorsB[0].skillWeight - armorsA[0].skillWeight;
    }
  });

  // get the weights at zero index
  // will be used to check the remaining weights for remaining slots
  const zeroIndexWeights = sortedByHighestCriteria.map((keyVal) => {
    const [, armors] = keyVal;

    if (armors.length === 0) {
      return 0;
    } else {
      // TODO: conditional based on "fill with deco" option
      return armors[0].skillWeight;
      // return considerDecorations ? armors[0].totalWeight : armors[0].skillWeight;
    }
  });

  // get the total required weight to compare against
  const requiredWeight = requirements.reduce(
    (acc, curr) => acc + curr.minimumRequirement,
    0
  );

  // early exit if we don't have gear that can be combined to fit the criteria.
  if (zeroIndexWeights.reduce((acc, curr) => acc + curr, 0) < requiredWeight) {
    return null;
  }

  // ['Slot', gear[]]
  const [kvA, kvB, kvC, kvD, kvE] = sortedByHighestCriteria;
  // [Head, Chest, Gloves, Waist, Feet], but in order of what highest weight
  const slots = sortedByHighestCriteria.map((kv) => kv[0]);

  // TODO: locked armors would be passed in from the generator right?
  const gearsA = [...kvA[1], null];
  const gearsB = [...kvB[1], null];
  const gearsC = [...kvC[1], null];
  const gearsD = [...kvD[1], null];
  const gearsE = [...kvE[1], null];

  // TODO: do we need this?
  const stopAt100 = false;

  let startIndex = 0;
  let totalWeight = 0;
  let currentLoadout = {};

  recursiveLoadoutGenerator(
    currentLoadout,
    gearsA,
    [gearsA, gearsB, gearsC, gearsD, gearsE],
    startIndex,
    slots,
    totalWeight,
    requiredWeight,
    requirements,
    prospectiveLoadouts,
    zeroIndexWeights
  );

  return prospectiveLoadouts.length > 1 ? prospectiveLoadouts : null;
};

const recursiveLoadoutGenerator = (
  currentLoadout: any,
  currentGearArray: any[],
  allGearArrays: any[],
  currentIndex: number,
  slots: any[],
  totalLoadoutWeight: number,
  requiredWeight: number,
  requirements: any[],
  generatedLoadouts: any[],
  zeroIndexWeights: any[]
) => {
  // for every gear in the current slot
  currentGearArray.every((gear) => {
    // if we have 100 or more at any point, break from the loop
    if (generatedLoadouts.length >= 100) {
      return false;
    }
    // copy over current totalLoadoutWeight
    let currentLoadoutWeight = totalLoadoutWeight;
    // assign that to the loadout
    currentLoadout[slots[currentIndex]] = gear;
    // sum up the running weight
    currentLoadoutWeight += gear?.skillWeight ?? 0;

    // if the weight is greater than the required weight, that means that we have a potential match
    if (currentLoadoutWeight >= requiredWeight) {
      // check if the loadout meets requirements
      const { meetsRequirement, metadata } = loadoutMeetsRequirements(
        currentLoadout,
        requirements
      );

      // add it to the generated loadouts if it does
      if (meetsRequirement) {
        generatedLoadouts.push({
          ...currentLoadout,
          metadata: { ...metadata },
        });
      }
      // if it doesn't, we want to check the next item since we could again get a better required weight
      return true;
    } else {
      // if our total weight doesn't meet the minimum
      // last index, break. sorted order implies any further gear will not meet the minimum
      if (currentIndex === 4) {
        return false;
      }
      // check if we can find possible combination with the max values of the remaining slots.
      if (
        currentLoadoutWeight +
          zeroIndexWeights
            .slice(currentIndex + 1, 5)
            .reduce((acc, curr) => acc + curr, 0) <
        requiredWeight
      ) {
        // break out if not
        return false;
      } else {
        // keep going down the rabbit hole
        recursiveLoadoutGenerator(
          currentLoadout,
          allGearArrays[currentIndex + 1],
          allGearArrays,
          currentIndex + 1,
          slots,
          currentLoadoutWeight,
          requiredWeight,
          requirements,
          generatedLoadouts,
          zeroIndexWeights
        );
        return true;
      }
    }
  });
};

// TODO: requirements[] => object
const loadoutMeetsRequirements = (currLoadout: any, requirements: any[]) => {
  // do we need a ratio? in the case that we have 4 required skills, 1 of them isn't present but the rest are?

  // create an array of statuses from requirements - initialize to unmet for each skill.
  enum RequirementStatus {
    Unmet,
    PartiallyMet,
    Met,
    Exceeded,
  }

  const start = performance.now();
  // start with all requirements being unmet
  const requirementsMet = requirements.map(() => RequirementStatus.Unmet);

  // get all the skills from a loadout
  const allSkills = getAppliedSkillsFromLoadout(currLoadout);
  const condensedSkills = condenseAppliedSkills([...allSkills]);
  // create a map for accessing easier
  let skillIdToStatsMap: { [key: number]: any } = {};

  condensedSkills.forEach((condensedSkill) => {
    const { id } = condensedSkill;
    skillIdToStatsMap[id] = { ...condensedSkill };
  });

  // for each required skill, check the condensed skills in order, and mark the status.
  // TODO: rename legibleSkills
  requirements.forEach((skillRequirement, index) => {
    const { skillId, minimumRequirement, maximumRequirement } =
      skillRequirement;
    const loadoutSkill = skillIdToStatsMap[skillId];
    if (loadoutSkill) {
      const { appliedSkillLevel } = loadoutSkill;
      if (appliedSkillLevel + 1 === minimumRequirement) {
        // RequirementStatus.Met;
        requirementsMet[index] = RequirementStatus.Met;
      } else if (appliedSkillLevel + 1 < minimumRequirement) {
        // RequirementStatus.PartiallyMet
        requirementsMet[index] = RequirementStatus.PartiallyMet;
      } else if (appliedSkillLevel + 1 > maximumRequirement) {
        // RequirementStatus.Exceeded
        requirementsMet[index] = RequirementStatus.Exceeded;
      }
    } else {
      requirementsMet[index] = RequirementStatus.Unmet;
    }
  });
  const end = performance.now();
  // flag these based on inputs.
  return {
    meetsRequirement:
      requirementsMet.includes(RequirementStatus.Met) &&
      !requirementsMet.includes(RequirementStatus.Exceeded) &&
      !requirementsMet.includes(RequirementStatus.Unmet) &&
      !requirementsMet.includes(RequirementStatus.PartiallyMet),
    metadata: {
      requirementStatuses: requirementsMet,
      condensedSkills,
      skillIdToStatsMap,
      timeTaken: end - start,
    },
  };
};

// keep iterative just in case...
/**
export const generateAllLoadoutsV3 = (
  armorTypeToArmorsMap: any[],
  requirements: any[]
) => {
  const armorTypeToArmorsMapClone = cloneDeep(armorTypeToArmorsMap);
  // store prospective loadouts
  const prospectiveLoadouts = [] as any[];

  // sort entries by the first item weights
  const sortedByHighestCriteria = Object.entries(
    armorTypeToArmorsMapClone
  ).sort((kvA: any[], kvB: any[]) => {
    const [armorTypeA, armorsA] = kvA;
    const [armorTypeB, armorsB] = kvB;

    if (armorsA.length === 0 && armorsB.length === 0) {
      return 0;
    } else if (armorsA.length === 0) {
      return 1;
    } else if (armorsB.length === 0) {
      return -1;
    } else {
      return armorsB[0].skillWeight - armorsA[0].skillWeight;
    }
  });

  // console.log(
  //   sortedByHighestCriteria.map((kv) => {
  //     const [k, v] = kv;
  //     const vals = v.map((vs: any) => vs.skillWeight);
  //     const totalValues = vals.reduce((acc: any, cur: any) => acc + cur, 0);
  //     return [k, vals, totalValues];
  //   })
  // );
  const zeroIndexWeights = sortedByHighestCriteria.map((keyVal) => {
    const [armorType, armors] = keyVal;

    if (armors.length === 0) {
      return 0;
    } else {
      // TODO: conditional based on "fill with deco" option (skillWeight, decoWeight, totalWeight etc)
      return armors[0].skillWeight;
    }
  });
  // console.log('zero index', zeroIndexWeights);
  // get the total required weight
  const requiredWeight = requirements.reduce(
    (acc, curr) => acc + curr.minimumRequirement,
    0
  );
  // console.log(requiredWeight);
  // early exit if we don't have gear that can be combined to fit the criteria.
  if (zeroIndexWeights.reduce((acc, curr) => acc + curr, 0) < requiredWeight) {
    console.log('no sets can be made to meet requirment, early exiting!');
    return [];
  }

  // [Slot, [...gear]] for each thing
  const [kvA, kvB, kvC, kvD, kvE] = sortedByHighestCriteria;
  // [Head, Chest, Gloves, Waist, Feet], but in order of what has highest weight.
  const slots = sortedByHighestCriteria.map((kv) => kv[0]);

  // what does this look like if we need to skip an armor? since it's locked.
  const gearsA = [...kvA[1], null];
  const gearsB = [...kvB[1], null];
  const gearsC = [...kvC[1], null];
  const gearsD = [...kvD[1], null];
  const gearsE = [...kvE[1], null];

  let checkTimes = 0;
  let totalTime = 0.0;
  const stopAt100 = true;

  // how to make recursive??
  // parameters - array of gear to grab from, the rest of the gear arrays, the current level/slot, the running total of armorweight, and the options?
  //
  // START MONKEY CODE
  for (let a = 0; a < gearsA.length; a++) {
    const currentLoadout = {} as any;
    // always start with 0
    let currentLoadoutWeight = 0;

    currentLoadout[slots[0]] = gearsA[a];
    // this might give issues for null.
    currentLoadoutWeight += gearsA[a]?.skillWeight ?? 0;

    // loadout can possibly meet skill requirement
    if (currentLoadoutWeight >= requiredWeight) {
      // add it to the generated lists.
      // continue checking the rest of the gear available.
      // OPTION 1: push anything that may fit
      // prospectiveLoadouts.push({ ...currentLoadout });

      // OPTION 2: check as we go
      const { meetsRequirement, metadata } = loadoutMeetsRequirements(
        currentLoadout,
        requirements
      );
      const { timeTaken } = metadata;
      totalTime += timeTaken;
      checkTimes++;
      if (meetsRequirement) {
        prospectiveLoadouts.push({
          ...currentLoadout,
          metadata: { ...metadata },
        });
        if (stopAt100 && prospectiveLoadouts.length >= 100) {
          console.log('checkTimes', checkTimes);
          console.log('totalTimes', totalTime);
          console.log('avg', totalTime / checkTimes);
          return prospectiveLoadouts;
        }
      }
      continue;
    } else {
      // else, it doesn't meet the requirement yet. we must do 2 things:
      // check if the remaining slots can contribute enough skills:
      if (
        currentLoadoutWeight +
          zeroIndexWeights.slice(1, 5).reduce((acc, cur) => acc + cur, 0) <
        requiredWeight
      ) {
        // if we can't, then we need to break?
        break;
      } else {
        // we can, so let's continue checking.
        for (let b = 0; b < gearsB.length; b++) {
          // record the running weight
          let currentLoadoutWeightB = currentLoadoutWeight;

          currentLoadout[slots[1]] = gearsB[b];

          currentLoadoutWeightB += gearsB[b]?.skillWeight ?? 0;

          // redo all the checks.
          if (currentLoadoutWeightB >= requiredWeight) {
            // OPTION 1: push anything that may fit
            // prospectiveLoadouts.push({ ...currentLoadout });

            // OPTION 2: check as we go
            const { meetsRequirement, metadata } = loadoutMeetsRequirements(
              currentLoadout,
              requirements
            );
            const { timeTaken } = metadata;
            totalTime += timeTaken;
            checkTimes++;
            if (meetsRequirement) {
              prospectiveLoadouts.push({
                ...currentLoadout,
                metadata: { ...metadata },
              });
              if (stopAt100 && prospectiveLoadouts.length >= 100) {
                console.log('checkTimes', checkTimes);
                console.log('totalTimes', totalTime);
                console.log('avg', totalTime / checkTimes);
                return prospectiveLoadouts;
              }
            }
            continue;
          } else {
            if (
              currentLoadoutWeightB +
                zeroIndexWeights
                  .slice(2, 5)
                  .reduce((acc, cur) => acc + cur, 0) <
              requiredWeight
            ) {
              break;
            } else {
              for (let c = 0; c < gearsC.length; c++) {
                let currentLoadoutWeightC = currentLoadoutWeightB;

                currentLoadout[slots[2]] = gearsC[c];

                currentLoadoutWeightC += gearsC[c]?.skillWeight ?? 0;

                if (currentLoadoutWeightC >= requiredWeight) {
                  // OPTION 1: push anything that may fit
                  // prospectiveLoadouts.push({ ...currentLoadout });

                  // OPTION 2: check as we go
                  const { meetsRequirement, metadata } =
                    loadoutMeetsRequirements(currentLoadout, requirements);
                  const { timeTaken } = metadata;
                  totalTime += timeTaken;
                  checkTimes++;
                  if (meetsRequirement) {
                    prospectiveLoadouts.push({
                      ...currentLoadout,
                      metadata: { ...metadata },
                    });
                    if (stopAt100 && prospectiveLoadouts.length >= 100) {
                      console.log('checkTimes', checkTimes);
                      console.log('totalTimes', totalTime);
                      console.log('avg', totalTime / checkTimes);
                      return prospectiveLoadouts;
                    }
                  }
                  continue;
                } else {
                  if (
                    currentLoadoutWeightC +
                      zeroIndexWeights
                        .slice(3, 5)
                        .reduce((acc, cur) => acc + cur, 0) <
                    requiredWeight
                  ) {
                    break;
                  } else {
                    for (let d = 0; d < gearsD.length; d++) {
                      let currentLoadoutWeightD = currentLoadoutWeightC;

                      currentLoadout[slots[3]] = gearsD[d];

                      currentLoadoutWeightD += gearsD[d]?.skillWeight ?? 0;

                      if (currentLoadoutWeightD >= requiredWeight) {
                        // OPTION 1: push anything that may fit
                        // prospectiveLoadouts.push({ ...currentLoadout });

                        // OPTION 2: check as we go
                        const { meetsRequirement, metadata } =
                          loadoutMeetsRequirements(
                            currentLoadout,
                            requirements
                          );
                        const { timeTaken } = metadata;
                        totalTime += timeTaken;
                        checkTimes++;
                        if (meetsRequirement) {
                          prospectiveLoadouts.push({
                            ...currentLoadout,
                            metadata: { ...metadata },
                          });
                          if (stopAt100 && prospectiveLoadouts.length >= 100) {
                            console.log('checkTimes', checkTimes);
                            console.log('totalTimes', totalTime);
                            console.log('avg', totalTime / checkTimes);
                            return prospectiveLoadouts;
                          }
                        }
                        continue;
                      } else {
                        if (
                          currentLoadoutWeightD +
                            zeroIndexWeights
                              .slice(4, 5)
                              .reduce((acc, cur) => acc + cur, 0) <
                          requiredWeight
                        ) {
                          break;
                        } else {
                          for (let e = 0; e < gearsE.length; e++) {
                            let currentLoadoutWeightE = currentLoadoutWeightD;

                            currentLoadout[slots[4]] = gearsE[e];

                            currentLoadoutWeightE +=
                              gearsE[e]?.skillWeight ?? 0;

                            if (currentLoadoutWeightE >= requiredWeight) {
                              // OPTION 1: push anything that may fit
                              // prospectiveLoadouts.push({ ...currentLoadout });

                              // OPTION 2: check as we go
                              const { meetsRequirement, metadata } =
                                loadoutMeetsRequirements(
                                  currentLoadout,
                                  requirements
                                );
                              const { timeTaken } = metadata;
                              totalTime += timeTaken;
                              checkTimes++;
                              if (meetsRequirement) {
                                prospectiveLoadouts.push({
                                  ...currentLoadout,
                                  metadata: { ...metadata },
                                });
                                if (
                                  stopAt100 &&
                                  prospectiveLoadouts.length >= 100
                                ) {
                                  console.log('checkTimes', checkTimes);
                                  console.log('totalTimes', totalTime);
                                  console.log('avg', totalTime / checkTimes);
                                  return prospectiveLoadouts;
                                }
                              }
                              continue;
                            } else {
                              // at this point, we're at the last slot. if a gear doesn't meet the requirment
                              // then we must break since sorted order implies the rest won't meet the requirement.
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // OPTION 1:
  // post process prospective loadouts.
  // const criteriaLoadouts = prospectiveLoadouts.map((loadout) => {
  //   const { meetsRequirement, metadata } = loadoutMeetsRequirements(
  //     loadout,
  //     requirements
  //   );
  //   const { timeTaken } = metadata;
  //   totalTime += timeTaken;
  //   checkTimes++;
  //   if (meetsRequirement) {
  //     return { ...loadout, metadata: { ...metadata } };
  //   }
  //   return null;
  // });
  // // END MONKEY CODE
  // console.log('checkTimes', checkTimes);
  // console.log('totalTimes', totalTime);
  // console.log('avg', totalTime / checkTimes);
  // console.log(criteriaLoadouts);
  // return criteriaLoadouts.filter((loadout) => loadout !== null);

  // OPTION 2: process as we go along.
  return prospectiveLoadouts;
};
*/
