import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const StyledWeaponGearContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    justifyContent: 'center',
    borderRadius: 'medium',
    border: '1px solid',
    borderColor: 'cardBorder',
    transition: 'all 0.1s ease-in',
    backgroundColor: 'cardBackground',
    minHeight: ['12rem', null, 'initial', null, null],
    overflow: 'auto',
  })}
`;

export const StyledWeaponTypeSelectContainer = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    justifyItems: 'center',
    padding: 'medium',
  })}
`;
