import { Bow } from 'models/weapons/Bow';
import { ChargeBlade } from 'models/weapons/ChargeBlade';
import { DualBlades } from 'models/weapons/DualBlades';
import { GreatSword } from 'models/weapons/GreatSword';
import { Gunlance } from 'models/weapons/Gunlance';
import { Hammer } from 'models/weapons/Hammer';
import { HeavyBowgun } from 'models/weapons/HeavyBowgun';
import { HuntingHorn } from 'models/weapons/HuntingHorn';
import { InsectGlaive, Kinsect } from 'models/weapons/InsectGlaive';
import { Lance } from 'models/weapons/Lance';
import { LightBowgun } from 'models/weapons/LightBowgun';
import { LongSword } from 'models/weapons/LongSword';
import { SwitchAxe } from 'models/weapons/SwitchAxe';
import { SwordAndShield } from 'models/weapons/SwordAndShield';

export type Weapon =
  | ChargeBlade
  | DualBlades
  | GreatSword
  | Gunlance
  | Hammer
  | HeavyBowgun
  | HuntingHorn
  | InsectGlaive
  | Lance
  | LightBowgun
  | LongSword
  | SwitchAxe
  | SwordAndShield
  | Bow;

export type MeleeWeapon =
  | ChargeBlade
  | DualBlades
  | GreatSword
  | Gunlance
  | Hammer
  | HuntingHorn
  | InsectGlaive
  | Lance
  | LongSword
  | SwitchAxe
  | SwordAndShield;

export type RangedWeapon = Bow | LightBowgun | HeavyBowgun;

export type { Bow } from './Bow';
export type { ChargeBlade } from './ChargeBlade';
export type { DualBlades } from './DualBlades';
export type { GreatSword } from './GreatSword';
export type { Gunlance } from './Gunlance';
export type { Hammer } from './Hammer';
export type { HeavyBowgun } from './HeavyBowgun';
export type { HuntingHorn } from './HuntingHorn';
export type { InsectGlaive, Kinsect } from './InsectGlaive';
export type { Lance } from './Lance';
export type { LightBowgun } from './LightBowgun';
export type { LongSword } from './LongSword';
export type { SwitchAxe } from './SwitchAxe';
export type { SwordAndShield } from './SwordAndShield';

export const isWeapon = (tbd: any): tbd is Weapon => {
  return tbd.hasOwnProperty('weaponType');
};

export const isMeleeWeapon = (tbd: any): tbd is MeleeWeapon => {
  return tbd.hasOwnProperty('baseSharpness');
};

export const isRangedWeapon = (tbd: any): tbd is RangedWeapon => {
  return !tbd.hasOwnProperty('baseSharpness');
};

export const isBowgun = (tbd: any): tbd is LightBowgun | HeavyBowgun => {
  return tbd.hasOwnProperty('properties');
};

export const isBow = (tbd: any): tbd is Bow => {
  return tbd.hasOwnProperty('coatings');
};

export const hasPhial = (tbd: any): tbd is SwitchAxe | ChargeBlade => {
  return tbd.hasOwnProperty('phial');
};

export const isHuntingHorn = (tbd: any): tbd is HuntingHorn => {
  return tbd.hasOwnProperty('songs');
};

export const isInsectGlaive = (tbd: any): tbd is InsectGlaive => {
  return tbd.hasOwnProperty('kinsectLevel');
};
