import React, { useState, useMemo } from 'react';
import { Container, Span } from 'ui/core';
import styled from 'styled-components';
import css from '@styled-system/css';
import { ArmorConfig } from '../../types';

interface ArmorPoolSelectProps {
  armorConfig: ArmorConfig;
  handleChangeArmorRank: any;
  handleToggleArmorRarity: any;
}

// TODO: make this nicer. Possibly extract to component.
const Checkbox = styled.input`
  ${css({
    padding: 'tiny',
  })}
`;

const Label = styled.label`
  ${css({
    marginLeft: 'tiny',
    fontSize: 'small',
  })}
`;

export const ArmorPoolSelect: React.FC<ArmorPoolSelectProps> = ({
  armorConfig,
  handleChangeArmorRank,
  handleToggleArmorRarity,
}) => {
  const { armorRank, armorRarityConfigs } = armorConfig;

  const ranks = ['low', 'high', 'master'] as const;

  return (
    <>
      <Container
        borderRadius="medium"
        variant="flexRow"
        backgroundColor="cardBackground"
        overflow="hidden"
        margin="auto"
        border="1px solid"
        borderColor="detailsBorder"
      >
        {ranks.map((rank) => {
          return (
            <Container
              onClick={() => handleChangeArmorRank(rank)}
              padding="tiny"
              borderRight={rank === 'master' ? null : '1px solid'}
              borderColor="selectBorder"
              backgroundColor={armorRank === rank ? 'primary' : null}
              fontSize="small"
              style={{ cursor: 'pointer', textTransform: 'uppercase' }}
              key={rank}
            >
              {rank}
            </Container>
          );
        })}
      </Container>
      <Container margin="auto" marginTop="small">
        {armorRarityConfigs.map((rarityConfig, index) => {
          const { rarity, selected, name } = rarityConfig;

          return (
            <Span key={rarity} paddingRight="tiny">
              <Checkbox
                type="checkbox"
                checked={selected}
                onChange={() => handleToggleArmorRarity(index)}
                id={rarity}
              />
              <Label htmlFor={rarity}>{name}</Label>
            </Span>
          );
        })}
      </Container>
    </>
  );
};
