import React, { useState, useEffect } from 'react';
import { useDataContext } from 'hooks';

import { DataTable } from 'components';
import { Icon, Modal } from 'ui/core';
import {
  SelectionContainer,
  CancelIcon,
  CancelTypeButton,
  WeaponTypeIconContainer,
  WeaponModalHack,
} from './styled';

import { Rarity } from 'models/enums';
import { WeaponType } from 'models/enums';
import { Weapon } from 'models/weapons';

interface WeaponSelectProps {
  selectedWeaponType: WeaponType;
  onClickWeapon: (weapon: Weapon) => void;
  onClickCancelWeaponType: () => void;
}

// select an actual weapon from the tree
export const WeaponSelect: React.FC<WeaponSelectProps> = ({
  onClickCancelWeaponType,
  onClickWeapon,
  selectedWeaponType,
}) => {
  const { getWeaponData } = useDataContext();
  const data = getWeaponData(selectedWeaponType);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setOpenModal(true);
  }, []);

  const closeModalAndGoBack = () => {
    onClickCancelWeaponType();
    setOpenModal(false);
  };

  return (
    <>
      <SelectionContainer onClick={() => setOpenModal(true)}>
        <WeaponTypeIconContainer
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
        >
          <Icon name={selectedWeaponType} height={90} width={90} />
        </WeaponTypeIconContainer>
        <CancelTypeButton onClick={onClickCancelWeaponType}>
          <CancelIcon name="X" />
        </CancelTypeButton>
      </SelectionContainer>
      {openModal && (
        <WeaponModalHack
          setIsOpen={closeModalAndGoBack}
          isOpen={true}
          modalTitle={selectedWeaponType}
          modalDescription="Select a weapon."
        >
          <DataTable
            tableData={data}
            slot={selectedWeaponType}
            onClickRow={onClickWeapon}
          />
        </WeaponModalHack>
      )}
    </>
  );
};
