import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M5.5 16C5.5 20 9 22 12 22C15 22 18.5 20 18.5 16C18.5 12 15.5 10.5 14 8C13 6 13 3 13 3H11C11 3 11 6 10 8C8.5 11 5.5 12 5.5 16Z"
        fill="#84BCF0"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="7.68167"
      />
    </>
  ),
};
