import styled from 'styled-components';
import css from '@styled-system/css';
import { Button, Container, Text, Icon } from 'ui/core';

export const ToolingContainer = styled(Container)`
  ${css({
    gridArea: 'tooling',
    padding: 'large',
    borderRadius: ['none', null, 'small', null, null],
    borderBottom: ['1px solid', null, 'none', null, null],
    borderColor: ['border', null, null, null, null],
    transition: 'all .3s ease',
    backgroundColor: 'accentGray',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  })}
`;

export const AppTitleText = styled(Text).attrs({
  as: 'h1',
})`
  ${css({
    lineHeight: 1,
  })}
`;

export const TinyText = styled(Text)`
  ${css({
    fontSize: 'xSmall',
  })}
`;

export const TitleContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  })}
`;

export const TitleRow = styled(Container)`
  ${css({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginLeft: 'medium',
    position: 'relative',
  })}
`;

export const ImportButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    margin: 'auto',
    width: '100%',
  })}
`;

export const SimpleGrid = styled(Container)`
  ${css({
    display: ['flex', null, 'grid', null, null],
    flexDirection: 'column',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '.5rem .25rem',
    margin: ['none', null, 'auto', null, null],
    paddingTop: ['large', null, 0, null, null],
    width: '100%',
  })}
`;

export const ClearButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    width: '100%',
  })}
`;

export const SetPickerButton = styled(Button).attrs({
  variant: 'tertiary',
})`
  ${css({
    backgroundColor: 'transparent',
    width: '100%',
  })}
`;

export const OpenGenerateButton = styled(Button)`
  ${css({
    minWidth: 0,
    backgroundColor: 'transparent',
    justifySelf: 'center',
  })}
`;

export const ExportButton = styled(Button).attrs({
  variant: 'primary',
})`
  ${css({
    width: '100%',
  })}
`;

export const ClearConfirmationContainer = styled(Container).attrs({
  variant: 'flexCol',
})``;
export const ClearConfirmationButtonContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({ marginTop: 'medium' })}
`;
export const ClearLoadoutConfirmationButton = styled(Button).attrs({
  width: 'fit-content',
  marginY: 0,
  paddingX: 'medium',
  paddingY: 'xSmall',
})``;

export const HelpIcon = styled(Icon).attrs({ name: 'Help' })`
  ${css({
    position: 'absolute',
    top: 'tiny',
    left: 'tiny',
    opacity: '40%',
    transition: 'all .2s ease',
    ':hover': {
      opacity: '100%',
    },
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const MobileCollapseIconContainer = styled(Container)`
  ${css({
    position: 'absolute',
    right: 'tiny',
    bottom: 0,
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const ColorModeContainer = styled(Container)`
  ${css({
    alignSelf: 'center',
    marginLeft: 'medium',
  })}
`;

export const ColorModeButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({})}
`;
