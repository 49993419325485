import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M17.3333 20H6.66667L4 13.7778L6.66667 15.5556L4.88889 8.44443L7.55556 11.1111V5.33332L10.2222 8.44447L12 4L14.2222 10.2222L16.4444 8V11.1111L19.1111 8.44443L17.3333 15.5556L20 13.7778L17.3333 20Z"
        fill="#F1B339"
        stroke="black"
      />
      <path
        d="M15 20H9L8 14.3529L10.5 16.2353L12 12L13 16.2353L16 14.3529L15 20Z"
        fill="#C16F6F"
        stroke="black"
      />
    </>
  ),
};
