import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Text } from 'ui/core';

export const DataSearchContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    margin: 'medium',
  })}
`;

export const SearchFormAndResultsContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  })}
`;

export const SearchInput = styled.input`
  ${css({
    borderRadius: 'small',
    border: 'black',
    background: 'gainsboro',
    padding: 'small',
    fontSize: 'medium',
  })}
`;

export const SearchResultContainer = styled(Container)`
  ${css({
    borderLeft: 'black',
    borderRight: 'black',
    borderBottom: 'black',
    fontSize: 'medium',
  })}
`;

export const ResultText = styled(Text)<{ selected: boolean }>`
  ${css({
    padding: 'tiny',
    ':hover': {
      backgroundColor: 'gray',
      fontWeight: 'bold',
    },
  })}
  ${({ selected }) =>
    selected
      ? css({
          backgroundColor: 'gray',
          fontWeight: 'bold',
        })
      : ''}
`;

export const ResultForm = styled.form`
  ${css({
    width: '100%',
  })}
`;

export const FilterContainer = styled(Container)`
  ${css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
    marginY: 'tiny',
    fontSize: 'tiny',
    flexWrap: 'wrap',
  })}
`;

export const CloseIconContainer = styled(Container)`
  ${css({
    marginRight: '0.2rem',
  })}
`;
