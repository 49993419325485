import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Button } from 'ui/core';

export const DecorationSelectContainer = styled(Container).attrs({
  variant: 'flexCol',
})`
  ${css({
    flexGrow: 1,
    justifyContent: 'center',
  })}
`;

export const SelectedDecorationContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    fontSize: ['tiny', null, 'medium', null, null],
    margin: 'tiny',
    marginX: 'xSmall',
    justifyContent: 'center',
    alignItems: 'center',
  })}
`;

export const SelectedDecorationCancelButton = styled(Button).attrs({
  variant: 'secondary',
})`
  ${css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 'xSmall',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 'large',
    textTransform: 'capitalize',
    letterSpacing: 0,
    ':hover:enabled': {
      backgroundColor: 'warning',
    },
    '& path': {
      stroke: 'text',
    },
  })}
`;

export const ButtonTextSpan = styled.span`
  ${css({
    flex: 1,
  })}
`;
