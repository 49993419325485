import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Button, Text } from 'ui/core';

export const ExporterContainer = styled(Container)`
  ${css({})}
`;

export const ExportText = styled(Text)`
  ${css({
    fontSize: 'large',
  })}
`;

export const ExportContainer = styled(Container)`
  ${css({
    margin: 'medium',
    display: 'flex',
    flexDirection: 'column',
  })}
`;

export const CopyLinkContainer = styled(Container)`
  ${css({
    margin: 'medium',
    display: 'flex',
    flexDirection: 'row',
  })}
`;

export const CopyButton = styled(Button).attrs({
  variant: 'primary',
})`
  ${css({
    margin: 0,
    paddingX: 'small',
    width: 'fit-content',
  })}
`;

export const LinkText = styled.input<{ chars: any }>`
  ${({ chars }) =>
    css({
      borderRadius: 'medium',
      border: '1px solid',
      borderColor: 'border',
      padding: 'small',
      fontSize: 'small',
      marginLeft: 'tiny',
      width: chars,
      color: 'text',
      backgroundColor: 'detailsBackground',
      '::placeholder': {
        color: 'text',
        opacity: 0.5,
      },
    })}
`;

export const HelperText = styled(Text)`
  ${css({
    fontSize: 'tiny',
  })}
`;
