import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M6 19V5H18V19H6Z" stroke="black" strokeWidth="2" />
      <path d="M9 9H15M9 12H15M9 15H15" stroke="black" strokeWidth="2" />
    </>
  ),
};
