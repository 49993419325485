import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M7.28571 7.95L12 3L16.7143 7.95V9.3V12V18.3H18V21H14.5714V18.3H13.7143V21H10.2857V18.3H9.42857V21H6V18.3H7.28571V12V9.3V7.95Z"
        fill="white"
      />
      <path
        d="M16.7143 18.3V12M16.7143 18.3H14.5714M16.7143 18.3H18V21H14.5714V18.3M7.28571 18.3V12M7.28571 18.3H6V21H9.42857V18.3M7.28571 18.3H9.42857M7.28571 12H16.7143M7.28571 12V9.3M16.7143 12V9.3M7.28571 9.3V7.95L12 3L16.7143 7.95V9.3M7.28571 9.3H16.7143M9.42857 18.3H10.2857M10.2857 18.3V21H13.7143V18.3M10.2857 18.3H13.7143M13.7143 18.3H14.5714"
        stroke="black"
        strokeWidth="2"
      />
    </>
  ),
};
