import styled from 'styled-components';
import css from '@styled-system/css';
import { Container, Icon } from 'ui/core';

export const CoatingsCellContainer = styled(Container).attrs({
  variant: 'flexRow',
})`
  ${css({
    justifyContent: 'spaceAround',
  })}
`;

const coatings = {
  CloseRange: 'white',
  Power: '#CE585E',
  Poison: '#968ACC',
  Para: '#DFE34A',
  Sleep: '#6EC0E1',
  Blast: '#90B24A',
  Exhaust: '#4C68E3',
} as const;

type coatingType = keyof typeof coatings;

export const CoatingIcon = styled(Icon).attrs({ name: 'Phial' })<{
  coating: coatingType;
  isEnabled: boolean;
}>`
  ${({ coating, isEnabled }) =>
    css({
      fill: coatings[coating],
      opacity: isEnabled ? '100%' : '25%',
    })}
`;
