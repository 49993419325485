import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path
        d="M13.5 4H4.5L3 7L6.5 7.5L5.5 10L8.5 10.5L10.5 14.5L6.5 14L4 11.5L3 13L6.5 18L5.5 21H21.5L20.5 10L13.5 4Z"
        fill="#85DA9C"
        stroke="black"
      />
      <path d="M11.5 7L14.5 10M11.5 10L14.5 7" stroke="black" />
      <path
        d="M13.5782 15.5C13.026 13.8431 15.5783 11 15.5783 11C15.5783 11 18.1307 13.8431 17.5784 15.5C17.2291 16.5479 16.5782 17 15.5783 17C14.5784 17 13.9275 16.5479 13.5782 15.5Z"
        fill="#BDEEEB"
        stroke="black"
      />
    </>
  ),
};
