import React from 'react';

export default {
  viewBox: '0 0 24 24',
  paths: (
    <>
      <path d="M5.5 9L8 11V7V3.5H6L5.5 4V9Z" fill="white" />
      <path d="M9 2L8 3.5V7H10.5V2H9Z" fill="white" />
      <path d="M10.5 2V7H12.5V2H10.5Z" fill="white" />
      <path d="M15 3L14.5 2H12.5V7H15V3Z" fill="white" />
      <path d="M17 3H15V7H17.5V4L17 3Z" fill="white" />
      <path d="M8 7V11V12H17.5V7H15H12.5H10.5H8Z" fill="white" />
      <path d="M17.5 12H8V14.5H17.5V12Z" fill="white" />
      <path d="M8 19.5H17.5V14.5H8V19.5Z" fill="white" />
      <path d="M8 19.5H7V21.5H18.5V19.5H17.5H8Z" fill="white" />
      <path
        d="M7 19.5H18.5M7 19.5V21.5H18.5V19.5M7 19.5H8M18.5 19.5H17.5M8 3.5L9 2H10.5M8 3.5H6L5.5 4V9L8 11M8 3.5V7M15 3L14.5 2H12.5M15 3H17L17.5 4V7M15 3V7M17.5 19.5H8M17.5 19.5V14.5M8 19.5V14.5M8 11V7M8 11V12M8 7H10.5M17.5 7H15M17.5 7V12M10.5 2V7M10.5 2H12.5M10.5 7H12.5M12.5 2V7M12.5 7H15M8 12H17.5M8 12V14.5M17.5 12V14.5M8 14.5H17.5M12 8.5V9.5M14 8.5V9.5M16 8.5V9.5M10 8.5V9.5M12 16.5V17.5M14 16.5V17.5M16 16.5V17.5M10 16.5V17.5"
        stroke="black"
      />
      <path d="M5.5 9L8 11V7V3.5H6L5.5 4V9Z" fill="#C4C4C4" />
      <path d="M9 2L8 3.5V7H10.5V2H9Z" fill="#C4C4C4" />
      <path d="M10.5 2V7H12.5V2H10.5Z" fill="#C4C4C4" />
      <path d="M15 3L14.5 2H12.5V7H15V3Z" fill="#C4C4C4" />
      <path d="M17 3H15V7H17.5V4L17 3Z" fill="#C4C4C4" />
      <path
        d="M8 3.5L9 2H10.5M8 3.5H6L5.5 4V9L8 11M8 3.5V7M15 3L14.5 2H12.5M15 3H17L17.5 4V7M15 3V7M8 11V7M8 11V12M8 7H10.5M17.5 7H15M17.5 7V12M10.5 2V7M10.5 2H12.5M10.5 7H12.5M12.5 2V7M12.5 7H15M8 12H17.5M8 12V14.5M17.5 12V14.5M8 14.5V19.5H17.5V14.5M8 14.5H17.5M12 8.5V9.5M14 8.5V9.5M16 8.5V9.5M10 8.5V9.5M12 16.5V17.5M14 16.5V17.5M16 16.5V17.5M10 16.5V17.5M7 19.5H18.5V21.5H7V19.5Z"
        stroke="black"
      />
    </>
  ),
};
