import { Text } from 'ui/core';
import { matchSorter } from 'match-sorter';
import { NameFilter } from './components/filters';

const defaultColumn = {
  Filter: () => <></>,
};

const decorationColumns = [
  {
    id: 'name',
    Header: 'NAME',
    accessor: 'name',
    width: 220,
  },
  {
    id: 'slotValue',
    Header: 'SLOT VALUE',
    accessor: 'slotValue',
    width: 66,
  },
  {
    id: 'description',
    Header: 'DESCRIPTION',
    accessor: (originalRow: any) => {
      return originalRow.description;
    },
    Cell: ({ value }: { value: string }) => {
      return <Text fontSize="xSmall">{value}</Text>;
    },
    width: 320,
  },
];

const mobileDecorationColumns = [
  {
    id: 'name',
    Header: 'NAME',
    accessor: 'name',
  },
  {
    id: 'slotValue',
    Header: 'SLOT VALUE',
    accessor: 'slotValue',
    width: 66,
  },
];

const initialGlobalFilterState = [
  {
    id: 'name',
    value: '',
  },
  {
    id: 'description',
    value: '',
  },
];

const globalFilterResolver = (
  rows: any,
  columnIds: any,
  globalFilterValue: any
) => {
  const nameMatches = matchSorter(rows, globalFilterValue, {
    keys: [
      (row: any) => row.original.name,
      (row: any) => row.original.description,
    ],
    // keys: ['original.name', 'original.appliedSkills.*.name'],
  });

  return globalFilterValue === '' ? rows : nameMatches;
};

export const getDecorationProperties = () => {
  const baseDecorationOptions = {
    defaultColumn,
    initialFilterState: [
      {
        id: 'slotValue',
        value: [],
      },
    ],
    initialGlobalFilterState,
  };

  const getGlobalFilterComponent = (setFilter: any) => {
    return <NameFilter setFilter={setFilter}></NameFilter>;
  };

  return {
    getColumns: () => decorationColumns,
    getMobileColumns: () => mobileDecorationColumns,
    getOptions: () => ({
      ...baseDecorationOptions,
      globalFilterResolver,
    }),
    getFilterComponents: (
      setFilter: any,
      setAllFilters: any,
      filterValues: any
    ) => <></>,
    getGlobalFilterComponent: (setFilter: any) =>
      getGlobalFilterComponent(setFilter),
  };
};
