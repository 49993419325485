import React, { useState } from 'react';
import { useFocusStore } from 'hooks/useFocusStore';
import { useLoadoutStore } from 'hooks/useLoadoutStore';
import shallow from 'zustand/shallow';

import { Button, Icon, Span, Modal } from 'ui/core';
import {
  GearControlsAndTitleContainer,
  GearTitle,
  GearRarity,
  IconContainer,
  LeftAlignedContainer,
  TitleAlignmentContainer,
} from './styled';
import { QurioWeapon } from 'components';

// TODO: cleanup models
// TODO: normalize rarity; change enum to numbers
import { ArmorType, Rarity } from 'models/enums';
import { WeaponType } from 'models/enums/WeaponType';
import { Weapon } from 'models/weapons';
import { Armor, isArmor } from 'models/Armor';
import _ from 'lodash';

interface GearControlsAndTitleProps {
  backButtonHandler?: any;
  cancelButtonHandler?: any;
  rarity: Rarity;
  gear: Weapon | Armor;
  gearType: ArmorType | WeaponType;
}

export const GearControlsAndTitle: React.FC<GearControlsAndTitleProps> = ({
  backButtonHandler,
  cancelButtonHandler,
  rarity,
  gear,
  gearType,
}) => {
  const [showQurioCrafting, setShowQurioCrafting] = useState(false);
  const { focusedGear, focusGear, unfocusGear } = useFocusStore(
    (state) => ({
      focusedGear: state.focusedGear,
      focusGear: state.focusGear,
      unfocusGear: state.unfocusGear,
    }),
    shallow
  );

  const { loadout } = useLoadoutStore(
    (state) => ({
      loadout: state.loadout,
    }),
    shallow
  );

  const { name } = gear;
  const displayRarity = isArmor(gear)
    ? `Rarity ${gear.rarity}`
    : `Rarity ${gear.rarity}`;

  const gearIsFocused = () => {
    return focusedGear && focusedGear.name === name;
  };

  const { availableSlots } = loadout.QurioWeaponConfig;

  const showWeaponQurioCraftingButton = !isArmor(gear) && gear.rarity === 10;

  return (
    <GearControlsAndTitleContainer>
      <Icon name={gearType}></Icon>
      <TitleAlignmentContainer>
        <GearTitle>{name}</GearTitle>
        {displayRarity ? (
          <GearRarity>{_.upperCase(displayRarity)}</GearRarity>
        ) : null}
        {showWeaponQurioCraftingButton && (
          <Span marginLeft="xSmall">
            <Button
              onClick={() => setShowQurioCrafting(true)}
              variant="primary"
            >
              QURIO {5 - availableSlots}/5
            </Button>
          </Span>
        )}
      </TitleAlignmentContainer>
      <LeftAlignedContainer>
        {backButtonHandler && (
          <IconContainer onClick={backButtonHandler}>
            <Icon name={'BackArrow'} height={20} width={20} />
          </IconContainer>
        )}
        {cancelButtonHandler && (
          <IconContainer onClick={cancelButtonHandler}>
            <Icon name={'X'} height={20} width={20} />
          </IconContainer>
        )}
      </LeftAlignedContainer>
      {showQurioCrafting && (
        <Modal
          isOpen={true}
          setIsOpen={setShowQurioCrafting}
          modalTitle="Qurio Crafting"
          modalDescription="Click on an augment to activate. Click on an activated augment to toggle off. (Bonuses will display on summary and weapon details.)"
        >
          <QurioWeapon onSave={setShowQurioCrafting} weapon={gear as Weapon} />
        </Modal>
      )}
    </GearControlsAndTitleContainer>
  );
};
