import React, { useEffect } from 'react';
import { useLoadoutStore } from 'hooks';
import shallow from 'zustand/shallow';

import { SwitchSkillsContainer, SwitchSlotAndSelectContainer } from './styled';
import { Container, Icon, Select, Span, Text, Tooltip } from 'ui/core';

import { WeaponType } from 'models/enums';
import _ from 'lodash';
import { switchSkillData } from './constants';

interface SwitchSkillsProps {
  weapon: WeaponType;
  interactive?: boolean;
}

export const SwitchSkills: React.FC<SwitchSkillsProps> = ({
  weapon,
  interactive = true,
}) => {
  const config = switchSkillData.find(
    (switchSkills) => switchSkills.weapon === weapon
  );
  const icon = <Icon name={weapon} height={20} width={20} />;

  const defaultSelections = config!.switchSkills.map((options) => options[0]);

  const { loadout, setSwitchSkills } = useLoadoutStore(
    (state) => ({
      loadout: state.loadout,
      setSwitchSkills: state.setSwitchSkills,
    }),
    shallow
  );
  const switchSkills = loadout.LoadoutSwitchSkills || defaultSelections;

  useEffect(() => {
    // set them for the first time
    if (loadout.LoadoutSwitchSkills === null) {
      setSwitchSkills(switchSkills);
    }
  }, []);

  const setCorrectSwitchSkill = (index: number, selectedOption: any) => {
    const newSwitchSkills = [...switchSkills];
    newSwitchSkills[index] = selectedOption;

    setSwitchSkills(newSwitchSkills);
  };

  return interactive ? (
    <SwitchSkillsContainer>
      {config!.switchSkills.map((options, index) => {
        const currentSwitchSkill = switchSkills[index];
        const setCorrectSwitchSkillWithIndex = _.partial(
          setCorrectSwitchSkill,
          index
        );
        return (
          <>
            <SwitchSlotAndSelectContainer key={`${index}_switchskills`}>
              {index + 1}
              <Select
                options={options}
                selectedOption={currentSwitchSkill}
                setSelectedOption={setCorrectSwitchSkillWithIndex}
                icon={icon}
                width="100%"
                marginX="tiny"
              ></Select>
              <Tooltip
                content={
                  <>
                    <Text>
                      <Span fontWeight="bold">Recovery:</Span>{' '}
                      {currentSwitchSkill.wirebugRecovery}
                    </Text>
                    <br />
                    {currentSwitchSkill.description}
                  </>
                }
              >
                <Container style={{ cursor: 'help' }}> ? </Container>
              </Tooltip>
            </SwitchSlotAndSelectContainer>
          </>
        );
      })}
    </SwitchSkillsContainer>
  ) : (
    <Container
      backgroundColor="detailsColor"
      border="1px solid"
      borderColor="detailsBorder"
      borderRadius="medium"
      variant="flexRow"
      width="100%"
      justifyContent="space-around"
    >
      {config!.switchSkills.map((options, index) => {
        const currentSwitchSkill = switchSkills[index];
        return <Span fontSize="tiny">{currentSwitchSkill.name}</Span>;
      })}
    </Container>
  );
};
