import React from 'react';

export default {
  viewBox: '0 0 498 541',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M108 92l-10 11v174l10 10h280l10-10V103l-10-11H108zm96-11l43-56 46 56h-89zm208 57l61 50-61 54V138zm-325 0v104l-62-54 62-50zm129 149v229h66V287h-66zm0 55l66-1v52h-66v-51zm0 97h66v-46h-66v46zm-15-301l-11 11 2 71 10 10h94l10-10v-71l-10-11h-95zm29 46h36-36zm-29-46l-11 11 2 71 10 10h94l10-10v-71l-10-11h-95z"
        />
      </g>
      <g id="lightColor">
        <path
          id="light"
          d="M108 92l-10 11v174l10 10h280l10-10V103l-10-11H108zm108 249h66v52h-66v-52zm0 98h66v-46h-66v46zm-15-301l-11 11 2 71 10 10h94l10-10v-71l-10-11h-95z"
        />
      </g>
      <g id="outline">
        <path
          id="line"
          fill="none"
          stroke="#000"
          strokeWidth="17.9"
          d="M108 92l-10 11v174l10 10h280l10-10V103l-10-11H108zm96-11l43-56 46 56h-89zm208 57l61 50-61 54V138zm-325 0v104l-62-54 62-50zm129 149v229h66V287h-66zm0 55l66-1v52h-66v-51zm0 97h66v-46h-66v46zm-15-301l-11 11 2 71 10 10h94l10-10v-71l-10-11h-95zm29 46h36-36z"
        />
      </g>
    </>
  ),
};
