import React, { useContext } from 'react';
import { DataContext } from 'context';
import { WeaponType, ArmorType, SlotValue } from 'models/enums';
import _ from 'lodash';

export const useDataContext = () => {
  const data = useContext(DataContext);

  const getWeaponData = (weaponType: WeaponType) => {
    const weaponByType = data['weapons'][weaponType];
    return _.cloneDeep(weaponByType);
  };

  // TODO: refactor into own getter
  const getArmorData = (armorType: ArmorType, hrOnly: boolean = false) => {
    const allArmorRarities = _.flatten(
      Object.values(data['armor']).filter((_, index) =>
        hrOnly ? index > 2 : true
      )
    );
    const armorByType = allArmorRarities.filter(
      (armor) => armor.armorType === armorType
    );
    return _.cloneDeep(armorByType);
  };

  const getArmorDataV2 = (armorType: ArmorType, rank: any) => {
    type rankType = 'low' | 'high' | 'master';
    const ranges = {
      low: [0, 3],
      high: [3, 7],
      master: [7, 10],
    };
    const [start, end] = ranges[rank as rankType];

    // console.log(Object.values(data['armor']).slice(start, end));
    const allArmorRarities = _.flatten(
      Object.values(data['armor']).slice(start, end)
    );
    const armorByType = allArmorRarities.filter(
      (armor) => armor.armorType === armorType
    );

    return _.cloneDeep(armorByType);
  };

  type ArmorRarity =
    | 'rarity1'
    | 'rarity2'
    | 'rarity3'
    | 'rarity4'
    | 'rarity5'
    | 'rarity6'
    | 'rarity7'
    | 'rarity8'
    | 'rarity9'
    | 'rarity10';

  const getArmorByRarity = (armorRarity: ArmorRarity) => {
    const armorByRarity = data['armor'][armorRarity];

    return _.cloneDeep(armorByRarity);
  };

  interface ArmorRarityConfig {
    rarity: ArmorRarity;
    selected: boolean;
  }
  const getArmorsFromConfig = (armorRarityConfigs: ArmorRarityConfig[]) => {
    const allArmors = armorRarityConfigs
      .filter((rarityConfig) => rarityConfig.selected)
      .map((rarityConfig) => getArmorByRarity(rarityConfig.rarity));

    return allArmors;
  };

  const getDecorationData = (slotValue: SlotValue) => {
    const decorationsBySlotValue = data['decorations'].filter(
      (decoration) => decoration.slotValue <= slotValue
    );
    return _.cloneDeep(decorationsBySlotValue);
  };

  const getRampageDecorationData = (slotValue: SlotValue) => {
    const rampageDecorationsBySlotValue = data['rampageDecorations'].filter(
      (decoration) => decoration.slotValue <= slotValue
    );
    return _.cloneDeep(rampageDecorationsBySlotValue);
  };

  const getAllDecorationData = () => {
    return _.cloneDeep(data['decorations']);
  };

  const getSkillData = () => {
    const skills = data['skills'];
    return _.cloneDeep(skills);
  };

  const getRampageSkillData = () => {
    const rampageSkills = data['rampageSkills'];
    return _.cloneDeep(rampageSkills);
  };

  return {
    data,
    getWeaponData,
    getArmorData,
    getDecorationData,
    getSkillData,
    getArmorByRarity,
    getArmorDataV2,
    getAllDecorationData,
    getArmorsFromConfig,
    getRampageDecorationData,
    getRampageSkillData,
  };
};
