import styled from 'styled-components';
import css from '@styled-system/css';
import { Container } from 'ui/core';

export const FocusedGearCardContainer = styled(Container)`
  ${css({
    border: 'black',
    minWidth: '250px',
    borderRadius: 'small',
    overflow: 'auto',
  })}
`;
