import React from 'react';

export default {
  viewBox: '0 0 568 584',
  paths: (
    <>
      <g id="baseColor">
        <path
          id="base"
          d="M228 394v102h22v47h91v-70h-25v-79h-88zm0-93h139v93H202v-45h26v-48zM367 67v234H228v-23h-49v-50h-51v-66h51V67h188zM179 95L40 94v264c12 38 36 66 72 83h64v-35a79 79 0 01-48-63v-42h100v-13h-49v-60h-51v-66h51V95zm188 24l49-1V40h112v188L416 349v-75h-49V119zm0 63h45v31h-45v-31z"
        />
      </g>
      <g id="lightColor">
        <path
          id="light"
          d="M228 301h139v93H202v-45h26v-48zm0 0h139-139zm-108 0v-72l59-1v73h-59zm0-138V91h68v72h-68zM416 40v279l48-52-15-11 48-49-48-46 48-47-48-36 43-38h-76z"
        />
      </g>
      <g id="mediumColor">
        <path
          id="medium"
          d="M228 301h139-139zM367 67v234H228v-23h-49v-50h-51v-66h51V67h188z"
        />
      </g>
      <g id="baseColor">
        <path d="M298 211a27 27 0 010 53 27 27 0 010-53zm0-100a27 27 0 010 54 27 27 0 010-54zm-28 195c0 18 13 32 29 32s28-14 28-32h-57z" />
      </g>
      <g id="darkColor">
        <path
          id="dark"
          d="M367 274v-61h49v61h-49zm0-89v-72h49v72h-49zM228 394v102h37V394h-37z"
        />
      </g>
      <g id="outline">
        <path d="M324 403h52V283h31v89l129-140V31H407v79h-31V58H170v28L31 85v275c13 41 38 71 77 90h77v-50l-5-2a71 71 0 01-43-56v-32h82v30h-26v63h26v102h22v47h109v-88h-26v-61zm-17 0h-26v79h-18v-79h-26v84h22v47h73v-53h-25v-78zM170 104l-121-1v253c11 35 32 60 65 77h53v-22a87 87 0 01-47-67l-1-1v-51h51v-55h-51v-84h51v-49zm67 206h121v75H211v-27h26v-48zm139-62v18h49v60l94-101V49h-94v78l-49 1v45h45v49h-45v26zm-188-29h-51v-48h51V76h170v216H237v-23h-49v-50zm188-28h27v13h-27v-13z" />
        <path d="M281 480v28h27v-28h-27zm-1-52l1 29 27-1v-29l-28 1zM298 202a36 36 0 100 71 36 36 0 000-71zm0 18c10 0 18 8 18 17 0 10-8 18-18 18s-18-8-18-18c0-9 8-17 18-17zM298 102a36 36 0 100 72 36 36 0 000-72zm0 18c10 0 18 8 18 18s-8 18-18 18-18-8-18-18 8-18 18-18zM270 297h-9v9c0 23 17 41 38 41 20 0 37-18 37-41v-9h-66zm10 18h37c-3 8-10 14-18 14-9 0-16-6-19-14z" />
      </g>
    </>
  ),
};
