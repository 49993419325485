import Affinity from './affinity';
import Attack from './attack';
import Back from './back';
import Base from './base';
import BackArrow from './backArrow';
import Blast from './blast';
import Chest from './chest';
import Defense from './defense';
import Down from './down';
import Dragon from './dragon';
import Element from './element';
import Exhaust from './exhaust';
import Feet from './feet';
import Fire from './fire';
import Forward from './forward';
import Gloves from './gloves';
import Head from './head';
import Health from './health';
import Help from './help';
import Ice from './ice';
import Kinsect from './kinsect';
import Overview from './overview';
import Para from './para';
import Petalace from './petalace';
import Phial from './phial';
import PlusCircle from './plusCircle';
import Poison from './poison';
import Sleep from './sleep';
import Shelling from './shell';
import Shuriken from './shuriken';
import Stamina from './stamina';
import Stun from './stun';
import Talisman from './talisman';
import Tick from './tick';
import Thunder from './thunder';
import Up from './up';
import Waist from './waist';
import Water from './water';
import X from './x';

import GreatSword from './greatsword';
import LongSword from './longsword';
import SwordAndShield from './swordandshield';
import DualBlades from './dualblades';
import Hammer from './hammer';
import HuntingHorn from './huntinghorn';
import Lance from './lance';
import Gunlance from './gunlance';
import SwitchAxe from './switchaxe';
import ChargeBlade from './chargeblade';
import InsectGlaive from './insectglaive';
import Bow from './bow';
import LightBowgun from './lightbowgun';
import HeavyBowgun from './heavybowgun';

import OneSlot from './oneslot';
import TwoSlot from './twoslot';
import ThreeSlot from './threeslot';

export default {
  Affinity,
  Attack,
  Back,
  Base,
  BackArrow,
  Blast,
  Chest,
  Defense,
  Dragon,
  Down,
  Element,
  Exhaust,
  Feet,
  Fire,
  Forward,
  Gloves,
  Head,
  Health,
  Help,
  Ice,
  Kinsect,
  Overview,
  Para,
  Petalace,
  Phial,
  PlusCircle,
  Poison,
  Sleep,
  Shelling,
  Shuriken,
  Stamina,
  Stun,
  Talisman,
  Tick,
  Thunder,
  Up,
  Waist,
  Water,
  X,
  GreatSword,
  LongSword,
  SwordAndShield,
  DualBlades,
  Hammer,
  HuntingHorn,
  Lance,
  Gunlance,
  SwitchAxe,
  ChargeBlade,
  InsectGlaive,
  Bow,
  LightBowgun,
  HeavyBowgun,
  OneSlot,
  TwoSlot,
  ThreeSlot,
} as const;
